import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { BiBug } from 'react-icons/bi'
import { FaClock } from 'react-icons/fa'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import TailSpin from 'react-loading-icons/dist/esm/components/tail-spin'
import { useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import queryKeys from 'tasksV2/api/queryKeys'
import { useGetTasksSearchBar } from 'tasksV2/api/useQueries'
import { TaskV2 } from 'tasksV2/constants/tasks'
import Association from 'tasksV2/tasks/components/Association'
import CreateTicket from 'tasksV2/tasks/components/CreateTicket'
import TaskType from 'tasksV2/tasks/components/TaskType'
import { getAssociationLink } from 'taxExemptions/helpers'
import { CustomerInit } from '../customers/customers.types'
import {
    WebsocketProvider,
    closePopout,
    closePopoutAdvanced,
    formatDateToLocale,
    parseResObject,
    sortByAlphanumeric,
    sortRelevantSearch,
    vFetch,
} from '../helpers'
import { OrderInit } from '../orders/orders.types'
import TaskPreviewNavBlock from '../tasks/components/TaskPreviewNavBlock'
import { orderOrDraftOrderId } from '../tasks/tasks.helpers'
import { TaskEditInit } from '../tasks/tasks.types'
import { taskVariables } from '../tasks/TaskVariables'
import Notifications from './Notifications'

type TopbarProps = {
    title: string
    currentStoreId: string
    setCurrentStoreId: any
}
export default function Topbar({ currentStoreId, setCurrentStoreId }: TopbarProps) {
    const user = useSelector((state: any) => state.user)
    const settings = useSelector((state: any) => state.settings)
    const navigate = useNavigate()
    const [quickModal, setQuickModal] = useState<undefined | string>()
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showResults, setShowResults] = useState<string>('orders')
    const [search, setSearch] = useState<string>('')
    const [orders, setOrders] = useState<OrderInit[]>([])
    const [draftOrders, setDraftOrders] = useState<OrderInit[]>([])
    const [ordersTasksMap, setOrdersTasksMap] = useState<any>({})
    const [draftOrdersTasksMap] = useState<any>({})
    const [customers, setCustomers] = useState<CustomerInit[]>([])
    const [storeIds, setStoreIds] = useState<any>([])
    const [showNotifications, setShowNotifications] = useState<boolean>(false)
    const [ordersLoading, setOrdersLoading] = useState(false)
    const [draftOrdersLoading, setDraftOrdersLoading] = useState(false)
    const [customersLoading, setCustomersLoading] = useState(false)
    const customersLimit = 250
    const queryClient = useQueryClient()

    const useDebounce = (value: any, delay: number) => {
        const [debouncedValue, setDebouncedValue] = useState(value)
        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value.toLowerCase())
            }, delay)
            return () => {
                clearTimeout(handler)
            }
        }, [value, delay])
        return debouncedValue
    }
    const debouncedSearch = useDebounce(search, 300)
    const abortController = new AbortController()
    useEffect(() => {
        const storeIdsArray = user.store_ids.split(',')
        setStoreIds(storeIdsArray)
    }, [user])

    const filters = {
        search: debouncedSearch,
        dateRange: undefined,
        overdue: undefined,
        limit: 2000,
    }

    const tasksQuery = useGetTasksSearchBar(filters, keepPreviousData)

    const { tasks: tasksV2 }: { tasks: TaskV2[] } = tasksQuery?.data || {}

    const filteredTasks =
        user.type === 'Admin' || user.type === 'SuperAdmin'
            ? tasksV2
            : tasksV2?.filter((task) => task.status !== 'Archived')

    useEffect(() => {
        if (debouncedSearch.length) {
            const orderSearch = debouncedSearch.replaceAll('#', '')
            const orderCustomerSearch = debouncedSearch.replaceAll('#', '')
            const draftOrderCustomerSearch = debouncedSearch.replaceAll('#', '')
            const customerSearch = debouncedSearch.replaceAll('#', '')
            setOrdersLoading(true)
            setDraftOrdersLoading(true)
            setDraftOrdersLoading(true)
            setCustomersLoading(true)

            setShowSearch(true)
            vFetch(
                `/v1/orders?fields=name,id,customer,email,created_at&search=${orderSearch}&customer_search=${encodeURIComponent(
                    orderCustomerSearch
                )}`,
                {
                    abortController,
                    cb: (res: any) => {
                        setOrdersLoading(false)
                        const orderSearchWeightSet = [
                            { property: 'name', points: 100 },
                            { property: 'draft_order_name', points: 10 },
                        ]
                        if (res.success) {
                            const resOrders = res.orders.map((order: any) => parseResObject(order))
                            resOrders.sort((a: any, b: any) =>
                                sortRelevantSearch(
                                    [`#${orderSearch}`, customerSearch],
                                    a,
                                    b,
                                    false,
                                    orderSearchWeightSet
                                )
                            )
                            setOrders(resOrders)
                        }
                    },
                }
            )
            vFetch(
                `/v1/draftOrders?fields=name,id,customer,email,created_at&search=${orderSearch}&customer_search=${encodeURIComponent(
                    draftOrderCustomerSearch
                )}`,
                {
                    abortController,
                    cb: (res: any) => {
                        setDraftOrdersLoading(false)
                        const orderSearchWeightSet = [
                            { property: 'name', points: 100 },
                            { property: 'draft_order_name', points: 10 },
                        ]
                        if (res.success) {
                            const resDraftOrders = res.draft_orders.map((draftOrder: any) => parseResObject(draftOrder))
                            resDraftOrders.sort((a: any, b: any) =>
                                sortRelevantSearch(
                                    [`#${orderSearch}`, customerSearch],
                                    a,
                                    b,
                                    false,
                                    orderSearchWeightSet
                                )
                            )
                            setDraftOrders(resDraftOrders)
                        }
                    },
                }
            )
            vFetch(`/v2/customers?search=${customerSearch}&limit=${customersLimit}`, {
                abortController,
                cb: (res: any) => {
                    setCustomersLoading(false)
                    if (res.success) {
                        const customerSearchWeightSet = [
                            { property: 'email', points: 100 },
                            { property: 'full_name', points: 25 },
                            { property: 'last_name', points: 50 },
                            { property: 'first_name', points: 10 },
                        ]
                        const resCustomers = res.customers.map((c: any) => parseResObject(c))
                        resCustomers.sort((a: any, b: any) =>
                            sortRelevantSearch(customerSearch, a, b, false, customerSearchWeightSet)
                        )
                        setCustomers(resCustomers)
                    }
                },
            })
        } else {
            setOrders([])
        }
        return () => abortController.abort()
    }, [currentStoreId, debouncedSearch])
    useEffect(() => {
        if (orders.length)
            vFetch(`/v1/tasks/search?order_name=${orders.map((o) => o.name.replaceAll('#', ''))}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resOrdersTasksMap: any = {}

                        res.tasks.forEach((t: any) => {
                            const resTask = parseResObject(t)
                            if (resTask.draft_order_name)
                                resOrdersTasksMap[resTask.draft_order_name]
                                    ? resOrdersTasksMap[resTask.draft_order_name].push(resTask)
                                    : (resOrdersTasksMap[resTask.draft_order_name] = [resTask])
                            if (resTask.order_name)
                                resOrdersTasksMap[resTask.order_name]
                                    ? resOrdersTasksMap[resTask.order_name].push(resTask)
                                    : (resOrdersTasksMap[resTask.order_name] = [resTask])
                        })
                        Object.keys(resOrdersTasksMap).forEach((o: any) =>
                            resOrdersTasksMap[o].sort((a: any, b: any) => sortByAlphanumeric(a, b, 'task_type'))
                        )
                        setOrdersTasksMap(resOrdersTasksMap)
                    }
                },
            })
    }, [currentStoreId, orders])

    const notificationsRef = useRef<any>()
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            const { target } = e
            closePopout(e, ['js-topbar__search', 'js-topbar__buttons'], setShowSearch)
            if (!notificationsRef.current.contains(target)) setShowNotifications(false)
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    const handleShowSearch = ({ target }: SyntheticEvent) => {
        setShowSearch(true)
        ;(target! as any).parentNode.parentNode.querySelector('input').focus()
    }
    const handleSignOut = () => {
        localStorage.setItem('session_token', '')
        window.location.reload()
    }

    function handleClickOrder(e: any, o: OrderInit) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (!closePopoutAdvanced(e, ['js-topbar__order'], ['js-topbar__order__task'])) navigate(`/orders/${o.id}`)
    }
    function handleClickDraftOrder(e: any, o: OrderInit) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (!closePopoutAdvanced(e, ['js-topbar__order'], ['js-topbar__order__task']))
            navigate(`/orders/draft-orders/${o.id}`)
    }
    function handleClickOrderTask(e: any, t?: any) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (!closePopout(e, ['js-topbar__order'])) navigate(orderOrDraftOrderId(t))
    }
    function handleClickCustomer(e: any, c: CustomerInit) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (!closePopout(e, ['js-topbar__customer__last-order-button'])) navigate(`/orders/${c.last_order_id}`)
        else navigate(`/customers?customer_id=${c.id}`)
    }
    const handleClickTask = (e: any, t: TaskEditInit) => {
        if (e.ctrlKey) return
        e.preventDefault()
        navigate(orderOrDraftOrderId(t))
    }
    function handleStoreChange(target: any) {
        setCurrentStoreId(target.value)
        localStorage.setItem('store_id', target.value)
        setOrders([])
        setOrdersTasksMap({})
        setShowNotifications(false)
        setShowSearch(false)
        setShowResults('orders')
        setSearch('')
        setCustomers([])
        navigate(`/home`)
    }

    const labelStyle = 'block font-bold text-[12px] uppercase'
    const buttonStyle =
        'text-left px-[8px] pr-[16px] py-[2px] font-semibold hover:text-black hover:bg-offwhite dark:hover:bg-accent/80 dark:hover:text-black border-[1px] border-darkgrey'
    const { taskTypeColors }: any = taskVariables

    return (
        <div className='text-black dark:text-offwhite w-full fixed top-0 right-0 px-[16px] py-[8px] flex justify-end items-center bg-white dark:bg-darkaccent z-50'>
            <div className='h-[32px] w-[32px] icon-box cursor-pointer' onClick={() => navigate('/home')}>
                <img
                    className='dark:brightness-0 w-full h-full object-contain'
                    src={`${settings.logo}`}
                    alt='logo'
                    width='50'
                    height='50'
                    loading='lazy'
                />
            </div>
            {user && storeIds.length > 1 && (
                <select
                    onChange={({ target }) => {
                        handleStoreChange(target)
                    }}
                    className='py-[2px] px-[4px] dark:bg-darkness rounded-[4px] max-w-[200px] ml-[16px] focus:outline-none capitalize'
                    value={currentStoreId}
                    name='type'
                    id='type'
                >
                    {storeIds &&
                        user.store_names &&
                        storeIds.map((storeId: any) => {
                            return <option key={storeId} value={`${storeId}`}>{`${user.store_names[storeId]}`}</option>
                        })}
                </select>
            )}
            {user && (
                <>
                    <div className='relative max-w-[640px] w-full mx-auto left-[-47px]'>
                        <div
                            className={`js-topbar__search w-full dark:border-none flex items-center border-b border-grey relative cursor-pointer transition-all ${
                                showSearch ? 'dark:border-accent' : ''
                            }`}
                            onClick={handleShowSearch}
                        >
                            <HiMagnifyingGlass
                                size={'30px'}
                                className={`w-[25px] h-[25px] left-[4px] absolute fill-darkgrey dark:fill-grey transition-all ${
                                    showSearch ? 'dark:!fill-accent' : ''
                                }`}
                            />
                            <input
                                className={`w-full overflow-hidden bg-transparent h-[35px] p-[8px] pl-[32px] focus:outline-none dark:rounded-t ${
                                    showSearch ? '' : 'dark:rounded-b'
                                } dark:text-offwhite dark:bg-darkness`}
                                type='text'
                                placeholder='Search'
                                name='main-search'
                                id='main-search'
                                value={search}
                                autoComplete='off'
                                onChange={({ target }) => setSearch(target.value)}
                            />
                        </div>
                        {showSearch && (
                            <div className='flex flex-col absolute top-[100%] left-0 z-[5] bg-white w-full shadow-small dark:bg-darkaccent rounded-b'>
                                <div className='js-topbar__buttons flex shadow-small [&>*:first-child]:border-l-[1px] [&>*]:border-l-0'>
                                    <button
                                        className={`relative grow basis-1 ${buttonStyle} ${
                                            showResults === 'orders'
                                                ? 'bg-blue dark:bg-accent text-white dark:text-black'
                                                : ''
                                        }`}
                                        onClick={() => setShowResults('orders')}
                                    >
                                        Orders
                                        <div className='grid absolute right-[8px] top-0 h-[100%] justify-center items-center z-50 pointer-events-none'>
                                            {ordersLoading && draftOrdersLoading ? (
                                                <TailSpin
                                                    className='w-[20px] h-[20px]'
                                                    stroke={showResults === 'orders' ? '#000000' : '#42EFD0'}
                                                />
                                            ) : (
                                                <div>
                                                    ({orders.length > 0 && orders.length % 100 === 0 && <>&ge;</>}
                                                    {orders.length})
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                    <button
                                        className={`relative grow basis-1 pr-6 ${buttonStyle} ${
                                            showResults === 'draftOrders'
                                                ? 'bg-blue dark:bg-accent text-white dark:text-black'
                                                : ''
                                        }`}
                                        onClick={() => setShowResults('draftOrders')}
                                    >
                                        Draft Orders
                                        <div className='grid absolute right-[8px] top-0 h-[100%] justify-center items-center z-50 pointer-events-none'>
                                            {draftOrdersLoading ? (
                                                <TailSpin
                                                    className='w-[20px] h-[20px]'
                                                    stroke={showResults === 'draftOrders' ? '#000000' : '#42EFD0'}
                                                />
                                            ) : (
                                                <div>({draftOrders.length})</div>
                                            )}
                                        </div>
                                    </button>
                                    <button
                                        className={`relative grow basis-1 ${buttonStyle} ${
                                            showResults === 'tasks'
                                                ? 'bg-blue dark:bg-accent text-white dark:text-black'
                                                : ''
                                        }`}
                                        onClick={() => setShowResults('tasks')}
                                    >
                                        Tasks
                                        <div className='grid absolute right-[8px] top-0 h-[100%] justify-center items-center z-50 pointer-events-none'>
                                            {tasksQuery.isLoading ? (
                                                <TailSpin
                                                    className='w-[20px] h-[20px]'
                                                    stroke={showResults === 'tasks' ? '#000000' : '#42EFD0'}
                                                />
                                            ) : (
                                                <div>({filteredTasks?.length ?? 0})</div>
                                            )}
                                        </div>
                                    </button>
                                    <button
                                        className={`relative grow basis-1 ${buttonStyle} ${
                                            showResults === 'customers'
                                                ? 'bg-blue dark:bg-accent text-white dark:text-black'
                                                : ''
                                        }`}
                                        onClick={() => setShowResults('customers')}
                                    >
                                        Customers
                                        <div className='grid absolute right-[8px] top-0 h-[100%] justify-center items-center z-50 pointer-events-none'>
                                            {customersLoading ? (
                                                <TailSpin
                                                    className='w-[20px] h-[20px]'
                                                    stroke={showResults === 'customers' ? '#000000' : '#42EFD0'}
                                                />
                                            ) : (
                                                <div>
                                                    ({customers.length === customersLimit && <>&ge;</>}
                                                    {customers.length})
                                                </div>
                                            )}
                                        </div>
                                    </button>
                                </div>
                                {showResults === 'orders' && (
                                    <div className='flex flex-col w-full max-h-[calc(90vh-51px)] overflow-y-auto overflow-x-hidden'>
                                        {orders.map((o) => (
                                            <NavLink
                                                key={o.id}
                                                className='js-topbar__order flex gap-[16px] justify-between w-full hover:bg-lightgrey dark:hover:bg-blue p-[16px] cursor-pointer'
                                                onClick={(e) => handleClickOrder(e, o)}
                                                to={`/orders/${o.id}`}
                                            >
                                                <a className='flex flex-col gap-[4px]'>
                                                    <div className='leading-[1] text-[15px] text-darkgrey dark:text-offwhite'>
                                                        <strong>Order {o.name}</strong> on{' '}
                                                        {formatDateToLocale((o as any).created_at)}
                                                    </div>
                                                    {o.customer && [
                                                        <p className='text-[14px] text-darkgrey leading-[1] dark:text-offwhite'>
                                                            Placed by {o.customer.first_name} {o.customer.last_name}
                                                        </p>,
                                                        <p className='text-[12px] text-darkgrey leading-[1] dark:text-offwhite'>
                                                            {o.customer.email}
                                                        </p>,
                                                    ]}
                                                </a>
                                                <div className='flex flex-wrap max-w-[500px] justify-between gap-[16px] [&>*]:max-w-[100px]'>
                                                    {ordersTasksMap[o.name]?.map((t: any) => (
                                                        <TaskPreviewNavBlock
                                                            task={t}
                                                            handleClick={handleClickOrderTask}
                                                        />
                                                    ))}
                                                </div>
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                                {showResults === 'draftOrders' && (
                                    <div className='flex flex-col w-full max-h-[calc(90vh-51px)] overflow-y-auto overflow-x-hidden'>
                                        {draftOrders.map((d) => (
                                            <NavLink
                                                key={'D' + d.id}
                                                className='js-topbar__order flex gap-[16px] justify-between w-full hover:bg-lightgrey dark:hover:bg-blue p-[16px] cursor-pointer'
                                                onClick={(e) => handleClickDraftOrder(e, d)}
                                                to={`/orders/draft-orders/${d.id}`}
                                            >
                                                <a className='flex flex-col gap-[4px]'>
                                                    <div className='leading-[1] text-[15px] text-darkgrey dark:text-offwhite'>
                                                        <strong>Order {d.name}</strong> on{' '}
                                                        {formatDateToLocale((d as any).created_at)}
                                                    </div>
                                                    {d.customer && [
                                                        <p className='text-[14px] text-darkgrey leading-[1] dark:text-offwhite'>
                                                            Placed by {d.customer.first_name} {d.customer.last_name}
                                                        </p>,
                                                        <p className='text-[12px] text-darkgrey leading-[1] dark:text-offwhite'>
                                                            {d.customer.email}
                                                        </p>,
                                                    ]}
                                                </a>
                                                <div className='flex flex-wrap max-w-[500px] justify-between gap-[16px] [&>*]:max-w-[100px]'>
                                                    {draftOrdersTasksMap[d.name]?.map((t: any) => (
                                                        <TaskPreviewNavBlock
                                                            task={t}
                                                            handleClick={handleClickOrderTask}
                                                        />
                                                    ))}
                                                </div>
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                                {showResults === 'tasks' && (
                                    <div className='flex flex-col w-full max-h-[calc(90vh-51px)] overflow-y-auto overflow-x-hidden'>
                                        {filteredTasks?.slice(0, 100).map((t) => (
                                            <div
                                                className='flex gap-[16px] justify-between p-[16px] w-full hover:bg-lightgrey dark:hover:bg-blue cursor-pointer'
                                                onClick={(event) => {
                                                    const associations = t.associations.filter(
                                                        (association: any) => association.resource_name !== 'user'
                                                    )
                                                    queryClient.setQueryData(
                                                        queryKeys.tasks.detail(t.id.toString()).queryKey,
                                                        {
                                                            success: true,
                                                            task: t,
                                                        }
                                                    )
                                                    if (event.button === 1 || event.ctrlKey || event.metaKey) {
                                                        return window.open(
                                                            t.prioritize_association_view &&
                                                                associations.length === 1 &&
                                                                t.status != 'Archived'
                                                                ? getAssociationLink(associations[0], user, queryClient)
                                                                : `/tasks/${t.id}`,
                                                            '_blank'
                                                        )
                                                    }
                                                    return navigate(
                                                        t.prioritize_association_view &&
                                                            associations.length === 1 &&
                                                            t.status != 'Archived'
                                                            ? getAssociationLink(associations[0], user, queryClient)
                                                            : `/tasks/${t.id}`
                                                    )
                                                }}
                                            >
                                                <div className='relative flex flex-col w-full gap-[4px]'>
                                                    <div className='flex gap-1 w-full'>
                                                        <div className='flex flex-col gap-1 w-[100px]'>
                                                            <p className=' font-bold leading-[1] text-[15px] text-darkgrey dark:text-offwhite whitespace-nowrap basis-[1]'>
                                                                Task {t.id}
                                                            </p>
                                                        </div>
                                                        <div className='flex gap-[4px] text-sm max-w-[350px] grow items-start'>
                                                            <label
                                                                className={`${
                                                                    taskTypeColors[t.department_title]
                                                                } ${labelStyle} cursor-pointer w-[20px]`}
                                                            >
                                                                {t.department_title
                                                                    .split(' ')
                                                                    .map((word: string) => word[0])
                                                                    .join('')}
                                                                :
                                                            </label>
                                                            <TaskType
                                                                taskId={t.id}
                                                                departmentId={t.department_id}
                                                                typeId={t.type_id}
                                                                editable={false}
                                                            />
                                                            <p className='text-[12px] truncate'>- {t.title}</p>
                                                        </div>
                                                        <div className='w-[100px]'>
                                                            <p className=' text-[12px] text-right justify-self-end truncate basis-[1]'>
                                                                {t.status}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className='flex gap-1'>
                                                        {t.associations
                                                            ?.filter(
                                                                (association: any) =>
                                                                    association.resource_name !== 'user'
                                                            )
                                                            ?.map((association: any) => (
                                                                <React.Fragment key={association.id}>
                                                                    <Association
                                                                        id={t.id}
                                                                        association={association}
                                                                        className={'flex gap-2'}
                                                                    ></Association>
                                                                </React.Fragment>
                                                            ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {showResults === 'customers' && (
                                    <div className='flex flex-col w-full max-h-[calc(90vh-51px)] overflow-x-hidden'>
                                        {customers.slice(0, 100).map((c) => (
                                            <NavLink
                                                className='flex gap-[16px] justify-between p-[16px] w-full hover:bg-lightgrey dark:hover:bg-blue cursor-pointer'
                                                onClick={(e) => handleClickCustomer(e, c)}
                                                to={`/customers?customer_id=${c.id}`}
                                            >
                                                <div className='flex flex-col gap-[4px]'>
                                                    <p className='font-bold leading-[1] text-[15px] text-darkgrey dark:text-offwhite'>
                                                        {c.first_name} {c.last_name}
                                                    </p>
                                                    <p className='text-[12px] text-darkgrey leading-[1] dark:text-offwhite'>
                                                        {c.email}
                                                    </p>
                                                </div>
                                                {c.last_order_id && (
                                                    <button
                                                        className={
                                                            buttonStyle +
                                                            ' dark:hover:!bg-accent js-topbar__customer__last-order-button'
                                                        }
                                                    >
                                                        Last Order
                                                    </button>
                                                )}
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className='flex gap-[16px] items-center'>
                        <WebsocketProvider>
                            <Notifications
                                notificationsRef={notificationsRef}
                                showNotifications={showNotifications}
                                setShowNotifications={setShowNotifications}
                            />
                        </WebsocketProvider>
                        <div className='relative group flex gap-[8px] items-center cursor-pointer'>
                            <div className='w-7 h-7 rounded-full bg-accent1 dark:bg-darkaccent1 grid place-items-center text-text2 dark:text-darktext2 text-[18px] font-bold'>
                                {user.firstName && `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`}
                            </div>
                            <div className='rounded-[4px] absolute top-[100%] right-[0] w-[100px] group-hover:block hover:block hidden overflow-hidden dark:text-darktext1 bg-bg1 dark:bg-darkbg1 shadow-small p-[16px]'>
                                <Link className='font-bold' to={'/account'}>
                                    Settings
                                </Link>
                                <button className='font-bold' onClick={handleSignOut}>
                                    Sign Out
                                </button>
                            </div>
                        </div>
                        <button
                            className='relative cursor-pointer'
                            onClick={() => setQuickModal(quickModal === 'ticket' ? undefined : 'ticket')}
                            aria-label='Submit a feature request!'
                        >
                            <BiBug className='fill-accent1 dark:fill-darkaccent1 h-[32px] w-[32px]' />
                        </button>
                        <Link to='/timesheets'>
                            <FaClock className='w-7 h-7 text-accent1 dark:text-darkaccent1' />
                        </Link>
                    </div>
                </>
            )}
            {quickModal === 'ticket' && <CreateTicket setQuickModal={setQuickModal} />}
        </div>
    )
}
