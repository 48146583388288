import { closestCenter, DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { Button } from 'custom_components/component_Basics/Button'
import { cn, looseIndexOf, sendToastWarning } from 'helpers'
import { ChangeEvent, Fragment, useState } from 'react'
import { Option } from './ProductGroupDetail'
import SortableOptionName from './SortableOptionName'

export default function ProductGroupDetailOptions({
    options,
    groupId,
    idPositions,
    updateOptions,
    handleOptionValuesCancel,
}: {
    options: Option[]
    groupId: number | string
    idPositions: number[]
    updateOptions: any
    handleOptionValuesCancel: any
}) {
    const [editableOptionNameFields, setEditableOptionNameFields] = useState<any>({})
    const [tempOptionNameCount, setTempOptionNameCount] = useState(0)
    const [tempOptions, setTempOptions] = useState<any>([])
    const [removedOptions, setRemovedOptions] = useState<any>([])
    const [tempIdPositions, setTempIdPositions] = useState<any>(idPositions ? idPositions : [])
    const originalPositions = idPositions ? idPositions : []

    const namesEdited =
        Object.keys(editableOptionNameFields).length > 0 ||
        removedOptions.length > 0 ||
        tempOptions.length > 0 ||
        originalPositions.join(',') != tempIdPositions.join(',')

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableOptionNameFields({
            ...editableOptionNameFields,
            [target.name]: target.value,
        })
    }
    const handleRemoveName = (e: any, optionId: string) => {
        // add to removal array
        const newRemovedOptions = structuredClone(removedOptions)
        newRemovedOptions.push(optionId)
        setRemovedOptions(newRemovedOptions)

        // remove from editableFields
        const newEditableFields = structuredClone(editableOptionNameFields)
        delete newEditableFields[optionId]
        setEditableOptionNameFields(newEditableFields)
    }
    const handleRemoveTempName = (e: any, optionId: string) => {
        // remove from tempOptions array
        const newTempOptions = structuredClone(tempOptions).filter(
            (tempOption: { option_id: string | number; option_name: string }) => tempOption.option_id != optionId
        )
        setTempOptions(newTempOptions)

        // remove from tempIdPositions array
        const newIdPositions = structuredClone(tempIdPositions).filter(
            (idPosition: number | string) => idPosition != optionId
        )
        setTempIdPositions(newIdPositions)

        // remove from editableFields
        const newEditableFields = structuredClone(editableOptionNameFields)
        delete newEditableFields[optionId]
        setEditableOptionNameFields(newEditableFields)
    }

    function handleNewTempOption(event: any): void {
        // increment new option counter used for temp Id
        setTempOptionNameCount((prev: number) => prev + 1)
        // create new tempOption
        const newTempOptions = structuredClone(tempOptions)
        newTempOptions.push({ option_id: 'tempOption' + (tempOptionNameCount + 1), option_name: '' })
        setTempOptions(newTempOptions)
        // add tempOption Id to position array
        const newIdPositions = structuredClone(tempIdPositions)
        newIdPositions.push('tempOption' + (tempOptionNameCount + 1))
        setTempIdPositions(newIdPositions)
    }

    // only options that have not been added to the removal array
    const validOptions = options?.filter((option) => !removedOptions.includes(option.option_id.toString()))
    // only idPositions that have not been added to the removal array
    const validIdPositions = tempIdPositions?.filter((id: number | string) => !removedOptions.includes(id.toString()))

    function handleOptionsCancel() {
        setEditableOptionNameFields({})
        setRemovedOptions([])
        setTempOptions([])
        setTempIdPositions(idPositions ? idPositions : [])
    }

    const handleSaveOptionNames = () => {
        const currentOptionIds = options?.map((option) => option.option_id.toString())
        const currentOptionNames = options?.map((option) => option.option_name.toLowerCase().trim())

        let foundDuplicate = false
        let foundEmpty = false

        // empty value tempOptions will not be saved so do not account for them in the position array
        const finalIdPositions = validIdPositions.filter((id: string | number) => {
            if (
                validOptions.map((option) => option.option_id.toString()).includes(id.toString()) ||
                Object.keys(editableOptionNameFields).includes(id.toString())
            ) {
                return id
            }
        })

        // filter editableFields for only edits to existing options
        let currentEditedOptionIds = Object.keys(editableOptionNameFields)
            .map((key: string) => {
                if (currentOptionIds?.length > 0 && currentOptionIds?.includes(key)) {
                    return key
                }
                return ''
            })
            .filter((v) => v)

        // add existing optionIds that didnt recieve an edit, but did change positions to the currentEditedOptionIds array
        options.forEach((option) => {
            // if option removed, skip
            if (removedOptions.includes(option.option_id.toString())) {
                return
            }
            // if position the same, skip
            if (looseIndexOf(finalIdPositions, option.option_id) == option.position) {
                return
            }
            // if already accounted for in edits, skip
            if (currentEditedOptionIds.includes(option.option_id.toString())) {
                return
            }
            // add to editedId array
            currentEditedOptionIds.push(option.option_id.toString())
        })

        const updates: any = []

        currentEditedOptionIds.forEach((id) => {
            const relatedEdit = editableOptionNameFields[id]
            const relatedOption = options.find((option) => option.option_id.toString() == id)
            const position = looseIndexOf(finalIdPositions, id)
            if (relatedEdit?.trim() == '') {
                foundEmpty = true
            }
            if (currentOptionNames.includes(relatedEdit?.trim())) {
                foundDuplicate = true
            }
            updates.push({ option_id: id, option_name: relatedEdit ?? relatedOption?.option_name, position })
        })

        const additions: ({ option_name: string; position: number } | undefined)[] = Object.entries(
            editableOptionNameFields
        )
            .map(([key, value]: any) => {
                const position = looseIndexOf(finalIdPositions, key)
                // if editableField is for existing option, return
                if (currentOptionIds && currentOptionIds.includes(key)) {
                    return
                }
                if (currentOptionNames.includes(value.trim())) {
                    foundDuplicate = true
                    return
                }
                return {
                    option_name: value.trim(),
                    position,
                }
            })
            .filter((v) => {
                if (v?.option_name && typeof v?.position == 'number') {
                    return v
                }
            })

        if (
            Array.from(new Set(additions.map((addition) => addition?.option_name))).length !=
            additions.map((addition) => addition?.option_name).length
        ) {
            foundDuplicate = true
        }

        const deletions = removedOptions
        if (foundEmpty) {
            return sendToastWarning({ message: 'Provide values or remove blank options' })
        }
        if (foundDuplicate) {
            return sendToastWarning({ message: 'Cannot contain duplicate option names' })
        }

        if (groupId) {
            updateOptions.mutate(
                { groupId, updates, additions, deletions },
                {
                    onSuccess: (data: any) => {
                        handleOptionsCancel()
                        setTempIdPositions(data.newPositions)
                        handleOptionValuesCancel()
                    },
                }
            )
        }
    }

    function handleDragEnd(event: any) {
        const { active, over } = event

        if (active.id !== over.id) {
            setTempIdPositions((ids: any) => {
                const oldIndex = ids.indexOf(active.id)
                const newIndex = ids.indexOf(over.id)

                return arrayMove(ids, oldIndex, newIndex)
            })
        }
    }

    const allOptions = [...validOptions, ...tempOptions]

    // drag and drop library needs object to possess explicit id key
    allOptions.forEach((option) => (option.id = option.option_id))

    return (
        <>
            <div className='w-1/3 relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Options
                </p>
                <div className='flex flex-col gap-1 dark:text-offwhite border-b pb-3 mb-3 dark:border-darkgrey'>
                    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext
                            items={validIdPositions.map((id: (number | string)[]) => {
                                return { id }
                            })}
                        >
                            {validIdPositions?.map((id: number | string) => {
                                return (
                                    <Fragment key={id}>
                                        <SortableOptionName
                                            id={id}
                                            allOptions={allOptions}
                                            handleChange={handleChange}
                                            handleRemoveName={handleRemoveName}
                                            handleRemoveTempName={handleRemoveTempName}
                                            editableOptionNameFields={editableOptionNameFields}
                                        />
                                    </Fragment>
                                )
                            })}
                        </SortableContext>
                    </DndContext>
                </div>
                <div className='flex gap-3 justify-between items-center'>
                    <Button
                        onClick={handleNewTempOption}
                        size={'sm'}
                        variant={'outline'}
                        className='dark:border-darkgrey'
                    >
                        Add option
                    </Button>
                    <div className={cn('flex gap-2 items-center', !namesEdited && 'pointer-events-none opacity-40')}>
                        <button
                            onClick={handleSaveOptionNames}
                            className={cn(
                                updateOptions.isPending && 'opacity-[0.8] pointer-events-none',
                                'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-accent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[110px]'
                            )}
                        >
                            {updateOptions.isPending ? 'SAVING...' : 'SAVE CHANGES'}
                        </button>
                        <button
                            onClick={handleOptionsCancel}
                            className={cn(
                                updateOptions.isPending && 'opacity-[0.6] pointer-events-none',
                                'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                            )}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
