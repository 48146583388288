import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getResourceTasks } from 'tasksV2/api/service'
import { SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES } from 'tasksV2/constants/tasks'

export default function CarefulCreateTaskButton({
    task,
    createTaskMutation,
    handleCreate,
}: {
    task: any
    createTaskMutation: any
    handleCreate: (sendWarning?: boolean) => void
}) {
    const [tasks, setTasks] = useState<any[]>([])
    const [showWarning, setShowWarning] = useState(false)
    const [showAreYouSure, setShowAreYouSure] = useState(false)

    useEffect(() => {
        const foundTasks = tasks?.filter((t: any) => task.department_id == t.department_id && task.type_id == t.type_id)
        if (foundTasks.length) {
            setShowWarning(true)
        } else {
            setShowWarning(false)
        }
    }, [task.type_id, task.department_id, tasks])

    useEffect(() => {
        setShowWarning(false)
        ;(async () => {
            const tempTasks = []
            for (const { resource_name, resource_id } of task.associations?.filter(
                (association: any) => association.resource_name !== 'user'
            )) {
                if (!SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES.includes(resource_name)) {
                    continue
                }
                const tasksResponse = await getResourceTasks({ resource_name, resource_id })
                tempTasks.push(...(tasksResponse?.tasks || []))
            }
            setTasks(tempTasks)
        })()
    }, [task.associations])
    return (
        <>
            <Button
                className={cn('mx-auto block mt-4 dark:border-darkgrey', createTaskMutation.isPending && 'opacity-70')}
                variant={'outline'}
                onClick={showWarning ? () => setShowAreYouSure(true) : () => handleCreate()}
            >
                {createTaskMutation.isPending ? 'Creating..' : 'Create Task'}
            </Button>
            {showAreYouSure && (
                <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'>
                    <div className='bg-bg1 dark:bg-darkbg1 relative p-6 rounded shadow-md max-w-[400px] items-center flex flex-col'>
                        <p className='font-semibold mb-2 text-center'>
                            <Link className='underline' to={`/tasks/${tasks[0].id}`}>
                                A task (#{tasks[0].id})
                            </Link>{' '}
                            with this department and type already exists, are you sure you want to create another?
                        </p>
                        <div className='flex gap-2'>
                            <Button
                                variant='outline'
                                size='sm'
                                onClick={() => {
                                    handleCreate(true)
                                    setShowAreYouSure(false)
                                    setShowWarning(false)
                                }}
                            >
                                Yes, create another task
                            </Button>
                            <Button variant='destructive' size='sm' onClick={() => setShowAreYouSure(false)}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
