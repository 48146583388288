import { Button } from 'custom_components/component_Basics/Button'
import { useState } from 'react'
import { FaRegCommentDots } from 'react-icons/fa'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { TbCircle, TbCircleFilled } from 'react-icons/tb'
import { useSelector } from 'react-redux'
import { DayMap, MonthMap, dayMap, formatDateToLocale, monthMap } from '../../helpers'
import PrivateEvent from '../../tasks/components/PrivateEvent'
import PrivateEventModal from '../../tasks/components/PrivateEventModal'
import { PrivateEventType, TaskEditInit } from '../../tasks/tasks.types'
import {
    FinancialStatusMap,
    FulfillmentStatusMap,
    LineItemInit,
    OrderInit,
    financialStatusMap,
    fulfillmentStatusMap,
} from '../orders.types'
import OrderActions from './OrderActions'
import CustomerV2 from './CustomerV2'
import EmailModal from 'emailModal/EmailModal'
import { ToggleSwitch } from 'custom_components/ToggleSwitch'
import { useNavigate } from 'react-router-dom'

type HeaderProps = {
    orderId: string
    order: OrderInit
    tasks: TaskEditInit[]
    actions?: any[]
    addNewTask: Function
    orderEventsPrivate: PrivateEventType[]
    showActivity: boolean
    setShowActivity: Function
    eventSource: any
    onEmailSubmit: Function
    origin: 'order' | 'draft_order'
}

export default function OrderHeader({
    addNewTask,
    order,
    orderEventsPrivate,
    showActivity,
    setShowActivity,
    eventSource,
    onEmailSubmit,
    origin = 'order',
}: HeaderProps) {
    const navigate = useNavigate()
    const user = useSelector<any, any>((state) => state.user)
    const [showOrderEventsPrivate, setShowOrderEventsPrivate] = useState(false)
    const [emailMode, setEmailMode] = useState<string | null>(null)

    function renderCreatedAt(order?: any) {
        const foundDate = order ? order.created_at : new Date()

        const shopifyAppNamesMap: any = {
            580111: 'FactoryPure.com',
            1775805: 'eBay.com',
            1456995: 'Walmart',
        }
        const createdAtMap: any = {
            shopify_draft_order: 'Draft Orders',
            web: shopifyAppNamesMap[order?.app_id ? order.app_id : 0],
            1456995: 'Walmart',
        }
        const month = monthMap[formatDateToLocale(foundDate).split(' ')[0] as keyof MonthMap]
        const dateYear = formatDateToLocale(foundDate).split(' ').slice(1, 3).join(' ')
        const time = formatDateToLocale(foundDate).split(' ').slice(3).join(' ')
        const from = order?.source_name ? `from ${createdAtMap[order.source_name] || order.source_name}` : ''
        return [month, dateYear, 'at', time, from].join(' ').trim()
    }

    const tagStyle =
        'relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full'
    const successColor = 'bg-[rgb(190,255,200)]'

    function CanceledLabel() {
        return (
            <div
                className={`${tagStyle} bg-[rgb(255,180,160)]`}
                title={`Reason: ${order.cancel_reason}\n${formatDateToLocale(order.cancelled_at as Date)}`}
            >
                <h2 className='inline-block font-semibold text-[14px] text-center'>Canceled</h2>
            </div>
        )
    }
    function FinancialStatusLabel() {
        return (
            <div
                className={`${tagStyle} ${
                    order.financial_status && financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].bg
                        : 'bg-fire'
                }`}
            >
                {order.financial_status &&
                financialStatusMap[order.financial_status as keyof FinancialStatusMap] &&
                financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol ? (
                    order.financial_status.toLowerCase() === 'paid' ? (
                        <TbCircleFilled />
                    ) : (
                        financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol
                    )
                ) : (
                    <TbCircle />
                )}
                <h2 className='inline-block font-semibold text-[14px] text-center'>
                    {order.financial_status && financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].status
                        : 'Unpaid'}
                </h2>
            </div>
        )
    }
    function FulfillmentStatusLabel() {
        return (
            <div
                className={`${tagStyle} ${
                    order.fulfillment_status &&
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].bg
                        : 'bg-fire'
                } relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full`}
            >
                {order.fulfillment_status &&
                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap] &&
                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol ? (
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol
                ) : (
                    <TbCircle />
                )}
                <h2 className='inline-block font-semibold text-[14px] text-center'>
                    {order.fulfillment_status &&
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].status
                        : 'Unfulfilled'}
                </h2>
            </div>
        )
    }
    function SubmittedLabel() {
        return (
            <div className={`${tagStyle} ${successColor}`} title='This order has timeline comments'>
                <FaRegCommentDots />
                <h2 className='inline-block font-semibold text-[14px] text-center truncate'>Submitted</h2>
            </div>
        )
    }

    function draftOrderCreatedOn(order: any) {
        return new Date(Date.parse(order))
            .toDateString()
            .split(' ')
            .map((dateItem: string) =>
                dayMap[dateItem as keyof DayMap]
                    ? `${dayMap[dateItem as keyof DayMap]}, `
                    : monthMap[dateItem as keyof MonthMap]
                      ? `${monthMap[dateItem as keyof MonthMap]}`
                      : Number(dateItem) < 32
                        ? `${dateItem},`
                        : dateItem
            )
            .join(' ')
    }
    function draftOrderCreatedTime(order: any) {
        return `${
            new Date(order).getHours() > 12
                ? new Date(order).getHours() % 12
                : new Date(order).getHours() === 0
                  ? 12
                  : new Date(order).getHours()
        }:${new Date(order).getMinutes() < 10 ? `0${new Date(order).getMinutes()}` : new Date(order).getMinutes()} ${
            new Date(order).getHours() >= 12 ? 'PM' : 'AM'
        }`
    }

    const unsubmittedQuantity =
        !order?.has_timeline_comment &&
        order?.line_items?.reduce((acc: number, cur: LineItemInit) => acc + cur.fulfillable_quantity, 0)

    function UnsubmittedItemsLabel() {
        const unsubmittedLineItems = order.line_items.filter((item) => item.fulfillable_quantity !== 0)
        const [showMessage, setShowMessage] = useState(false)

        return (
            <div className={`relative ${tagStyle} bg-fire`} onMouseOver={() => setShowMessage(true)}>
                <IoAlertCircleOutline className='' />
                <h2 className='inline-block font-semibold text-[14px] text-center truncate'>
                    {unsubmittedQuantity} Unsubmitted
                </h2>
                {showMessage && (
                    <div
                        className='absolute left-0 top-[0] p-[16px] text-black dark:text-offwhite z-index-2 opacity-0 hover:opacity-100 duration-300'
                        onMouseOut={() => setShowMessage(false)}
                    >
                        <div className='flex flex-col gap-[8px] p-[8px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey shadow-small '>
                            <p className='whitespace-nowrap'>
                                {unsubmittedQuantity} item{unsubmittedQuantity === 1 ? ' does' : 's do'} not have a
                                purchase order.
                            </p>
                            <div>
                                {unsubmittedLineItems.map((item) => (
                                    <p className='whitespace-nowrap'>
                                        ({item.fulfillable_quantity}) x {item.name}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='w-[100%]'>
            {orderEventsPrivate?.length > 0 && showOrderEventsPrivate && (
                <PrivateEventModal
                    origin='order'
                    setViewModal={setShowOrderEventsPrivate}
                    events={orderEventsPrivate}
                />
            )}
            <div className='overflow-auto w-[100%] text-text1 dark:text-darktext1'>
                <div className='justify-between grid grid-cols-[1fr_auto_1fr] md:max-lg:grid-cols-2 gap-y-2 min-w-[1000px]'>
                    <div className='flex  flex-col '>
                        <div className='flex items-center gap-[8px]'>
                            <h1 className='text-[24px]'>{order?.name || '#00000'}</h1>
                            {order?.id && (
                                <div className='flex gap-[8px] items-center'>
                                    {order.cancel_reason && !!order.cancelled_at && <CanceledLabel />}
                                    <FinancialStatusLabel />
                                    <FulfillmentStatusLabel />
                                    {!!order.has_timeline_comment && <SubmittedLabel />}
                                    {!!unsubmittedQuantity && <UnsubmittedItemsLabel />}
                                </div>
                            )}
                        </div>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div>
                                <PrivateEvent events={orderEventsPrivate} setViewModal={setShowOrderEventsPrivate} />
                            </div>
                        )}
                        {order?.id && <span className='font-light text-[14px]'>{renderCreatedAt(order)}</span>}
                        {!order?.id && <span className='font-light text-[14px]'>{renderCreatedAt()}</span>}
                    </div>
                    <CustomerV2 order={order} />
                    <div className='flex flex-col justify-between items-end gap-3 md:max-lg:col-start-2'>
                        <div className='flex gap-2 '>
                            <OrderActions actions={[]} addNewTask={addNewTask} order={order} origin={origin} />
                        </div>
                        <div className='flex justify-between gap-2 w-full'>
                            <div className='flex gap-2'>
                                {order?.customer?.email && (
                                    <Button
                                        variant={'outline'}
                                        size={'sm'}
                                        onClick={() => setEmailMode('customer')}
                                        className='border-blue'
                                    >
                                        Email Customer
                                    </Button>
                                )}
                                {order?.name && !order?.name.includes('D') && (
                                    <Button
                                        variant={'outline'}
                                        size={'sm'}
                                        onClick={() => setEmailMode('manufacturer')}
                                        className=' border-blue'
                                    >
                                        Email Manu
                                    </Button>
                                )}
                            </div>
                            <div className='flex flex-col gap-2 items-center'>
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                    className='flex gap-1'
                                    onClick={() => setShowActivity(!showActivity)}
                                >
                                    <p className='text-sm'>Events Hidden</p>
                                    <ToggleSwitch
                                        className=''
                                        checked={!showActivity}
                                        onClick={() => setShowActivity(!showActivity)}
                                    ></ToggleSwitch>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                {order?.order_id && origin === 'draft_order' && (
                    <div className='grid col-span-3 w-[100%] bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                        <div className='bg-[#007a5c] dark:bg-accent text-white rounded-t dark:text-black'>
                            Completed!
                        </div>
                        <div>
                            <span className='dark:text-offwhite'>
                                Order Created on {draftOrderCreatedOn(order?.completed_at)} at{' '}
                                {draftOrderCreatedTime(order?.completed_at)}
                            </span>
                            <button
                                className='px-[16px] py-[8px]  dark:hover:bg-darkaccent text-blue dark:text-accent hover:underline '
                                onContextMenu={() => {
                                    window.open(`/orders/${order?.order_id}`)
                                }}
                                onClick={() => {
                                    ;(window.event as MouseEvent).ctrlKey
                                        ? window.open(`/orders/${order?.order_id}`)
                                        : navigate(`/orders/${order?.order_id}`)
                                }}
                            >
                                View Order
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {order?.id && (
                <EmailModal
                    eventSource={eventSource}
                    mode={emailMode}
                    setMode={setEmailMode}
                    handleSubmitEmailEvent={onEmailSubmit}
                    customer={order?.customer}
                    order={order}
                />
            )}
        </div>
    )
}
