import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { UserInit } from 'users/users.types'
import { useGetAllUsers } from 'usersV2/api/useQueries'
import UserTable from './UserTable'

export default function UserList({ filters }: { filters: { roles: string[]; types: string[]; search: string } }) {
    // if filtering on back end pass filters to hook here
    // const usersQuery = useGetAllUsers(filters)
    const usersQuery = useGetAllUsers({})
    const users: any[] = usersQuery.data?.users || []

    // front end filtering
    const filteredUsers = users?.filter((user) => {
        const filterFuncs = [
            filters?.roles?.length ? filters.roles.some((v: string) => user.role_ids.includes(parseInt(v))) : true,
            filters?.types?.length ? filters.types.some((v: string) => user.type == v) : true,
            filters?.search
                ? (user.first_name.toLowerCase() + ' ' + user.last_name.toLowerCase()).includes(
                      filters.search.toLowerCase()
                  ) || user.email.split('@')[0].includes(filters.search.toLowerCase())
                : true,
        ].every((v: any) => v === true)
        if (!filterFuncs) {
            return 0
        }
        return 1
    })

    // react query result could be undefined so ternary with an empty array
    const data = filteredUsers?.length > 0 ? filteredUsers : []
    // memoize tableData to speed up rerenders
    // I dont think this one actually does much in this case
    const dataMemo = React.useMemo(() => data ?? [], [data])
    // initalize react-table column helper with Type of object you pass in
    const columnHelper = createColumnHelper<UserInit>()

    // Define columns
    // columnHelpers.accessor must refer to a key on Type UserInit passed above
    // column unrelated to key on object can be created by passing an normal object with correct options instead of using accessor function
    // cell can return any html/jsx
    // size, maxSize, minSize params that accept integer values
    // original user object accesible within cell by using info.row.original
    // e.stopPropagation() to skip onClick on the table itself.
    const columns = [
        // {
        //     header: 'I do not use the columnHelper Example',
        //     cell: (row: any) => {
        //         return (
        //             <button
        //                 onClick={(e) => {
        //                     e.stopPropagation()
        //                     // do something unrelated to navigation/navigate somewhere else
        //                 }}
        //             >
        //                 I am a button that shouldnt navigate when row is clicked
        //             </button>
        //         )
        //     },
        //     size: 200,
        // },
        columnHelper.accessor('id', {
            header: 'Id',
            cell: (info) => {
                // info.getValue() returns the value for the accessor key
                // info.getValue() === user.id
                // original row accesible with info.row.original
                return <p>{info.getValue()}</p>
            },
            maxSize: 60,
        }),
        columnHelper.accessor('email', {
            header: 'Email',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            minSize: 260,
        }),
        columnHelper.accessor('first_name', {
            header: 'First Name',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('last_name', {
            header: 'Last Name',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('type', {
            header: 'Type',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('roles', {
            header: 'Roles',
            cell: (info) => {
                return <p className='capitalize'>{info.getValue().join(', ')}</p>
            },
            minSize: 400,
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
    ]

    return (
        <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none h-full min-h-0'>
            <div className='w-full h-full min-h-0 rounded-md'>
                {dataMemo && (
                    <div className='relative w-full h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                        {/* Table exists in separate component that we pass memoized data into */}
                        <UserTable columns={columns} tableData={dataMemo} showHeaders={true} size={'auto'} />
                        {!usersQuery.isLoading && data?.length < 1 && (
                            <div className='pointer-events-none absolute top-0 left-0 flex justify-center items-center w-full h-full z-[60] text-black dark:text-offwhite'>
                                <div className='p-2 px-4 rounded-md border mt-3 '>No users</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
