import { createColumnHelper } from '@tanstack/react-table'
import RefreshButton from 'custom_components/RefreshButton'
import { cn } from 'helpers'
import Select from 'procurement/components/Select'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { migrationQueries } from './api/migrationQueryKeys'
import { useGetProcessList } from './api/useQueries'
import ProcessListTable from './ProcessListTable'
import { Button } from 'custom_components/component_Basics/Button'
import { formatDate } from 'procurement/helpers'
import { CheckIcon } from '@radix-ui/react-icons'

type ProcessListData = {
    Id: number
    User: string
    Host: string
    db: string
    Command: string
    Time: number
    State: string
    Info: string
}

export default function ProcessListTableContainer() {
    const user = useSelector<any, any>((state) => state.user)
    const [refetchInterval, setRefetchInterval] = useState(1000)
    const [queryTimeLength, setQueryTimeLength] = useState(0)
    const [queryEnabled, setQueryEnabled] = useState(true)
    const [truncateQueries, setTruncateQueries] = useState(false)
    const [queryInfoArray, setQueryInfoArray] = useState<{ id: number; timeStamp: Date; info: string; time: number }[]>(
        []
    )

    const refetchIntervalOptions: { [key: string]: string } = {
        '1000': '1 second',
        '5000': '5 seconds',
        '15000': '15 seconds',
        '30000': '30 seconds',
        '60000': '60 seconds',
    }
    const queryTimeOptions: { [key: string]: string } = {
        '0': 'All',
        '3': '3 seconds',
        '5': '5 seconds',
        '10': '10 seconds',
    }

    const processListQuery = useGetProcessList(refetchInterval, queryEnabled)

    const { processList: processListData, completionTime }: { processList: ProcessListData[]; completionTime: number } =
        processListQuery?.data || {}

    useEffect(() => {
        if (processListData?.length) {
            const newInfoArray: any = []
            for (let row of processListData) {
                if (row.Info && row.Info !== 'SHOW FULL PROCESSLIST' && row.Time >= queryTimeLength) {
                    newInfoArray.push({
                        id: row.Id,
                        timeStamp: new Date(),
                        size: row.Info.length,
                        info: truncateQueries ? row.Info.slice(0, 500) : row.Info,
                        time: row.Time,
                        completionTime,
                        state: row.State,
                    })
                }
            }
            setQueryInfoArray((prev: any) => {
                return [...prev, ...newInfoArray]
            })
        }
    }, [processListData])

    const tableData = processListData?.length > 0 ? processListData : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<ProcessListData>()

    const processListColumns = [
        columnHelper.accessor('Id', {
            enableGlobalFilter: false,
            header: 'Id',
            cell: (info) => <div className=''>{info.getValue()}</div>,
            size: 65,
        }),
        columnHelper.accessor('User', {
            size: 50,
            header: 'User',
            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{info.getValue()}</div>
            },
        }),

        columnHelper.accessor('Host', {
            size: 70,
            header: 'Host',
            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{info.getValue()}</div>
            },
        }),
        columnHelper.accessor('db', {
            size: 50,
            header: 'Db',
            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{info.getValue()}</div>
            },
        }),
        columnHelper.accessor('Command', {
            size: 90,
            header: 'Command',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('Time', {
            size: 80,
            header: 'Time',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('State', {
            header: 'State',
            cell: (info) => {
                return (
                    <div className='flex justify-between items-center gap-2 '>
                        <span className='font-semibold'>{info.getValue()}</span>
                    </div>
                )
            },
        }),
        columnHelper.accessor('Info', {
            size: 1400,
            header: 'Info',
            cell: (info) => {
                return (
                    <div className='flex justify-between items-center gap-2 '>
                        <span className='font-semibold'>
                            {info.getValue() ? info.getValue().slice(0, 1000) : info.getValue()}
                        </span>
                    </div>
                )
            },
        }),
    ]

    const columnCount = processListColumns?.length.toString() || 10

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex justify-between'>
                <div className='flex gap-4'>
                    <h1 className='text-[24px] tracking-[2px] font-[300]  uppercase'>Process List</h1>
                </div>
                <div className='flex gap-4 items-end'>
                    <div className='min-w-[250px] flex gap-2'>Fetch Time - {completionTime} seconds</div>
                    {queryEnabled && (
                        <Button
                            onClick={() => setQueryEnabled(false)}
                            className='border min-w-[100px] border-emerald-700'
                            size={'sm'}
                            variant={'outline'}
                        >
                            Active
                        </Button>
                    )}
                    {!queryEnabled && (
                        <Button
                            onClick={() => setQueryEnabled(true)}
                            className='border min-w-[100px] border-rose-600'
                            size={'sm'}
                            variant={'outline'}
                        >
                            Paused
                        </Button>
                    )}
                    <Select
                        className='dark:bg-darkbg1'
                        label='Refetch Interval'
                        id='refetch_interval'
                        name='refetch_interval'
                        onChange={(e) => {
                            setRefetchInterval(parseInt(e.target.value))
                        }}
                        value={refetchInterval.toString()}
                    >
                        {Object.entries(refetchIntervalOptions).map(([key, value]) => {
                            return <option value={key}>{value}</option>
                        })}
                    </Select>
                    <RefreshButton
                        queryKeys={[migrationQueries.migrations._def.toString()]}
                        isFetching={processListQuery.isFetching}
                    />
                </div>
            </div>
            <>
                <div className='w-full'>
                    {tableDataMemo && (
                        <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness '>
                            <ProcessListTable
                                columns={processListColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                            />
                        </div>
                    )}
                </div>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-end min-h-[46px]'>
                        <Select
                            className='dark:bg-darkbg1'
                            label='Log queries longer than'
                            id='query_time'
                            name='query_time'
                            onChange={(e) => {
                                setQueryTimeLength(parseInt(e.target.value))
                            }}
                            value={queryTimeLength.toString()}
                        >
                            {Object.entries(queryTimeOptions).map(([key, value]) => {
                                return <option value={key}>{value}</option>
                            })}
                        </Select>
                        <Button
                            className='flex gap-3'
                            onClick={() => setTruncateQueries((prev) => !prev)}
                            variant={'outline'}
                            size={'sm'}
                        >
                            <p>Truncate Massive Queries </p>
                            <div
                                className={cn(
                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                    truncateQueries === true
                                        ? 'bg-primary text-primary-foreground'
                                        : 'opacity-50 [&_svg]:invisible'
                                )}
                            >
                                <CheckIcon className={cn('h-4 w-4')} />
                            </div>
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => setQueryInfoArray([])} variant={'outline'} size={'sm'}>
                            Clear Logged Queries
                        </Button>
                    </div>
                </div>
            </>
            <div className='w-full min-h-[200px] max-h-[600px] overflow-scroll flex flex-col gap-1 border border-darkgrey rounded-md p-1'>
                <div className='grid grid-cols-[300px_130px_130px_130px_1fr] border-b border-b-darkgrey pb-1'>
                    <div className='flex flex-col gap-2'>
                        <p>Created</p>
                    </div>
                    <div>Time Elapsed</div>
                    <div>Query Length</div>
                    <div>State</div>
                    <div className='max-h-[400px] overflow-scroll'>Query</div>
                </div>
                {queryInfoArray.length > 0 &&
                    queryInfoArray.map((row: any, index: number) => {
                        return (
                            <div className='grid grid-cols-[300px_130px_130px_130px_1fr] border-b border-b-darkgrey pb-1'>
                                <div className='flex flex-col gap-2'>
                                    <p>Row {index + 1}</p>
                                    <p>{formatDate(row.timeStamp)}</p>
                                    <p>{row.timeStamp.toISOString().replace('T', ' ')}</p>
                                    <p>Fetch Time - {row.completionTime} seconds</p>
                                </div>
                                <div>{row.time}</div>
                                <div>{row.size}</div>
                                <div>{row.state}</div>
                                <div className='max-h-[400px] overflow-scroll'>{row.info}</div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
