import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import {
    ColumnResizeDirection,
    ColumnResizeMode,
    SortingState,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { cn } from 'helpers'
import React from 'react'

export default function ProcessListTable({ tableData, columns, columnCount }: any) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [columnResizeMode] = React.useState<ColumnResizeMode>('onEnd')

    const [columnResizeDirection] = React.useState<ColumnResizeDirection>('ltr')

    const table = useReactTable({
        columns,
        data: tableData,
        columnResizeMode,
        columnResizeDirection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }

    return (
        <div
            style={{ direction: table.options.columnResizeDirection }}
            className='overflow-x-auto bg-bg1 text-text1 dark:bg-darkbg1 dark:text-darktext1 w-full dark:border dark:border-darkgrey dark:rounded-md'
        >
            <table
                {...{
                    style: {
                        width: table.getCenterTotalSize(),
                    },
                }}
            >
                <thead className='bg-blue dark:bg-darkbg2 text-text2 '>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    {...{
                                        className: cn('group relative p-1'),
                                        key: header.id,
                                        colSpan: header.colSpan,
                                        style: {
                                            width: header.getSize(),
                                        },
                                    }}
                                >
                                    <div
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() === 'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() === 'desc'
                                                      ? 'Sort descending'
                                                      : 'Clear sort'
                                                : undefined
                                        }
                                        {...{
                                            className: cn(
                                                header.column.getCanSort()
                                                    ? 'cursor-pointer select-none flex gap-1 '
                                                    : 'flex gap-1'
                                            ),
                                            key: header.id,
                                            colSpan: header.colSpan,
                                            style: {
                                                width: header.getSize(),
                                            },
                                        }}
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretUpIcon />
                                                </div>
                                            ),
                                            desc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretDownIcon />
                                                </div>
                                            ),
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                    <div
                                        {...{
                                            onDoubleClick: () => header.column.resetSize(),
                                            onMouseDown: header.getResizeHandler(),
                                            onTouchStart: header.getResizeHandler(),
                                            className: `absolute top-0 h-full w-[5px] bg-blue dark:bg-darkbg2 group-hover:bg-accent2 cursor-col-resize right-0 ${
                                                table.options.columnResizeDirection
                                            } ${header.column.getIsResizing() ? 'isResizing' : ''}`,
                                            style: {
                                                transform:
                                                    columnResizeMode === 'onEnd' && header.column.getIsResizing()
                                                        ? `translateX(${
                                                              (table.options.columnResizeDirection === 'rtl' ? -1 : 1) *
                                                              (table.getState().columnSizingInfo.deltaOffset ?? 0)
                                                          }px)`
                                                        : '',
                                            },
                                        }}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id} className='border-b border-darkgrey'>
                            {row.getVisibleCells().map((cell) => (
                                <td
                                    {...{
                                        key: cell.id,
                                        style: {
                                            width: cell.column.getSize(),
                                        },
                                    }}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}
