import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { PurchaseOrderEditInit } from '../purchaseOrders.types'
import { closePopout, formatDateToLocale, formatMoney } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

type PurchaseOrdersListProps = {
    purchaseOrders: PurchaseOrderEditInit[]
    selected?: string[]
    setSelected?: Function
}

function renderEmailBody(id: number | string, body: string) {
    setTimeout(() => {
        const div = document.createElement('div')
        div.classList.add(
            'border-[1px]',
            'bg-[rgb(250,250,250)]',
            'dark:border-darkgrey',
            'dark:bg-darkness',
            'p-[8px]',
            'rounded',
            'js-purchase-orders__email'
        )
        div.innerHTML = body
            .replaceAll('<h1>', "<h1 class='text-[16px] font-semibold mb-[8px] js-purchase-orders__email'>")
            .replaceAll('<p>', "<p class='mt-[16px] js-purchase-orders__email'>")
        const parent = document.getElementById(`${id}-body`)

        if (parent && parent.childNodes.length === 0) {
            parent.appendChild(div)
        }
    }, 0)
}

export default function PurchaseOrdersList({
    purchaseOrders,
    selected,
    setSelected = () => {},
}: PurchaseOrdersListProps) {
    const navigate = useNavigate()
    const [iconMessage, setIconMessage] = useState<[string, string]>(['', ''])
    const [showLineItems, setShowLineItems] = useState(0)
    const [showEmails, setShowEmails] = useState(0)

    function handleNavigate(e: any, orderId: number) {
        if (e.ctrlKey) return
        e.preventDefault()
        closePopout(e, ['js-purchase-orders__line-items', 'js-purchase-orders__email', 'js-dont-navigate'], () =>
            navigate(`/orders/purchase-orders/${orderId}`)
        )
    }
    function handleCheck({ target }: any) {
        if (target.name === 'all' && target.checked) return setSelected(purchaseOrders.map((order) => String(order.id)))
        else if (target.name === 'all' && !target.checked) return setSelected([])

        if (target.id) {
            const checkbox = target.childNodes[0]
            if (checkbox.name === 'all' && !checkbox.checked) {
                checkbox.checked = true
                return setSelected(purchaseOrders.map((order) => String(order.id)))
            } else if (checkbox.name === 'all' && checkbox.checked) {
                checkbox.checked = false
                return setSelected([])
            } else if (checkbox.checked) {
                return setSelected(selected!.filter((selected) => selected !== checkbox.name))
            } else {
                return setSelected([...selected!, checkbox.name])
            }
        }

        if (target.checked) setSelected([...selected!, target.name])
        else setSelected(selected!.filter((selected) => selected !== target.name))
    }

    function resizeGrid() {
        const calcStyle: any = {}
        for (let i = 0; i <= 11; i++) {
            const contentBox = document.getElementsByClassName(`js-purchase-order__grid-content-${i}`)[0]
            if (contentBox)
                calcStyle[i] = { ...gridBoxStyle, minWidth: contentBox.getBoundingClientRect().width + 'px' }
            else calcStyle[i] = { ...gridBoxStyle, minWidth: 'auto' }
        }
        setCalculatedStyle(calcStyle)
    }

    useEffect(() => {
        const listenToWindow = (e: any) => {
            closePopout(e, ['js-purchase-orders__email'], setShowEmails, 0)
            closePopout(e, ['js-purchase-orders__line-items'], setShowLineItems, 0)
        }
        window.addEventListener('click', listenToWindow)
        window.addEventListener('resize', resizeGrid)
        return () => {
            window.removeEventListener('click', listenToWindow)
            window.removeEventListener('resize', resizeGrid)
        }
    }, [])

    const gridBoxStyle = { padding: `8px ${selected ? 24 : 8}px` }

    const [calculatedStyle, setCalculatedStyle] = useState<any>({})
    useEffect(() => resizeGrid(), [purchaseOrders])

    return (
        <div className='relative text-black dark:text-offwhite mb-10'>
            <div
                // There are 11 or 9 columns, but the last one is 1fr to take up the remaining space.
                style={{ gridTemplateColumns: `repeat(${selected ? 10 : 8}, auto) 1fr` }}
                className='grid justify-start relative text-[12px] font-bai font-extrabold border-[1px] border-darkgrey rounded-t cursor-pointer'
            >
                {/* Used for calculating a style. This entire thing is hidden from HIDDEN START */}
                <div className='contents [&>*]:!py-0 [&>*]:px-[16px]'>
                    {selected && [
                        <div
                            className='grid js-purchase-order__grid-content-1 opacity-0 h-0 items-center justify-center cursor-default js-dont-navigate'
                            id='all'
                        >
                            <input type='checkbox' name='all' />
                        </div>,

                        <div className='js-purchase-order__grid-content-2 opacity-0 h-0' style={gridBoxStyle}>
                            PO #
                        </div>,
                    ]}
                    <div className='js-purchase-order__grid-content-3 opacity-0 h-0' style={gridBoxStyle}></div>
                    <div className='js-purchase-order__grid-content-4 opacity-0 h-0' style={gridBoxStyle}>
                        Date
                    </div>
                    <div className='js-purchase-order__grid-content-5 opacity-0 h-0' style={gridBoxStyle}>
                        Supplier
                    </div>
                    <div className='js-purchase-order__grid-content-6 opacity-0 h-0' style={gridBoxStyle}>
                        Items
                    </div>
                    <div className='js-purchase-order__grid-content-7 opacity-0 h-0' style={gridBoxStyle}>
                        Status
                    </div>
                    <div className='js-purchase-order__grid-content-8 opacity-0 h-0' style={gridBoxStyle}>
                        Emails
                    </div>
                    <div className='js-purchase-order__grid-content-9 opacity-0 h-0' style={gridBoxStyle}>
                        Total
                    </div>
                    <div className='js-purchase-order__grid-content-10 opacity-0 h-0' style={gridBoxStyle}>
                        Opened
                    </div>
                    <div className='js-purchase-order__grid-content-11 grow opacity-0 h-0' style={gridBoxStyle}>
                        Clicked
                    </div>
                </div>
                {/* HIDDEN END */}

                <div
                    style={{ gridColumn: `1 / span 11` }}
                    className={`flex [&>*]:py-[8px] [&>*]:px-[16px] [&>*]:border-b-[1px] [&>*]:border-b-darkgrey !p-0 bg-blue text-white dark:text-offwhite dark:bg-darkness z-index-1`}
                >
                    {selected && [
                        <div
                            style={calculatedStyle[1]}
                            className='!p-[0px] grid items-center justify-center cursor-default js-dont-navigate h-[100%]'
                            onClick={handleCheck}
                            id='all'
                        >
                            <input type='checkbox' name='all' onChange={handleCheck} />
                        </div>,
                        <div style={calculatedStyle[2]}>PO #</div>,
                    ]}
                    <div style={calculatedStyle[3]}></div>
                    <div style={calculatedStyle[4]}>Date</div>
                    <div style={calculatedStyle[5]}>Supplier</div>
                    <div style={calculatedStyle[6]} className='text-center'>
                        Items
                    </div>
                    <div style={calculatedStyle[7]}>Status</div>
                    <div style={calculatedStyle[8]} className='text-center'>
                        Emails
                    </div>
                    <div style={calculatedStyle[9]}>Total</div>
                    <div style={calculatedStyle[10]}>Opened</div>
                    <div style={calculatedStyle[11]} className='w-full'>
                        Clicked
                    </div>
                </div>

                {purchaseOrders?.map((order, i) => (
                    <NavLink
                        className={`contents items-center justify-between text-[12px] font-bai cursor-pointer [&>*]:py-[8px] [&>*]:px-[16px] [&>*:first-child]:pl-[8px] [&>*:last-child]:pr-[8px] ${
                            i % 2 ? '[&>*]:bg-[rgb(224,228,235)] [&>*]:dark:bg-darkness' : '[&>*]:dark:bg-darkaccent'
                        } ${
                            order.email_timeline && !order.email_timeline[0].delivery_success
                                ? '[&>*]:!bg-[#8a0f0f] [&>*]:!text-[white] [&>*]:hover:!bg-[#CC0000]'
                                : '[&>*]:hover:!bg-[rgb(199,202,209)] [&>*]:dark:hover:!bg-blue'
                        }`}
                        onClick={(e: any) => handleNavigate(e, order.id)}
                        to={`/orders/purchase-orders/${order.id}`}
                    >
                        {selected && [
                            <div
                                className='grid items-center justify-center cursor-default js-dont-navigate h-[100%]'
                                onClick={handleCheck}
                                id={String(order.id)}
                            >
                                <input
                                    className='js-dont-navigate'
                                    type='checkbox'
                                    onChange={handleCheck}
                                    checked={selected.includes(String(order.id))}
                                    name={String(order.id)}
                                />
                            </div>,

                            <div style={gridBoxStyle} className='flex items-center'>
                                {order.order_name}
                            </div>,
                        ]}
                        <div
                            style={gridBoxStyle}
                            className='js-orders-icon-list relative flex items-center py-[8px] gap-[4px] h-[100%] w-[100%]'
                        >
                            {order.notes && (
                                <div
                                    className='flex items-center js-orders-icon'
                                    onMouseOver={() => {
                                        setIconMessage([order.order_name, order.notes || ''])
                                    }}
                                >
                                    <svg
                                        className='stroke-black dark:stroke-accent pointer-events-none'
                                        stroke='currentColor'
                                        fill='currentColor'
                                        strokeWidth='0'
                                        viewBox='0 0 24 24'
                                        height='1em'
                                        width='1em'
                                        xmlns='http://www.w3.org/2000/svg'
                                    >
                                        <path
                                            className='pointer-events-none'
                                            fill='none'
                                            strokeWidth='2'
                                            d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                        ></path>
                                    </svg>
                                </div>
                            )}
                            {iconMessage[0] === order.order_name && (
                                <div className='hidden js-orders-icon-message absolute hover:flex p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px]'>
                                    <div className='p-[4px] left-[0px] top-[0px] js-orders-icon  min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                        {iconMessage[1]}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center'>
                            {formatDateToLocale(order.created_at).split(' ').slice(0, 3).join(' ')}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center'>
                            {order.supplier}
                        </div>
                        <div
                            style={gridBoxStyle}
                            className='flex items-center justify-center relative h-[100%] w-[100%]'
                        >
                            {order.line_items.reduce((acc, cur) => acc + Number(cur.quantity), 0)}
                            <div
                                className='js-purchase-orders__line-items absolute flex w-[100%] h-[100%] hover:opacity-[1] opacity-0 bg-[rgba(0,0,0,0.1)] dark:bg-[rgba(0,0,0,0.2)] items-center justify-end'
                                onClick={() => setShowLineItems(showLineItems === order.id ? 0 : order.id)}
                            >
                                {showLineItems === order.id ? (
                                    <AiFillCaretUp className='pointer-events-none mr-[8px]' />
                                ) : (
                                    <AiFillCaretDown className='pointer-events-none mr-[8px]' />
                                )}
                            </div>
                            {showLineItems === order.id && (
                                <div className='[&>*]:pointer-events-none js-purchase-orders__line-items grid absolute top-[calc(100%-5px)] min-w-[300px] w-[25vw] right-[0] p-[8px] text-black bg-white shadow-small dark:text-offwhite dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey z-index-2 cursor-auto'>
                                    <span className='inline font-bold text-[16px] w-[auto] text-end'>Items</span>
                                    {order.line_items.map((item) => {
                                        return (
                                            <div key={`${order.id}-${item.id}`} className='flex gap-[8px]'>
                                                <div className='w-[100px] h-[100px] relative'>
                                                    <img
                                                        className='object-contain h-[100px] w-[100px]'
                                                        src={
                                                            window.IMAGE_MAP[`gid://shopify/Product/${item.product_id}`]
                                                        }
                                                        alt={item.name}
                                                        height='100px'
                                                        width='100px'
                                                    />
                                                    <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                        {item.quantity}
                                                    </span>
                                                </div>
                                                <div className='my-[12px]'>
                                                    <a
                                                        href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                        target='_blank'
                                                        className='js-dont-navigate font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                    >
                                                        {item.title}
                                                    </a>
                                                    <p className='pt-[4px]'>
                                                        <strong>SKU:</strong> {item.sku}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center'>
                            <span
                                className={`px-[8px] p-[2px] text-black font-semibold rounded-full bg-${
                                    order.status.toUpperCase() === 'ACTIVE' ? 'accent' : '[rgb(190,255,200)]'
                                }`}
                            >
                                {order.status}
                            </span>
                        </div>
                        <div
                            style={gridBoxStyle}
                            className='flex relative h-[100%] w-[100%] items-center justify-center'
                        >
                            <span className='justify-self-end '>{order.email_timeline.length}</span>
                            <div
                                className='js-purchase-orders__email absolute flex w-[100%] h-[100%] hover:opacity-[1] opacity-0 bg-[rgba(0,0,0,0.1)] dark:bg-[rgba(0,0,0,0.2)] items-center justify-end'
                                onClick={() => {
                                    showEmails !== order.id ? setShowEmails(order.id) : setShowEmails(0)
                                }}
                            >
                                {showEmails === order.id ? (
                                    <AiFillCaretUp className='pointer-events-none mr-[8px]' />
                                ) : (
                                    <AiFillCaretDown className='pointer-events-none mr-[8px]' />
                                )}
                            </div>
                            {showEmails === order.id && (
                                <div className='absolute top-[calc(100%-5px)] right-0 z-index-4 w-[30vw] min-w-[300px] min-h-[200px] cursor-auto js-emails-dropdown'>
                                    {order.email_timeline.map((email) => {
                                        return (
                                            <div
                                                key={order.id}
                                                className='bg-white dark:bg-darkaccent p-[16px] pb-[8px] border-[1px] dark:border-darkgrey w-[100%] min-h-[200px] js-emails-dropdown'
                                            >
                                                <div className='mb-[8px]'>
                                                    <h2 className='font-bold text-[20px] js-emails-dropdown'>
                                                        {email.subject}
                                                    </h2>
                                                    {formatDateToLocale(email.time_sent)}
                                                </div>
                                                <h3 className='font-semilight text-darkgrey dark:text-grey my-[8px] js-emails-dropdown'>
                                                    To: {String(email.recipient).split(',').join(', ')}
                                                </h3>
                                                <div id={`${email.time_sent.toISOString()}-body`}>
                                                    {renderEmailBody(email.time_sent.toISOString(), email.body) as any}
                                                </div>
                                                {email.delivery_success ? (
                                                    <div className='flex justify-center text-black font-semibold js-emails-dropdown'>
                                                        <span className='bg-[rgb(190,255,200)] p-[4px] px-[8px] rounded-full mt-[4px] js-emails-dropdown'>
                                                            Delivered
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className='flex justify-center text-white font-semibold js-emails-dropdown'>
                                                        <span className='bg-[#8a0f0f] p-[4px] px-[8px] rounded-full mt-[4px] js-emails-dropdown'>
                                                            Not Delivered
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center h-full w-full'>
                            {formatMoney(order.total)}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center h-full w-full'>
                            {order.opened ? 'Yes' : 'No'}
                        </div>
                        <div style={gridBoxStyle} className='flex items-center'>
                            {order.clicked ? 'Yes' : 'No'}
                        </div>
                    </NavLink>
                ))}
                {purchaseOrders?.length === 0 && (
                    <p className='font-bold text-center text-[16px] col-span-10'>No purchase orders found.</p>
                )}
            </div>
        </div>
    )
}
