import { useContext, useEffect, useRef, useState } from 'react'
import { BiSolidFactory } from 'react-icons/bi'
import { Button } from '../../../custom_components/component_Basics/Button'
import {
    useParentCompanyContactsQuery,
    useParentCompanyDetailTasksQuery,
    useParentCompanyEmailsQuery,
    useParentCompanyFilesQuery,
    useParentCompanyIncomingEmailsQuery,
    useParentCompanyNotesQuery,
    useParentCompanyQuery,
} from '../../api/useQueries'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import ParentCompanyQuickActions from '../ParentCompanyQuickActions'
import ParentActivityList from '../lists/ParentActivityList'
import ParentContactList from '../lists/ParentContactList'
import ParentEmailList from '../lists/ParentEmailList'
import ParentFileList from '../lists/ParentFileList'
import ParentNoteList from '../lists/ParentNoteList'
import ParentTaskList from '../lists/ParentTaskList'
import ParentQuickModal from './ParentQuickModal'

export default function ParentCompanyModal({ parentCompany }: { parentCompany: string }) {
    const { toggleModal, selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const modalRef = useRef<HTMLDivElement>(null)
    const [view, setView] = useState('activity')
    const [showQuickModal, setShowQuickModal] = useState<string | undefined>()

    const parentCompanyQuery = useParentCompanyQuery(parentCompany)
    const { isLoading: loadingParent } = parentCompanyQuery

    const companyNames = parentCompanyQuery?.data?.parentCompany?.company_names?.split(', ') || undefined
    const companyEmails = parentCompanyQuery?.data?.parentCompany?.company_emails?.split(', ') || undefined
    const companyIds = parentCompanyQuery?.data?.parentCompany?.company_ids?.split(', ') || undefined
    const companyNameMap = parentCompanyQuery?.data
        ? Object.fromEntries(companyNames?.map((cn: string, index: number) => [companyIds[index], cn]))
        : undefined

    const companyContactsQuery = useParentCompanyContactsQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingContacts } = companyContactsQuery
    const { contacts } = companyContactsQuery.data || {}

    const companyEventsQuery = useParentCompanyNotesQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingEvents } = companyEventsQuery

    const companyTasksQuery = useParentCompanyDetailTasksQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingTasks } = companyTasksQuery

    const companyIncomingEmailsQuery = useParentCompanyIncomingEmailsQuery({
        parentCompany,
        contactList: contacts || [],
        companyNameMap,
    })
    const { isLoading: loadingIncomingEmails } = companyIncomingEmailsQuery

    const companyEmailsQuery = useParentCompanyEmailsQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingEmails } = companyEmailsQuery

    const companyFilesQuery = useParentCompanyFilesQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingFiles } = companyFilesQuery

    const isLoading =
        loadingContacts ||
        loadingEvents ||
        loadingTasks ||
        loadingIncomingEmails ||
        loadingEmails ||
        loadingFiles ||
        loadingParent

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                toggleModal()
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <>
            {isLoading && <div>LOADING</div>}
            <div
                ref={modalRef}
                className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center company-modal'
            >
                <div className='p-[32px] gap-[16px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative w-[95%] h-[95%] flex'>
                    <button
                        onClick={() => toggleModal(undefined)}
                        className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                    >
                        &times;
                    </button>
                    <div className='pr-[32px] border-r border-lightgrey dark:border-darkgrey w-[300px] shrink-0'>
                        <div className='flex flex-col gap-2'>
                            <div className='flex gap-[8px] items-center'>
                                <BiSolidFactory className='text-blue dark:text-fire p-[4px] bg-lightgrey dark:bg-darkness w-[72px] h-[72px] object-contain rounded-full shrink-0' />
                                <div>
                                    <h2 className='text-darkgrey dark:text-offwhite text-[24px] font-bold uppercase leading-[1] mb-[4px]'>
                                        {parentCompany}
                                    </h2>
                                    <p className='text-[14px]'>Parent of {companyNames?.join(', ')}</p>
                                </div>
                            </div>
                            <ParentCompanyQuickActions setShowQuickModal={setShowQuickModal} />
                            <div className='pb-[8px] border-b border-lightgrey dark:border-darkgrey'>
                                <button
                                    className='font-bold uppercase text-[14px] leading-[1] py-[4px] px-[8px] w-full rounded-[4px] border border-blue text-blue dark:border-fire dark:text-fire'
                                    onClick={() => toggleModal('company', selectedCompany)}
                                >
                                    Return to {selectedCompany.name}
                                </button>
                            </div>

                            <div className='flex flex-col justify-between gap-[16px]'>
                                <Button
                                    onClick={() => setView('activity')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'activity'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Activity
                                </Button>
                                <Button
                                    onClick={() => setView('notes')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'notes'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Notes
                                </Button>
                                <Button
                                    onClick={() => setView('emails')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'emails'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Emails
                                </Button>
                                <Button
                                    onClick={() => setView('tasks')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'tasks'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Tasks
                                </Button>
                                <Button
                                    onClick={() => setView('contacts')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'contacts'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Contacts
                                </Button>
                                <Button
                                    onClick={() => setView('files')}
                                    variant={'outline'}
                                    className={`${
                                        view === 'files'
                                            ? '!bg-blue text-white dark:!bg-offwhite dark:!text-darkaccent'
                                            : ''
                                    } !font-bold'`}
                                >
                                    Files
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-[8px] overflow-auto'>
                        {view === 'activity' && (
                            <ParentActivityList
                                parentCompany={parentCompany}
                                companyNameMap={companyNameMap}
                                companyIds={companyIds}
                            />
                        )}
                        {view === 'notes' && (
                            <ParentNoteList
                                parentCompany={parentCompany}
                                companyNameMap={companyNameMap}
                                setShowQuickModal={setShowQuickModal}
                            />
                        )}
                        {view === 'emails' && (
                            <ParentEmailList
                                parentCompany={parentCompany}
                                companyNameMap={companyNameMap}
                                companyEmails={companyEmails}
                            />
                        )}
                        {view === 'tasks' && (
                            <ParentTaskList
                                parentCompany={parentCompany}
                                companyNameMap={companyNameMap}
                                companyIds={companyIds}
                            />
                        )}
                        {view === 'contacts' && (
                            <ParentContactList
                                parentCompany={parentCompany}
                                companyNameMap={companyNameMap}
                                setShowQuickModal={setShowQuickModal}
                            />
                        )}
                        {view === 'files' && (
                            <ParentFileList parentCompany={parentCompany} companyNameMap={companyNameMap} />
                        )}
                    </div>
                </div>
            </div>
            {showQuickModal && (
                <ParentQuickModal
                    company_ids={companyIds}
                    showQuickModal={showQuickModal}
                    setShowQuickModal={setShowQuickModal}
                />
            )}
        </>
    )
}

export type ParentCompanyType = {
    name: string
    company_names: string
    company_ids: string
}

export type ParentEventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
