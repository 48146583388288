import { useContext, useState } from 'react'
import { Button } from '../../../../custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from '../../../../custom_components/component_Basics/FilterBarSelectorButton'
import { capitalize, cn } from '../../../../helpers'
import { useCompanyProductsQuery, useCreateTaskModal } from '../../../api/useQueries'
import { PROCUREMENT_PRODUCT_LIST_FILTER_OPTIONS } from '../../../constants'
import { ProcurementContext, ProcurementContextType } from '../../../helpers'
import { CompanyProductType } from '../../CompanyProduct'

export default function ExportCsv({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [selectedFilterValues, setSelectedFilterValues] = useState<any>({
        statuses: [],
        exportType: ['all'],
        SKUs: [],
    })
    const [exportMargin, setExportMargin] = useState(true)
    const [exportProfit, setExportProfit] = useState(true)

    const createTask = useCreateTaskModal(setShowQuickModal)

    const { isPending } = createTask

    const selectedExportType = selectedFilterValues.exportType[0] || ''
    const exportDisabled =
        !selectedExportType ||
        (selectedExportType === 'statuses' && selectedFilterValues.statuses.length < 1) ||
        (selectedExportType === 'custom' && selectedFilterValues.SKUs.length < 1)
            ? true
            : false

    const allCompanyProductIdsQuery = useCompanyProductsQuery(
        {
            companyId: selectedCompany.id,
            limit: 10000,
        },
        {}
    )
    const {
        products: allProducts,
    }: {
        products: CompanyProductType[]
    } = allCompanyProductIdsQuery?.data || { products: [] }

    const allProductOptions = allProducts?.map((product: any) => {
        return {
            label: product.sku,
            value: product.sku,
            icon: undefined,
        }
    })
    const fileName = `${selectedCompany.name}_${selectedExportType}${
        selectedExportType === 'statuses' ? `_${selectedFilterValues.statuses.join('_')}_` : '_'
    }product_export`
    const filteredProducts = allProducts.filter((product) => {
        if (selectedExportType === 'all') {
            return product
        }
        if (selectedExportType === 'statuses') {
            return selectedFilterValues.statuses.includes(product.status)
        }
        if (selectedExportType === 'custom') {
            return selectedFilterValues.SKUs.includes(product.sku)
        }
    })

    const filterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        label: 'All Products',
                        value: 'all',
                        icon: undefined,
                    },
                    {
                        label: 'By Status',
                        value: 'statuses',
                        icon: undefined,
                    },
                    {
                        label: 'Custom Selection',
                        value: 'custom',
                        icon: undefined,
                    },
                ],
                title: 'Type',
                field: 'exportType',
                values: selectedFilterValues.exportType,
                searchToggle: false,
                selectType: 'single',
                preview: true,
            },
            {
                component: FilterBarSelectorButton,
                options: PROCUREMENT_PRODUCT_LIST_FILTER_OPTIONS,
                title: 'Status',
                field: 'statuses',
                values: selectedFilterValues.statuses,
                searchToggle: false,
                selectType: 'multi',
                align: 'center',
                preview: true,
                hidden:
                    !selectedFilterValues.exportType.includes('statuses') || selectedFilterValues.exportType.length < 1
                        ? true
                        : false,
            },
            {
                component: FilterBarSelectorButton,
                options: allProductOptions,
                title: 'Sku',
                field: 'SKUs',
                values: selectedFilterValues.SKUs,
                searchToggle: true,
                selectType: 'multi',
                preview: true,
                clearFilters: true,
                align: 'center',
                hidden:
                    !selectedFilterValues.exportType.includes('custom') || selectedFilterValues.exportType.length < 1
                        ? true
                        : false,
            },
        ],
        setFunction: setSelectedFilterValues,
        resetFunction: () => {
            setSelectedFilterValues({
                statuses: [],
            })
        },
    }

    function exportToCsv(filename: string, products: any) {
        const exportProducts = products.map((product: any) => {
            const profit = (product.list_price - product.cost - product.shipping_fee).toFixed(2)
            const margin = (
                ((product.list_price - product.cost - product.shipping_fee) / product.list_price) *
                100
            ).toFixed(2)
            const weblinks = product?.weblinks
                ? Array.isArray(product.weblinks)
                    ? product.weblinks
                    : Array.isArray(JSON.parse(product.weblinks))
                      ? JSON.parse(product.weblinks)
                      : undefined
                : undefined
            const manufacturer_website = weblinks?.find((weblink: any) => weblink?.title === 'Manufacturer Website')
                ?.link
            return {
                sku: product?.sku,
                supplier_sku: product?.supplier_sku,
                upc: product?.upc,
                model_number: product?.model_number,
                product_type: product?.product_type,
                manufacturer_website,
                cost: product?.cost,
                shipping_fee: product?.shipping_fee,
                list_price: product?.list_price,
                profit: exportProfit ? profit : undefined,
                margin: exportMargin ? margin : undefined,
                lowest_competitor_price: product?.lowest_competitor_price,
                lowest_competitor_link: product?.lowest_competitor_link,
                notes: product?.notes,
                shipping_dimensions: product?.shipping_dimensions,
                shipping_weight: product?.shipping_weight,
                shipping_method: product?.shipping_method,
            }
        })
        if (exportProducts.length === 0) {
            alert('Current selection has no products to export!')
            return
        }
        const rows = [
            Object.keys(exportProducts[0]).map((key) => {
                const formattedKey = key
                    .replaceAll('_', ' ')
                    .split(' ')
                    .map((string) => {
                        return (string = capitalize(string))
                    })
                    .join(' ')
                if (formattedKey === 'Sku') {
                    return 'Shopify SKU'
                }
                if (formattedKey === 'Supplier Sku') {
                    return 'Supplier SKU'
                }
                if (formattedKey === 'Upc') {
                    return 'UPC'
                }
                if (formattedKey === 'Manufacturer Website') {
                    return 'Manufacturer Weblink'
                }
                if (formattedKey === 'Lowest Competitor Price') {
                    return 'Lowest Price'
                }
                if (formattedKey === 'Lowest Competitor Link') {
                    return 'Lowest Price Link'
                }
                return formattedKey
            }),
            ...exportProducts.map((product: any) => {
                return Object.values(product).map((value: any) => value?.toString() || '')
            }),
        ]
        const processRow = function (row: any) {
            var finalVal = ''
            for (var j = 0; j < row.length; j++) {
                var innerValue = row[j] === null ? '' : row[j].toString()
                if (row[j] instanceof Date) {
                    innerValue = row[j].toLocaleString()
                }
                var result = innerValue.replace(/"/g, '""')
                if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
                if (j > 0) finalVal += ','
                finalVal += result
            }
            return finalVal + '\n'
        }

        let csvFile = ''
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i])
        }

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
        var link = document.createElement('a')
        if (link.download !== undefined) {
            // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
        setShowQuickModal(undefined)
    }
    return (
        <div className={cn(createTask.isPending && 'opacity-60', '')}>
            <div className='flex justify-between flex-col w-full min-h-[240px]'>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>Export Products</h3>
                </div>
                <div className='p-[16px] h-fit flex flex-col gap-[8px]'>
                    <div className='flex flex-col gap-3 w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Export Selector
                        </label>
                        <div className='flex flex-col items-center gap-[8px]'>
                            {filterBarObject.params.length &&
                                filterBarObject.params.map((param: any) => {
                                    if (param.hidden) {
                                        return
                                    }
                                    return (
                                        <param.component
                                            align={param.align}
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={filterBarObject.setFunction}
                                            selectType={param.selectType}
                                            variant={'select'}
                                            clearFilters={param.clearFilters || false}
                                            preview={param.preview}
                                        />
                                    )
                                })}
                        </div>
                        <div className='flex items-center gap-[4px]'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                export profit
                            </label>
                            <input
                                className='h-[16px] w-[16px]'
                                name='export_profit'
                                id='export_profit'
                                type='checkbox'
                                checked={exportProfit === true}
                                onChange={() => setExportProfit(exportProfit ? false : true)}
                            />
                        </div>
                        <div className='flex items-center gap-[4px]'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                export margin
                            </label>
                            <input
                                className='h-[16px] w-[16px]'
                                name='export_margin'
                                id='export_margin'
                                type='checkbox'
                                checked={exportMargin === true}
                                onChange={() => setExportMargin(exportMargin ? false : true)}
                            />
                        </div>
                    </div>
                </div>
                <div className='p-[16px] mt-auto w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <Button
                        variant={'outline'}
                        onClick={() => exportToCsv(fileName, filteredProducts)}
                        disabled={isPending || exportDisabled}
                    >
                        <p>Export CSV</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}
