import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { MdChat } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import UsersName from './UsersName'

export default function YourQuestionCard({
    question,
    setSelectedQuestion,
}: {
    question: any
    setSelectedQuestion: any
}) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const handleSetQuestion = () => {
        setSelectedQuestion(question)
        setSearchParams((prev: any) => {
            prev.set('questionID', question.id)
            return prev
        })
    }
    return (
        <div>
            <div className='border rounded border-lightgrey dark:border-none  shadow-md dark:shadow-darkbg2 hover:shadow-lg mb-4'>
                <div className='pt-2 pl-2'>
                    <div className='flex justify-between'>
                        <div className='flex'>
                            <div className='ml-2'>
                                <div>
                                    {' '}
                                    {/* the questions in question */}
                                    <button
                                        onClick={handleSetQuestion}
                                        className='font-bold text-lg text-left line-clamp-1'
                                    >
                                        {question.question}
                                    </button>
                                    <div className='mb-2'>
                                        <UsersName questionRow={question} verb={'asked'} textSize={'text-sm'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col mt-1'>
                            <div className='flex items-center gap-2 mt-0 pr-2 font-bold mr-2'>
                                <div className='flex flex-col gap-1 items-center'>
                                    <MdChat className='h-[24px] w-[24px] text-darkgrey dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {question.total_answers_per_question}
                                    </span>
                                </div>
                                {question.is_liked ? (
                                    <div className='flex flex-col gap-1 items-center'>
                                        <FaHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                        <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                            {question.total_question_likes}
                                        </span>
                                    </div>
                                ) : (
                                    <div className='flex flex-col gap-1 items-center'>
                                        <FaRegHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                        <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                            {question.total_question_likes}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
