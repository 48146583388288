import { Button } from 'custom_components/component_Basics/Button'
import { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import DraftOrderLineItemAssociationBuilder from '../components/DraftOrderLineItemAssociationBuilder'

export default function DraftOrderLineItemLinkModal({
    draftOrderIds,
    closeModal,
    associations,
    toggleAssociation,
    handleSave,
    handleReset,
}: {
    draftOrderIds: string[]
    closeModal: () => void
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
    handleSave: any
    handleReset: any
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                handleReset()
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative rounded p-6 bg-bg1 dark:bg-darkbg1 min-w-[400px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>

                <DraftOrderLineItemAssociationBuilder
                    associations={associations}
                    toggleAssociation={toggleAssociation}
                    draftOrderIds={draftOrderIds}
                />

                <div className='flex gap-4 items-center justify-center mx-auto mt-2 '>
                    <Button
                        variant={'outline'}
                        className='dark:border-darkgrey'
                        onClick={() => {
                            handleSave()
                            closeModal()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant={'outline'}
                        className='dark:border-darkgrey'
                        onClick={() => {
                            handleReset()
                        }}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    )
}
