import { useSearchParams } from 'react-router-dom'

export default function SearchResultsArticle({
    article,
    setSelectedArticle,
    setView,
    setSearch,
}: {
    article: any
    setSelectedArticle: any
    setView: any
    setSearch: any
}) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const handleArticleSelection = () => {
        setSelectedArticle(article)
        setView('articleView')
        setSearch('')
        setSearchParams((prev: any) => {
            prev.set('articleID', article.id)
            return prev
        })
    }
    return (
        <div className='mb-2 border rounded shadow-md p-2'>
            <div className='flex gap-2'>
                <div className='shrink-0 w-[50px] h-[50px]'>
                    <img src={article.featured_image} className='object-cover h-full w-full rounded-full' />
                </div>
                <div>
                    <div className='flex gap-1'>
                        <button className='font-semibold text-lg' onClick={handleArticleSelection}>
                            {article.title}
                        </button>
                        <div className='opacity-50 text-lg'>/{article.department_label} Article</div>
                    </div>
                    <div className='line-clamp-1'>{article.excerpt}</div>
                </div>
            </div>
        </div>
    )
}
