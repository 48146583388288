import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import RefreshButton from 'custom_components/RefreshButton'
import { InventoryEmailType } from 'edi/EDIScreens.types'
import { cn, LoadingGear } from 'helpers'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useFilesQuery, useGetInventoryEmailsQuery, useUpdateEmailStatus } from 'productSetupV2/api/useQueries'
import { useEffect, useState } from 'react'
import { BsClockHistory } from 'react-icons/bs'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdOutlineCancel } from 'react-icons/md'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import ExternalEmailMapSetupV2 from './ExternalEmailMapSetupV2'
import FailedItemsList from './FailedItemsList'
import InternalEmailMapSetupV2 from './InternalEmailMapSetupV2'
import SuccessfulItemsList from './SuccessfulItemsList'

export default function InventoryEmailDetail({}: {}) {
    const queryClient = useQueryClient()
    const user = useSelector((state: any) => state.user)
    const { emailId } = useParams()
    const navigate = useNavigate()
    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname
    const [selectedFileIndex, setSelectedFileIndex] = useState(0)

    const allowedUserIds = [1, 4, 9, 12, 26, 10, 20, 34]
    useEffect(() => {
        if (!allowedUserIds.includes(user.id)) {
            navigate(`/`)
        }
    }, [])

    const inventoryEmailsQuery = useGetInventoryEmailsQuery({ emailIdParam: emailId })
    const {
        inventoryEmails,
        companyInfo,
        inventoryEmailMaps: inventoryEmailMapsInfo,
        files,
    }: {
        inventoryEmails: InventoryEmailType[]
        companyInfo: any
        inventoryEmailMaps: any
        files: any
    } = inventoryEmailsQuery?.data || {}
    const email: any = inventoryEmails?.length > 0 ? inventoryEmails[0] : {}

    const filesQuery = useFilesQuery(files)

    const { files: completeFiles }: { files: any } = filesQuery?.data || {}

    const emailFound = Object.keys(email).length > 0

    const sentFromFP = email?.sent_from?.split('@').includes('factorypure.com')

    const updateEmailStatus = useUpdateEmailStatus()

    const handleSetCancelled = (status: string) => {
        if (emailId) {
            updateEmailStatus.mutate(
                { emailId, status },
                {
                    onSettled: () => {
                        return queryClient.invalidateQueries({
                            queryKey: productSetupQueries.productSetup.inventory._def,
                        })
                    },
                }
            )
        }
    }
    return (
        <div>
            <div className='flex justify-between mb-[8px]'>
                <div className='flex gap-2'>
                    <span
                        onClick={() => {
                            if (previousLocationPathname?.includes('inventory-emails')) {
                                navigate(-1)
                                return
                            }
                            navigate('/products/inventory-emails')
                        }}
                        className='self-center cursor-pointer'
                    >
                        <RiArrowGoBackLine />
                    </span>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'> Email: {emailId}</h1>
                </div>
                <div className='flex gap-[8px]'>
                    <RefreshButton
                        queryKeys={[productSetupQueries.productSetup.inventory._def.toString()]}
                        isFetching={inventoryEmailsQuery.isFetching}
                    />
                </div>
            </div>
            {inventoryEmailsQuery.isLoading && (
                <div className='w-full h-[55vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                    <LoadingGear />
                </div>
            )}

            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'></div>
                {!inventoryEmailsQuery.isLoading && !emailFound && (
                    <>
                        <div className='w-full'>
                            <div>
                                <div className='p-5 relative flex flex-col gap-[16px] h-[90%] w-full'>
                                    <div className='flex gap-4 items-center'>
                                        <div>No email found</div>
                                        <Link
                                            draggable={false}
                                            to={`/products/inventory-emails`}
                                            className={cn('flex gap-1 items-center')}
                                        >
                                            <Button
                                                variant={'outline'}
                                                size={'sm'}
                                                className='self-center cursor-pointer flex gap-2'
                                            >
                                                <RiArrowGoBackLine />
                                                <p>back</p>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {!inventoryEmailsQuery.isLoading && emailFound && (
                    <>
                        <div className='w-full'>
                            <div>
                                <div className='p-5 relative flex flex-col gap-[16px] h-[90%] w-full'>
                                    <div className='flex justify-between'>
                                        <div className='flex gap-6'>
                                            <div className='flex flex-col gap-2'>
                                                <p className='text-xl font-semibold'>{email.subject}</p>
                                                <p className='text-sm'>{email.sent_from}</p>
                                            </div>
                                            <div className='flex gap-2 h-fit'>
                                                <div className='flex items-center'>
                                                    {email.status === 'Failed' || email.status === 'Cancelled' ? (
                                                        <MdOutlineCancel className=' text-lightred w-[18px] h-[18px]' />
                                                    ) : email.status == 'Success' ? (
                                                        <IoMdCheckmarkCircle className=' text-lime w-[18px] h-[18px]' />
                                                    ) : (
                                                        <BsClockHistory className=' text-fire w-[18px] h-[18px]' />
                                                    )}
                                                </div>
                                                <p
                                                    className={cn(
                                                        'text-xl text-danger  font-semibold',
                                                        email.status === 'Success' && 'text-success'
                                                    )}
                                                >
                                                    {email.status}
                                                </p>
                                            </div>
                                        </div>
                                        <Button
                                            onClick={() => handleSetCancelled('Cancelled')}
                                            variant={'outline'}
                                            size={'sm'}
                                            className='text-danger dark:bg-offwhite'
                                            disabled={email.status == 'Success'}
                                        >
                                            Mark Cancelled
                                        </Button>
                                    </div>
                                    {email?.updated_items?.length > 0 && <SuccessfulItemsList email={email} />}
                                    {email?.update_failures?.length > 0 && <FailedItemsList email={email} />}

                                    {sentFromFP && email.status !== 'Success' && completeFiles?.length && (
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex gap-2'>
                                                {completeFiles?.map((file: any, index: number) => {
                                                    return (
                                                        <button
                                                            onClick={() => {
                                                                return setSelectedFileIndex(index)
                                                            }}
                                                            className={`${
                                                                selectedFileIndex === index
                                                                    ? 'border-x border-t border-white bg-blue text-white dark:text-offwhite dark:bg-darkaccent dark:border-x dark:border-t mb-[-1px] dark:border-darkgrey font-bold'
                                                                    : 'border-x border-t border-white bg-lightgrey dark:bg-darkness dark:border-darkaccent'
                                                            } p-2 py-1 rounded-md self-start`}
                                                        >
                                                            <span className='capitalize'>{file.name}</span>
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                            <InternalEmailMapSetupV2
                                                companyInfo={companyInfo}
                                                file={completeFiles[selectedFileIndex]}
                                            />
                                        </div>
                                    )}
                                    {!sentFromFP && completeFiles?.length > 0 && (
                                        <div className='flex flex-col gap-2'>
                                            <div className='flex gap-2 max-w-full flex-wrap'>
                                                {completeFiles?.map((file: any, index: number) => {
                                                    return (
                                                        <button
                                                            onClick={() => {
                                                                return setSelectedFileIndex(index)
                                                            }}
                                                            className={`${
                                                                selectedFileIndex === index
                                                                    ? 'border-x border-t border-white bg-blue text-white dark:text-offwhite dark:bg-darkaccent dark:border-x dark:border-t mb-[-1px] dark:border-darkgrey font-bold'
                                                                    : 'border-x border-t border-white bg-lightgrey dark:bg-darkness dark:border-darkaccent'
                                                            } p-2 py-1 rounded-md self-start`}
                                                        >
                                                            <span className='capitalize'>{file.name}</span>
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                            <ExternalEmailMapSetupV2
                                                email={email}
                                                companyInfo={companyInfo}
                                                inventoryEmailMapsInfo={inventoryEmailMapsInfo}
                                                file={completeFiles[selectedFileIndex]}
                                            />
                                        </div>
                                    )}
                                    <div className='flex flex-col gap-3'>
                                        <p>File Downloads</p>
                                        {files?.length > 0 &&
                                            files.map((file: any) => (
                                                <a href={file.src} className=''>
                                                    <Button variant={'outline'} className=''>
                                                        {file.src.split('/')[file.src.split('/').length - 1]}
                                                    </Button>
                                                </a>
                                            ))}
                                    </div>
                                    <div>
                                        <p>Email body</p>
                                        <pre className='block h-full w-full p-[16px] rounded-[4px] bg-lightgrey dark:bg-darkness dark:text-offwhite overflow-auto'>
                                            {email?.text_plain}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
