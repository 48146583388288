import { RECURRING_TASK_INTERVAL_TYPES } from 'tasksV2/constants/tasks'

export default function RecurringTaskIntervalType({
    intervalType,
    handleChange,
}: {
    intervalType?: string
    handleChange: (...args: any) => void
}) {
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey dark:bg-darkbg2 rounded p-1 w-full'
            id='interval_type'
            name='interval_type'
            value={intervalType ?? ''}
            onChange={handleChange}
        >
            <option value=''>No Interval Type Selected</option>
            {RECURRING_TASK_INTERVAL_TYPES.map((type: any) => (
                <option value={type}>
                    {type[0]}
                    {type.toLowerCase().slice(1)}
                </option>
            ))}
        </select>
    )
}
