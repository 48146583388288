import { useQueryClient } from '@tanstack/react-query'
import { useContext } from 'react'
import { FaTasks } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { cn } from '../../../helpers'
import { procurementQueries } from '../../api/procurementQueryKeys'
import { useCreateNote, useUpdateCompany } from '../../api/useQueries'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS, defaultCompanyLogo } from '../../constants'
import { ProcurementContext, ProcurementContextType, formatDateDayMonthYear, formatDateSimple } from '../../helpers'
import EditListNote from '../EditListNote'
import ScoreView from '../ScoreView'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

export default function CompanyV2({ company, showListQuickModal, setShowListQuickModal, setSelectedLifecycle }: any) {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const navigate = useNavigate()
    const { toggleModal, setSelectedCompany, setSelectedCompanyId, users } =
        useContext<ProcurementContextType>(ProcurementContext)
    const queryClient = useQueryClient()

    const updateCompany = useUpdateCompany()
    const { isPending } = updateCompany

    const createEvents = useCreateNote()
    const eventIsPending = createEvents.isPending

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    return (
        <>
            <div
                key={company.name + company.id}
                className={`${cn(
                    isPending && 'opacity-50',
                    eventIsPending && 'opacity-50'
                )} text-[14px] grid grid-cols-[64px_200px_30px_74px_1fr_1fr_1fr_1fr_100px_100px] items-center border-b border-lightgrey dark:text-darktext1 dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness`}
            >
                <div className='p-[8px]'>
                    <img
                        className='p-[4px] bg-lightgrey dark:bg-darkness w-[48px] h-[48px] object-contain rounded-full'
                        src={company.logo || defaultCompanyLogo}
                    />
                </div>
                <div className='relative p-[8px] w-full'>
                    <button
                        onClick={() => {
                            queryClient.prefetchQuery(procurementQueries.companies.detail(company.id)._ctx.contacts())
                            queryClient.prefetchQuery(
                                procurementQueries.companies.detail(company.id)._ctx.events(users)
                            )
                            setShowListQuickModal(undefined)
                            setSearchParams((prev: any) => {
                                prev.set('company_id', company.id)
                                return prev
                            })
                            toggleModal('company', company)
                        }}
                        className='font-bold  text-left w-full'
                    >
                        {company.name}
                        <p className='font-normal'>{company.phone}</p>
                    </button>
                </div>
                {(company.lifecycle_status === 'Listed' || company.lifecycle_status === 'Listing') &&
                !company?.shopify_vendor_name ? (
                    <div className='group relative z-[5]'>
                        <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                        <div className='hidden group-hover:flex border absolute top-[100%] left-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                            Company listed but has no shopify vendor link
                        </div>
                    </div>
                ) : (
                    <div></div>
                )}

                <ScoreView company={company} />
                <div className='w-full h-full font-bold  flex flex-col gap-[4px]'>
                    <select
                        disabled={isPending}
                        value={company.lead_type}
                        name='lead_type'
                        onChange={({ target }) => {
                            const newValue = target.value
                            if (newValue.includes('Follow Up')) {
                                setSelectedCompany(company)
                                setShowListQuickModal('task')
                            }
                            updateCompany.mutateAsync({
                                id: company.id,
                                editableFields: { lead_type: target.value },
                            })
                        }}
                        className='cursor-pointer p-[8px] w-full h-full block text-[16px] leading-1 focus:outline-none py-[4px] bg-transparent'
                    >
                        {LEAD_TYPE_OPTIONS.map((leadType) => (
                            <option key={leadType.label} className='dark:bg-darkness/90' value={leadType.value}>
                                {leadType.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='w-full h-full font-bold  flex flex-col gap-[4px]'>
                    <select
                        disabled={isPending}
                        value={company.lifecycle_status}
                        name='lifecycle_status'
                        onChange={({ target }) => {
                            const newValue = target.value
                            if (newValue === 'Listing' || newValue === 'Listed') {
                                setSelectedCompany(company)
                                setSelectedCompanyId(company.id)
                                setSelectedLifecycle(newValue)
                                setShowListQuickModal('listing')
                                return
                            }
                            return updateCompany.mutate({
                                id: company.id,
                                editableFields: { lifecycle_status: target.value },
                            })
                        }}
                        className='cursor-pointer p-[8px] w-full h-full block text-[16px] leading-1 focus:outline-none py-[4px] bg-transparent'
                    >
                        {LIFECYCLE_STATUS_OPTIONS.map((stage) => (
                            <option key={stage.label} className='dark:bg-darkness/90' value={stage.value}>
                                {stage.label}
                            </option>
                        ))}
                    </select>
                </div>
                <EditListNote
                    company={company}
                    mutationFunction={createEvents}
                    latestNote={company.latest_note || undefined}
                />
                <div className='p-[8px] text-sm font-semibold '>
                    {company.task_id && (
                        <button
                            onClick={() => {
                                navigate(`/tasks/${company.task_id}`)
                            }}
                            className='flex flex-col text-left'
                        >
                            <span>Task: {company.task_id}</span>
                            <span>{formatDateSimple(new Date(company.task_due_at))}</span>
                        </button>
                    )}
                </div>
                <div className='p-2 font-semibold '>{formatDateDayMonthYear(new Date(company.created_at))}</div>
                <div className='flex gap-[8px] justify-center items-center'>
                    <button
                        onClick={() => {
                            setSelectedCompany(company)
                            return showListQuickModal === 'email'
                                ? setShowListQuickModal(undefined)
                                : setShowListQuickModal('email')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <MdEmail className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Email</p>
                    </button>
                    <button
                        onClick={() => {
                            setSelectedCompany(company)
                            toggleCreateModal()
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaTasks className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                    </button>
                </div>
            </div>
        </>
    )
}
