import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingGear, sendToast, vFetch } from '../helpers/index'
import PurchaseOrder from './components/PurchaseOrder'
import { parseIfJson } from './helpers'
import { PurchaseOrderLineItem, PurchaseOrderType, SupplierType, defaultAddress } from './PurchaseOrder.types'

export default function CreatePurchaseOrdersScreen() {
    const [params] = useSearchParams()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState<PurchaseOrderType[]>([])
    const [suppliers, setSuppliers] = useState<SupplierType[]>([])
    const [products, setProducts] = useState<PurchaseOrderLineItem[]>([])
    const [isSending, setIsSending] = useState(false)
    const [attachments, setAttachments] = useState<any>([])

    async function initializeScreen() {
        const [currentSuppliers, currentProducts, currentOrders] = await Promise.all([
            getSuppliers(),
            getFormattedProducts(),
            getOrders(),
        ]).catch(() => [[], [], []])
        setSuppliers(currentSuppliers)
        setProducts(currentProducts)
        setOrders(currentOrders)
        setLoading(false)
    }

    async function getOrders() {
        const ordersParam = params.get('orders')
        if (!ordersParam) {
            return [
                {
                    is_default_order: true,
                    order_name: '',
                    line_items: [],
                    shipping_address: { ...defaultAddress },
                    ship_from_address: { ...defaultAddress },
                },
            ]
        }
        const orders = await vFetch(`/v2/orders/orderSet?orders=${params.get('orders')}`)
            .then((res) => res.order_set.map(parseIfJson))
            .catch(console.error)
        return orders || []
    }

    async function getSuppliers() {
        const suppliers = await vFetch(`/v2/suppliers?limit=99999`)
            .then((res) => res.suppliers.map(parseIfJson))
            .catch(console.error)
        return suppliers || []
    }

    async function getFormattedProducts() {
        const [products, variants] = await Promise.all([
            vFetch(`/v2/products?fields=vendor,id,title&limit=999999`).then((res) => res.products),
            vFetch(
                `/v2/variants?fields=id,product_id,position,sku,unit_cost,custom_supplier_sku,custom_supplier_model_number,custom_upc&limit=999999`
            ).then((res) => res.variants),
        ])
        const newProducts = []
        const productMap: {
            [key: string]: any
        } = {}
        for (let product of products) {
            productMap[product.id] = product
        }
        for (let variant of variants) {
            if (productMap[variant.product_id]) {
                newProducts.push({
                    ...productMap[variant.product_id],
                    variant_id: variant.id,
                    product_id: variant.product_id,
                    item_cost: variant.unit_cost,
                    title: `${variant.sku} - ${productMap[variant.product_id].title}`,
                    product_title: productMap[variant.product_id].title,
                    model_number: variant.custom_supplier_model_number || undefined,
                    upc: variant.custom_upc || undefined,
                    supplier_sku: variant.custom_supplier_sku || undefined,
                })
            }
        }
        return newProducts.sort((a, b) => (a.title > b.title ? 1 : -1))
    }

    async function handleSend() {
        if (isSending) {
            return
        }
        setIsSending(true)
        const unsent = orders.filter((o) => !o.supplier)
        // .filter((o) =>
        //     o.line_items.find(
        //         (li) => li.custom_not_carb_compliant === 1 && o.shipping_address?.province_code == 'CA'
        //     )
        // )
        const readyToEmail = orders
            .filter((o) => !!o.supplier)
            .filter((o) => !o.supplier_edi_info)
            // .filter(
            //     (o) =>
            //         !o.line_items.find(
            //             (li) => li.custom_not_carb_compliant === 1 && o.shipping_address?.province_code == 'CA'
            //         )
            // )
            .map((o) => ({
                ...o,
                total: o.line_items
                    .map((item) => item.quantity * (item.item_cost || 0))
                    .reduce((acc, cur) => acc + cur, 0),
            }))
        const ediReady = orders
            .filter((o) => !!o.supplier)
            .filter((o) => o.supplier_edi_info)
            // .filter(
            //     (o) =>
            //         !o.line_items.find(
            //             (li) => li.custom_not_carb_compliant === 1 && o.shipping_address?.province_code == 'CA'
            //         )
            // )
            .map((o) => ({
                ...o,
                ediOrderType: '00',
                total: o.line_items
                    .map((item) => item.quantity * (item.item_cost || 0))
                    .reduce((acc, cur) => acc + cur, 0),
            }))
        if (readyToEmail.length > 0) {
            await vFetch(`/v1/orders/purchase-orders`, {
                method: 'POST',
                body: JSON.stringify({ purchaseOrders: readyToEmail }),
                cb: (res: any) => {
                    if (res.success) {
                        const successfulEmails = res.statuses
                            .filter((s: any) => s.success)
                            .map((s: any) => `${s.name} - ${s.supplier}`)
                            .join(',\n')
                        const failedEmails = res.statuses
                            .filter((s: any) => !s.success)
                            .map((s: any) => `${s.name} - ${s.supplier}`)
                            .join(',\n')
                        if (successfulEmails.length > 0) {
                            sendToast({ message: `Purchase orders sent: ${successfulEmails}` })
                        }
                        if (failedEmails.length > 0) {
                            sendToast({ message: `Some emails failed to send: ${failedEmails}` })
                        }
                    }
                },
            })
        }
        if (ediReady.length > 0) {
            vFetch(`/v1/orders/purchase-orders/edi`, {
                method: 'POST',
                body: JSON.stringify({ purchaseOrders: ediReady }),
            })
        }
        if (unsent.length > 0) {
            setOrders([...unsent])
            sendToast({ message: 'Some purchase orders were not sent! Please review the remaining purchase orders.' })
        } else {
            navigate('/orders/purchase-orders')
        }
        setIsSending(false)
    }

    useEffect(() => {
        initializeScreen()
    }, [])

    return (
        <div className=''>
            {loading ? (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <LoadingGear />
                </div>
            ) : (
                <>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Create Purchase Orders</h1>
                    <button
                        onClick={handleSend}
                        className='fixed top-[50px] right-0 py-[4px] px-[8px] text-[14px] rounded-b-[4px] font-bold bg-blue text-white dark:text-darkness dark:bg-accent border-0 z-[5] uppercase'
                    >
                        Send Purchase Orders
                    </button>
                    {suppliers.length > 0 && orders.length > 0 && products.length > 0 && (
                        <>
                            {orders.map((o, index) => (
                                <PurchaseOrder
                                    key={`${o.id}-${index}`}
                                    order={o}
                                    orderIndex={index}
                                    orders={orders}
                                    setOrders={setOrders}
                                    suppliers={suppliers}
                                    products={products}
                                    attachments={attachments}
                                    setAttachments={setAttachments}
                                    editMode={false}
                                />
                            ))}
                        </>
                    )}
                </>
            )}
        </div>
    )
}
