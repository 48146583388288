import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CustomerAddressType } from 'customersV2/Customers.types'
import AreYouSureModal from 'customersV2/modals/AreYouSureModal'
import { vFetch } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaStar, FaTrash } from 'react-icons/fa'
import { FaPencil } from 'react-icons/fa6'
import { useSearchParams } from 'react-router-dom'

export default function CustomerAddressRow({ address }: { address: CustomerAddressType }) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [areYouSureMode, setAreYouSureMode] = useState<'default' | 'delete' | undefined>()
    const [showMoreActions, setShowMoreActions] = useState(false)
    const [_searchParams, setSearchParams] = useSearchParams()
    const queryClient = useQueryClient()
    const setAsDefaultMutation = useMutation({
        mutationFn: async ({
            customer_id,
            address_id,
        }: {
            customer_id: string | number
            address_id: string | number
        }) =>
            vFetch('/v2/customers/addresses/default', {
                method: 'PUT',
                body: JSON.stringify({
                    customer_id,
                    address_id,
                }),
            }),
        onSuccess: () => {
            setAreYouSureMode(undefined)
            queryClient.invalidateQueries({ queryKey: ['customers', address.customer_id] })
            queryClient.invalidateQueries({ queryKey: ['customers'] })
            return
        },
    })
    const deleteAddressMutation = useMutation({
        mutationFn: async ({
            customer_id,
            address_id,
        }: {
            customer_id: string | number
            address_id: string | number
        }) =>
            vFetch('/v2/customers/addresses', {
                method: 'DELETE',
                body: JSON.stringify({
                    customer_id,
                    address_id,
                }),
            }),
        onSuccess: () => {
            setAreYouSureMode(undefined)
            queryClient.invalidateQueries({ queryKey: ['customers', address.customer_id] })
            queryClient.invalidateQueries({ queryKey: ['customers'] })
            return
        },
    })

    const areYouSureOptions =
        areYouSureMode === 'default'
            ? {
                  message: 'Are you sure you want to change the default address?',
                  onConfirm: () =>
                      setAsDefaultMutation.mutate({ customer_id: address.customer_id, address_id: address.id }),
              }
            : {
                  message: 'Are you sure you want to delete this customer address? This action cannot be undone.',
                  onConfirm: () =>
                      deleteAddressMutation.mutate({ customer_id: address.customer_id, address_id: address.id }),
              }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as HTMLElement)) {
                setShowMoreActions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div className='p-2 rounded bg-lightgrey dark:bg-darkbg2 relative'>
            {address.is_default === 1 && <p className='font-bold text-xs mb-1'>Default Address</p>}
            {address.company && <p>{address.company}</p>}
            {[address.first_name, address.last_name].filter((v) => v).length > 0 && (
                <p>{[address.first_name, address.last_name].filter((v) => v).join(' ')}</p>
            )}
            <p>{address.address1}</p>
            <p>{address.address2}</p>
            <p>
                {address.city}, {address.province_code}
            </p>
            {address.phone && <p>{address.phone}</p>}
            <div ref={modalRef} className='absolute top-2 right-1'>
                <button onClick={() => setShowMoreActions((prev) => !prev)}>
                    <BsThreeDotsVertical />
                </button>
                {showMoreActions && (
                    <div className='border border-lightgrey dark:border-darkgrey rounded shadow-md absolute z-10 top-full right-0 p-2 rounded bg-white dark:bg-darkbg1 flex flex-col gap-1'>
                        <button
                            className='shrink-0 whitespace-nowrap flex gap-1 items-center'
                            onClick={() => {
                                setSearchParams((prev) => {
                                    prev.set('address_id', address.id.toString())
                                    return prev
                                })
                                setShowMoreActions(false)
                            }}
                        >
                            <FaPencil />
                            <span>Edit Address</span>
                        </button>
                        {address.is_default === 0 && (
                            <button
                                className='shrink-0 whitespace-nowrap flex gap-1 items-center'
                                onClick={() => {
                                    setAreYouSureMode('default')
                                    setShowMoreActions(false)
                                }}
                            >
                                <FaStar className='text-fire' />
                                <span>Set As Default Address</span>
                            </button>
                        )}
                        <button
                            className='shrink-0 whitespace-nowrap flex gap-1 items-center'
                            onClick={() => setAreYouSureMode('delete')}
                        >
                            <FaTrash className='text-red dark:text-lightred' />
                            <span>Delete Address</span>
                        </button>
                    </div>
                )}
            </div>
            {areYouSureMode && (
                <AreYouSureModal
                    message={areYouSureOptions.message}
                    onDeny={() => setAreYouSureMode(undefined)}
                    onConfirm={areYouSureOptions.onConfirm}
                />
            )}
        </div>
    )
}
