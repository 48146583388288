import { useRef } from 'react'
import { MdOutlineAttachment } from 'react-icons/md'

// holds attachment icon and adds to attachment array.
// uploadS3FileMutation that handles adding attachments to s3 bucket is commented out
export default function EmailAttachment({
    attachments,
    setAttachments,
    setLoadingWidth,
    setLoadingWidthWrapper,
    right,
}: {
    attachments: any
    setAttachments: any
    setLoadingWidth: any
    setLoadingWidthWrapper: any
    right: any
}) {
    const fileInputRef = useRef<any>()

    const handleFileSelection = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (e: any) => {
        if (e.target.files[0] === undefined && attachments != undefined) {
            return
        }
        const newArray = [...attachments]
        newArray.push(e.target.files[0])
        setAttachments(newArray)
        handleAttachmentAnimation()
    }

    const handleAttachmentAnimation = () => {
        setTimeout(() => {
            setLoadingWidth('w-6')
            setLoadingWidthWrapper('bg-white border border-grey dark:border-white w-24 h-4')

            setTimeout(() => {
                setLoadingWidth('w-12')

                setTimeout(() => {
                    setLoadingWidth('w-24')

                    setTimeout(() => {
                        setLoadingWidth('w-0')
                        setLoadingWidthWrapper('')
                    }, 140)
                }, 120)
            }, 120)
        }, 120)
    }

    return (
        <div className='group flex relative'>
            {right && (
                <button className='flex' onClick={handleFileSelection} aria-label='add attachment'>
                    <MdOutlineAttachment size={'25px'} className={`m-auto rotate-[-90deg]`} />
                    <main className='absolute bottom-[-1.5px] left-6 hidden group-hover:flex'>
                        <section className='container max-w-2xl p-2'>
                            <div className='flex items-center'>
                                <label
                                    htmlFor='attachFileInput'
                                    className='whitespace-nowrap bg-gray-700 dark:bg-accent text-white dark:text-darkaccent p-1 px-2 rounded flex-1 text-xs'
                                >
                                    Add Attachment
                                </label>
                            </div>
                        </section>
                    </main>
                </button>
            )}
            {!right && (
                <button className='flex' onClick={handleFileSelection} aria-label='add attachment'>
                    <main className='absolute bottom-[-6.5px] right-6 hidden group-hover:flex'>
                        <section className='container max-w-2xl p-2'>
                            <div className='flex items-center'>
                                <label
                                    htmlFor='attachFileInput'
                                    className='whitespace-nowrap bg-gray-700 dark:bg-accent text-white dark:text-darkaccent p-1 px-2 rounded flex-1 text-xs'
                                >
                                    Add Attachment
                                </label>
                            </div>
                        </section>
                    </main>
                    <MdOutlineAttachment size={'25px'} className={`m-auto rotate-[-90deg]`} />
                </button>
            )}

            <input type='file' ref={fileInputRef} onChange={handleFileChange} id='attachFileInput' className='hidden' />
        </div>
    )
}
