import { useSelector } from 'react-redux'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'

export default function AssignmentUser({ userId }: { userId: any }) {
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery?.data?.users || []
    const foundUser = users.find((user) => user.id == userId)
    const formattedName = `${foundUser?.first_name} ${foundUser?.last_name[0]}.`
    const initials = `${foundUser?.first_name[0]}${foundUser?.last_name[0]}`
    return (
        <div className='relative group flex items-center'>
            {/* <div className='rounded-full w-5 h-5 border-2 border-blue bg-blue text-white dark:text-offwhite text-text2 flex font-semibold uppercase text-xs items-center justify-center z-[19]'> // */}
            <div className='rounded-full w-5 h-5 border-2 border-blue bg-blue text-white dark:text-offwhite text-text2 flex font-semibold uppercase text-xs items-center justify-center'>
                {initials}
            </div>
            {/* <div className='hidden group-hover:flex border absolute top-[100%] right-[-30px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[21]'> // */}
            <div className='hidden group-hover:flex border absolute top-[-10px] right-[-10px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[1]'>
                <div className=''>{formattedName}</div>
            </div>
        </div>
    )
}
