import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerType } from 'customersV2/Customers.types'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useRef, useState } from 'react'
import { FaPlus, FaTimes } from 'react-icons/fa'

export default function CustomerContactInfo({ customer }: { customer: CustomerType }) {
    const tagListRef = useRef<HTMLDivElement>(null)
    const [showTags, setShowTags] = useState(false)
    const [tagSearch, setTagSearch] = useState('')
    const [editedFields, setEditedFields] = useState<any>({})
    const tagsQuery = useQuery({
        queryKey: ['customers', 'tags'],
        queryFn: () => vFetch(`/v2/customers/tags`),
    })
    const tags: string[] =
        tagsQuery?.data?.tags
            ?.map((tag: any) => tag.tag)
            .filter(
                (tag: string) =>
                    tag.toLowerCase() !== tagSearch.toLowerCase() &&
                    !customer.tags?.split(',').includes(tag) &&
                    tag.toLowerCase().includes(tagSearch.toLowerCase())
            ) || []
    const edited = Object.keys(editedFields).length > 0
    const queryClient = useQueryClient()
    const updateCustomerMutation = useMutation({
        mutationFn: async ({ customer }: { customer: CustomerType }) =>
            vFetch('/v2/customers', {
                method: 'PUT',
                body: JSON.stringify({
                    ...customer,
                }),
            }),
        onSuccess: () => {
            setTagSearch('')
            setShowTags(false)
            return queryClient.invalidateQueries({ queryKey: ['customers', customer.id.toString()] })
        },
    })

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => ({ ...prev, [target.name]: target.value }))
    }

    const handleUpdateCustomer = () => {
        updateCustomerMutation.mutate(
            {
                customer: {
                    id: customer.id,
                    ...editedFields,
                } as CustomerType,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                },
            }
        )
    }

    const handleAddTag = (tag: string) => {
        const currentTags = customer.tags || ''
        const tags = currentTags
            .split(',')
            .concat([tag])
            .filter((v) => v)
            .join(',')
        updateCustomerMutation.mutate({
            customer: {
                id: customer.id,
                tags,
            } as CustomerType,
        })
    }

    const handleDeleteTag = (tag: string) => {
        const currentTags = customer.tags || ''
        const tags = currentTags
            .split(',')
            .filter((currentTag) => currentTag && currentTag !== tag)
            .join(',')
        updateCustomerMutation.mutate({
            customer: {
                id: customer.id,
                tags,
            } as CustomerType,
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!tagListRef.current?.contains(e.target as HTMLElement)) {
                setShowTags(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <>
            <h3 className='text-base font-bold mb-2'>Contact Info</h3>
            <div className='flex flex-col gap-2 h-full overflow-auto'>
                <Input
                    type='text'
                    value={editedFields.first_name ?? customer.first_name}
                    onChange={handleChange}
                    id='first_name'
                    name='first_name'
                    label='First Name'
                />
                <Input
                    type='text'
                    value={editedFields.last_name ?? customer.last_name}
                    onChange={handleChange}
                    id='last_name'
                    name='last_name'
                    label='Last Name'
                />
                <Input
                    type='text'
                    value={editedFields.email ?? customer.email}
                    onChange={handleChange}
                    id='email'
                    name='email'
                    label='Email Address'
                />
                <Input
                    type='text'
                    value={editedFields.phone ?? customer.phone}
                    onChange={handleChange}
                    id='phone'
                    name='phone'
                    label='Phone Number'
                />
                <Textarea
                    outerClassName='h-fit'
                    value={editedFields.note ?? customer.note}
                    onChange={handleChange}
                    id='note'
                    name='note'
                    label='Note'
                />
                <div
                    ref={tagListRef}
                    onClick={() => setShowTags(true)}
                    className={`relative z-[5] ${
                        updateCustomerMutation.isPending ? 'brightness-75 pointer-events-none' : ''
                    }`}
                >
                    <Input
                        type='text'
                        value={tagSearch}
                        onChange={({ target }) => setTagSearch(target.value)}
                        id='tagSearch'
                        name='tagSearch'
                        placeholder='Add tags...'
                        label='Tags'
                    />
                    {showTags && (
                        <div className='w-full flex gap-1 flex-wrap max-h-[100px] overflow-auto absolute top-full left-0 bg-white dark:bg-darkbg1 shadow-md rounded-b p-2 border border-t-0 border-lightgrey dark:border-darkgrey'>
                            {tagSearch && (
                                <button
                                    onClick={() => handleAddTag(tagSearch)}
                                    className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'
                                >
                                    <span>{tagSearch}</span>
                                    <FaPlus className='text-[8px]' />
                                </button>
                            )}
                            {tags.map((tag) => (
                                <button
                                    onClick={() => handleAddTag(tag)}
                                    className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'
                                >
                                    <span>{tag}</span>
                                    <FaPlus className='text-[8px]' />
                                </button>
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className={`flex flex-wrap gap-1 ${
                        updateCustomerMutation.isPending ? 'brightness-75 pointer-events-none' : ''
                    }`}
                >
                    {customer.tags?.split(',').map((tag) => (
                        <div className='font-bold text-xs py-1 px-2 rounded-[99vw] bg-lightgrey dark:bg-darkbg2 flex gap-1 items-center'>
                            <span>{tag}</span>
                            <button onClick={() => handleDeleteTag(tag)}>
                                <FaTimes className='text-[8px]' />
                            </button>
                        </div>
                    ))}
                </div>
                {edited && (
                    <Button
                        disabled={updateCustomerMutation.isPending}
                        className='disabled:opacity-50'
                        variant={'outline'}
                        onClick={handleUpdateCustomer}
                    >
                        {updateCustomerMutation.isPending ? 'Saving...' : 'Save Changes'}
                    </Button>
                )}
            </div>
        </>
    )
}
