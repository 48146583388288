import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import QuillEditorApp from 'custom_components/quill_text_editor/QuillEditorApp'
import { useGetSuppliers } from 'purchaseOrdersV2/api/useQueries'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { ShopifyProduct } from '../ProductSetup.types'

export default function Product({
    product,
    setProduct,
    handleUpdateProduct,
    edited,
    setEdited,
}: {
    product: ShopifyProduct
    setProduct: Dispatch<SetStateAction<ShopifyProduct>>
    handleUpdateProduct: Function
    edited: boolean
    setEdited: any
}) {
    const suppliersQuery = useGetSuppliers({ limit: 9999 })
    const suppliers = suppliersQuery.data?.suppliers || []
    const { data: remainingCredits } = useQuery({
        queryKey: ['removebgCredits'],
        queryFn: () => vFetch('/v1/photoroom/credits').then((res) => res.credits || 0),
    })
    const handleUpload = async ({ target }: { target: HTMLInputElement }) => {
        setSettingImage(true)
        if (!target.files || !target.files[0]) {
            return
        }
        const data = new FormData()
        data.append('image', target.files[0], target!.files[0].name)
        return await vFetch(`/v1/photoroom?product_id=${product.id}`, {
            method: 'POST',
            body: data,
            contentType: 'skip',
            cb: (res: any) => {
                setSettingImage(false)
                if (res.success) {
                    setProduct((previousProduct) => ({
                        ...previousProduct,
                        featured_image: res.s3_img_url,
                    }))
                }
            },
            catchCb: () => setSettingImage(false),
        })
    }
    const useUpload = () => {
        const queryClient = useQueryClient()
        return useMutation({
            mutationFn: handleUpload,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['removebgCredits'] })
            },
        })
    }
    const { mutate: uploadImage, isPending: uploadPending } = useUpload()
    const [allVendors, setAllVendors] = useState<string[]>([])
    const [allProductTypes, setAllProductTypes] = useState<string[]>([])
    const [settingImage, setSettingImage] = useState(false)
    const [changedFields, setChangedFields] = useState<string[]>([])

    const handleSetDescription = (description: string) => {
        if (!changedFields.includes('body_html')) {
            setChangedFields([...changedFields, 'body_html'])
        }
        setProduct((previousProduct) => ({
            ...previousProduct,
            body_html: description,
        }))
    }

    const handleChangeProduct = ({
        target,
    }: {
        target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    }) => {
        if (!changedFields.includes(target.name)) {
            setChangedFields([...changedFields, target.name])
        }
        setProduct((previousState) => ({
            ...previousState,
            [target.name]: target.value,
        }))
    }

    useEffect(() => {
        setSettingImage(false)

        vFetch('/v2/products/types', {
            cb: (res: any) => {
                if (res.success) {
                    setAllProductTypes(res.productTypes || [])
                }
            },
        })
        vFetch('/v2/products/vendors', {
            cb: (res: any) => {
                if (res.success) {
                    setAllVendors(res.vendors || [])
                }
            },
        })

        return () => setSettingImage(false)
    }, [])

    return (
        <>
            <div className='flex gap-[16px]'>
                <div className='w-[65%] rounded-[4px] shadow-small dark:bg-darkaccent p-[16px]'>
                    <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Basic Details
                    </p>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Status
                        </label>
                        <select
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            value={product.status}
                            name='status'
                            onChange={handleChangeProduct}
                        >
                            <option value='active'>Active</option>
                            <option value='draft'>Draft</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Title
                        </label>
                        <input
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            type='text'
                            value={product.title}
                            name='title'
                            onChange={handleChangeProduct}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Product Type
                        </label>
                        <input
                            list='product_type'
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            type='text'
                            value={product.product_type}
                            name='product_type'
                            onChange={handleChangeProduct}
                        />
                        <datalist id='product_type'>
                            {allProductTypes.map((p) => (
                                <option value={p}>{p}</option>
                            ))}
                        </datalist>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Vendor
                        </label>
                        <input
                            list='vendor'
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            type='text'
                            value={product.vendor}
                            name='vendor'
                            onChange={handleChangeProduct}
                        />
                        <datalist id='vendor'>
                            {allVendors.map((v) => (
                                <option value={v}>{v}</option>
                            ))}
                        </datalist>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Supplier
                        </label>
                        <select
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            value={product.supplier_id}
                            name='supplier_id'
                            onChange={handleChangeProduct}
                        >
                            <option value=''>Select a supplier</option>
                            {suppliers.map((s: any) => (
                                <option value={s.id}>{s.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Sells to California
                        </label>
                        <select
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            value={product.custom_not_carb_compliant?.toString()}
                            name='custom_not_carb_compliant'
                            onChange={handleChangeProduct}
                        >
                            {/* You will have to trust :halo: */}
                            <option value='0'>Yes</option>
                            <option value='1'>No</option>
                            {/* You can thank Shopify for this logical nightmare because no default metafield values */}
                        </select>
                    </div>
                    <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Overview
                        </label>
                        <style>{`
                            .dark .ql-toolbar .ql-stroke {
                                fill: none; 
                                stroke: #fff;
                            }
                            .dark .ql-toolbar .ql-fill {
                                fill: #fff; 
                                stroke: none;
                            }
                            .dark .ql-toolbar .ql-picker {
                                color: #fff;
                            }
                            .dark .ql-toolbar,
                            .dark .ql-toolbar * {
                                background-color: var(--darkBackgroundPrimary);
                            }
                            .ql-editor {
                                min-height: 400px;
                                max-height: 580px;
                                background-color: var(--tw-bg-opacity);
                                overflow: scroll;
                            }
                            .dark .ql-editor {
                                min-height: 400px;
                                background-color: var(--darkBackgroundSecondary);
                            }
                            .ql-tooltip {
                                left: 10px;
                                top: 194.586px;
                            }
                            .quill, .quill * {
                             border-color: #e5e7eb;
                            }
                            .dark .quill, .dark .quill * {
                                border-color: #4A4A4A;
                            }
                            .quill {
                                border-radius: 4px;
                                border-color: #e5e7eb;
                            }
                            .ql-editor ol li:before {
                                content: none;
                            }
                            .ql-image {
                                position: relative;
                            }
                        `}</style>
                        <QuillEditorApp
                            value={product.body_html}
                            onChange={handleSetDescription}
                            setEdited={setEdited}
                        />
                        <div
                            className='w-[400px] hidden absolute left-[20%] shadow-md border bg-white dark:bg-darkaccent border-lightgrey dark:border-grey rounded px-4 py-8'
                            id='altTextModal'
                        >
                            <div id='promptText' className=''>
                                <p className=''>Enter alternative text for the image:</p>
                                <p className='mt-1 text-sm'>
                                    Alt text improves accessibility by describing what an image is showing to users who
                                    do not have the ability to see them or when an image cannot be rendered.
                                </p>
                            </div>
                            <input
                                id='altTextInput'
                                placeholder='Required'
                                required
                                size={32}
                                maxLength={100}
                                className='bg-lightgrey rounded px-1 mt-2 w-full'
                            ></input>
                            <div className='flex justify-end gap-2 mt-4'>
                                <button
                                    id='altTextSubmit'
                                    className='bg-blue rounded-3xl text-white  dark:text-darkaccent dark:bg-accent'
                                >
                                    <div className='py-2 px-7'>OK</div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Notes
                        </label>
                        <textarea
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none border-none bg-lightgrey  !max-h-[150px] dark:bg-faintplus dark:text-white'
                            value={product.custom_custom_note}
                            name='custom_custom_note'
                            onChange={handleChangeProduct}
                        ></textarea>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full mt-1'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Meta Title
                        </label>
                        <input
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            type='text'
                            value={product.meta_title}
                            name='meta_title'
                            onChange={handleChangeProduct}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full mt-1'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            Meta Description
                        </label>
                        <textarea
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none border-none bg-lightgrey  !max-h-[150px] dark:bg-faintplus dark:text-white'
                            value={product.meta_description}
                            name='meta_description'
                            onChange={handleChangeProduct}
                        ></textarea>
                    </div>
                    <div className='flex flex-col gap-[4px] mb-[8px] w-full mt-1'>
                        <label className='text-[12px] font-bold uppercase leading-[1] text-darkgrey dark:text-offwhite'>
                            URL Handle
                        </label>
                        <input
                            className='w-full py-[4px] px-[8px] rounded-[4px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white'
                            type='text'
                            value={product.handle}
                            name='handle'
                            onChange={handleChangeProduct}
                        />
                    </div>
                    {edited && (
                        <button
                            className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                            onClick={() => {
                                handleUpdateProduct(changedFields)
                            }}
                        >
                            Save Changes
                        </button>
                    )}
                </div>
                <div
                    className={`w-[35%] h-fit sticky top-[66px] rounded-[4px] shadow-small dark:bg-darkaccent p-[16px] overflow-hidden ${
                        uploadPending ? 'opacity-[0.5] pointer-events-none' : ''
                    }`}
                >
                    <p className='mt-[-16px] ml-[-16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Featured Image
                    </p>
                    <div className='mt-[16px]'>
                        <img
                            className='w-full p-[16px] border border-grey dark:border-darkgrey rounded-[4px]'
                            src={product.featured_image}
                        />
                    </div>
                    <label className='block w-full text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase my-[4px]'>
                        {settingImage ? 'Removing background...' : 'Upload new featured image'}
                    </label>
                    <input
                        className='w-full border border-grey bg-white dark:text-offwhite dark:bg-darkness dark:border-fire p-[8px] rounded-[4px]'
                        type='file'
                        name='image_upload'
                        onChange={uploadImage}
                    />
                    <p className='mt-[4px]'>
                        Remaining Photoroom credits: <span className='font-bold'>{remainingCredits}</span>
                    </p>
                </div>
            </div>
            {product.body_html && (
                <div className='ql-snow'>
                    <style>{`
                        .ql-snow .ql-editor img {
                            object-fit: contain;
                            max-width: 600px !important;
                        }
                    
                    `}</style>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] mt-[16px]'>Overview Preview</h2>
                    <div className=' bg-white description-preview description-preview--full dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__description__heading'>PRODUCT OVERVIEW</div>
                        <div
                            style={{ minHeight: 'none', maxHeight: 'none', overflow: 'unset', background: 'white' }}
                            className='pdp__description__left ql-editor'
                            dangerouslySetInnerHTML={{ __html: product.body_html }}
                        ></div>
                    </div>
                </div>
            )}
        </>
    )
}
