import { useEffect, useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { vFetch } from '../../helpers'
import Textarea from '../../procurement/components/Textarea'
import Answers from '../components/Answers'
import CreateAnswerModal from '../components/CreateAnswerModal'
import EditOptions from '../components/EditOptions'
import UsersName from '../components/UsersName'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../custom_components/component_Basics/Button'
import { isUserOrAdmin } from '../helpers'
import { useQueryClient } from '@tanstack/react-query'

export default function QuestionWithAnswersView({ setView, selectedQuestion }: any) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const [answerList, setAnswerList] = useState([])
    const [showEditButton, setShowEditButton] = useState(true)
    const [editQuestion, setEditQuestion] = useState(false)
    const [editableQuestion, setEditableQuestion] = useState<undefined | string>()
    const [question, setQuestion] = useState<any>(selectedQuestion)
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    const currentUser = useSelector((state: any) => state.user)
    const canEdit = isUserOrAdmin(currentUser, selectedQuestion)

    const getAnswers = async () => {
        vFetch(`/v1/knowledge/answers?question_id=${selectedQuestion.id}`, {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    setAnswerList(res.answers)
                }
            },
        })
    }

    const getQuestion = () => {
        vFetch(`/v1/knowledge/questions?id=${selectedQuestion.id}`, {
            cb: (res: any) => {
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    const foundQuestion = res.questions.find((question: any) => question.id == 'questionWithAnswers')
                    if (foundQuestion) {
                        setQuestion(res.questions[0])
                    }
                }
            },
        })
    }
    const queryClient = useQueryClient()
    const handleUpdateQuestion = () =>
        vFetch('/v1/knowledge/questions', {
            method: 'PUT',
            body: JSON.stringify({ id: selectedQuestion.id, question: editableQuestion }),
            cb: () => {
                getQuestion()
                setShowEditButton(true)
                setEditableQuestion(undefined)
                setEditQuestion(false)
                return queryClient.invalidateQueries({
                    queryKey: ['knowledge', 'questions'],
                })
            },
        })

    const handleClick = () => {
        setShowEditButton(false)
    }

    function handleCancel() {
        setShowEditButton(true)
        setEditQuestion(false)
    }

    const handleCancelEditQuestion = () => {
        setEditQuestion(false)
        setShowEditButton(true)
        setEditableQuestion(undefined)
    }

    useEffect(() => {
        getAnswers()
        getQuestion()
    }, [])

    useEffect(() => {
        setQuestion(selectedQuestion)
    }, [selectedQuestion])

    return (
        <div className='flex flex-col h-full pr-2'>
            <div className='flex-row'>
                <Button
                    onClick={() =>
                        setSearchParams((prev: any) => {
                            prev.delete('questionID', `${question.id}`)
                            return prev
                        })
                    }
                    variant={'outline'}
                    className='mx-auto mt-4 text-xs'
                >
                    BACK
                </Button>
            </div>
            <div className='relative flex w-full items-start gap-2 justify-between pt-2 mb-6'>
                <div className='w-full mt-2'>
                    <Textarea
                        id={selectedQuestion.id}
                        name='question'
                        value={editableQuestion ?? question.question}
                        onChange={({ target }) => {
                            setEditableQuestion(target.value)
                        }}
                        readOnly={!editQuestion}
                        className={
                            !editQuestion
                                ? 'dark:bg-darkbg1 focus:outline-none font-bold text-2xl p-0 border-0 h-fit'
                                : 'w-full bg-transparent focus:outline-none h-fit p-2 border-lightgrey dark:border-darkgrey rounded shadow-md'
                        }
                    />
                    <UsersName questionRow={question} verb={'asked'} textSize={'text-sm'} />
                </div>

                {canEdit && (
                    <div className='absolute top-3.5 right-1'>
                        {!editQuestion && (
                            <>
                                {showEditButton ? (
                                    <button
                                        className='text-black dark:text-offwhite text-xl font-bold'
                                        onClick={handleClick}
                                    >
                                        <BsThreeDotsVertical />
                                    </button>
                                ) : (
                                    <div>
                                        <button
                                            onClick={handleCancel}
                                            className='shrink-0 whitespace-nowrap
                                 dark:text-offwhite text-xl font-bold'
                                        >
                                            <FaTimes className='text-red w-[22px] h-[22px]' />
                                        </button>
                                    </div>
                                )}
                            </>
                        )}

                        {!showEditButton && !editQuestion && (
                            <>
                                <EditOptions
                                    setEditQuestion={setEditQuestion}
                                    setShowEditButton={setShowEditButton}
                                    setView={setView}
                                    selectedQuestion={selectedQuestion}
                                />
                            </>
                        )}
                    </div>
                )}
                {editQuestion && (
                    <div className='flex items-center gap-1 mt-3'>
                        {editableQuestion && editableQuestion !== question.question && (
                            <button
                                className='leading-none text-sm p-2 shrink-0 whitespace-nowrap bg-blue text-offwhite dark:bg-accent dark:text-darkaccent font-bold rounded'
                                onClick={handleUpdateQuestion}
                            >
                                Save
                            </button>
                        )}

                        <button
                            value='Cancel'
                            onClick={handleCancelEditQuestion}
                            className='leading-none text-sm p-2 shrink-0 whitespace-nowrap bg-darkgrey text-offwhite font-bold rounded'
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}

            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}

            {!isError && !isLoading && (
                <>
                    <div className='mb-[11px]'>
                        <span className='font-bold'>
                            {answerList.length} Answer{answerList.length == 1 ? '' : 's'}
                        </span>
                    </div>
                    <div className='h-full overflow-auto pr-2'>
                        {answerList?.map((answersRow: any) => (
                            <Answers
                                answersRow={answersRow}
                                getAnswers={getAnswers}
                                setView={setView}
                                setAnswerList={setAnswerList}
                            />
                        ))}
                    </div>
                    <div>
                        <CreateAnswerModal getAnswers={getAnswers} question_id={selectedQuestion.id} />
                    </div>
                </>
            )}
        </div>
    )
}
