import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import React, { useRef } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'
import { useGetRoles } from 'roles/api/useQueries'
import UserList from './lists/UserList'
import CreateUserModal from './modals/CreateUserModal'
import EditUserModal from './modals/EditUserModal'
import { UsersScreenV2SearchContainer } from './UsersScreenV2SearchContainer'
import { useGetStores } from 'storeManager/api/useQueries'

export default function UsersScreenV2() {
    // Biggest filter button gotcha is array of selected values not matching type of optionObject.value
    // must be both string or both number

    // Example of mismatched types
    // roleValues: string[] = ['1','2']
    // const roleOptions = [
    // {
    //     label: 'option 1',
    //     value: 1,
    //     icon:undefined
    // }
    //]

    // 1 != '1'

    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedUserId = searchParams.get('user_id')

    const searchRef: any = useRef()

    // pull selected string filter values from searchParams
    const search = searchParams.get('search') || ''
    const typesString = searchParams.get('types') || ''
    const rolesString = searchParams.get('roles') || ''

    // convert strings to arrays of values
    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const roleValues = decodeURIComponent(rolesString)
        .split(',')
        .filter((v) => v)

    const rolesQuery = useGetRoles({})
    const roles: any[] = rolesQuery.data?.roles || []
    const storesQuery = useGetStores({})

    // generate option objects
    const typeOptions = ['SuperAdmin', 'Admin', 'Client', 'Archived'].map((type) => {
        return {
            label: type,
            value: type,
            icon: undefined,
        }
    })
    const roleOptions = roles?.map((role) => {
        return {
            label: role.title,
            value: role.id.toString(),
            icon: undefined,
        }
    })

    const filters = {
        types: typeValues,
        roles: roleValues,
        search,
    }

    // ACTIVE FILTERS
    const filterValuesMap = {
        roles: roleValues,
        types: typeValues,
        search: search ? [search] : [],
    }

    const filterOptionsMap = {
        roles: roleOptions,
        types: typeOptions,
        search: [{ label: search, value: search, icon: undefined }],
    }
    const hasFilterOptions = Object.entries(filterValuesMap).filter(([_key, val]) => val.length > 0).length > 0

    const clearFilterValue = (groupName: string, value: string) => {
        const newValues = filterValuesMap[groupName as keyof typeof filterValuesMap].filter((v) => v !== value)
        if (!newValues.length) {
            searchParams.delete(groupName.replaceAll(' ', ''))
            return setSearchParams(searchParams)
        }
        searchParams.set(groupName.replaceAll(' ', ''), encodeURIComponent(newValues.join(',')))
        setSearchParams(searchParams)
    }
    // ACTIVE FILTERS

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }

    const toggleEditModal = (id?: number) => {
        if (!id) {
            searchParams.delete('user_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('user_id', id.toString())
        return setSearchParams(searchParams)
    }

    // THIS IS MOST IMPORTANT LINE FOR VIRTUALIZED TABLE
    // <div className='h-[calc(100vh-200px)]'>
    // height of component must be constrained so table component will scroll properly
    // edit subtracted px amount to fit your component
    return (
        <div className='h-[calc(100vh-200px)]'>
            <div className='flex justify-between mb-2'>
                <div className='flex gap-2 items-center min-w-[500px]'>
                    <h1 className='font-semibold text-2xl'>Users</h1>
                    {/* Searchbar in separate component to control rerenders */}
                    <UsersScreenV2SearchContainer ref={searchRef} />
                </div>
                <Button onClick={toggleCreateModal} size='sm' variant='outline'>
                    + New User
                </Button>
            </div>
            <div className='flex gap-2 mb-2'>
                <FilterBarSelectorButton
                    // Capital B button variant
                    variant='outline'
                    // popover box alignment 'end' | 'center' | 'start'
                    align='start'
                    // use to storeValues in local state. Pass do nothing function like here if not storing values in state
                    // ex setFilterValues={setFilterValues}
                    setFilterValues={() => {}}
                    // options client will see
                    options={roleOptions}
                    // Label on button
                    title={'Roles'}
                    // field must match searchParam.get key above
                    // const rolesString = searchParams.get('roles') || ''
                    field={'roles'}
                    // array of selected values
                    filterValues={roleValues}
                    // Toggle ability to search options in popover
                    searchToggle={false}
                    // Toggle user selections mutating searchParams
                    editSearchParams={true}
                    // Toggle selected option preview appearing on button
                    preview={false}
                />
                <FilterBarSelectorButton
                    variant='outline'
                    align='start'
                    setFilterValues={() => {}}
                    options={typeOptions}
                    title={'Types'}
                    field={'types'}
                    filterValues={typeValues}
                    searchToggle={false}
                    editSearchParams={true}
                    preview={false}
                />
            </div>
            {/* ACTIVE FILTERS */}
            <div className='flex items-center gap-2 min-h-[25px]'>
                <h3 className='text-xs font-semibold'>Active Filters:</h3>
                {hasFilterOptions && (
                    <>
                        {Object.entries(filterValuesMap)
                            .filter(([_fvGroup, values]) => values.length > 0)
                            .map(([fvGroup, values]) => (
                                <React.Fragment key={fvGroup}>
                                    <span className='text-xs font-medium capitalize'>{fvGroup} = </span>
                                    {values.map((val) => (
                                        <button
                                            key={val}
                                            className='flex gap-1 items-center text-xs font-medium rounded border border-lightgrey dark:border-darkgrey p-1 leading-none bg-bg1 dark:bg-darkbg1'
                                            onClick={() => clearFilterValue(fvGroup, val)}
                                        >
                                            {filterOptionsMap[fvGroup as keyof typeof filterOptionsMap]?.find(
                                                (option: any) => option?.value === val
                                            )?.label || 'Unknown Filter'}
                                            <FaTimes className='text-darkgrey' />{' '}
                                        </button>
                                    ))}
                                </React.Fragment>
                            ))}
                    </>
                )}
            </div>
            {/* ACTIVE FILTERS */}
            {selectedUserId && <EditUserModal userId={parseInt(selectedUserId)} closeModal={toggleEditModal} />}
            {showCreateModal && <CreateUserModal closeModal={toggleCreateModal} />}
            <UserList filters={filters} />
        </div>
    )
}
