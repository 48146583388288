import { useEffect, useRef, useState } from 'react'
import { AiFillCalendar, AiFillCloseCircle } from 'react-icons/ai'

export default function Calendar({ startDate, endDate, setStartDate, setEndDate, getFullDay = false }) {
    const getDaysInMonth = (currentYear, currentMonth) => {
        return 32 - new Date(currentYear, currentMonth, 32).getDate()
    }
    const buildCellClass = (isPrevMonth, isStartDate, isEndDate, isIncludedDate) => {
        let cellClass =
            'p-[2px] m-[1px] text-left bg-white dark:bg-darkaccent text-[10px] h-[40px] flex items-start justify-start relative'
        if (isPrevMonth)
            cellClass =
                cellClass.replace('bg-white', 'bg-black/[.25]').replace('bg-darkaccent', 'bg-blue/[0.25]') +
                ' opacity-[0.5]'
        if (isStartDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.5]') +
                ' rounded-tl-[10px] rounded-bl-[10px] text-white mr-0 font-black'
        if (isEndDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.5]')
                    .replace('opacity-[0.5]', '') + ' rounded-tr-[10px] rounded-br-[10px] text-white ml-0 font-black'
        if (isIncludedDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue/[.25]')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.25]') + ' mx-0'
        return cellClass
    }
    const calendar = useRef()
    const closeBtn = useRef()
    const [currentDayZero, setCurrentDayZero] = useState(new Date(startDate.getFullYear(), startDate.getMonth()))
    const daysInMonth = getDaysInMonth(currentDayZero.getFullYear(), currentDayZero.getMonth())
    const [selectedDateArr, setSelectedDateArr] = useState([startDate, endDate])
    const [tempDate, setTempDate] = useState(null)
    const [open, setOpen] = useState(false)
    const dayArr = []
    const leadingDays = currentDayZero.getDay() - 1

    const handlePushDate = (cellDate) => {
        const newDateArray = [...selectedDateArr]
        newDateArray.unshift(cellDate)
        if (newDateArray.length > 2) {
            setTempDate(newDateArray[0])
        } else {
            setTempDate(null)
        }
        setSelectedDateArr(
            newDateArray.length > 2
                ? newDateArray.slice(0, 1)
                : newDateArray.sort((a, b) => (a.getTime() > b.getTime() ? 1 : -1))
        )
    }
    useEffect(() => {
        if (selectedDateArr.length === 2) {
            setStartDate(
                selectedDateArr[0].getTime() > selectedDateArr[1].getTime() ? selectedDateArr[1] : selectedDateArr[0]
            )
            const endDateHolder =
                selectedDateArr[0].getTime() > selectedDateArr[1].getTime() ? selectedDateArr[0] : selectedDateArr[1]
            const isToday =
                endDateHolder.getTime() ===
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
            setEndDate(
                new Date(
                    endDateHolder.getFullYear(),
                    endDateHolder.getMonth(),
                    endDateHolder.getDate(),
                    isToday ? new Date().getHours() : getFullDay ? 23 : 0,
                    isToday ? new Date().getMinutes() : getFullDay ? 59 : 0,
                    isToday ? new Date().getSeconds() : getFullDay ? 59 : 0,
                    isToday ? new Date().getMilliseconds() : getFullDay ? 999 : 0
                )
            )
        }
    }, [selectedDateArr])
    useEffect(() => {
        if (tempDate) {
            setStartDate(tempDate)
            const isToday =
                tempDate.getTime() ===
                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime()
            setEndDate(
                new Date(
                    tempDate.getFullYear(),
                    tempDate.getMonth(),
                    tempDate.getDate(),
                    isToday ? new Date().getHours() : getFullDay ? 23 : 0,
                    isToday ? new Date().getMinutes() : getFullDay ? 59 : 0,
                    isToday ? new Date().getSeconds() : getFullDay ? 59 : 0,
                    isToday ? new Date().getMilliseconds() : getFullDay ? 999 : 0
                )
            )
        }
    }, [tempDate])
    for (let i = -leadingDays; i <= daysInMonth + 7; i++) {
        const d = new Date(currentDayZero.getFullYear(), currentDayZero.getMonth(), i)
        if (i > daysInMonth && d.getDay() === 0) {
            break
        }
        const hasRange = selectedDateArr.length === 2
        dayArr.push([
            d,
            buildCellClass(
                i < 1 || i > daysInMonth,
                d.toDateString() === selectedDateArr[0].toDateString(),
                hasRange ? d.toDateString() === selectedDateArr[1].toDateString() : false,
                hasRange ? d.getTime() > startDate.getTime() && d.getTime() < endDate.getTime() : false
            ),
        ])
    }
    useEffect(() => {
        const listenToWindow = ({ target }) => {
            if (calendar.current.contains(target) && !closeBtn.current.contains(target)) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [calendar.current])
    return (
        <div className='relative z-4 dark:text-white' ref={calendar}>
            <div onClick={() => setOpen(!open)}>
                <div style={open ? {} : { display: 'none' }} ref={closeBtn}>
                    <AiFillCloseCircle className='h-[24px] w-[24px] fill-blue cursor-pointer dark:fill-accent' />
                </div>
                <div style={open ? { display: 'none' } : {}}>
                    <AiFillCalendar className='h-[24px] w-[24px] fill-blue cursor-pointer dark:fill-accent' />
                </div>
            </div>
            {open && (
                <div className='shadow-small rounded bg-[#eeeeee] dark:bg-darkness p-[4px] absolute top-[100%] right-0'>
                    <div className='flex items-center justify-between mb-[4px]'>
                        <button
                            className='bg-white dark:bg-darkaccent dark:hover:bg-blue rounded border-none font-black text-[#a1a1a1] cursor-pointer px-[8px] py-[4px] hover:bg-[lightgrey]'
                            onClick={() =>
                                setCurrentDayZero(new Date(currentDayZero.setMonth(currentDayZero.getMonth() - 1)))
                            }
                        >{`<`}</button>
                        <p
                            className='font-bold text-[#4a4a4a] dark:text-offwhite cursor-pointer hover:bg-darkaccent px-[4px] rounded'
                            onClick={() => {
                                if (selectedDateArr.length === 2)
                                    handlePushDate(new Date(currentDayZero.getFullYear(), currentDayZero.getMonth(), 1))
                                if (selectedDateArr.length === 1)
                                    handlePushDate(
                                        new Date(currentDayZero.getFullYear(), currentDayZero.getMonth() + 1, 0)
                                    )
                            }}
                        >
                            {currentDayZero.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </p>
                        <button
                            className='bg-white dark:bg-darkaccent dark:hover:bg-blue rounded border-none font-black text-[#a1a1a1] cursor-pointer px-[8px] py-[4px] hover:bg-[lightgrey]'
                            onClick={() =>
                                setCurrentDayZero(new Date(currentDayZero.setMonth(currentDayZero.getMonth() + 1)))
                            }
                        >{`>`}</button>
                    </div>
                    <div className='grid grid-cols-[repeat(7,1fr)] w-[280px]'>
                        {dayArr.map(([d, cellClass]) => (
                            <div onClick={() => handlePushDate(d)} className={cellClass}>
                                {d.getDate()}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
