import { useState } from 'react'
import { useParentCompanyFilesQuery } from '../../api/useQueries'
import ParentFile from '../listItems/ParentFile'
import FilterToolBar from '../FilterToolBar'

export default function ParentFileList({ parentCompany, companyNameMap }: { parentCompany: any; companyNameMap: any }) {
    const [search, setSearch] = useState('')

    const companyFilesQuery = useParentCompanyFilesQuery({ parentCompany, companyNameMap })
    const { files }: { files: any[] } = companyFilesQuery?.data || {}

    const filteredFiles = files?.filter((file) => {
        return (
            file.name?.toLowerCase().includes(search.toLowerCase()) ||
            file.mime_type?.toLowerCase().includes(search.toLowerCase())
        )
    })

    return (
        <div className='p-[16px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={undefined}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px]'}
                />
            </div>
            <div className='flex flex-col overflow-auto'>
                <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Company</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Type</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>File Mime Type</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Uploaded On</div>
                </div>
                {filteredFiles?.map((file: any) => <ParentFile key={file.id} file={file} />)}
            </div>
        </div>
    )
}
