import { useInfiniteQuery } from '@tanstack/react-query'
import { auditQueries } from './auditQueryKeys'

export const AUDIT_TABLE_QUERY_KEY = ['audit', 'auditHistory']

export function useAuditHistoryQuery({
    table,
    record_id,
    parent_id,
    filters,
    limit,
}: {
    table: string
    record_id?: string | number
    parent_id?: string | number
    filters?: { user_ids: number[]; fields: string[] }
    limit?: number
}): any {
    const query = useInfiniteQuery({
        ...auditQueries.audit.auditHistory(table, record_id, parent_id, filters, limit),
        initialPageParam: 1,
        getNextPageParam: (lastPage: any, pages) => lastPage.nextCursor ?? undefined,
        staleTime: 50000,
    })

    return query
}
