import { Button } from 'custom_components/component_Basics/Button'
import { cn, vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

export default function DeleteTokenModal({ setShowDeleteToken }: any) {
    const user = useSelector<any, any>((state) => state.user)

    const modalRef = useRef<HTMLDivElement>(null)
    const [selectedUserId, setSelectedUserId] = useState('')

    const handleDeleteToken = () => {
        vFetch(`/v2/google/auth?user_id=${selectedUserId}`, {
            method: 'DELETE',
            cb: (res: any) => {
                if (res.success) {
                    setShowDeleteToken(false)
                }
            },
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                setShowDeleteToken(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className={cn('bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md flex gap-4')}>
                <div className='flex flex-col gap-4'>
                    <p>Input user_id to delete gmail_api_token</p>
                    <Input
                        id='user_id'
                        name='user_id'
                        type='text'
                        value={selectedUserId}
                        onChange={({ target }) => setSelectedUserId(target.value)}
                        className='dark:bg-darkness w-[100%] rounded px-[2px] text-[16px] py-[3px]'
                    />
                    <Button onClick={handleDeleteToken} variant={'outline'} size={'sm'}>
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    )
}
