import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { MdChat } from 'react-icons/md'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import UsersName from './UsersName'
import { useSearchParams } from 'react-router-dom'

// handles each question layout, like/unlike functions
export default function SearchResultsQuestion({ questionRow, setView, setSearch }: any) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}
    const user = users?.find((user) => {
        return user.id === questionRow.user_id
    })

    const handleSetQuestion = () => {
        setView('qna')
        setSearch('')
        setSearchParams((prev: any) => {
            prev.set('section', 'qna')
            return prev
        })
    }

    return (
        <>
            <div className='shadow-md border rounded border-lightgrey dark:border-darkgrey'>
                <div className='gap-3 pt-2 pl-2'>
                    <div className='flex justify-between gap-2'>
                        <div className='flex'>
                            <div className='shrink-0 rounded-full flex items-center justify-center w-[50px] h-[50px] text-2xl font-bold text-white dark:text-offwhite bg-orange-500'>
                                {user?.first_name[0]}
                                {user?.last_name[0]}
                            </div>
                            <div className='ml-2'>
                                <button onClick={handleSetQuestion}>
                                    <div className='flex gap-1'>
                                        <div className='font-semibold text-lg text-left'>{questionRow.question}</div>
                                        <div className='opacity-50 text-lg'>/Question</div>
                                    </div>
                                    <div className='mb-2'>
                                        <UsersName questionRow={questionRow} verb={'asked'} textSize={'text-sm'} />
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex items-center gap-2 mt-2 pr-2 font-bold mr-2'>
                                <button onClick={handleSetQuestion} className='flex flex-col gap-1 items-center'>
                                    <MdChat className='h-[24px] w-[24px] text-darkgrey dark:text-white' />
                                    <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                        {questionRow.total_answers_per_question}
                                    </span>
                                </button>
                                {questionRow.is_liked ? (
                                    <button className='flex flex-col gap-1 items-center'>
                                        <FaHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                        <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                            {questionRow.total_question_likes}
                                        </span>
                                    </button>
                                ) : (
                                    <button className='flex flex-col gap-1 items-center'>
                                        <FaRegHeart className='h-[24px] w-[24px] text-red/75 dark:text-white' />
                                        <span className='block leading-none text-xs text-darkgrey dark:text-offwhite'>
                                            {questionRow.total_question_likes}
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
