import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { useDebounce } from 'helpers'
import { useContext, useState } from 'react'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetTaskEvents, useGetTasks } from 'tasksV2/api/useQueries'
import {
    useCompanyContactsQuery,
    useCompanyDetailTasksQuery,
    useCompanyEmailsQuery,
    useCompanyFilesQuery,
    useCompanyIncomingEmailsQuery,
    useCompanyNotesQuery,
    useTasksQuery,
} from '../../api/useQueries'
import { DATE_TODAY, defaultTaskFilterParams } from '../../constants'
import { MONTH_NAME_MAP, ProcurementContext, ProcurementContextType, createdAtSort } from '../../helpers'
import FilterToolBar from '../FilterToolBar'
import BasicEvent from '../listItems/BasicEvent'
import BasicEventGroup from '../listItems/BasicEventGroup'
import BasicEventSkeleton from '../listItems/BasicEventSkeleton'
import CompanyEmail from '../listItems/CompanyEmail'
import CompanyNoteV2 from '../listItems/CompanyNoteV2'
import IncomingEmail from '../listItems/IncomingEmail'
import { CompanyTaskType } from 'procurement/Procurements.types'

export default function ActivityListV2({ company }: any) {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [search, setSearch] = useState('')
    const queryClient = useQueryClient()
    const debouncedSearch = useDebounce(search.trim(), 500)
    const [filters, setFilters] = useState<any>({
        activityTypes: [],
    })

    const activityTypeOptions = ['Edits', 'Emails', 'Notes', 'Tasks']

    const companyContactsQuery = useCompanyContactsQuery({ companyId: selectedCompany.id })
    const { isLoading: loadingContacts } = companyContactsQuery
    const { contacts } = companyContactsQuery.data || {}

    const companyEventsQuery = useCompanyNotesQuery({ companyId: selectedCompany.id, users, search: debouncedSearch })
    const { isLoading: loadingEvents } = companyEventsQuery
    const { events }: { events: EventType[] } = companyEventsQuery?.data || {}

    const edits = events?.filter((event) => event.type != 'note')
    const notes = events?.filter((event) => event.type == 'note')

    const companyTasksQuery = useCompanyDetailTasksQuery({ companyId: selectedCompany.id, search: debouncedSearch })
    const { isLoading: loadingTasks } = companyTasksQuery
    const { tasks }: { tasks: CompanyTaskType[] } = companyTasksQuery?.data || {}

    const tasksQuery = useGetTasks(
        { resourceTable: 'company_tasks', resourceIds: [selectedCompany.id], search: debouncedSearch },
        keepPreviousData
    )
    const { tasks: tasksV2 } = tasksQuery?.data || {}

    const taskIds = tasksV2?.map((taskV2: any) => taskV2.id.toString())
    const taskEventsQuery = useGetTaskEvents(taskIds)
    const { events: taskEvents } = taskEventsQuery?.data || {}

    tasksV2?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const companyIncomingEmailsQuery = useCompanyIncomingEmailsQuery({
        companyId: company.id,
        companyEmail: company.email,
        contactList: contacts || [],
        search: debouncedSearch,
    })
    const { isLoading: loadingIncomingEmails } = companyIncomingEmailsQuery
    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}

    const companyEmailsQuery = useCompanyEmailsQuery({ companyId: selectedCompany.id, search: debouncedSearch })
    const { isLoading: loadingEmails } = companyEmailsQuery
    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const isLoading = loadingContacts || loadingEvents || loadingTasks || loadingIncomingEmails || loadingEmails

    // seed cache for other tabs
    useTasksQuery({
        filters: {
            ...defaultTaskFilterParams,
            company_id: selectedCompany.id,
            statuses: ['OPEN'],
            pageParam: 1,
            selectedTaskDueDate: DATE_TODAY.toISOString(),
        },
        search: '',
    })
    useCompanyFilesQuery({ companyId: selectedCompany.id })

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: activityTypeOptions?.map((activity: string) => {
                    return {
                        value: activity,
                        label: activity,
                        icon: undefined,
                    }
                }),
                title: 'Event Types',
                field: 'activityTypes',
                values: filters.activityTypes,
                searchToggle: false,
                editSearchParams: false,
            },
        ],
        setFunction: setFilters,
        resetFunction: () => setFilters({ activityTypes: [] }),
    }

    let activities: EventType[] = []
    if ((notes && filters.activityTypes.length < 1) || (notes && filters.activityTypes.includes('Notes'))) {
        activities.push(...notes)
    }
    if ((edits && filters.activityTypes.length < 1) || (edits && filters.activityTypes.includes('Edits'))) {
        activities.push(...edits)
    }
    if ((tasksV2 && filters.activityTypes.length < 1) || (tasksV2 && filters.activityTypes.includes('Tasks'))) {
        activities = [
            ...activities,
            taskEvents
                ? taskEvents?.map((event: any) => {
                      return { ...event, message: event.message.replaceAll('this task', `task #${event.new_value.id}`) }
                  })
                : [],
            tasksV2
                ? tasksV2
                      ?.filter((task: any) => task.status != 'Archived')
                      ?.map((task: CompanyTaskType) => ({ ...task, type: 'task' }) as any as EventType)
                : [],
        ]
            .flat()
            .filter((v) => v)
    }
    if ((emails && filters.activityTypes.length < 1) || (emails && filters.activityTypes.includes('Emails'))) {
        activities = [...activities, ...emails?.map((email) => ({ ...email, type: 'email' }) as any as EventType)]
    }
    if (
        (incomingEmails && filters.activityTypes.length < 1) ||
        (incomingEmails && filters.activityTypes.includes('Emails'))
    ) {
        activities = [
            ...activities,
            ...incomingEmails?.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType),
        ]
    }
    activities = activities.sort(createdAtSort)
    const tempMap: any = {}
    for (const event of activities) {
        const createdAtDate = new Date(event.created_at || (event as any).delivered_at)
        const key = `${
            MONTH_NAME_MAP[createdAtDate.getMonth() as keyof typeof MONTH_NAME_MAP]
        } ${createdAtDate.getFullYear()}`
        if (!tempMap[key]) {
            tempMap[key] = []
        }
        tempMap[key].push(event)
    }
    const monthMap = tempMap

    return (
        <div className='p-[2px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px] sticky top-[32px] z-40 bg-bg1'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={companyFilterBarObject}>
                    {companyFilterBarObject.params.length &&
                        companyFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.title + param.field}
                                    searchToggle={param.searchToggle}
                                    editSearchParams={param.editSearchParams}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={companyFilterBarObject.setFunction}
                                />
                            )
                        })}
                </FilterToolBar>
            </div>
            {isLoading && (
                <div className='flex flex-col gap-3'>
                    {skeletons.map((skeleton) => (
                        <BasicEventSkeleton key={skeleton} />
                    ))}
                </div>
            )}
            {!isLoading && (
                <div className='flex flex-col gap-[8px] min-h-[300px]'>
                    {Object.entries(monthMap)?.map(([key, value]: any) => (
                        <div key={key + value} className='flex flex-col gap-[8px]'>
                            <div
                                key={key}
                                className='max-w-full text-[18px] p-[8px] border-b border-darklightgrey dark:border-darkgrey mb-[8px]'
                            >
                                {key}
                            </div>
                            {value.map((event: any) => {
                                if (event.type === 'note') return <CompanyNoteV2 key={event.id} note={event} />
                                if (event.type === 'task') {
                                    return (
                                        <div className='px-1' key={event.id.toString() + 'procurementTask'}>
                                            <TaskEditCreateModalV2 taskId={event.id.toString()} createEvent={false} />
                                        </div>
                                    )
                                }
                                if (event.type === 'email') {
                                    return <CompanyEmail key={event.id} email={event} />
                                }
                                if (event.type === 'incoming-email') {
                                    return <IncomingEmail key={event.thread_id} email={event} />
                                }
                                if (event.type === 'basic-event-group') {
                                    return <BasicEventGroup key={event.created_at + event.events[0].id} event={event} />
                                }
                                return <BasicEvent key={event.id} event={event} />
                            })}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
