import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { FetchOptionSelector } from 'development/components/FetchOptionSelector'
import { useCreatePrivateEvent, useGetPrivateEvents } from 'privateEvents/api/useQueries'
import { formatDateSimple } from 'procurement/helpers'
import React, { useEffect, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp, FaCheck, FaTimes } from 'react-icons/fa'
import { IoIosArrowDown } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'
import queryKeys from 'tasksV2/api/queryKeys'
import { useDeleteTask, useGetTask, useUpdateTask } from 'tasksV2/api/useQueries'
import { PRIORITY_MAP } from 'tasksV2/constants/tasks'
import Assignment from 'tasksV2/tasks/components/Assignment'
import Association from 'tasksV2/tasks/components/Association'
import Department from 'tasksV2/tasks/components/Department'
import LineItemAssociation from 'tasksV2/tasks/components/LineItemAssociation'
import TaskAlert from 'tasksV2/tasks/components/TaskAlert'
import TaskScreenDueAt from 'tasksV2/tasks/components/TaskScreenDueAt'
import TaskStatus from 'tasksV2/tasks/components/TaskStatus'
import TaskType from 'tasksV2/tasks/components/TaskType'
import LineItemLinkModal from 'tasksV2/tasks/modals/LineItemLinkModal'
import TaskAssignmentsModal from 'tasksV2/tasks/modals/TaskAssignmentsModal'
import TaskAssociationsModal from 'tasksV2/tasks/modals/TaskAssociationsModal'
import PinnedEscalatedIcons from 'tasksV2/tasks/PinnedEscalatedIcons'
import { useAllUsersQuery } from 'users/api/useQueries'
import Modal from '../../custom_components/Modal'
import { closePopout, cn, sendToastWarning } from '../../helpers'
import { taskVariables } from '../TaskVariables'
import PrivateEvent from './PrivateEvent'
import PrivateEventModal from './PrivateEventModal'
import DraftOrderLineItemLinkModal from 'tasksV2/tasks/modals/DraftOrderLineItemLinkModal'

type TaskEditCreateModalProps = {
    taskId?: string
    createEvent: boolean
}

export const TASKS_PRIVATE_EVENT_URL = '/v1/taskEvents'
export default function TaskEditCreateModalV2({ createEvent, taskId }: TaskEditCreateModalProps) {
    const { orderId } = useParams()
    const user = useSelector<any, any>((state) => state.user)
    const location = useLocation()
    const { taskTypeColors }: any = taskVariables

    const [editNote, setEditNote] = useState(false)
    const noteRef = useRef<HTMLDivElement>(null)
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const [showLinkModal, setShowLinkModal] = useState(false)
    const [editedFields, setEditedFields] = useState<any>({})
    const [showTaskEvents, setShowTaskEvents] = useState(false)
    const [showTaskActions, setShowTaskActions] = useState(false)
    const [showAreYouSureDelete, setShowAreYouSureDelete] = useState(false)
    const [popover, setPopover] = useState<undefined | string>()
    const [dismountingPopover, setDismountingPopover] = useState(false)
    const [initRender, setInitRender] = useState(true)
    const [showDueAtEditor, setShowDueAtEditor] = useState(false)
    const popoverRef: any = useRef()
    const edited =
        Object.keys(editedFields).filter((key: any) => key != 'associations' && key != 'disassociations').length > 0

    const queryClient = useQueryClient()

    const createPrivateEvent = useCreatePrivateEvent()

    const privateEventsQuery = useGetPrivateEvents({
        privateEventUrl: TASKS_PRIVATE_EVENT_URL,
        element: 'task_id',
        id: taskId,
        enabled: createEvent,
    })
    const { taskEvents } = privateEventsQuery?.data || {}

    const taskQuery = useGetTask(taskId || '')
    const task = taskQuery.data?.task || {}

    const updateTaskMutation = useUpdateTask(task.id || '')
    const deleteTaskMutation = useDeleteTask(task.id || '')
    // seed
    useAllUsersQuery()

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setEditedFields({
            ...editedFields,
            [target.name]: target.value,
        })
    }
    const taskActions: any = {
        'Delete Task': () => setShowAreYouSureDelete(true),
    }

    useEffect(() => {
        if (initRender === true && taskId && createEvent) {
            createPrivateEvent.mutate({ id: taskId, user_id: user.id, privateEventUrl: TASKS_PRIVATE_EVENT_URL })
            setInitRender(false)
        }
    }, [])

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setEditedFields((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = structuredClone(task.associations)
            }
            if (!newState.disassociations) {
                newState.disassociations = []
            }
            if (
                newState.associations.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.disassociations.push(association)
                return newState
            } else {
                newState.disassociations = newState.disassociations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.associations.push(association)
                return newState
            }
        })
    }

    const setDate = (HTMLDateString: string) => {
        setEditedFields({
            ...editedFields,
            HTMLDateString: HTMLDateString,
        })
    }
    const setTime = (HTMLTimeString: string) => {
        setEditedFields({
            ...editedFields,
            HTMLTimeString: HTMLTimeString,
        })
    }
    function parseHTMLInputToDate(htmlDate: string) {
        return new Date(
            `${htmlDate ? htmlDate : parseDateToHTMLInput(new Date(task.due_at))}T${
                editedFields.HTMLTimeString ??
                `${`0${new Date(task.due_at).getHours()}`.slice(-2)}:${`0${new Date(task.due_at).getMinutes()}`.slice(
                    -2
                )}`
            }:00Z`
        ).toISOString()
    }
    function parseDateToHTMLInput(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return `${year}-${month}-${day}`
    }
    const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    }).format

    const handleDeleteTask = () => {
        deleteTaskMutation.mutate(undefined, {
            onSuccess: () => {
                setShowAreYouSureDelete(false)
            },
        })
    }
    const handleSave = () => {
        if (editedFields?.associations?.filter((association: any) => association.resource_name === 'user').length < 1) {
            return sendToastWarning({ message: 'Task must be assigned to a user' })
        }
        const parsedUpdates: any = {}
        if (editedFields.associations) {
            const associations = editedFields.associations.filter(
                (association: any) =>
                    !task.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            const disassociations = task.associations.filter(
                (association: any) =>
                    !editedFields.associations.find(
                        (resource: any) =>
                            association.resource_name === resource.resource_name &&
                            association.resource_id === resource.resource_id
                    )
            )
            parsedUpdates.associations = associations
            parsedUpdates.disassociations = disassociations
        }
        const selectedDate = parseHTMLInputToDate(editedFields.HTMLDateString)
        if (!editedFields.HTMLDateString) {
            queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        task: { ...oldData.task, ...editedFields },
                    }
                }
            })
        }
        updateTaskMutation.mutate(
            {
                id: task.id,
                ...editedFields,
                ...parsedUpdates,
                due_at: selectedDate,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                    setShowDueAtEditor(false)
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: any) => closePopout(e, [`js-newTask-actions__${taskId}`], setShowTaskActions)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [taskId])

    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const inputStyle =
        'bg-lightgrey dark:bg-darkness p-[4px] border-[1px] border-transparent focus:border-darkgrey focus:dark:border-accent outline-0 rounded'

    const handleChangePinned = () => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.pinned) {
            return
        }

        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, pinned: task.pinned ? 0 : 1 },
                }
            }
        })
        updateTaskMutation.mutate({
            id: task.id,
            pinned: task.pinned ? 0 : 1,
        })
    }
    const handleChangeComplete = () => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.status) {
            return
        }

        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, status: task.status === 'Completed' ? 'To Do' : 'Completed' },
                }
            }
        })
        const selectedDate = parseHTMLInputToDate(editedFields.HTMLDateString)
        updateTaskMutation.mutate(
            {
                id: task.id,
                ...editedFields,
                due_at: selectedDate,
                status: task.status === 'Completed' ? 'To Do' : 'Completed',
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                    setShowDueAtEditor(false)
                },
            }
        )
    }

    const handleInstantChange = (newStatus: string) => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.status) {
            return
        }

        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, status: newStatus },
                }
            }
        })
        updateTaskMutation.mutate(
            {
                id: task.id,
                ...editedFields,
                associations:
                    task.status === 'Completed'
                        ? [
                              {
                                  resource_name: 'user',
                                  resource_id: user.id,
                                  locked: 1,
                                  readable_name: `${user.firstName} ${user.lastName}`,
                              },
                          ]
                        : undefined,
                disassociations:
                    task.status === 'Completed'
                        ? task.associations.filter((association: any) => association.resource_name === 'user')
                        : undefined,
                status: newStatus,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                    setShowDueAtEditor(false)
                },
            }
        )
    }
    const handleDisassociation = (association: any) => {
        updateTaskMutation.mutate({
            id: task.id,
            disassociations: [association],
        })
    }
    const handleChangeEscalated = () => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.is_escalated) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, is_escalated: task.is_escalated ? 0 : 1 },
                }
            }
        })
        updateTaskMutation.mutate({
            id: task.id,
            is_escalated: task.is_escalated ? 0 : 1,
        })
    }
    const handleChangePriority = (priority: string) => {
        if (updateTaskMutation.isPending && updateTaskMutation.variables.is_escalated) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, priority },
                }
            }
        })

        updateTaskMutation.mutate({
            id: task.id,
            priority,
        })
    }
    const handleTogglePopover = (target: string) => {
        if (target !== popover) {
            return setPopover(target)
        }
        setDismountingPopover(true)
        setTimeout(() => {
            setPopover(undefined)
            setDismountingPopover(false)
        }, 200)
    }
    useEffect(() => {
        const listenToWindow = (e: any) => {
            if (e.target !== popoverRef.current) {
                setPopover(undefined)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [popover])

    const { icon: Priority, className: priorityClassName } =
        PRIORITY_MAP[(task?.priority as keyof typeof PRIORITY_MAP) || 'Low']

    const priorityOptions = [
        {
            label: 'Low',
            value: 'Low',
            icon: PRIORITY_MAP['Low' as keyof typeof PRIORITY_MAP].icon,
            className: PRIORITY_MAP['Low' as keyof typeof PRIORITY_MAP].className,
        },
        {
            label: 'Medium',
            value: 'Medium',
            icon: PRIORITY_MAP['Medium' as keyof typeof PRIORITY_MAP].icon,
            className: PRIORITY_MAP['Medium' as keyof typeof PRIORITY_MAP].className,
        },
        {
            label: 'High',
            value: 'High',
            icon: PRIORITY_MAP['High' as keyof typeof PRIORITY_MAP].icon,
            className: PRIORITY_MAP['High' as keyof typeof PRIORITY_MAP].className,
        },
    ]
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!noteRef.current?.contains(e.target as Element)) {
                setTimeout(() => {
                    setEditNote(false)
                }, 100)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    if (!taskId || !task.id) {
        return (
            <div className='min-h-[320px] relative flex flex-col gap-3 p-2 bg-lightgrey/50 dark:bg-darkaccent shadow-small rounded'>
                <div className='mx-auto my-auto'>
                    <FetchingSpinner isFetching={true} />
                </div>
            </div>
        )
    }

    if (location.pathname.includes('orders') || location.pathname.includes('draft-orders')) {
        const relatedAssociations = task.associations?.filter((association: any) => {
            return (
                association.resource_name !== 'user' &&
                association.resource_id != orderId &&
                association.resource_name !== 'order_line_item' &&
                association.resource_name !== 'draft_order_line_item'
            )
        })
        const relatedLineItems = task.associations?.filter(
            (association: any) =>
                association.resource_name === 'order_line_item' || association.resource_name === 'draft_order_line_item'
        )
        return (
            <div>
                {taskEvents && showTaskEvents && (
                    <PrivateEventModal origin='task' setViewModal={setShowTaskEvents} events={taskEvents} />
                )}
                {showAreYouSureDelete && (
                    <Modal setShow={() => setShowAreYouSureDelete(false)} z={50}>
                        <div className='flex flex-col gap-[16px] p-[16px] bg-white dark:bg-darkaccent shadow-small rounded dark:border dark:border-darkgrey'>
                            <p>Are you sure ?</p>

                            <div className='flex justify-between gap-4'>
                                <Button
                                    variant={'destructive'}
                                    size={'sm'}
                                    className={' border border-lightgrey hover:bg-lightgrey'}
                                    onClick={handleDeleteTask}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant={'outline'}
                                    size={'sm'}
                                    className={''}
                                    onClick={() => setShowAreYouSureDelete(false)}
                                >
                                    No
                                </Button>
                            </div>
                        </div>
                    </Modal>
                )}
                <div className='relative flex flex-col gap-3 p-2 bg-white dark:bg-darkaccent shadow-small rounded'>
                    <div>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div>
                                <PrivateEvent events={taskEvents} setViewModal={setShowTaskEvents} />
                            </div>
                        )}
                        <div className='flex justify-between'>
                            <div className={`flex flex-col gap-[8px]`}>
                                <div className='flex gap-[8px]'>
                                    <div
                                        id={task.id}
                                        className='flex gap-2 items-center relative border bg-lightgrey border-lightgrey rounded cursor-pointer hover:bg-lightgrey dark:bg-darkbg2 dark:border-darkgrey'
                                    >
                                        <div
                                            onClick={(e: any) => {
                                                e.stopPropagation()
                                                return handleTogglePopover('priority')
                                            }}
                                            className='flex gap-2 items-center'
                                        >
                                            <Priority className={`text-[20px] ${priorityClassName}`} />
                                            <IoIosArrowDown />
                                        </div>

                                        {popover === 'priority' && (
                                            <FetchOptionSelector
                                                ref={popoverRef}
                                                options={priorityOptions}
                                                selectedOption={task.priority}
                                                onClick={(option: string) => handleChangePriority(option)}
                                                isPending={false}
                                                className='left-0 translate-x-0'
                                                searchText='Set priority...'
                                                dismountingPopover={dismountingPopover}
                                            />
                                        )}
                                    </div>
                                    <span className={`font-semibold ${taskTypeColors[task.department_title ?? '']}`}>
                                        <div key={task.id + 'department'}>
                                            <Department
                                                departmentId={task.department_id}
                                                title={task.department_title}
                                            />
                                        </div>
                                    </span>

                                    <Link
                                        to={`/tasks/${taskId}`}
                                        className='text-blue dark:text-accent cursor-pointer hover:underline'
                                    >
                                        #{taskId}
                                    </Link>

                                    <div className='flex gap-1 flex-wrap'>
                                        {task?.associations
                                            ?.filter((association: any) => association.resource_name === 'user')
                                            ?.map((association: any) => (
                                                <Assignment key={association.resource_id} association={association} />
                                            ))}
                                    </div>
                                </div>
                            </div>

                            <div className='flex flex-col gap-[8px] items-end'>
                                <div className='flex gap-1'>
                                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                        <Button
                                            onClick={() => setShowAssignments(true)}
                                            variant={'outline'}
                                            size={'sm'}
                                            className='p-1 leading-none h-fit dark:border-darkgrey'
                                        >
                                            Edit Users
                                        </Button>
                                    )}
                                    <div className='flex gap-1 text-xl'>
                                        <PinnedEscalatedIcons
                                            taskId={task.id.toString()}
                                            mutation={updateTaskMutation}
                                            pinned={task.user_pinned}
                                            is_escalated={task.is_escalated}
                                        />
                                    </div>
                                    {user.type.match(/admin/i) && (
                                        <div className='relative'>
                                            <button
                                                className={`js-newTask-actions__${taskId} flex justify-center items-center relative w-[35px] h-[15px] text-grey hover:text-darkgrey dark:hover:text-offwhite`}
                                                onClick={() => setShowTaskActions(!showTaskActions)}
                                            >
                                                <span className='js-order__dot-button text-[20px] font-black tracking-wider leading-[0px] bottom-[4px] relative'>
                                                    ...
                                                </span>
                                            </button>
                                            {showTaskActions && (
                                                <div className='absolute top-full right-0 flex flex-col p-[8px] bg-white dark:bg-darkaccent shadow-small gap-[8px] border-[1px] border-darkgrey'>
                                                    <h3 className='text-[14px] font-semibold'>Admin Actions</h3>
                                                    <div className='flex flex-col border-grey border-[1px]'>
                                                        {Object.keys(taskActions).map((action, index) => (
                                                            <div
                                                                key={index}
                                                                className='hover:bg-black/20 px-[8px] py-[2px] cursor-pointer whitespace-nowrap'
                                                                onClick={() => taskActions[action]()}
                                                            >
                                                                {action}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='flex gap-1 justify-between mt-1'>
                            <div className='flex gap-2'>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className={labelStyle}>Topic</label>
                                    <div className='max-w-[240px]'>
                                        <TaskType
                                            taskId={task.id}
                                            departmentId={task.department_id}
                                            typeId={task.type_id}
                                            className='bg-lightgrey dark:bg-darkbg2 min-w-[75px]'
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1 '>
                                    <label className={labelStyle}>Status</label>
                                    <TaskStatus
                                        taskId={task.id || -1}
                                        status={task.status}
                                        handleInstantChange={handleInstantChange}
                                        dueAt={task.due_at}
                                        className='p-0 bg-lightgrey dark:bg-darkbg2'
                                    />
                                </div>
                                {relatedLineItems.length > 0 && (
                                    <div className='flex flex-col gap-[4px] min-w-[100px] '>
                                        <label className={labelStyle}>Line Item</label>

                                        <div className={`flex  gap-1 flex-wrap`}>
                                            {relatedLineItems?.map((association: any) => (
                                                <React.Fragment key={association.id}>
                                                    <LineItemAssociation
                                                        id={task.id}
                                                        association={association}
                                                        className={'flex gap-2'}
                                                    >
                                                        <Button
                                                            size={'icon'}
                                                            variant={'outline'}
                                                            className='p-[2px] h-5 w-5 border-none'
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleDisassociation(association)
                                                            }}
                                                        >
                                                            <FaTimes />
                                                        </Button>
                                                    </LineItemAssociation>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setShowLinkModal(true)
                                            }}
                                            variant={'outline'}
                                            size={'sm'}
                                            className='h-[23px] w-fit dark:border-darkgrey'
                                        >
                                            +Link
                                        </Button>
                                    </div>
                                )}
                                {relatedLineItems.length == 0 && (
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className={labelStyle}>Line Item</label>
                                        <div className={`flex  gap-1`}>
                                            <Button
                                                onClick={() => {
                                                    setShowLinkModal(true)
                                                }}
                                                variant={'outline'}
                                                size={'sm'}
                                                className='h-[23px] w-fit dark:border-darkgrey'
                                            >
                                                +Link
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                {relatedAssociations.length > 0 && (
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className={labelStyle}>Related</label>
                                        <div className={`flex  gap-1 flex-wrap`}>
                                            {relatedAssociations?.map((association: any) => (
                                                <React.Fragment key={association.id}>
                                                    <Association
                                                        id={task.id}
                                                        association={association}
                                                        className={'flex gap-2'}
                                                    >
                                                        <Button
                                                            size={'icon'}
                                                            variant={'outline'}
                                                            className='p-[2px] h-5 w-5 border-none'
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                handleDisassociation(association)
                                                            }}
                                                        >
                                                            <FaTimes />
                                                        </Button>
                                                    </Association>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setShowAssociations(true)
                                            }}
                                            variant={'outline'}
                                            size={'sm'}
                                            className='h-[23px] w-fit dark:border-darkgrey'
                                        >
                                            +Edit
                                        </Button>
                                    </div>
                                )}
                                {relatedAssociations.length == 0 && (
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className={labelStyle}>Related</label>
                                        <div className={`flex  gap-1 `}>
                                            <Button
                                                onClick={() => {
                                                    setShowAssociations(true)
                                                }}
                                                variant={'outline'}
                                                size={'sm'}
                                                className='h-[23px] w-fit dark:border-darkgrey'
                                            >
                                                +Edit
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[16px]'>
                        <div className='flex flex-col gap-[4px] grow'>
                            <label className={labelStyle}>Latest Activity</label>
                            <textarea
                                value={editedFields.title ?? task.title}
                                name='title'
                                className={`${inputStyle} max-h-20`}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    {task.allow_descriptions === 1 && (
                        <div className='flex gap-[16px]'>
                            <div className='flex flex-col gap-[4px] grow'>
                                <label className={labelStyle}>Additional Details</label>
                                <textarea
                                    value={editedFields.description ?? task.description}
                                    name='description'
                                    className={`${inputStyle} max-h-20`}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    )}
                    <div className='flex justify-between gap-[16px]'>
                        <div className='flex flex-col gap-[4px] relative w-[49%]'>
                            <TaskScreenDueAt
                                taskId={task.id || -1}
                                dueAt={task.due_at}
                                mutation={updateTaskMutation}
                                setParentDate={setDate}
                                setParentTime={setTime}
                                className='bg-lightgrey dark:bg-darkbg2'
                            />
                        </div>
                        <TaskAlert
                            taskId={task.id || -1}
                            alertOffset={task.alert_offset}
                            sendEmailAlert={task.send_email_alert}
                            sendSlackAlert={task.send_slack_alert}
                            mutation={updateTaskMutation}
                            parentAlertDetails={editedFields}
                            setParentAlertDetails={setEditedFields}
                        />
                    </div>
                </div>
                {edited && (
                    <div className='flex justify-end items-center gap-3'>
                        <FetchingSpinner isFetching={updateTaskMutation.isPending} />
                        <button
                            className={cn(
                                'bg-blue dark:bg-accent px-[8px] py-[2px] rounded-b text-white dark:text-black font-semibold',
                                updateTaskMutation.isPending && 'opacity-75 pointer-events-none cursor-default'
                            )}
                            onClick={handleSave}
                        >
                            Submit Changes
                        </button>
                    </div>
                )}
                {showAssignments && task.associations && (
                    <TaskAssignmentsModal
                        closeModal={() => {
                            setShowAssignments(false)
                        }}
                        handleSave={handleSave}
                        associations={editedFields.associations ?? task.associations}
                        toggleAssociation={toggleAssociations}
                        handleReset={() => {
                            setEditedFields((prev: any) => {
                                const newState = structuredClone(prev)
                                newState.associations = structuredClone(task.associations)
                                return newState
                            })
                        }}
                    />
                )}
                {showAssociations && task.associations && (
                    <TaskAssociationsModal
                        closeModal={() => {
                            setShowAssociations(false)
                        }}
                        handleSave={handleSave}
                        associations={editedFields.associations ?? task.associations}
                        toggleAssociation={toggleAssociations}
                        handleReset={() => {
                            setEditedFields((prev: any) => {
                                const newState = structuredClone(prev)
                                newState.associations = structuredClone(task.associations)
                                return newState
                            })
                        }}
                    />
                )}
                {showLinkModal && !location.pathname.includes('draft') && (
                    <LineItemLinkModal
                        orderIds={task?.associations
                            .filter((association: any) => association.resource_name === 'order')
                            ?.map((association: any) => association.resource_id)}
                        closeModal={() => {
                            setShowLinkModal(false)
                        }}
                        associations={editedFields.associations ?? task.associations}
                        toggleAssociation={toggleAssociations}
                        handleSave={handleSave}
                        handleReset={() => {
                            setEditedFields((prev: any) => {
                                const newState = structuredClone(prev)
                                newState.associations = structuredClone(task.associations)
                                return newState
                            })
                        }}
                    />
                )}
                {showLinkModal && location.pathname.includes('draft') && (
                    <DraftOrderLineItemLinkModal
                        draftOrderIds={task?.associations
                            .filter((association: any) => association.resource_name === 'draft_order')
                            ?.map((association: any) => association.resource_id)}
                        closeModal={() => {
                            setShowLinkModal(false)
                        }}
                        associations={editedFields.associations ?? task.associations}
                        toggleAssociation={toggleAssociations}
                        handleSave={handleSave}
                        handleReset={() => {
                            setEditedFields((prev: any) => {
                                const newState = structuredClone(prev)
                                newState.associations = structuredClone(task.associations)
                                return newState
                            })
                        }}
                    />
                )}
            </div>
        )
    }

    if (
        location.pathname.includes('procurement') ||
        location.pathname.includes('vendors') ||
        location.pathname.includes('customers') ||
        location.pathname.includes('tax-exemptions')
    ) {
        return (
            <React.Fragment>
                <div>
                    {taskEvents && showTaskEvents && (
                        <PrivateEventModal origin='task' setViewModal={setShowTaskEvents} events={taskEvents} />
                    )}
                    {showAreYouSureDelete && (
                        <Modal setShow={() => setShowAreYouSureDelete(false)} z={50}>
                            <div className='flex flex-col gap-[16px] p-[16px] bg-white dark:bg-darkaccent shadow-small rounded dark:border dark:border-darkgrey'>
                                <p>Are you sure ?</p>

                                <div className='flex justify-between gap-4'>
                                    <Button
                                        variant={'destructive'}
                                        size={'sm'}
                                        className={' border border-lightgrey hover:bg-lightgrey'}
                                        onClick={handleDeleteTask}
                                    >
                                        Delete
                                    </Button>
                                    <Button
                                        variant={'outline'}
                                        size={'sm'}
                                        className={''}
                                        onClick={() => setShowAreYouSureDelete(false)}
                                    >
                                        No
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    <div className='relative flex flex-col gap-1 p-2 bg-white dark:bg-darkaccent shadow-small rounded mb-2'>
                        <div>
                            <div className='grid grid-cols-[2fr_3fr_1fr] gap-1'>
                                <div className={`flex gap-2 items-center`}>
                                    <div className='flex gap-2 items-center'>
                                        <div
                                            id={task.id}
                                            className='flex gap-2 items-center relative border bg-lightgrey border-lightgrey rounded cursor-pointer hover:bg-lightgrey dark:bg-darkbg2 dark:border-darkgrey'
                                        >
                                            <div
                                                onClick={(e: any) => {
                                                    e.stopPropagation()
                                                    return handleTogglePopover('priority')
                                                }}
                                                className='flex gap-2 items-center'
                                            >
                                                <Priority className={`text-[20px] ${priorityClassName}`} />
                                                <IoIosArrowDown />
                                            </div>
                                            {popover === 'priority' && (
                                                <FetchOptionSelector
                                                    ref={popoverRef}
                                                    options={priorityOptions}
                                                    selectedOption={task.priority}
                                                    onClick={(option: string) => handleChangePriority(option)}
                                                    isPending={false}
                                                    className='left-0 translate-x-0'
                                                    searchText='Set priority...'
                                                    dismountingPopover={dismountingPopover}
                                                />
                                            )}
                                        </div>
                                        <Link
                                            to={`/tasks/${taskId}`}
                                            className='text-blue dark:text-accent cursor-pointer hover:underline'
                                        >
                                            #{taskId}
                                        </Link>
                                        <div className={`flex gap-1 items-center`}>
                                            {task.associations
                                                ?.filter((association: any) => association.resource_name !== 'user')
                                                ?.map((association: any) => (
                                                    <div key={association.id}>
                                                        <Association id={task.id} association={association}>
                                                            {' '}
                                                            <Button
                                                                size={'icon'}
                                                                variant={'outline'}
                                                                className='p-[2px] h-5 w-5 ml-2 border-none hover:bg-danger dark:hover:bg-danger hover:text-text1 dark:hover:text-darktext1'
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDisassociation(association)
                                                                }}
                                                            >
                                                                <FaTimes />
                                                            </Button>
                                                        </Association>
                                                    </div>
                                                ))}
                                            <Button
                                                onClick={() => {
                                                    setShowAssociations(true)
                                                }}
                                                variant={'outline'}
                                                size={'sm'}
                                                className='h-[23px] w-fit dark:border-darkgrey'
                                            >
                                                +Edit
                                            </Button>
                                        </div>

                                        <div className='flex gap-1'>
                                            {task?.associations
                                                ?.filter((association: any) => association.resource_name === 'user')
                                                ?.map((association: any) => (
                                                    <Assignment
                                                        key={association.resource_id}
                                                        association={association}
                                                    />
                                                ))}
                                        </div>
                                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                            <Button
                                                onClick={() => setShowAssignments(true)}
                                                variant={'outline'}
                                                size={'sm'}
                                                className='max-h-[25px] dark:border-darkgrey'
                                            >
                                                Edit Users
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <div className='grid grid-cols-3 gap-2'>
                                    <div className='max-w-[240px]'>
                                        <TaskType
                                            taskId={task.id}
                                            departmentId={task.department_id}
                                            typeId={task.type_id}
                                            className='bg-lightgrey dark:bg-darkbg2 min-w-[75px]'
                                        />
                                    </div>
                                    <div className='flex gap-2 items-center'>
                                        <div className='flex items-center justify-center gap-1 text-[8px]'>
                                            <FaCheck
                                                onClick={handleChangeComplete}
                                                className={cn(
                                                    updateTaskMutation.isPending && 'pointer-events-none',
                                                    `${
                                                        task.status !== 'Completed'
                                                            ? 'opacity-[0.25] text-black border-black dark:text-offwhite dark:border-offwhite'
                                                            : 'text-lime border-lime'
                                                    } cursor-pointer rounded-full p-[4px] border-[1px]  w-[24px] h-[24px]`
                                                )}
                                            />
                                        </div>
                                        <TaskStatus
                                            taskId={task.id || -1}
                                            dueAt={task.due_at}
                                            status={task.status}
                                            mutation={updateTaskMutation}
                                            className='p-0 bg-lightgrey dark:bg-darkbg2 min-w-[75px]'
                                        />
                                    </div>
                                    <div className='relative flex items-center w-fit'>
                                        <button
                                            onClick={() => setShowDueAtEditor((previousState) => !previousState)}
                                            className='font-bold text-[12px] min-w-[180px] flex gap-[8px] items-center'
                                        >
                                            <p>
                                                Due{' '}
                                                {formatDateSimple(
                                                    editedFields.HTMLDateString
                                                        ? new Date(parseHTMLInputToDate(editedFields.HTMLDateString))
                                                        : new Date(task.due_at)
                                                )}
                                            </p>
                                            {showDueAtEditor ? <FaCaretUp /> : <FaCaretDown />}
                                        </button>
                                        {showDueAtEditor && (
                                            <div className=' absolute shadow-small border-[1px] dark:border-darkgrey top-[100%] right-0 w-[460px] z-[20] bg-white dark:bg-darkaccent p-[8px]'>
                                                <>
                                                    <div className='flex justify-between gap-[16px]'>
                                                        <div className='flex flex-col gap-[4px] relative w-[33%]'>
                                                            <TaskScreenDueAt
                                                                taskId={task.id || -1}
                                                                dueAt={task.due_at}
                                                                mutation={updateTaskMutation}
                                                                setParentDate={setDate}
                                                                setParentTime={setTime}
                                                                className='bg-lightgrey dark:bg-darkbg2'
                                                            />
                                                        </div>
                                                        <TaskAlert
                                                            taskId={task.id || -1}
                                                            alertOffset={task.alert_offset}
                                                            sendEmailAlert={task.send_email_alert}
                                                            sendSlackAlert={task.send_slack_alert}
                                                            mutation={updateTaskMutation}
                                                            parentAlertDetails={editedFields}
                                                            setParentAlertDetails={setEditedFields}
                                                        />
                                                    </div>
                                                </>
                                            </div>
                                        )}

                                        <>
                                            {task.status !== 'Completed' ? (
                                                <button
                                                    onClick={handleChangeComplete}
                                                    className='text-[12px] border border-lightgrey dark:text-darktext1 font-bold px-[4px] rounded-[4px]'
                                                    disabled={updateTaskMutation.isPending}
                                                >
                                                    Complete
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={handleChangeComplete}
                                                    className='text-[12px] text-white dark:text-offwhite bg-darkgrey font-bold px-[4px] rounded-[4px]'
                                                    disabled={updateTaskMutation.isPending}
                                                >
                                                    Reopen
                                                </button>
                                            )}
                                        </>
                                    </div>
                                </div>

                                <div className='flex flex-col gap-[8px] items-end'>
                                    <div className='flex gap-3'>
                                        <PinnedEscalatedIcons
                                            taskId={task.id.toString()}
                                            mutation={updateTaskMutation}
                                            pinned={task.user_pinned}
                                            is_escalated={task.is_escalated}
                                        />

                                        {user.type.match(/admin/i) && (
                                            <div className='relative'>
                                                <button
                                                    className={`js-newTask-actions__${taskId} flex justify-center items-center relative w-[35px] h-[15px] text-grey hover:text-darkgrey dark:hover:text-offwhite`}
                                                    onClick={() => setShowTaskActions(!showTaskActions)}
                                                >
                                                    <span className='js-order__dot-button text-[20px] font-black tracking-wider leading-[0px] bottom-[4px] relative'>
                                                        ...
                                                    </span>
                                                </button>
                                                {showTaskActions && (
                                                    <div className='absolute top-full right-0 flex flex-col p-[8px] bg-white dark:bg-darkaccent shadow-small gap-[8px] border-[1px] border-darkgrey'>
                                                        <h3 className='text-[14px] font-semibold'>Admin Actions</h3>
                                                        <div className='flex flex-col border-grey border-[1px]'>
                                                            {Object.keys(taskActions).map((action, index) => (
                                                                <div
                                                                    key={index}
                                                                    className='hover:bg-black/20 px-[8px] py-[2px] cursor-pointer whitespace-nowrap'
                                                                    onClick={() => taskActions[action]()}
                                                                >
                                                                    {action}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            ref={noteRef}
                            onClick={() => {
                                setEditNote(true)
                            }}
                            className='flex gap-[16px]'
                        >
                            <textarea
                                value={editedFields.title ?? task.title}
                                name='title'
                                className={cn(
                                    `${inputStyle} transition-all`,
                                    !editNote && `max-h-20`,
                                    editNote && 'min-h-20 h-52'
                                )}
                                onChange={handleChange}
                            />
                        </div>
                        {task.allow_descriptions === 1 && (
                            <div className='flex gap-[16px]'>
                                <div className='flex flex-col gap-[4px] grow'>
                                    <label className={labelStyle}>Additional Details</label>
                                    <textarea
                                        value={editedFields.description ?? task.description}
                                        name='description'
                                        className={`${inputStyle} max-h-20`}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    {edited && (
                        <div className='flex justify-end items-center gap-3'>
                            <FetchingSpinner isFetching={updateTaskMutation.isPending} />
                            <button
                                className={cn(
                                    'bg-blue dark:bg-accent px-[8px] py-[2px] rounded-b text-white dark:text-black font-semibold',
                                    updateTaskMutation.isPending && 'opacity-75 pointer-events-none cursor-default'
                                )}
                                onClick={handleSave}
                            >
                                Submit Changes
                            </button>
                        </div>
                    )}
                    {showAssignments && task.associations && (
                        <TaskAssignmentsModal
                            closeModal={() => {
                                setShowAssignments(false)
                            }}
                            handleSave={handleSave}
                            associations={editedFields.associations ?? task.associations}
                            toggleAssociation={toggleAssociations}
                            handleReset={() => {
                                setEditedFields((prev: any) => {
                                    const newState = structuredClone(prev)
                                    newState.associations = structuredClone(task.associations)
                                    return newState
                                })
                            }}
                        />
                    )}
                    {showLinkModal && (
                        <TaskAssociationsModal
                            closeModal={handleSave}
                            associations={editedFields.associations ?? task.associations}
                            toggleAssociation={toggleAssociations}
                            handleSave={handleSave}
                            handleReset={() => {
                                setEditedFields((prev: any) => {
                                    const newState = structuredClone(prev)
                                    newState.associations = structuredClone(task.associations)
                                    return newState
                                })
                            }}
                        />
                    )}
                    {showAssociations && task.associations && (
                        <TaskAssociationsModal
                            closeModal={() => {
                                setShowAssociations(false)
                            }}
                            handleSave={handleSave}
                            associations={editedFields.associations ?? task.associations}
                            toggleAssociation={toggleAssociations}
                            handleReset={() => {
                                setEditedFields((prev: any) => {
                                    const newState = structuredClone(prev)
                                    newState.associations = structuredClone(task.associations)
                                    return newState
                                })
                            }}
                        />
                    )}
                </div>
            </React.Fragment>
        )
    }
    return <div></div>
}
