import { useEffect, useRef, useState } from 'react'
import CloseButton from '../custom_components/CloseButton'
import { sendToast, vFetch } from '../helpers'
import { VendorContactCreateInit, VendorContactEditInit } from './vendors.types'
import VendorContactFields from './components/VendorContactFields'

type EditVendorContactProps = {
    contactTypes: string[]
    contact: VendorContactCreateInit | VendorContactEditInit
    setVendorContactToEdit: Function
    refreshVendorsList: Function
}

export default function EditVendorContact({
    contactTypes,
    contact,
    setVendorContactToEdit,
    refreshVendorsList,
}: EditVendorContactProps) {
    const outer: any = useRef()
    const deleteOuter: any = useRef()

    const { id } = contact as VendorContactEditInit
    const [edited, setEdited] = useState(false)
    const [deleteVendor, setDeleteVendor] = useState(false)

    const [contactsArray, setContactsArray] = useState([contact])

    useEffect(() => {
        setEdited(contactsArray.every((c) => JSON.stringify(c) !== JSON.stringify(contact)))
    }, [contactsArray])

    function refreshAndClose() {
        refreshVendorsList()
        setVendorContactToEdit(null)
    }

    function handleSubmitArray() {
        vFetch(`/v1/vendors/contacts`, {
            method: id ? 'PUT' : 'POST',
            body: JSON.stringify(id ? { contact: contactsArray[0] } : { contacts: contactsArray }),
            cb: (res: any) => {
                if (res.success) refreshAndClose()
            },
        })
    }
    function handleDelete() {
        if (!id) return sendToast({ message: 'Something went wrong. Please refresh the page and try again.' })
        vFetch(`/v1/vendors/contacts`, {
            method: 'DELETE',
            body: JSON.stringify({ contact }),
            cb: () => refreshAndClose(),
        })
    }

    const canSubmitContactsArray = contactsArray.every((contact) => {
        return contactsArray.every(
            (c) =>
                Object.keys(c)
                    .filter((k) => !['vendor', 'contact_type'].includes(k))
                    .some((k) => !!contact[k as keyof VendorContactCreateInit]) && edited
        )
    })
    return (
        <div className='fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center bg-[rgba(0,0,0,0.5)] z-50'>
            <div
                className='relative flex justify-center items-center h-[calc(100%-51px)] w-[100%]'
                ref={outer}
                onMouseDown={({ target }) => {
                    if (outer.current === target) setVendorContactToEdit(null)
                }}
            >
                <div className='relative min-w-[500px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                    <CloseButton
                        onClick={() => {
                            setVendorContactToEdit(null)
                        }}
                    />
                    <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                        {id ? 'Edit' : 'Create'} {contact.vendor ? contact.vendor : 'Vendor'} Contact
                    </h2>

                    <div className='grid gap-[8px] overflow-auto max-h-[calc(70vh-51px)]'>
                        <VendorContactFields
                            contactTypes={contactTypes}
                            contactsArray={contactsArray}
                            setContactsArray={setContactsArray}
                            contact={contact}
                        />
                        <div className='relative flex justify-center mt-[16px] h-[40px]'>
                            <button
                                className={`${
                                    canSubmitContactsArray ? '' : 'opacity-[0.2] !bg-grey cursor-auto'
                                } duration-200 p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small`}
                                onClick={() => {
                                    canSubmitContactsArray
                                        ? handleSubmitArray()
                                        : sendToast({
                                              message:
                                                  'Please fill out all vendor contact forms before clicking submit or remove empty contact forms.',
                                          })
                                }}
                            >
                                Submit
                            </button>
                            {id && (
                                <button
                                    className='absolute right-[0] w-[100px] p-[8px] text-white font-semibold bg-red hover:bg-red/75 rounded shadow-small'
                                    onClick={() => setDeleteVendor(true)}
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {deleteVendor && (
                <div className='flex absolute top-0 w-[100%] h-[100%] bg-black/50 z-50'>
                    <div
                        className='flex w-[100%] h-[100%] justify-center items-center'
                        ref={deleteOuter}
                        onMouseDown={({ target }) => {
                            if (deleteOuter.current === target) setDeleteVendor(false)
                        }}
                    >
                        <div className='relative min-w-[500px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                            <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                                Are you sure you want to delete this contact?
                            </h2>
                            <div className='p-[16px]'>
                                {Object.keys(contact).map((key) => (
                                    <p>
                                        {key.replaceAll('_', ' ')}:{' '}
                                        <strong>{contact[key as keyof VendorContactCreateInit]}</strong>
                                    </p>
                                ))}
                            </div>
                            <div className='flex justify-around'>
                                <button
                                    className='w-[100px] p-[8px] text-white font-semibold bg-red hover:bg-red/75 rounded shadow-small'
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                                <button
                                    className='w-[100px] p-[8px] text-white font-semibold bg-blue hover:bg-blue/75 dark:bg-accent dark:hover:bg-accent/75 dark:text-black rounded shadow-small'
                                    onClick={() => setDeleteVendor(false)}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
