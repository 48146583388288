import { useGetProjects } from 'tasksV2/api/useQueries'

export default function ProjectAssociationBuilder({
    associations,
    toggleAssociation,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const projectsQuery = useGetProjects({})
    const projects = projectsQuery.data?.projects || []

    return (
        <div>
            <h2 className='font-bold mb-2'>Choose Projects To Associate</h2>
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {projectsQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {projects.map((project: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'project',
                                        resource_id: project.id,
                                        readable_name: project.title,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'project' &&
                                                association.resource_id === project.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>{project.title}</span>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
