import { NavLink } from 'react-router-dom'
import { OrderTimelineEvent } from '../orders/orders.types'

export function renderOrderTimelineEvent(event: OrderTimelineEvent) {
    const date = new Date(event.createdAt)
    const time = [
        [date.toLocaleTimeString().split(':')[0], date.toLocaleTimeString().split(':')[1]].join(':'),
        date.toLocaleTimeString().split(':')[2].split(' ')[1],
    ].join(' ')
    const linkRegex = /\<a href="(.+)">(.+)<\/a>/

    let { message } = event
    let strings = []

    message = message.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
    if (message.match(linkRegex)) {
        while (message.match(linkRegex)) {
            const link: string[] = message.match(linkRegex) || []
            const linkIndex = message.indexOf(link[0])
            const string = message.slice(0, linkIndex)
            strings.push(
                <span key={string} className='inline'>
                    {string}
                </span>
            )
            message = message.replace(string + link[0], '')
            strings.push(
                <NavLink
                    key={link[1]}
                    className='inline hover:underline text-blue dark:text-accent'
                    to={link[1].replaceAll(
                        'https://factorypure.myshopify.com/admin/draft_orders/',
                        '/orders/draft-orders/'
                    )}
                >
                    {link[2]}
                </NavLink>
            )
        }
        strings.push(message.slice(0))
    } else {
        strings = [<p key={message}>{message}</p>]
    }

    return (
        <li
            key={event.id}
            className={`flex gap-[8px] ${
                event.__typename === 'CommentEvent'
                    ? 'p-[8px] border-[1px] dark:border-darkgrey dark:bg-darkness shadow-md'
                    : ''
            } basis-[100%] items-center`}
        >
            <div
                className={`w-[100%] max-w-[100%] ${
                    event.__typename !== 'CommentEvent' && 'pl-[8px]'
                } gap-[8px]  rounded dark:text-offwhite`}
            >
                {strings.map((string) => string)}
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date.toLocaleDateString()}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    )
}
