import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { SortingState, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { cn } from 'helpers'
import queryKeys from 'orderHubScreen/api/queryKeys'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { OrderInit } from './orders.types'

export default function OrdersListTable({
    tableData,
    columns,
    showDepartment,
    showHeaders,
    size,
    rowSelection,
    setRowSelection,
}: any) {
    const user = useSelector((state: any) => state.user)
    const [sorting, setSorting] = React.useState<SortingState>([])
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const tableContainerRef = React.useRef<HTMLDivElement>(null)

    const table = useReactTable({
        columns,
        data: tableData,
        state: {
            rowSelection,
        },
        getCoreRowModel: getCoreRowModel(),
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        initialState: {
            columnVisibility: {
                department_id: showDepartment,
            },
        },
    })
    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 28,
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 15,
    })
    const orderExpiring = (order: OrderInit) =>
        new Date(order.created_at.getFullYear(), order.created_at.getMonth(), order.created_at.getDate()) <=
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 6) &&
        order.financial_status !== 'paid' &&
        order.financial_status !== 'refunded' &&
        order.financial_status !== 'voided'

    return (
        <div
            ref={tableContainerRef}
            className={cn('overflow-y-auto relative', 'w-full  transition-all delay-200 h-full min-h-0')}
        >
            <table className=' grid '>
                {showHeaders && (
                    <thead className='sticky top-0 z-[49] grid'>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                style={{ display: 'flex', width: '100%' }}
                                className={cn(
                                    ' bg-lightgrey text-text1 dark:bg-darkbg2 dark:text-darktext1 flex w-full text-[12px]'
                                )}
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            style={{
                                                display: 'flex',
                                                width: header.getSize(),
                                            }}
                                            className='text-start flex  px-1 font-semibold capitalize z-50'
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    className={
                                                        header.column.getCanSort()
                                                            ? ' select-none flex gap-1'
                                                            : 'flex gap-1'
                                                    }
                                                    onClick={header.column.getToggleSortingHandler()}
                                                    title={
                                                        header.column.getCanSort()
                                                            ? header.column.getNextSortingOrder() === 'asc'
                                                                ? 'Sort ascending'
                                                                : header.column.getNextSortingOrder() === 'desc'
                                                                  ? 'Sort descending'
                                                                  : 'Clear sort'
                                                            : undefined
                                                    }
                                                >
                                                    {flexRender(header.column.columnDef.header, header.getContext())}
                                                    {{
                                                        asc: (
                                                            <div className='self-center min-h-[18px]'>
                                                                <CaretUpIcon />
                                                            </div>
                                                        ),
                                                        desc: (
                                                            <div className='self-center min-h-[18px]'>
                                                                <CaretDownIcon />
                                                            </div>
                                                        ),
                                                    }[header.column.getIsSorted() as string] ?? null}
                                                </div>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                    }}
                    className='w-full grid relative text-[12px] font-semibold font-bai cursor-pointer'
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow, i) => {
                        const row = rows[virtualRow.index] as any
                        const order = row.original
                        return (
                            <tr
                                data-index={virtualRow.index}
                                ref={(node) => rowVirtualizer.measureElement(node)}
                                key={row.id}
                                style={{
                                    transform: `translateY(${virtualRow.start}px)`,
                                }}
                                className={cn(
                                    `w-full bg-bg1 dark:bg-darkbg1 flex absolute text-black dark:text-offwhite  border-lightgrey dark:border-gray-500 hover:bg-lightgrey cursor-pointer dark:hover:bg-darkbg2 hover:z-40 last:w-auto`,
                                    i % 2
                                        ? '[&>*]:bg-[rgb(224,228,235)] [&>*]:dark:bg-darkness'
                                        : '[&>*]:dark:bg-darkaccent',
                                    orderExpiring(order)
                                        ? '[&>*]:!bg-[#8a0f0f] [&>*]:!text-[white] [&>*]:hover:!bg-[#CC0000]'
                                        : '[&>*]:hover:!bg-[rgb(199,202,209)] [&>*]:dark:hover:!bg-blue'
                                )}
                                onClick={(event) => {
                                    queryClient.setQueryData(
                                        queryKeys.orders.detail(row.original.id.toString()).queryKey,
                                        {
                                            success: true,
                                            order: order,
                                        }
                                    )
                                    if (event.button === 1 || event.ctrlKey || event.metaKey) {
                                        return window.open(`/orders/${order.id}`, '_blank')
                                    }
                                    return navigate(`/orders/${order.id}`)
                                }}
                            >
                                {row.getVisibleCells().map((cell: any) => {
                                    return (
                                        <td
                                            style={{ width: cell.column.getSize() }}
                                            key={cell.id}
                                            className='p-1 flex '
                                            onClick={(e) => {
                                                if (cell.id.includes('select-col')) {
                                                    e.stopPropagation()
                                                }
                                            }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
