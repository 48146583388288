import { DraftOrderInit } from '../../orders/orders.types'
import DraftOrderPaymentStatus from '../components/DraftOrderPaymentStatus'
import DraftOrderUnfulfilledItems from '../components/DraftOrderUnfulfilledItems'

type OverviewProps = {
    events: any
    order: DraftOrderInit
}
export default function DraftOrderOverView({ order }: OverviewProps) {
    return (
        <>
            <div className='flex flex-col basis-[calc(65%)] grow-[1] min-w-[450px] gap-2'>
                <DraftOrderUnfulfilledItems order={order} />
                <DraftOrderPaymentStatus order={order} />
            </div>
        </>
    )
}
