import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { differenceInCalendarDays } from 'date-fns'
import { cn } from 'helpers'
import { useSelector } from 'react-redux'
import queryKeys from 'tasksV2/api/queryKeys'
import { useUpdateTask } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'

export default function TaskStatus({
    taskId,
    status,
    dueAt,
    mutation,
    handleInstantChange,
    className,
    editable = true,
}: {
    taskId: number
    dueAt: string
    status: string
    mutation?: any
    handleInstantChange?: (newStatus: string) => void
    className?: string
    editable?: boolean
}) {
    const user = useSelector<any, any>((state) => state.user)
    const queryClient = useQueryClient()
    const updateTaskMutation = useUpdateTask(taskId.toString())
    const overdue =
        differenceInCalendarDays(new Date(), new Date(dueAt)) > 0 && status != 'Completed' && status != 'Archived'
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        queryClient.setQueryData(queryKeys.tasks.detail(taskId.toString()).queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, status: target.value },
                }
            }
        })
        if (handleInstantChange) {
            handleInstantChange(target.value)
        } else if (mutation) {
            mutation.mutate({
                id: taskId,
                status: target.value,
                associations: [],
            })
        } else {
            updateTaskMutation.mutate({
                id: taskId,
                status: target.value,
            })
        }
    }
    const statusList =
        user.type === 'Admin' || (user.type === 'SuperAdmin' && status === 'Archived')
            ? [...TASK_STATUSES, 'Archived']
            : TASK_STATUSES
    return (
        <div className='flex gap-1 items-center'>
            {editable ? (
                <select
                    className={cn(
                        'border border-lightgrey dark:border-darkgrey rounded p-1 w-full dark:bg-darkbg1 cursor-pointer',
                        className
                    )}
                    id='status'
                    name='status'
                    value={status}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onChange={handleChange}
                >
                    <option value=''>None</option>
                    {statusList.map((status: any) => (
                        <option key={`${taskId}-${status}`} value={status}>
                            {status}
                        </option>
                    ))}
                </select>
            ) : (
                <p>{status}</p>
            )}
            {overdue && (
                <div className='group relative'>
                    <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                    <div className='hidden group-hover:flex border absolute top-[100%] right-[-50px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[100] '>
                        Task Overdue
                    </div>
                </div>
            )}
        </div>
    )
}
