import Select from 'procurement/components/Select'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { ACTIVE_BRIDGE_TABLE_RESOURCE_NAMES } from 'tasksV2/constants/tasks'
import OrderAssociationBuilder from '../components/OrderAssociationBuilder'
import { Button } from 'custom_components/component_Basics/Button'
import DraftOrderAssociationBuilder from '../components/DraftOrderAssociationBuilder'
import ProjectAssociationBuilder from '../components/ProjectAssociationBuilder'
import CustomerAssociationBuilder from '../components/CustomerAssociationBuilder'
import TaxExemptionAssociationBuilder from '../components/TaxExemptionAssociationBuilder'
import CompanyAssociationBuilder from '../components/CompanyAssociationBuilder'
import OrderLineItemAssociationBuilder from '../components/OrderLineItemAssociationBuilder'
import DraftOrderLineItemAssociationBuilder from '../components/DraftOrderLineItemAssociationBuilder'

export default function TaskAssociationsModal({
    closeModal,
    associations,
    toggleAssociation,
    handleSave,
    handleReset,
    selectedType,
}: {
    closeModal: () => void
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
    handleSave: any
    handleReset: any
    selectedType?: string
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [selectedAssociationType, setSelectedAssociationType] = useState<null | string>(selectedType ?? null)

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setSelectedAssociationType(target.value)
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                handleReset()
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative rounded p-6 bg-bg1 dark:bg-darkbg1 min-w-[400px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='font-bold mb-2'>Associate Task To A Resource</h2>
                <div className='flex flex-col gap-1'>
                    <Select
                        label='Resource Type'
                        id='association_type'
                        name='association_type'
                        onChange={handleChange}
                        value={selectedAssociationType}
                    >
                        <option value=''>Select a resource type</option>
                        {ACTIVE_BRIDGE_TABLE_RESOURCE_NAMES.map((resourceName: string) => (
                            <option className='capitalize' value={resourceName}>
                                {resourceName[0].toUpperCase()}
                                {resourceName.slice(1).replaceAll('_', ' ')}
                            </option>
                        ))}
                    </Select>
                    {selectedAssociationType === 'order' && (
                        <OrderAssociationBuilder associations={associations} toggleAssociation={toggleAssociation} />
                    )}
                    {selectedAssociationType === 'order_line_item' && (
                        <OrderLineItemAssociationBuilder
                            associations={associations}
                            toggleAssociation={toggleAssociation}
                        />
                    )}
                    {selectedAssociationType === 'draft_order' && (
                        <DraftOrderAssociationBuilder
                            associations={associations}
                            toggleAssociation={toggleAssociation}
                        />
                    )}
                    {selectedAssociationType === 'company' && (
                        <CompanyAssociationBuilder associations={associations} toggleAssociation={toggleAssociation} />
                    )}
                    {selectedAssociationType === 'project' && (
                        <ProjectAssociationBuilder associations={associations} toggleAssociation={toggleAssociation} />
                    )}
                    {selectedAssociationType === 'customer' && (
                        <CustomerAssociationBuilder associations={associations} toggleAssociation={toggleAssociation} />
                    )}
                    {selectedAssociationType === 'customer_tax_exemption' && (
                        <TaxExemptionAssociationBuilder
                            associations={associations}
                            toggleAssociation={toggleAssociation}
                        />
                    )}
                    {selectedAssociationType === 'draft_order_line_item' && (
                        <DraftOrderLineItemAssociationBuilder
                            associations={associations}
                            toggleAssociation={toggleAssociation}
                        />
                    )}
                </div>
                <div className='flex gap-4 items-center justify-center mx-auto mt-2 '>
                    <Button
                        variant={'outline'}
                        className='dark:border-darkgrey'
                        onClick={() => {
                            handleSave()
                            closeModal()
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        variant={'outline'}
                        className='dark:border-darkgrey'
                        onClick={() => {
                            handleReset()
                        }}
                    >
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    )
}
