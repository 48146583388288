import * as service from "./service"
import { createQueryKeys } from "@lukemorales/query-key-factory";

const developmentQueryKeys = createQueryKeys('development', {
    tasks: () => ({
        queryKey: ['tasks'],
        queryFn: () => service.getTasks(false)
    }),
    tickets: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getTickets(filters)
    }),
    projects: () => ({
        queryKey: ['projects'],
        queryFn: () => service.getProjects()
    }),
    roadmaps: () => ({
        queryKey: ['roadmaps'],
        queryFn: () => service.getRoadmaps()
    })
})

export default developmentQueryKeys