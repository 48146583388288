import { useEffect, useState } from 'react'
import { closePopout } from '../../helpers'
import BottomItemInfo from '../../orders/components/lineItems/bottomItemInfo'
import { DraftOrderInit } from '../../orders/orders.types'

type UnfulfilledItemsProps = {
    order: DraftOrderInit
}

export default function DraftOrderUnfulfilledItems({ order }: UnfulfilledItemsProps) {
    return (
        <>
            <div className='grid gap-2 bg-white dark:bg-darkaccent p-[16px] shadow-small rounded dark:text-offwhite'>
                <div className='grid grid-rows-[40px] items-center gap-[16px]'>
                    <div className='grid grid-cols-[25px_1fr] gap-[10px] items-center text-[14px]'>
                        <div>
                            <h2 className='font-bold text-darkgrey dark:text-offwhite text-[20px]'>Products</h2>
                        </div>
                    </div>
                </div>
                {order.line_items.length > 0 &&
                    order.line_items.map((item: any) => {
                        return (
                            <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                <BottomItemInfo item={item} order={order} />
                            </div>
                        )
                    })}
            </div>
        </>
    )
}
