import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'

export default function UserSelector({ selectedUserId }: { selectedUserId: string }) {
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    return (
        <FilterBarSelectorButton
            variant='outline'
            align='end'
            selectType='single'
            setFilterValues={() => {}}
            options={userOptions}
            title={'User'}
            field={'user_id'}
            filterValues={[selectedUserId]}
            searchToggle={true}
            editSearchParams={true}
            preview={true}
        />
    )
}
