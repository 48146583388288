import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { CompanyTaskType } from 'procurement/Procurements.types'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import {
    useParentCompanyContactsQuery,
    useParentCompanyEmailsQuery,
    useParentCompanyIncomingEmailsQuery,
    useParentCompanyNotesQuery,
    useParentCompanyQuery,
} from '../../api/useQueries'
import { MONTH_NAME_MAP, createdAtSort } from '../../helpers'
import BasicEvent from '../listItems/BasicEvent'
import BasicEventGroup from '../listItems/BasicEventGroup'
import BasicEventSkeleton from '../listItems/BasicEventSkeleton'
import IncomingEmail from '../listItems/IncomingEmail'
import ParentEmail from '../listItems/ParentEmail'
import ParentNote from '../listItems/ParentNote'
import TaskListItem from '../listItems/TaskListItem'
import { ParentEventType } from '../modals/ParentCompanyModal'
import { useGetTasks } from 'tasksV2/api/useQueries'
import React from 'react'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'

export default function ParentActivityList({
    parentCompany,
    companyNameMap,
    companyIds,
}: {
    parentCompany: string
    companyNameMap: any
    companyIds: any
}) {
    const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const queryClient = useQueryClient()
    const parentCompanyQuery = useParentCompanyQuery(parentCompany)
    const { isLoading: loadingParent } = parentCompanyQuery

    const companyContactsQuery = useParentCompanyContactsQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingContacts } = companyContactsQuery
    const { contacts } = companyContactsQuery.data || {}

    const tasksQueryV2 = useGetTasks(
        {
            resourceTable: 'company_tasks',
            resourceIds: companyIds?.filter((v: any) => v),
            limit: 300,
        },
        keepPreviousData
    )
    const { tasks: tasksV2 } = tasksQueryV2?.data || {}
    tasksV2?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })
    const companyEventsQuery = useParentCompanyNotesQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingEvents } = companyEventsQuery
    const { events }: { events: ParentEventType[] } = companyEventsQuery?.data || {}

    const companyEmailsQuery = useParentCompanyEmailsQuery({ parentCompany, companyNameMap })
    const { isLoading: loadingEmails } = companyEmailsQuery
    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const companyIncomingEmailsQuery = useParentCompanyIncomingEmailsQuery({
        parentCompany,
        contactList: contacts || [],
        companyNameMap,
    })
    const { isLoading: loadingIncomingEmails } = companyIncomingEmailsQuery
    const { incomingEmails }: { incomingEmails: any[] } = companyIncomingEmailsQuery?.data || {}

    const isLoading =
        loadingContacts ||
        loadingEvents ||
        tasksQueryV2.isLoading ||
        loadingIncomingEmails ||
        loadingEmails ||
        loadingParent

    let activities: EventType[] = []
    if (events) {
        activities = [...events]
    }
    if (tasksV2) {
        activities = [
            ...activities,
            tasksV2?.map((task: CompanyTaskType) => ({ ...task, type: 'task' }) as any as EventType),
        ].flat()
    }

    if (emails) {
        activities = [...activities, ...emails?.map((email) => ({ ...email, type: 'email' }) as any as EventType)]
    }
    if (incomingEmails) {
        activities = [
            ...activities,
            ...incomingEmails?.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType),
        ]
    }
    activities = activities.sort(createdAtSort)

    const tempMap: any = {}
    for (const event of activities) {
        const createdAtDate = new Date(event.created_at || (event as any).delivered_at)
        const key = `${
            MONTH_NAME_MAP[createdAtDate.getMonth() as keyof typeof MONTH_NAME_MAP]
        } ${createdAtDate.getFullYear()}`
        if (!tempMap[key]) {
            tempMap[key] = []
        }
        tempMap[key].push(event)
    }
    const monthMap = tempMap

    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            {isLoading && (
                <div className='flex flex-col gap-3'>
                    {skeletons.map((skeleton) => (
                        <BasicEventSkeleton key={skeleton} />
                    ))}
                </div>
            )}
            {!isLoading && (
                <div className='flex flex-col gap-[8px]'>
                    {Object.entries(monthMap).map(([key, value]: any) => (
                        <>
                            <div
                                key={key + value}
                                className='text-[18px] p-[8px] border-b border-darklightgrey dark:border-darkgrey mb-[8px]'
                            >
                                {key}
                            </div>
                            {value.map((event: any) => {
                                if (event.type === 'note') return <ParentNote key={event.id} note={event} />
                                if (event.type === 'task')
                                    return (
                                        <React.Fragment key={event.id.toString() + 'procurementTask'}>
                                            <TaskEditCreateModalV2 taskId={event.id.toString()} createEvent={false} />
                                        </React.Fragment>
                                    )
                                if (event.type === 'email') {
                                    return <ParentEmail key={event.id} email={event} />
                                }
                                if (event.type === 'incoming-email') {
                                    return <IncomingEmail key={event.thread_id} email={event} />
                                }
                                if (event.type === 'basic-event-group') {
                                    return <BasicEventGroup key={event.created_at + event.events[0].id} event={event} />
                                }
                                return <BasicEvent key={event.id} event={event} />
                            })}
                        </>
                    ))}
                </div>
            )}
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
