import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

type selectedReturnsActionsProps = {
    selected: any
    setSelected: Dispatch<SetStateAction<number[]>>
}

// export default function selectedReturnsActions (({selected, setSelected} as selectedReturnsActionsProps)) {
export default function SelectedReturnsActions({ selected, setSelected }: selectedReturnsActionsProps) {
    const navigate = useNavigate()
    return (
        <div className='fixed flex justify-center bottom-[32px] left-[216px] w-[calc(100%-216px)] z-index-5 pointer-events-none'>
            <div className='flex gap-[8px] justify-between bg-white dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey rounded shadow-small dark:shadow-cool py-[8px] px-[16px] [&>*]:text-[14px] [&>*]:font-semibold [&>*]:dark:text-offwhite pointer-events-auto'>
                <button
                    onClick={() => {
                        navigate(`/orders/returns/edit?returns=${selected}`)
                    }}
                    className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                >
                    Edit Return{selected.length > 1 && 's'}
                </button>
                <div className='relative'></div>
            </div>
        </div>
    )
}
