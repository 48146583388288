import { useEffect, useState } from 'react'
import { closePopout, formatMoney } from '../../../helpers'
import { itemPriceAfterDiscounts, renderDiscount } from '../../orders.helpers'
import { DraftOrderInit, LineItemInit, OrderInit } from '../../orders.types'
import {
    draftOrderItemPriceAfterDiscounts,
    draftOrderRenderDiscount,
} from 'orderHubScreenDraft/helpers/draftOrders.helpers'

type ItemPriceProps = {
    order: OrderInit | DraftOrderInit
    item: LineItemInit
}

export default function ItemPrice({ order, item }: ItemPriceProps) {
    const [showDiscount, setShowDiscount] = useState('')

    useEffect(() => {
        const listenToWindow = (e: any) =>
            closePopout(e, [`js-order__line-item__${item.id}`, 'js-order__discount'], setShowDiscount)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div className='flex gap-[32px] ml-auto'>
            <div className='relative flex gap-[32px]'>
                <div className='flex flex-col'>
                    {item.applied_discount && (
                        <s
                            onClick={() => setShowDiscount(item.sku)}
                            className={`js-order__line-item__${item.id} mr-[8px] text-grey hover:underline cursor-pointer`}
                        >
                            {formatMoney(Number(item.price))}
                        </s>
                    )}
                    {item.discount_allocations?.length > 0 && (
                        <s
                            onClick={() => setShowDiscount(item.sku)}
                            className={`js-order__line-item__${item.id} mr-[8px] text-grey hover:underline cursor-pointer`}
                        >
                            {formatMoney(Number(item.price))}
                        </s>
                    )}
                    <span className='flex gap-[4px]'>
                        {!order.name.includes('D') && formatMoney(itemPriceAfterDiscounts(item))}
                        {order.name.includes('D') && formatMoney(draftOrderItemPriceAfterDiscounts(item))}
                        <span className='top-0 font-normal'>&times;</span>
                        {item.quantity}
                    </span>
                    {order.name.includes('D') && draftOrderRenderDiscount(order, item.sku, item, showDiscount)}
                    {!order.name.includes('D') && renderDiscount(order, item.sku, item, showDiscount)}
                </div>
                <div className='flex flex-col'>
                    {item.applied_discount && (
                        <s
                            onClick={() => setShowDiscount(item.sku)}
                            className={`js-order__line-item__${item.id} mr-[8px] text-grey hover:underline cursor-pointer`}
                        >
                            {formatMoney(Number(item.price) * item.quantity)}
                        </s>
                    )}
                    {item.discount_allocations?.length > 0 && (
                        <s
                            onClick={() => setShowDiscount(item.sku)}
                            className={`js-order__line-item__${item.id} mr-[8px] text-grey hover:underline cursor-pointer`}
                        >
                            {formatMoney(Number(item.price) * item.quantity)}
                        </s>
                    )}
                    {!order.name.includes('D') && (
                        <p className=''>{formatMoney(Number(itemPriceAfterDiscounts(item)) * item.quantity)}</p>
                    )}
                    {order.name.includes('D') && (
                        <p className=''>
                            {formatMoney(Number(draftOrderItemPriceAfterDiscounts(item)) * item.quantity)}
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}
