import { keepPreviousData, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import { useEffect } from 'react'
import * as auditService from '../../audit/api/service'
import { UserInit } from '../../users/users.types'
import { CompanyFilters, CompanyTaskFilters } from '../Procurements.types'
import { CompanyProductType } from '../components/CompanyProduct'
import { CompanyTaskType } from 'procurement/Procurements.types'
import { companyProductsTable, procurementDepartment, procurementEventType, taskEventType } from '../constants'
import { useCompanyEvent } from './events'
import { procurementQueries } from './procurementQueryKeys'
import * as service from './service'
import { sendToast } from 'helpers'
import { Link } from 'react-router-dom'
import { ExternalLinkIcon } from '@radix-ui/react-icons'

//company detail

export function useCompanyDetailQuery(companyId: number | undefined, selectedCompany?: any): any {
    const placeholderData = { company: selectedCompany }
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId),
        staleTime: 50000,
        enabled: Boolean(companyId),
        placeholderData: placeholderData,
        meta: {
            clientErrMessage: 'Failed to load company',
            pathname: useGetPathname(),
            functionName: 'useCompanyDetailQuery',
        },
    })

    return query
}
export function useCompanyNotesQuery({
    companyId,
    users,
    search,
}: {
    companyId: number
    users: UserInit[]
    search?: string
}): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.events(users, search),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load notes',
            pathname: useGetPathname(),
            functionName: 'useCompanyNotesQuery',
        },
    })

    return query
}
export function useCompanyIncomingEmailsQuery({
    companyId,
    companyEmail,
    contactList,
    search,
}: {
    companyId: number
    companyEmail: string
    contactList: any[]
    search?: string
}): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.incomingEmails({ companyEmail, contactList, search }),
        staleTime: 50000,
        enabled: Boolean(companyEmail),
        meta: {
            clientErrMessage: 'Failed to load emails',
            pathname: useGetPathname(),
            functionName: 'useCompanyIncomingEmailsQuery',
        },
    })

    return query
}

export function useCompanyDetailTasksQuery({
    companyId,
    search,
}: {
    companyId: number | undefined
    search?: string
}): any {
    const filters = {
        company_id: companyId,
        search,
        statuses: [],
        user_ids: [],
        due_at_options: [],
        lastCursor: [],
        pageParam: 1,
    }

    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.tasks(filters),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load tasks',
            pathname: useGetPathname(),
            functionName: 'useCompanyDetailTasksQuery',
        },
    })

    return query
}
export function useCompanyProductsQuery(filters: any, appState: any): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(filters.companyId)._ctx.products(filters),
        staleTime: 10000,
        enabled: Boolean(filters.companyId),
        refetchInterval: Object.keys(appState).length ? 5000 : false,
        meta: {
            clientErrMessage: 'Failed to load products',
            pathname: useGetPathname(),
            functionName: 'useCompanyProductsQuery',
        },
    })

    return query
}
export function useCompanyAllProductsQuery(filters: any, appState: any): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(filters.companyId)._ctx.allProducts(filters),
        staleTime: 10000,
        enabled: Boolean(filters.companyId),
        refetchInterval: Object.keys(appState).length ? 9000 : false,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanyAllProductsQuery',
        },
    })

    return query
}
export function useCompanyProductTypesQuery(): any {
    const query = useQuery({
        ...procurementQueries.companies.productTypes(),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanyProductTypesQuery',
        },
    })

    return query
}
export function useCompanyProductQuery(id: number): any {
    const query = useQuery({
        ...procurementQueries.companies.product(id),
        enabled: Boolean(id),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanyProductQuery',
        },
    })

    return query
}
export function useCompanyProductSkusQuery(showQuickModal: any): any {
    const query = useQuery({
        ...procurementQueries.companies.shopifySkus(),
        enabled: Boolean(showQuickModal),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanyProductSkusQuery',
        },
    })

    return query
}
export function useUnfulfilledLineItemsQuery(vendor: string | undefined, select: any): any {
    const query = useQuery({
        ...procurementQueries.companies.unfulfilledLineItems(vendor),
        select,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUnfulfilledLineItemsQuery',
        },
    })

    return query
}
export function useShopifyVendorsQuery(): any {
    const query = useQuery({
        ...procurementQueries.companies.shopifyVendors(),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useShopifyVendorsQuery',
        },
    })

    return query
}
export function useCompanyNamesQuery(): any {
    const query = useQuery({
        ...procurementQueries.companies.companyNames(),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanyNamesQuery',
        },
    })

    return query
}
export function useCompanySalesQuery(vendorName: string): any {
    const query = useQuery({
        ...procurementQueries.companies.companySales(vendorName),
        enabled: Boolean(vendorName),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanySalesQuery',
        },
    })

    return query
}
export function useCompanySalesListQuery(selectedStartDate: Date | undefined): any {
    const query = useQuery({
        ...procurementQueries.companies.companySalesList(selectedStartDate),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCompanySalesListQuery',
        },
    })

    return query
}
export function useShopifyGroupNamesQuery(): any {
    const query = useQuery({
        ...procurementQueries.companies.groupNames(),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useShopifyGroupNamesQuery',
        },
    })

    return query
}
export function useShopifyGroupOptionsQuery(customGroup: string): any {
    const query = useQuery({
        ...procurementQueries.companies.groupOptions(customGroup),
        enabled: Boolean(customGroup),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useShopifyGroupOptionsQuery',
        },
    })

    return query
}

export function useCompanyContactsQuery({ companyId }: { companyId: number | undefined }) {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.contacts(),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load contacts',
            pathname: useGetPathname(),
            functionName: 'useCompanyContactsQuery',
        },
    })

    return query
}
export function useVendorContactsQuery({ companyId }: { companyId: number | undefined }) {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.vendorContacts(),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load vendor contacts',
            pathname: useGetPathname(),
            functionName: 'useVendorContactsQuery',
        },
    })

    return query
}
export function useVendorInfoQuery({ companyId }: { companyId: number | undefined }) {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.vendorInfo(),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load company',
            pathname: useGetPathname(),
            functionName: 'useCompanyDetailQuery',
        },
    })

    return query
}
export function useInfoBlocksQuery({ companyId }: { companyId: number | undefined }) {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.infoBlocks(),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load vendor info',
            pathname: useGetPathname(),
            functionName: 'useInfoBlocksQuery',
        },
    })

    return query
}
export function useContactTypesQuery() {
    const query = useQuery({
        ...procurementQueries.companies.contactTypes(),
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useContactTypesQuery',
        },
    })

    return query
}

export function useCompanyFilesQuery({
    companyId,
    filters,
    search,
}: {
    companyId: number | undefined
    filters?: { fileTypes: string[]; after: any; before: any; page: any }
    search?: string
}): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.files(filters, search),
        staleTime: 50000,
        enabled: Boolean(companyId),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load files',
            pathname: useGetPathname(),
            functionName: 'useCompanyFilesQuery',
        },
    })

    return query
}
export function useCompanyEmailsQuery({ companyId, search }: { companyId: number | undefined; search?: string }): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.emails(search),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            clientErrMessage: 'Failed to load emails',
            pathname: useGetPathname(),
            functionName: 'useCompanyEmailsQuery',
        },
    })

    return query
}

//company detail mutations

export function useCreateTaskModal(setShowQuickModal?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, taskEventType)

    return useMutation({
        mutationFn: ({ task }: any) => {
            return service.createTaskModal({ task })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateTaskModal',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables?.task.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
        },
        onSettled: () => {
            setShowQuickModal(undefined)
        },
    })
}
export function useCreateCompany() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ editableCompany: newCompany, scoreOptionSelections }: any) => {
            return service.createCompany(newCompany, scoreOptionSelections)
        },
        meta: {
            clientErrMessage: 'Failed to create company',
            pathname: useGetPathname(),
            functionName: 'useCreateCompany',
        },
        onMutate: async () => {},
        onError: (error, variables, context) => {},
        onSuccess: (data) => {
            if (data && data.foundCompany) {
                sendToast(
                    {
                        message: (
                            <Link
                                id='product_id'
                                target='_blank'
                                rel='noopener noreferrer'
                                to={`/procurement?archived=true&company_id=${data?.foundCompany.id}`}
                                draggable={false}
                                className='flex gap-2 underline items-center w-full relative'
                            >
                                <p>Company with this domain already exists</p>
                                <ExternalLinkIcon />
                            </Link>
                        ),
                    },
                    { autoClose: 8000 }
                )
                queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
                return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
            }
            companyEvent.create(data, {})
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
        },
        onSettled: () => {},
    })
}
export function useCreateCompanyVendorMap() {
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ editableCompany: newCompany, scoreOptionSelections }: any) => {
            return service.createCompanyVendorMap(newCompany, scoreOptionSelections)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateCompanyVendorMap',
        },
        onMutate: async () => {},
        onError: (error, variables, context) => {},
        onSuccess: (data) => {
            companyEvent.create(data, {})
        },
        onSettled: () => {},
    })
}
export function useDuplicateCompany() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ editableCompany: newCompany }: any) => {
            return service.duplicateCompany(newCompany)
        },
        meta: {
            clientErrMessage: 'Failed to duplicate company',
            pathname: useGetPathname(),
            functionName: 'useDuplicateCompany',
        },
        onMutate: async () => {},
        onError: (error, variables, context) => {},
        onSuccess: (data) => {
            companyEvent.create(data, {})
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
        },
        onSettled: () => {},
    })
}

//NOTES
export function useCreateNote(setShowQuickModal?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'note')
    const taskEvent = useCompanyEvent(procurementDepartment, taskEventType)
    return useMutation({
        mutationFn: ({
            user,
            note,
            companyId,
            task,
            createTask,
        }: {
            user: UserInit
            note: any
            companyId: number
            task?: any
            createTask: boolean
        }) => {
            return service.createNote({ user, note, companyId, task, createTask })
        },
        meta: {
            clientErrMessage: 'Failed to create note',
            pathname: useGetPathname(),
            functionName: 'useCreateNote',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables.companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            if (variables?.createTask) {
                taskEvent.create(data, context)
            }
            companyEvent.create({}, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {
            setShowQuickModal(undefined)
        },
    })
}
export function useUpdateNote() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'note')
    return useMutation({
        mutationFn: ({ note }: any) => {
            return service.updateNote(note)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateNote',
        },
        onMutate: async (variables) => {
            const event_details = {
                company_id: variables?.note?.company_id,
                note_id: variables?.note?.id,
            }
            return event_details
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(data, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {},
    })
}
export function useDeleteNote() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'note')
    return useMutation({
        mutationFn: ({ note }: any) => {
            return service.deleteNote(note.id)
        },
        meta: {
            clientErrMessage: 'Failed to delete note',
            pathname: useGetPathname(),
            functionName: 'useDeleteNote',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                note_id: variables?.note.id,
                company_id: variables.note.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.delete(data, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {},
    })
}

// VARIANT OPTIONS

export function useOptionsQuery(productId: string | number): any {
    const query = useQuery({
        ...procurementQueries.companies.options(productId),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useOptionsQuery',
        },
    })
    return query
}

export function useCreateOption(companyId?: number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ option }: { option: any }) => service.createOption({ option }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateOption',
        },
        onSuccess: (data, variables) => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.options(variables.option.product_id).queryKey,
            })
        },
        onSettled: () => {},
    })
}
export function useUpdateOption(companyId?: number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body: { options: any; product_id: string | number }) => service.updateOption(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateOption',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.options(1).queryKey,
            })
        },
    })
}

export function useDeleteOption(companyId?: number) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (option: any) => service.deleteOption({ optionId: option.id }),

        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteOption',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.options(1).queryKey,
            })
        },
        onSettled: () => {},
    })
}

export function useOptionValuesQuery(productId: string | number): any {
    const query = useQuery({
        ...procurementQueries.companies.options(productId)._ctx.optionValues(productId),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useOptionValuesQuery',
        },
    })
    return query
}

export function useCreateOptionValue(companyId?: number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ optionValue }: { optionValue: any }) => service.createOptionValue({ optionValue }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateOptionValue',
        },
        onSuccess: (data, variables) => {
            return queryClient.invalidateQueries({
                queryKey: ['companies', 'options'],
            })
        },
        onSettled: () => {},
    })
}
export function useUpdateOptionValue(companyId?: number) {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body: { optionValues: any; product_id: string | number }) => service.updateOptionValue(body),

        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateOptionValue',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.options(1).queryKey,
            })
        },
    })
}
export function useDeleteOptionValue(companyId?: number) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (optionValue: any) =>
            service.deleteOptionValue({ optionValueId: optionValue.id, productId: optionValue.product_id }),

        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteOptionValue',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.options(1).queryKey,
            })
        },
        onSettled: () => {},
    })
}

//PRODUCTS MUTATIONS

export function useCreateProduct(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: ({
            companyId,
            related_variants_group_id,
        }: {
            companyId: number
            related_variants_group_id: string | null
        }) => service.createProduct({ companyId, related_variants_group_id }),
        meta: {
            clientErrMessage: 'Failed to create product',
            pathname: useGetPathname(),
            functionName: 'useCreateProduct',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}
export function useCreateShopifyProduct(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: ({ product, info }: { product: any; info: any }) => service.createShopifyProduct({ product, info }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateShopifyProduct',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables,
            }
            return eventDetails
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}
export function useBulkCreateShopifyVariants(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: ({ products, info }: { products: any; info: any }) =>
            service.createBulkShopifyVariants({ products, info }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useBulkCreateShopifyVariants',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables,
            }
            return eventDetails
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}
export function useCreateImportedProducts(companyId?: number) {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ products }: { products: any }) => {
            return service.createImportedProducts(products)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateImportedProducts',
        },
        onMutate: async (variables) => {},
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}
export function useDuplicateProduct(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: ({ product }: { product: any }) => service.duplicateProduct({ product }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDuplicateProduct',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                company_id: variables,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}
export function useProductAuditHistoryQuery({
    table,
    record_id,
    filters,
}: {
    table: string
    record_id: string | number
    filters: { user_ids: number[]; fields: string[] }
}): any {
    const query = useInfiniteQuery({
        queryKey: procurementQueries.companies.productAuditHistory(table, record_id, filters).queryKey,
        queryFn: (context) => auditService.getAuditHistory(table, record_id, undefined, context.pageParam, filters),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useProductAuditHistoryQuery',
        },
    })

    return query
}
export function useAlertAuditHistoryQuery({
    table,
    record_id,
    parent_id,
    filters,
}: {
    table: string
    record_id: string | number | undefined
    parent_id: string | number | undefined
    filters: { user_ids: number[]; fields: string[] }
}): any {
    const query = useInfiniteQuery({
        queryKey: procurementQueries.companies.productAuditHistory(table, record_id, filters).queryKey,
        queryFn: (context) => auditService.getAuditHistory(table, record_id, parent_id, context.pageParam, filters),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useAlertAuditHistoryQuery',
        },
    })

    return query
}

export function useUpsertProduct(companyId?: number, setEditableFields?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')

    return useMutation({
        mutationFn: (body: { products: CompanyProductType[]; company_id: string | number }) =>
            service.upsertProducts(body),
        meta: {
            clientErrMessage: 'Failed to update product',
            pathname: useGetPathname(),
            functionName: 'useUpsertProduct',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: console.error,
        onSuccess: (data, variables, context) => {
            if (setEditableFields) {
                setEditableFields({})
            }
            companyEvent.edit(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies.product._def })
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies.productTypes._def })
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.allProducts._def,
            })
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
    })
}
export function useUpdateProductPricingValues(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')

    return useMutation({
        mutationFn: (body: {
            products: { id: number; cost: number; shipping_fee: number; list_price: number }[]
            company_id: string | number
        }) => service.updateProductPricingValues(body),
        meta: {
            clientErrMessage: 'Failed to update pricing',
            pathname: useGetPathname(),
            functionName: 'useUpdateProductPricingValues',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: console.error,
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.productAuditHistory(
                    companyProductsTable,
                    variables.products[0].id,
                    {}
                ).queryKey,
            })
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
    })
}
export function useSetPrimaryVariant(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')

    return useMutation({
        mutationFn: (body: { products: CompanyProductType[]; company_id: string | number }) =>
            service.setPrimaryVariant(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useSetPrimaryVariant',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: console.error,
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies.productTypes._def })
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
    })
}

export function useDeleteProduct(companyId?: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: (product: any) => service.deleteProduct({ productId: product.id }),
        meta: {
            clientErrMessage: 'Failed to delete product',
            pathname: useGetPathname(),
            functionName: 'useDeleteProduct',
        },
        onMutate: async (variables) => {
            const eventDetails = {
                product_id: variables.id,
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products._def,
            })
        },
        onSettled: () => {},
    })
}

// Info Blocks
export function useCreateInfoBlock() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ infoBlock }: any) => service.createInfoBlock(infoBlock),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateInfoBlock',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useImportInfoBlock() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ vendorName, companyId }: any) => service.importInfoBlock(vendorName, companyId),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useUpdateInfoBlock() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ infoBlock }: any) => {
            return service.updateInfoBlock(infoBlock)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateInfoBlock',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useDeleteInfoBlock() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (infoBlock: any) => {
            return service.deleteInfoBlock(infoBlock.id)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteInfoBlock',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
// CONTACTS
export function useCreateContact(setShowQuickModal?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'contact')
    return useMutation({
        mutationFn: ({ contact }: any) => service.createContact(contact),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateContact',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.contact.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {
            setShowQuickModal(undefined)
        },
    })
}
export function useUpdateContact() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'contact')
    return useMutation({
        mutationFn: ({ contact }: any) => {
            return service.updateContact(contact)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateContact',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.contact.company_id,
                contact_id: variables.contact.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useDeleteContact() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'contact')
    return useMutation({
        mutationFn: ({ contact, skipToast = false }: any) => {
            return service.deleteContact(contact.id, skipToast)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteContact',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.contact.company_id,
                contact_id: variables.contact.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useArchiveVendorContact() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ contact }: any) => {
            return service.archiveVendorContact(contact.id)
        },

        meta: {
            pathname: useGetPathname(),
            functionName: 'useArchiveVendorContact',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}

//FILES
export function useDeleteFile() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'file')
    return useMutation({
        mutationFn: ({ file }: any) => {
            return service.deleteFile(file)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteFile',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.file.company_id,
                file_id: variables.file.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useCreateFile(setShowQuickModal?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'file')
    return useMutation({
        mutationFn: ({
            files,
            types,
            companyId,
            user,
        }: {
            files: FileList | null
            types: string[]
            companyId: number
            user: UserInit
        }) => {
            return service.createFile({
                files,
                types,
                companyId,
                user,
            })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateFile',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {
            setShowQuickModal(undefined)
        },
    })
}

export function useUpdateFile() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'file')
    return useMutation({
        mutationFn: ({ file }: any) => {
            return service.updateFile(file)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateFile',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.file.company_id,
                file_id: variables.file.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}

export function useUpdateLineItems() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ lineItemIds }: { lineItemIds: any }) => {
            return service.updateLineItems({ lineItemIds })
        },

        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateLineItems',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
//EMAILS
export function useCreateEmail(setShowQuickModal?: any) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'email')
    return useMutation({
        mutationFn: ({ email, companyId, user }: { email: any; companyId: number; user: UserInit }) => {
            return service.createEmail({ email, companyId, user })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateEmail',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(data, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {
            setShowQuickModal(undefined)
        },
    })
}

// ASSIGNMENTS
export function useAddAssignment() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ companyId, user_id }: { companyId: string | number; user_id: string | number }) => {
            return service.addAssignment({ company_id: companyId, user_id })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useAddAssignment',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useRemoveAssignment() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ companyId, user_id }: { companyId: number; user_id: string | number }) => {
            return service.removeAssignment({ company_id: companyId, user_id })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useRemoveAssignment',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}

//COMPANIES
export function useCompaniesQuery({ filters, search }: { filters: CompanyFilters; search: string }): any {
    const query = useQuery({
        ...procurementQueries.companies.list(filters),
        staleTime: 50000,
        enabled: !Boolean(search),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load companies',
            pathname: useGetPathname(),
            functionName: 'useCompaniesQuery',
        },
    })
    const queryClient = useQueryClient()

    // prefetch next pages
    useEffect(() => {
        if (query.data?.companies) {
            if (!(query.data.lastCursor[2] === null)) {
                const newFilters = {
                    ...filters,
                    pageParam: filters.pageParam + 1,
                    lastCursor: query.data.lastCursor,
                }
                queryClient.prefetchQuery({
                    ...procurementQueries.companies.list(newFilters),
                    staleTime: 50000,
                })
            }
        }
    }, [query.data?.companies, queryClient])

    return query
}
export function useGetCompaniesWithUnfulfilledQuery({
    filters,
    hasUnfulfilledFilter,
}: {
    filters: CompanyFilters
    hasUnfulfilledFilter: boolean
}): any {
    const query = useQuery({
        ...procurementQueries.companies.hasUnfulfilledList(filters),
        staleTime: 50000,
        enabled: Boolean(hasUnfulfilledFilter),
        meta: {
            clientErrMessage: 'Failed to load compay',
            pathname: useGetPathname(),
            functionName: 'useGetCompaniesWithUnfulfilledQuery',
        },
    })

    return query
}
export function useSearchCompanies({ filters, search }: { filters: CompanyFilters; search: string }): any {
    const query = useQuery({
        ...procurementQueries.companies.list(filters)._ctx.search(search),
        staleTime: 50000,
        enabled: Boolean(search),
        placeholderData: (previousData: any, previousQuery) => {
            let filteredOldCompanies: object[] = []
            if (filters.lead_type.length) {
                previousData?.companies.map((company: any) => {
                    if (filters.lead_type.includes(company.lead_type)) {
                        filteredOldCompanies.push(company)
                    }
                })
            }
            if (filters.lifecycle_status.length) {
                previousData?.companies.map((company: any) => {
                    if (filters.lifecycle_status.includes(company.lifecycle_status)) {
                        filteredOldCompanies.push(company)
                    }
                })
            }
            if (previousData) {
                const filteredPrevData = { ...previousData, companies: filteredOldCompanies }
                return filteredPrevData
            }
        },
        meta: {
            clientErrMessage: 'Failed to load search results',
            pathname: useGetPathname(),
            functionName: 'useSearchCompanies',
        },
    })

    const queryClient = useQueryClient()
    useEffect(() => {
        if (query.isError) {
            return
        }
        if (query.data?.companies) {
            if (!(query?.data?.lastCursor[2] === null)) {
                const newFilters = {
                    ...filters,
                    pageParam: filters.pageParam + 1,
                    lastCursor: query.data.lastCursor,
                }
                queryClient.prefetchQuery({
                    ...procurementQueries.companies.list(newFilters)._ctx.search(search),
                    staleTime: 50000,
                })
            }
        }
    }, [query.data?.companies, queryClient])
    return query
}

export function useUpdateCompany() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)

    return useMutation({
        mutationFn: ({ id, editableFields }: { id: number; editableFields: any }) => {
            return service.updateCompany({ id, ...editableFields })
        },
        meta: {
            clientErrMessage: 'Failed to update company',
            pathname: useGetPathname(),
            functionName: 'useUpdateCompany',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.id,
                ...variables.editableFields,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            if (context?.lead_type === 'Active') {
                companyEvent.completion(variables, context)
            } else {
                companyEvent.edit(variables, context)
            }
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
        },
    })
}
export function useRestoreCompany() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id }: { id: number }) => {
            return service.restoreCompany(id)
        },
        meta: {
            clientErrMessage: 'Failed to restore company',
            pathname: useGetPathname(),
            functionName: 'useRestoreCompany',
        },

        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
        },
    })
}

export function useDeleteCompany() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ company }: any) => {
            return service.deleteCompany(company)
        },
        meta: {
            clientErrMessage: 'Failed to delete company',
            pathname: useGetPathname(),
            functionName: 'useDeleteCompany',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.delete(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}
export function useArchiveCompany() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({ company }: any) => {
            return service.archiveCompany(company)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useArchiveCompany',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company.id,
                is_archived: 1,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
    })
}

//companies tasks

export function useTasksQuery({ filters, search }: { filters: CompanyTaskFilters; search: string }): any {
    const query = useQuery({
        ...procurementQueries.tasks.list(filters),
        staleTime: 50000,
        enabled: !Boolean(search),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load tasks',
            pathname: useGetPathname(),
            functionName: 'useTasksQuery',
        },
    })
    const queryClient = useQueryClient()

    useEffect(() => {
        if (query.data?.tasks) {
            if (!(query.data.lastCursor[0] === null)) {
                const newFilters = {
                    ...filters,
                    pageParam: filters.pageParam + 1,
                    lastCursor: query.data.lastCursor,
                }
                queryClient.prefetchQuery({
                    ...procurementQueries.tasks.list(newFilters),
                    staleTime: 20000,
                })
            }
        }
    }, [query.data?.tasks, queryClient])

    return query
}
export function useTasksQueryCalendar({ userId, day }: { userId: any; day: any }): any {
    const filters: CompanyTaskFilters = {
        fields: 'due_at',
        id: undefined,
        search: undefined,
        due_at: undefined,
        company_id: undefined,
        lastCursor: undefined,
        statuses: ['OPEN'],
        user_ids: [userId],
        due_at_options: [],
        selectedTaskDueDate: undefined,
        pageParam: 1,
        limit: 150,
    }
    const query = useQuery({
        ...procurementQueries.tasks.calendar(filters),
        staleTime: 50000,
        placeholderData: keepPreviousData,
        select: (data) => {
            const filteredTasks: object[] = []
            data.tasks?.forEach((task: any) => {
                const taskDay = new Date(task.due_at)
                taskDay.setHours(0, 0, 0, 0)
                if (taskDay.toISOString() == day.toISOString()) {
                    filteredTasks.push(task)
                }
            })
            return {
                ...data,
                tasks: filteredTasks,
            }
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useTasksQueryCalendar',
        },
    })

    return query
}
export function useTasksSearchQuery({ filters, search }: { filters: CompanyTaskFilters; search: string }): any {
    const query = useQuery({
        ...procurementQueries.tasks.list(filters)._ctx.search(filters.search),
        staleTime: 50000,
        enabled: Boolean(search),
        placeholderData: (previousData: any, previousQuery) => {
            let filteredOldTasks: object[] = []
            if (filters.statuses.length) {
                previousData?.tasks.map((task: any) => {
                    if (filters.statuses.includes(task.status)) {
                        filteredOldTasks.push(task)
                    }
                })
            }
            if (filters.user_ids.length) {
                previousData?.tasks.map((task: any) => {
                    if (filters.user_ids.includes(task.user_id)) {
                        filteredOldTasks.push(task)
                    }
                })
            }
            if (previousData) {
                const filteredPrevData = { ...previousData, tasks: filteredOldTasks }
                return filteredPrevData
            }
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useTasksSearchQuery',
        },
    })
    const queryClient = useQueryClient()

    useEffect(() => {
        if (query.data?.tasks) {
            if (!(query.data.lastCursor[0] === null)) {
                const newFilters = {
                    ...filters,
                    pageParam: filters.pageParam + 1,
                    lastCursor: query.data.lastCursor,
                }
                queryClient.prefetchQuery({
                    ...procurementQueries.tasks.list(newFilters)._ctx.search(search),
                    staleTime: 20000,
                })
            }
        }
    }, [query.data?.tasks, queryClient])
    return query
}

// task mutations

export function useUpdateTask() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, taskEventType)
    return useMutation({
        mutationFn: ({
            id,
            editableFields,
            company_id,
        }: {
            id: number | string | undefined
            editableFields: any
            company_id: any
        }) => {
            return service.updateTask({ id, ...editableFields })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateTask',
        },
        onMutate: (variables) => {
            const eventDetails = {
                task_id: variables.id,
                company_id: variables.company_id,
                ...variables.editableFields,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            if (context?.status === 'COMPLETED') {
                companyEvent.completion(variables, context)
            } else {
                companyEvent.edit(variables, context)
            }
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
        },
        onSettled: () => {},
    })
}
export function useDeleteTask() {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, taskEventType)
    return useMutation({
        mutationFn: (task: CompanyTaskType) => {
            return service.deleteTask(task)
        },
        meta: {
            clientErrMessage: 'Failed to delete task',
            pathname: useGetPathname(),
            functionName: 'useDeleteTask',
        },
        onMutate: (variables) => {
            const eventDetails = {
                task_id: variables.id,
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.delete(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.tasks._def })
        },
        onSettled: () => {},
    })
}

export function useProductAlertsQuery(companyId: number | undefined): any {
    const query = useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.products()._ctx.alerts(),
        staleTime: 50000,
        enabled: Boolean(companyId),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useProductAlertsQuery',
        },
    })
    return query
}

export function useCreateAlert(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product_alert')
    return useMutation({
        mutationFn: (body: any) => service.createAlert(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateAlert',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(variables, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products()._ctx.alerts._def,
            })
        },
        onSettled: () => {},
    })
}
export function useUpdateAlert(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product_alert')
    return useMutation({
        mutationFn: (body: any) => service.updateAlert(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateAlert',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: companyId,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.create(variables, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products()._ctx.alerts._def,
            })
        },
        onSettled: () => {},
    })
}

export function useDeleteAlert(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product_alert')
    return useMutation({
        mutationFn: (alert: any) => service.deleteAlert(alert.id),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteAlert',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.delete(variables, context)
            return queryClient.invalidateQueries({
                queryKey: procurementQueries.companies.detail(companyId)._ctx.products()._ctx.alerts._def,
            })
        },
        onSettled: () => {},
    })
}

export function useAddComment(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: (body: { value: string; product_id: string | number; company_id: number | string }) =>
            service.addComment(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useAddComment',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
                product_id: variables.product_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
        },
        onSettled: () => {},
    })
}

export function useAddVariantGrouping(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: (body: { product: any; sku: string | number; company_id: number | string }) =>
            service.addVariantGrouping(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useAddVariantGrouping',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
                product_id: variables.product.id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.detail(companyId).queryKey })
        },
        onSettled: () => {},
    })
}
export function useDeleteVariantGrouping(companyId: number) {
    const queryClient = useQueryClient()
    const companyEvent = useCompanyEvent(procurementDepartment, 'product')
    return useMutation({
        mutationFn: (body: { related_variants_group_id: string | number; company_id: number | string }) =>
            service.deleteVariantGrouping(body),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteVariantGrouping',
        },
        onMutate: (variables) => {
            const eventDetails = {
                company_id: variables.company_id,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            companyEvent.edit(variables, context)
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.detail(companyId).queryKey })
        },
        onSettled: () => {},
    })
}

// PARENT COMPANIES

export function useParentCompanyQuery(parentCompany: string): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany),
        staleTime: 50000,
        enabled: Boolean(parentCompany),
        placeholderData: keepPreviousData,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyQuery',
        },
    })

    return query
}

export function useParentCompanyNotesQuery({
    parentCompany,
    companyNameMap,
}: {
    parentCompany: string
    companyNameMap: any
}): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.events(parentCompany, companyNameMap),
        staleTime: 50000,
        enabled: Boolean(companyNameMap),
    })

    return query
}
export function useParentCompanyIncomingEmailsQuery({
    parentCompany,
    contactList,
    companyNameMap,
}: {
    parentCompany: string
    contactList: any[]
    companyNameMap: any
}): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.incomingEmails({ contactList, companyNameMap }),
        staleTime: 50000,
        enabled: Boolean(companyNameMap && contactList),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyNotesQuery',
        },
    })

    return query
}

export function useParentCompanyDetailTasksQuery({
    parentCompany,
    companyNameMap,
}: {
    parentCompany: string
    companyNameMap: any
}): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.tasks(parentCompany, companyNameMap),
        staleTime: 50000,
        enabled: Boolean(parentCompany),
        placeholderData: keepPreviousData,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyDetailTasksQuery',
        },
    })

    return query
}

export function useParentCompanyContactsQuery({
    parentCompany,
    companyNameMap,
}: {
    parentCompany: string
    companyNameMap: any
}) {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.contacts(companyNameMap),
        staleTime: 50000,
        enabled: Boolean(companyNameMap),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyContactsQuery',
        },
    })

    return query
}

export function useParentCompanyFilesQuery({
    parentCompany,
    companyNameMap,
}: {
    parentCompany: string
    companyNameMap: any
}): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.files(companyNameMap),
        staleTime: 50000,
        enabled: Boolean(companyNameMap),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyFilesQuery',
        },
    })

    return query
}
export function useParentCompanyEmailsQuery({
    parentCompany,
    companyNameMap,
}: {
    parentCompany: string
    companyNameMap: any
}): any {
    const query = useQuery({
        ...procurementQueries.companies.parent(parentCompany)._ctx.emails('', companyNameMap),
        staleTime: 50000,
        enabled: Boolean(companyNameMap),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useParentCompanyEmailsQuery',
        },
    })

    return query
}

// PARENT COMPANY MUTATIONS

export function useCreateParentCompanyNote() {
    const queryClient = useQueryClient()
    const parentCompanyEvent = useCompanyEvent(procurementDepartment, 'parent_company')
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({
            user,
            note,
            company_ids,
            parent_company,
        }: {
            user: UserInit
            note: any
            company_ids: string[]
            parent_company: string
        }) => {
            return service.createParentCompanyNote({ user, note, company_ids })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateParentCompanyNote',
        },
        onMutate: (variables) => {
            const eventDetails = {
                parent_company: variables.parent_company,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            variables.company_ids.forEach((company_id) => companyEvent.edit(variables, { company_id }))
            parentCompanyEvent.edit(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {},
    })
}
export function useCreateParentCompanyContact() {
    const queryClient = useQueryClient()
    const parentCompanyEvent = useCompanyEvent(procurementDepartment, 'parent_company')
    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    return useMutation({
        mutationFn: ({
            contact,
            company_ids,
            parent_company,
        }: {
            contact: any
            company_ids: string[]
            parent_company: string
        }) => {
            return service.createParentCompanyContact({ contact, company_ids })
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateParentCompanyContact',
        },
        onMutate: (variables) => {
            const eventDetails = {
                parent_company: variables.parent_company,
            }
            return eventDetails
        },
        onError: (error, variables, context) => {},
        onSuccess: (data, variables, context) => {
            variables.company_ids.forEach((company_id) => companyEvent.edit(variables, { company_id }))
            parentCompanyEvent.edit(variables, context)
            queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies._def })
        },
        onSettled: () => {},
    })
}

// SCORES

// SCORE GROUP SETUP
export function useCreateScoreSetupGroup() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (group: any) => {
            return service.createScoreSetupGroup(group)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateScoreSetupGroup',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}

export function useGetScoreSetupGroups() {
    return useQuery({
        ...procurementQueries.companies.scoreSetup(),
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useGetScoreSetupGroups',
        },
    })
}
export function useUpdateScoreSetupGroup() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ group }: any) => {
            return service.updateScoreSetupGroup(group)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateScoreSetupGroup',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}
export function useDeleteScoreSetupGroup() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ group }: any) => service.deleteScoreSetupGroup({ group }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteScoreSetupGroup',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}

//SCORE OPTION SETUP
export function useUpdatePositionMap() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (positionMap: any) => {
            return service.updatePositionMap(positionMap)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdatePositionMap',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}
export function useCreateScoreSetupOption() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (option: any) => service.createScoreSetupOption(option),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useCreateScoreSetupOption',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}
export function useGetScoreSetupOptions() {
    return useQuery({
        ...procurementQueries.companies.scoreSetup()._ctx.options(),
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useGetScoreSetupOptions',
        },
    })
}
export function useUpdateScoreSetupOption() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ option }: any) => {
            return service.updateScoreSetupOption(option)
        },
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateScoreSetupOption',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}
export function useDeleteScoreSetupOption() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ option }: any) => service.deleteScoreSetupOption({ option }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteScoreSetupOption',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
            return queryClient.invalidateQueries({ queryKey: procurementQueries.companies.scoreSetup._def })
        },
    })
}

export function useGetScores(companyId: number) {
    return useQuery({
        ...procurementQueries.companies.detail(companyId)._ctx.scores(),
        staleTime: 50000,
        meta: {
            pathname: useGetPathname(),
            functionName: 'useGetScores',
        },
    })
}

export function useUpdateScore() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ companyId, option }: any) => service.updateScore({ companyId, option }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useUpdateScore',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
        },
    })
}
export function useDeleteScore() {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: ({ companyId, option }: any) => service.deleteScore({ companyId, option }),
        meta: {
            pathname: useGetPathname(),
            functionName: 'useDeleteScore',
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: procurementQueries.companies._def,
            })
        },
    })
}
