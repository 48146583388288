import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const users = createQueryKeys('users', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getUsers(filters),
    }),
})

export const userLogins = createQueryKeys('userLogins', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getUserLogins(filters),
    }),
})

export const usersQueries = mergeQueryKeys(users, userLogins)
