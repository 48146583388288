import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { cn } from 'helpers'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function UserTable({ tableData, columns, showDepartment, showHeaders, size }: any) {
    const navigate = useNavigate()
    // sorting/filtering behaviour can be pulled into local state to be controlled with custom functions
    const [sorting, setSorting] = React.useState<SortingState>([])

    const tableContainerRef = React.useRef<HTMLDivElement>(null)

    // initiate table definition with sorting/filtering options, defaults, pass in local state
    const table = useReactTable({
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    })
    const { rows } = table.getRowModel()

    // unrelated to react-table
    // virtalize lists to increase performance when many items present.
    // should work copy/paste if you change estimateSize to match height of your items
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 28,
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 15,
    })

    // All html/css can be customized if you copy/paste this into new component.
    // Virtualization depends heavily on correct !!!GRID!!!/overflow/relative/h-full min-h-0 placement
    return (
        <div
            ref={tableContainerRef}
            className={cn('overflow-y-auto relative w-full', size === 'auto' && 'h-full min-h-0')}
        >
            <table className=' grid '>
                {showHeaders && (
                    <thead className='sticky top-0 z-[49] grid'>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                style={{ display: 'flex', width: '100%' }}
                                className={cn(
                                    ' bg-lightgrey text-text1 dark:bg-darkbg2 dark:text-darktext1 flex w-full'
                                )}
                            >
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{
                                            display: 'flex',
                                            width: header.getSize(),
                                        }}
                                        className='text-start flex  px-1 p-1 font-semibold capitalize z-50'
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none flex gap-1'
                                                        : 'flex gap-1'
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                              ? 'Sort descending'
                                                              : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: (
                                                        <div className='self-center max-h-[14px]'>
                                                            <CaretUpIcon />
                                                        </div>
                                                    ),
                                                    desc: (
                                                        <div className='self-center max-h-[14px]'>
                                                            <CaretDownIcon />
                                                        </div>
                                                    ),
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                    }}
                    className='w-full grid relative'
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index] as any
                        const user: any = table.getRow(row.id).original
                        return (
                            <tr
                                data-index={virtualRow.index}
                                ref={(node) => rowVirtualizer.measureElement(node)}
                                key={row.id}
                                style={{
                                    transform: `translateY(${virtualRow.start}px)`,
                                }}
                                className={cn(
                                    `w-full bg-bg1 dark:bg-darkbg1 flex absolute text-black dark:text-offwhite border-b-[2px]  border-lightgrey dark:border-gray-500 hover:bg-lightgrey cursor-pointer dark:hover:bg-darkbg2 hover:z-40`
                                )}
                                // change row click behaviour here. Open modal/navigate to new page etc
                                onClick={(event) => {
                                    if (event.button === 1 || event.ctrlKey || event.metaKey) {
                                        return window.open(`/admin/users?user_id=${user.id}`)
                                    }
                                    return navigate(`/admin/users?user_id=${user.id}`)
                                }}
                            >
                                {row.getVisibleCells().map((cell: any) => {
                                    return (
                                        <td
                                            style={{ width: cell.column.getSize() }}
                                            key={cell.id}
                                            className='p-1 flex items-center'
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
