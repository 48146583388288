import { PropsWithChildren, useEffect, useState } from 'react'
import CloseButton from './CloseButton'

interface ModalProps extends PropsWithChildren {
    children: any
    element?: any
    justifyCenter?: boolean
    itemsCenter?: boolean
    setShow?: Function
    z?: number
}

function Element({ setShow, children }: ModalProps) {
    const [rect, setRect] = useState<any>({})
    const [rendered, setRendered] = useState<boolean>(false)

    useEffect(() => {
        setRendered(true)
    }, [])

    const [test, setTest] = useState('0')

    useEffect(() => {
        if (rendered && setShow) {
            const marker = document.getElementById('modal-rect-marker')
            setRect((marker?.parentNode?.childNodes[1] as HTMLElement).getBoundingClientRect())
            setTest('300px')
        }
    }, [rendered])
    const topOffset = 51
    const leftOffset = 216

    return (
        <>
            {setShow && (
                <div
                    className='absolute'
                    id='modal-rect-marker'
                    style={{
                        zIndex: 1000,
                        left: `${rect?.right - leftOffset + 4}px` || 0,
                        top: `${rect?.top - topOffset - 4}px` || 0,
                    }}
                >
                    <div className='relative'>
                        <CloseButton onClick={setShow} />
                    </div>
                </div>
            )}
            {children}
        </>
    )
}

export default function Modal({ justifyCenter = true, itemsCenter = true, children, setShow, z = 30 }: ModalProps) {
    return (
        <div
            id='modal-outer'
            className={`fixed top-[51px] left-[216px] h-[calc(100%-51px)] w-[calc(100%-216px)] bg-black/20 z-${z}`}
        >
            <div
                className={`relative p-[32px] flex flex-col w-full h-full ${justifyCenter && 'items-center'} ${
                    itemsCenter ? 'justify-center' : ''
                }`}
            >
                {[].concat(children).map((c) => (
                    <Element setShow={setShow} children={c} />
                ))}
            </div>
        </div>
    )
}
