import CompetitorProductList from './lists/CompetitorProductList'

export default function CompetitorCatalogsScreen() {
    return (
        <div>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-2'>Competitor Products</h1>
            <CompetitorProductList />
        </div>
    )
}
