import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'
import { useGetEmailAttachments } from 'orderHubScreen/api/useQueries'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TaskPublicTimelineEventV2 from 'tasks/components/TaskPublicTimelineEventV2'
import { TaskV2 } from 'tasksV2/constants/tasks'
import { useAllUsersQuery } from 'users/api/useQueries'
import Email from '../../emails/Email'
import { EmailInit } from '../../emails/email.types'
import { cn, sortByAlphanumeric } from '../../helpers'
import { renderOrderTimelineEvent } from '../../orderHubScreen/orderHubScreen.helpers'
import { TaskTimelineEventV2 } from '../../tasks/tasks.types'
import { UserInit } from '../../users/users.types'
import { OrderInit, OrderNoteInit, OrderTimelineEvent } from '../orders.types'
import NewNoteField from './NewNoteField'
import { OrderNote } from './OrderNote'
import { TaskLatestActivity } from './TaskLatestActivity'

type EventsProps = {
    events: (OrderTimelineEvent | OrderNoteInit | TaskTimelineEventV2 | EmailInit)[]
    tasks: TaskV2[]
    addNewTask?: Function
    order?: OrderInit
    eventsLoaded?: any
    type?: 'orders' | 'draftOrders'
}

export function Events({ events, tasks, addNewTask, order, eventsLoaded, type = 'orders' }: EventsProps) {
    const savedActivityFilter: any =
        localStorage?.getItem('orderHubEventsFilter') !== null
            ? localStorage?.getItem('orderHubEventsFilter')?.split(',')
            : ['notes', 'order', 'task', 'email', 'order [all]', 'task [all]']
    const [activity, setActivity] = useState<string[]>(savedActivityFilter)
    const [sortedEvents, setSortedEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])
    const location = useLocation()
    const origin = location.pathname.toLowerCase().includes('draft-orders') ? 'draft_order' : 'order'

    const emailAttachmentsQuery = useGetEmailAttachments(order?.id?.toString(), origin)
    const { attachments: emailAttachments } = emailAttachmentsQuery?.data || {}

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const typeChecker: any = {
        notes: {
            check: (e: OrderNoteInit) => isOrderNote(e),
        },
        email: {
            check: (e: EmailInit) => isEmail(e),
        },
        order: {
            check: (e: OrderTimelineEvent) =>
                isOrderTimelineEvent(e) && !e.message.match(/((edited|removed) the|added a) note (on|to) this order\./),
        },
        'order [all]': {
            check: (e: OrderTimelineEvent) => isOrderTimelineEvent(e),
        },
        task: {
            check: (e: TaskTimelineEventV2) =>
                (isTaskTimelineEvent(e) || isTaskLatestActivity(e)) && !e.message.includes('edited this task'),
        },
        'task [all]': {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e),
        },
    }

    const normalize = (e: any, created_at: Date) => {
        return { ...e, normalized_created_at: created_at }
    }

    const isOrderTimelineEvent = (e: any) => e?.normalized_event_type === 'Shopify Timeline'
    const isTaskLatestActivity = (e: any) => e?.normalized_event_type === 'Task Events Public' && e.type == 'title'
    const isTaskTimelineEvent = (e: any) => e?.normalized_event_type === 'Task Events Public' && e.type !== 'title'
    const isOrderNote = (e: any) => e?.normalized_event_type === 'Order Note'
    const isEmail = (e: any) => e?.normalized_event_type === 'Email'
    useEffect(() => {
        const orderEvents = events.filter((e) => isOrderTimelineEvent(e)) as OrderTimelineEvent[]
        const taskEvents = events.filter(
            (e) => isTaskTimelineEvent(e) || isTaskLatestActivity(e)
        ) as TaskTimelineEventV2[]
        const orderNotes = events.filter((e) => isOrderNote(e)) as OrderNoteInit[]
        const emails = events.filter((e) => isEmail(e)) as EmailInit[]
        const normalizedEvents: any = [
            ...orderEvents.map((e) => normalize(e, new Date(e.createdAt))),
            ...taskEvents.map((e) => normalize(e, new Date(e.created_at))),
            ...orderNotes.map((e) => normalize(e, e.created_at)),
            ...emails.map((e) => normalize(e, e.created_at)),
        ]
        normalizedEvents.sort((a: any, b: any) =>
            sortByAlphanumeric(
                a.normalized_created_at?.getTime() || Infinity,
                b.normalized_created_at?.getTime() || Infinity
            )
        )
        normalizedEvents.forEach((e: any, i: number) => (e.normalized_event_index = i + 1))
        setSortedEvents(normalizedEvents)
    }, [events])

    useEffect(() => {
        if (activity.includes('all')) setActivity(Object.keys(typeChecker))
    }, [activity])
    useEffect(() => {
        let filteredEvents = sortedEvents.filter((e) => activity.some((type: any) => typeChecker[type].check(e)))
        setFilteredEvents(filteredEvents)
    }, [sortedEvents, activity])

    function FilterOptionBubble({ option, filter, setFilter }: any) {
        return (
            <div
                className={`text-sm relative whitespace-nowrap h-fit text-black bg-grey dark:bg-darkgrey ${
                    filter.includes(option) && '!bg-blue text-offwhite'
                } px-2 py-1 rounded cursor-pointer`}
                onClick={() => {
                    localStorage.setItem('orderHubEventsFilter', option)
                    setFilter([option])
                }}
            >
                <button className='capitalize'>{option}</button>
            </div>
        )
    }

    const timeline_eventsList: any = []
    filteredEvents.map((event) => {
        if (isTaskTimelineEvent(event)) {
            timeline_eventsList.push(event)
        }
    })
    const indexArr = timeline_eventsList.reverse().map((e: any) => e.id)
    return (
        <div className='grid gap-[8px] dark:text-offwhite'>
            <div className='max-w-[100%] grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] rounded shadow-small'>
                {!order?.id && (
                    <div className='w-[100%] dark:bg-darkaccent relative bg-white rounded'>
                        <textarea
                            id='new_note'
                            className='order-hub-screen__notes__textarea block min-h-[39px] h-[39px] w-[100%] p-[8px] max-w-none border-[1px] border-[#cdcdcd] rounded dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue shadow-md'
                            name='new_order_note'
                            value={''}
                            placeholder='New order note...'
                            onChange={({ target }: any) => {}}
                        />
                    </div>
                )}
                {order?.id && (
                    <NewNoteField
                        order={order}
                        tasks={(tasks as TaskV2[])?.filter((t) => t.id)}
                        addNewTask={addNewTask}
                    />
                )}
                <div className='flex gap-[8px] text-[14px] items-center'>
                    <h3 className='text-[16px] font-semibold'>Activity:</h3>
                    <div className='flex flex-col gap-[4px]'>
                        <div className='flex gap-[4px] flex-wrap items-center'>
                            <button
                                key={`order__filter-option${'All'}${-1}`}
                                className='text-fire font-semibold border-[2px] border-fire rounded py-[2px] px-[8px]'
                                onClick={() => {
                                    localStorage.setItem('orderHubEventsFilter', Object.keys(typeChecker).join(','))
                                    setActivity(Object.keys(typeChecker))
                                }}
                            >
                                All
                            </button>
                            {Object.keys(typeChecker).map((o, i) => (
                                <FilterOptionBubble
                                    key={`order__filter-option${o}${i}`}
                                    option={o}
                                    filter={activity}
                                    setFilter={setActivity}
                                />
                            ))}
                        </div>
                    </div>
                    <FetchingSpinner isFetching={!order?.id || !eventsLoaded} />
                </div>
                <div
                    className={cn(
                        'grid gap-[8px] border-l-[1px] border-grey dark:border-darkgrey',
                        (!order?.id || !eventsLoaded) && 'border-none'
                    )}
                >
                    {order?.id &&
                        eventsLoaded &&
                        filteredEvents
                            .map((e: any, i) => {
                                return (
                                    <div
                                        key={`order-event__${e.normalized_event_index}-${i}`}
                                        className='flex gap-[0] items-center'
                                    >
                                        <div className='relative left-[-14px] flex items-center justify-center text-[12px] min-w-[28px] min-h-[28px] border-[4px] border-white dark:border-darkaccent bg-white dark:bg-darkaccent basis-[1]'>
                                            <div className='absolute min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent z-index-1'>
                                                <span
                                                    key={e.normalized_event_index}
                                                    className='block text-center font-semibold min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent border-[1px] border-grey dark:border-accent rounded-full px-[2px]'
                                                >
                                                    {e.normalized_event_index}
                                                </span>
                                            </div>
                                            <div className='absolute left-[20px] bg-grey dark:bg-accent h-[1px] w-[12px]' />
                                        </div>
                                        {isOrderTimelineEvent(e) && renderOrderTimelineEvent(e)}
                                        {isOrderNote(e) && (
                                            <OrderNote
                                                index={e.normalized_event_index}
                                                note={e}
                                                state={order}
                                                type={type}
                                            />
                                        )}
                                        {isTaskLatestActivity(e) && (
                                            <TaskLatestActivity index={e.normalized_event_index} event={e} />
                                        )}
                                        {isTaskTimelineEvent(e) && (
                                            <TaskPublicTimelineEventV2
                                                key={e.id}
                                                task_type={
                                                    tasks.find((t) => (t as TaskV2).id === e.task_id)?.department_id ||
                                                    ''
                                                }
                                                tasks={tasks}
                                                event={e}
                                                user={users?.find((u) => u.id === e.user_id)}
                                            />
                                        )}
                                        {isEmail(e) && (
                                            <Email
                                                email={e}
                                                attachments={emailAttachments}
                                                isPending={emailAttachmentsQuery.isLoading}
                                            />
                                        )}
                                    </div>
                                )
                            })
                            .reverse()}
                    {(!order?.id || !eventsLoaded) && (
                        <div className='flex gap-[0] items-center bg-lightgrey/50 h-[66vh]'>
                            <SkeletonBasic className='relative mx-auto flex items-center justify-center text-[12px] w-full h-full dark:border-darkaccent dark:bg-darkaccent basis-[1]'></SkeletonBasic>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
