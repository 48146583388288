import { Button } from 'custom_components/component_Basics/Button'
import { DraftOrderInit, LineItemInit, OrderInit } from '../../orders.types'
import ItemPrice from './itemPrice'
import { useLocation, useSearchParams } from 'react-router-dom'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import { addBusinessDays } from 'date-fns'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { useSelector } from 'react-redux'
import { CREATE_TASK_BUTTON_CONTENT } from 'tasksV2/constants/tasks'

type BottomItemInfoProps = {
    item: LineItemInit
    order: OrderInit | DraftOrderInit
}

export default function BottomItemInfo({ item, order }: BottomItemInfoProps) {
    const user = useSelector((state: any) => state.user)
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showLineItemCreateModal') === 'true'
    const shippingAttribute = order.note_attributes.find((attr) => attr.name === 'shipping_estimate')
    const shippingEstimates = shippingAttribute?.value.split('\n\n').filter((v) => v) || []
    const shippingEstimate = (shippingEstimates.find((estimate) => estimate.split(':')[0] == item.sku) || '').split(
        ':'
    )[1]

    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const { departments } = departmentsQuery?.data || {}

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showLineItemCreateModal')
        } else {
            searchParams.set('showLineItemCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    return (
        <div
            key={item.id}
            className='flex gap-[16px] w-[100%] justify-between items-center col-span-2 text-[14px] py-[16px]'
        >
            <div className='flex gap-[16px]'>
                <div className='grid w-[100px] items-center relative'>
                    <img
                        className='object-contain min-w-[100px] min-h-[100px]'
                        src={window.IMAGE_MAP[`gid://shopify/Product/${item.product_id}`]}
                        alt={item.name}
                    />
                    <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                        {item.quantity}
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex flex-col gap-[4px]'>
                    <a
                        href={`https://factorypure.com/products/${item.handle}`}
                        target='_blank'
                        className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                    >
                        {item.title}
                    </a>
                    {item.variant_title && (
                        <p>
                            <strong className='text-darkgrey dark:text-grey'>Variant:</strong> {item.variant_title}
                        </p>
                    )}
                    <p>
                        <strong className='text-darkgrey dark:text-grey'>SKU:</strong> {item.sku}
                    </p>
                    {shippingEstimate && (
                        <p>
                            <strong className='text-darkgrey dark:text-grey'>Shipping Estimate:</strong>{' '}
                            {shippingEstimate}
                        </p>
                    )}
                    {order.name.includes('D') && item.discount_allocations && (
                        <ul className='list-disc ml-[16px] mt-[8px]'>
                            {item.discount_allocations.map((discount) => (
                                <li key={discount.title + discount.description}>
                                    {discount.title ? discount.title : discount.description} discount applied
                                </li>
                            ))}
                        </ul>
                    )}
                    {!order.name.includes('D') && item.discount_allocations.length > 0 && (
                        <ul className='list-disc ml-[16px] mt-[8px]'>
                            {item.discount_allocations.map((discount) => (
                                <li key={order.discount_applications[discount.discount_application_index].code}>
                                    {order.discount_applications[discount.discount_application_index].title
                                        ? order.discount_applications[discount.discount_application_index].title
                                        : order.discount_applications[discount.discount_application_index].code}{' '}
                                    discount applied
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='flex items-center justify-end w-full gap-2'>
                    <ItemPrice order={order} item={item} />
                </div>
                <div className='flex gap-2 justify-end'>
                    <a
                        className='inline-flex items-center rounded-md justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-bg1 dark:bg-darkbg1 shadow-sm hover:bg-lightgrey hover:dark:bg-darkbg2 h-8 rounded-md px-3 text-xs'
                        href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                    >
                        View On Shopify
                    </a>
                    <Button onClick={toggleCreateModal} size='sm' variant='outline'>
                        {CREATE_TASK_BUTTON_CONTENT}
                    </Button>
                </div>
            </div>
            {showCreateModal && (
                <CreateTaskModal
                    prePopulatedValues={{
                        department_id: departments?.find((department: any) => department.title === 'Customer Service')
                            .id,
                        priority: 'Low',
                        status: 'To Do',
                        send_slack_alert: false,
                        alert_offset: null,
                        associations: [
                            {
                                resource_name: 'user',
                                readable_name: `${user.firstName} ${user.lastName}`,
                                resource_id: user.id,
                            },
                            {
                                resource_name: location.pathname.includes('draft') ? 'draft_order' : 'order',
                                readable_name: order.name,
                                resource_id: order.id,
                            },
                            {
                                resource_name: location.pathname.includes('draft')
                                    ? 'draft_order_line_item'
                                    : 'order_line_item',
                                readable_name: `${order.name} - ${item?.sku}`,
                                resource_id: item.id,
                            },
                        ],
                    }}
                    closeModal={toggleCreateModal}
                />
            )}
        </div>
    )
}
