import { useEffect, useState } from 'react'

import SalesCalendar from './SalesCalendar'
import { useSelector } from 'react-redux'
import { DayMap, MonthMap, closePopout, dayMap, monthMap, userShopifyRepName } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { quickDateRange } from '../sales.helpers'

type SalesTimeFiltersProps = {
    reps: UserInit[]
    startDate: Date
    setStartDate: Function
    endDate: Date
    setEndDate: Function
    repName: string
    setRepName: Function
}

export default function SalesTimeFilters({
    reps,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    repName,
    setRepName,
}: SalesTimeFiltersProps) {
    const user = useSelector<any, any>((state) => state.user)

    const quarters = [1, 2, 3, 4]
    const months: any = {
        0: { name: 'January', orders: [] },
        1: { name: 'February', orders: [] },
        2: { name: 'March', orders: [] },
        3: { name: 'April', orders: [] },
        4: { name: 'May', orders: [] },
        5: { name: 'June', orders: [] },
        6: { name: 'July', orders: [] },
        7: { name: 'August', orders: [] },
        8: { name: 'September', orders: [] },
        9: { name: 'October', orders: [] },
        10: { name: 'November', orders: [] },
        11: { name: 'December', orders: [] },
    }
    const years = []
    for (let i = new Date().getFullYear() + 10; i >= 2019; i--) {
        years.unshift(i)
    }

    const [timeFilter, setTimeFilter] = useState('Year To Date')
    const [showTimeFilters, setShowTimeFilters] = useState(false)
    const [showSpecificTimeFilters, setShowSpecificTimeFilters] = useState('YTD')

    const [days, setDays] = useState(7)
    const [year, setYear] = useState(new Date().getFullYear())
    const [month, setMonth] = useState(new Date().getMonth())
    const [quarter, setQuarter] = useState(Math.ceil((new Date().getMonth() + 1) / 3))

    useEffect(() => {
        const listenToWindow = (e: any) => closePopout(e, ['js-time-filters'], setShowTimeFilters)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function handleQuickDateRange(y: number, q?: number, m?: number) {
        const [start, end] = quickDateRange(y, q, m)
        setStartDate(start)
        setEndDate(end)
    }

    return (
        <div className='sticky top-[51px] flex justify-between items-center dark:text-offwhite bg-white dark:bg-darkaccent border-[1px] border-darkgrey gap-[16px] p-[16px] rounded mb-[16px] zIndex-5'>
            <h2 className='text-[24px] font-bold font-bai'>
                {startDate
                    .toDateString()
                    .split(' ')
                    .map((dateItem) =>
                        dayMap[dateItem as keyof DayMap]
                            ? `${dayMap[dateItem as keyof DayMap]}, `
                            : monthMap[dateItem as keyof MonthMap]
                              ? `${monthMap[dateItem as keyof MonthMap]}`
                              : Number(dateItem) < 32
                                ? `${dateItem},`
                                : dateItem
                    )
                    .join(' ')}
                {startDate.toDateString() !== endDate.toDateString()
                    ? endDate
                          .toDateString()
                          .split(' ')
                          .map((dateItem) =>
                              dayMap[dateItem as keyof DayMap]
                                  ? ` - ${dayMap[dateItem as keyof DayMap]}, `
                                  : monthMap[dateItem as keyof MonthMap]
                                    ? `${monthMap[dateItem as keyof MonthMap]}`
                                    : Number(dateItem) < 32
                                      ? `${dateItem},`
                                      : dateItem
                          )
                          .join(' ')
                    : ''}
            </h2>
            <div className='flex gap-[16px]'>
                {user.type !== 'Client' && (
                    <select
                        className='dark:bg-darkaccent border-[1px] dark:border-grey'
                        value={repName}
                        onChange={({ target }) => setRepName(target.value)}
                    >
                        {reps.map((r) => (
                            <option value={userShopifyRepName(r)} className=''>
                                {userShopifyRepName(r)}
                            </option>
                        ))}
                    </select>
                )}
                <div className='relative flex js-time-filters'>
                    <button
                        onClick={() => {
                            setShowTimeFilters(!showTimeFilters)
                        }}
                        className='bg-white hover:bg-offwhite dark:bg-darkaccent dark:hover:bg-darkness dark:text-offwhite shadow-md text-black w-[130px] px-[8px] py-[2px] rounded border-[1px] border-[lightgrey] dark:border-darkgrey'
                    >
                        {timeFilter}
                    </button>
                    {showTimeFilters && (
                        <div className='flex absolute top-[calc(100%+16px)] right-[0px]'>
                            {showSpecificTimeFilters !== 'YTD' &&
                                showSpecificTimeFilters !== 'MTD' &&
                                showSpecificTimeFilters !== 'date' && (
                                    <div>
                                        <div className='flex flex-col gap-[16px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey p-[16px] min-w-[150px] shadow-small'>
                                            {showSpecificTimeFilters.match(/last \d+ days/) && (
                                                <div>
                                                    <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                        <label>Days</label>
                                                        <input
                                                            className='text-black dark:text-offwhite dark:bg-darkness w-[110px] px-[4px]'
                                                            placeholder={String(days)}
                                                            value={days}
                                                            max={10000}
                                                            onChange={({ target }) => {
                                                                if (
                                                                    (Number(target.value) &&
                                                                        Number(target.value) <= 9999999) ||
                                                                    Number(target.value) === 0
                                                                ) {
                                                                    setDays(Math.max(0, Number(target.value)))
                                                                    setTimeFilter(
                                                                        `Last ${target.value ? target.value : 0} Days`
                                                                    )
                                                                    setShowSpecificTimeFilters(
                                                                        `last ${target.value ? target.value : 0} days`
                                                                    )
                                                                    setStartDate(
                                                                        new Date(
                                                                            new Date().getFullYear(),
                                                                            new Date().getMonth(),
                                                                            new Date().getDate() - Number(target.value)
                                                                        )
                                                                    )
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                        <button
                                                            onClick={() => {
                                                                setTimeFilter('Last 7 Days')
                                                                setShowSpecificTimeFilters('last 7 days')
                                                                setDays(7)
                                                                setStartDate(
                                                                    new Date(
                                                                        new Date().getFullYear(),
                                                                        new Date().getMonth(),
                                                                        new Date().getDate() - 7
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            7 days
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setTimeFilter('Last 30 Days')
                                                                setShowSpecificTimeFilters('last 30 days')
                                                                setDays(30)
                                                                setStartDate(
                                                                    new Date(
                                                                        new Date().getFullYear(),
                                                                        new Date().getMonth(),
                                                                        new Date().getDate() - 30
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            30 days
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setTimeFilter('Last 90 Days')
                                                                setShowSpecificTimeFilters('last 90 days')
                                                                setDays(90)
                                                                setStartDate(
                                                                    new Date(
                                                                        new Date().getFullYear(),
                                                                        new Date().getMonth(),
                                                                        new Date().getDate() - 90
                                                                    )
                                                                )
                                                            }}
                                                        >
                                                            90 days
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {(showSpecificTimeFilters === 'year' ||
                                                showSpecificTimeFilters === 'quarter' ||
                                                showSpecificTimeFilters === 'month') && (
                                                <div>
                                                    <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                        <label>Year</label>
                                                        <select
                                                            className='dark:bg-darkness'
                                                            value={year}
                                                            onChange={({ target }) => {
                                                                setYear(Number(target.value))
                                                                setTimeFilter(
                                                                    `${target.value} ${
                                                                        showSpecificTimeFilters === 'year'
                                                                            ? ''
                                                                            : showSpecificTimeFilters === 'quarter'
                                                                              ? `Q${quarter}`
                                                                              : months[month].name.slice(0, 3)
                                                                    }`
                                                                )
                                                                handleQuickDateRange(
                                                                    Number(target.value),
                                                                    showSpecificTimeFilters === 'quarter'
                                                                        ? quarter
                                                                        : undefined,
                                                                    ['year', 'quarter'].includes(
                                                                        showSpecificTimeFilters
                                                                    )
                                                                        ? undefined
                                                                        : month
                                                                )
                                                            }}
                                                        >
                                                            {years.map((year) => (
                                                                <option value={year}>{year}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {showSpecificTimeFilters === 'year' && (
                                                        <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                            <button
                                                                onClick={() => {
                                                                    handleQuickDateRange(new Date().getFullYear())
                                                                    setYear(new Date().getFullYear())
                                                                    setTimeFilter(String(new Date().getFullYear()))
                                                                }}
                                                            >
                                                                This Year
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {showSpecificTimeFilters === 'quarter' && (
                                                <div>
                                                    <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                        <label>Quarter</label>
                                                        <select
                                                            className='dark:bg-darkness w-[100%]'
                                                            value={quarter}
                                                            onChange={({ target }) => {
                                                                handleQuickDateRange(year, Number(target.value))
                                                                setQuarter(parseInt(target.value))
                                                                setMonth((Number(target.value) - 1) * 3)
                                                                setTimeFilter(`${year} Q${target.value}`)
                                                            }}
                                                        >
                                                            {quarters.map((q) => (
                                                                <option value={q}>Q{q}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                        <button
                                                            onClick={() => {
                                                                handleQuickDateRange(
                                                                    new Date().getFullYear(),
                                                                    Math.ceil((new Date().getMonth() + 1) / 3)
                                                                )
                                                                setYear(new Date().getFullYear())
                                                                setMonth(new Date().getMonth())
                                                                setQuarter(Math.ceil((new Date().getMonth() + 1) / 3))
                                                                setTimeFilter(
                                                                    `${new Date().getFullYear()} Q${Math.ceil(
                                                                        (new Date().getMonth() + 1) / 3
                                                                    )}`
                                                                )
                                                            }}
                                                        >
                                                            This Quarter
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            {showSpecificTimeFilters === 'month' && (
                                                <div>
                                                    <div className='grid text-white dark:text-offwhite bg-darkness/25 p-[4px] rounded [&>select]:text-black [&>option]:text-black [&>select]:dark:text-offwhite'>
                                                        <label>Month</label>
                                                        <select
                                                            className='dark:bg-darkness w-[100%]'
                                                            value={month}
                                                            onChange={({ target }) => {
                                                                handleQuickDateRange(
                                                                    year,
                                                                    undefined,
                                                                    Number(target.value)
                                                                )
                                                                setMonth(parseInt(target.value))
                                                                setTimeFilter(
                                                                    `${year} ${months[target.value].name.slice(0, 3)}`
                                                                )
                                                            }}
                                                        >
                                                            {Object.keys(months).map((m) => (
                                                                <option value={m}>{months[m].name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='mt-[8px] grid gap-[8px] [&>*]:py-[4px] [&>*]:rounded  [&>*:hover]:dark:bg-darkgrey [&>*:hover]:bg-offwhite'>
                                                        <button
                                                            onClick={() => {
                                                                handleQuickDateRange(
                                                                    new Date().getFullYear(),
                                                                    undefined,
                                                                    new Date().getMonth()
                                                                )
                                                                setYear(new Date().getFullYear())
                                                                setQuarter(Math.ceil((new Date().getMonth() + 1) / 3))
                                                                setMonth(new Date().getMonth())
                                                                setTimeFilter(
                                                                    `${new Date().getFullYear()} ${months[
                                                                        new Date().getMonth()
                                                                    ].name.slice(0, 3)}`
                                                                )
                                                            }}
                                                        >
                                                            This Month
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            <div>
                                <div className='flex flex-col justify-start gap-[4px] bg-white dark:bg-darkaccent shadow-small rounded [&>button]:text-left [&>button:hover]:bg-offwhite [&>button:hover]:dark:bg-darkgrey  [&>button]:px-[16px] [&>button]:py-[4px] [&>button]:whitespace-nowrap [&>button]:relative'>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters('MTD')
                                            setTimeFilter('Month To Date')
                                            setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
                                            setEndDate(
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate(),
                                                    23,
                                                    59,
                                                    59
                                                )
                                            )
                                        }}
                                    >
                                        Month To Date
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters('YTD')
                                            setTimeFilter('Year To Date')
                                            setStartDate(new Date(new Date().getFullYear(), 0, 1))
                                            setEndDate(
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate(),
                                                    23,
                                                    59,
                                                    59
                                                )
                                            )
                                        }}
                                    >
                                        Year To Date
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters(`last ${days} days`)
                                            setTimeFilter(`Last ${days} Days`)
                                            setStartDate(
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate() - days
                                                )
                                            )
                                            setEndDate(
                                                new Date(
                                                    new Date().getFullYear(),
                                                    new Date().getMonth(),
                                                    new Date().getDate(),
                                                    23,
                                                    59,
                                                    59
                                                )
                                            )
                                        }}
                                    >
                                        <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                            &lt;
                                        </span>
                                        Last X Days
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters('year')
                                            setTimeFilter(String(year))
                                            handleQuickDateRange(year)
                                        }}
                                    >
                                        <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                            &lt;
                                        </span>
                                        By Year
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters('quarter')
                                            setTimeFilter(`${year} Q${quarter}`)
                                            handleQuickDateRange(year, quarter)
                                        }}
                                    >
                                        <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                            &lt;
                                        </span>
                                        By Quarter
                                    </button>
                                    <button
                                        onClick={() => {
                                            setShowSpecificTimeFilters('month')
                                            setTimeFilter(`${year} ${months[month].name.slice(0, 3)}`)
                                            handleQuickDateRange(year, undefined, month)
                                        }}
                                    >
                                        <span className='absolute left-[5px] text-[10px] top-[50%] translate-y-[-50%]'>
                                            &lt;
                                        </span>
                                        By Month
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='grid items-center'>
                    <div
                        className='flex gap-[8px] items-center'
                        onClick={() => {
                            setTimeFilter('By Date')
                            setShowSpecificTimeFilters('date')
                        }}
                    >
                        <button
                            className='bg-blue dark:bg-accent text-white dark:text-black font-semibold px-[8px] text-[12px] rounded h-[18px]'
                            onClick={() => {
                                setStartDate(
                                    new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - 1)
                                )
                                setEndDate(
                                    new Date(
                                        endDate.getFullYear(),
                                        endDate.getMonth(),
                                        endDate.getDate() - 1,
                                        23,
                                        59,
                                        59,
                                        999
                                    )
                                )
                            }}
                        >
                            &lt; Day
                        </button>
                        <SalesCalendar
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                            getFullDay={true}
                        />
                        <button
                            className='bg-blue dark:bg-accent text-white dark:text-black font-semibold px-[8px] text-[12px] rounded h-[18px]'
                            onClick={() => {
                                setStartDate(
                                    new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1)
                                )
                                setEndDate(
                                    new Date(
                                        endDate.getFullYear(),
                                        endDate.getMonth(),
                                        endDate.getDate() + 1,
                                        23,
                                        59,
                                        59,
                                        999
                                    )
                                )
                            }}
                        >
                            Day &gt;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
