import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const orders = createQueryKeys('orders', {
    detail: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getOrder(orderId),
        contextQueries: {
            events: () => ({
                queryKey: [orderId],
                queryFn: () => service.getOrderEventsPrivate(orderId),
            }),
        },
    }),
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getOrderList(filters),
    }),

    timeLineEvents: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getShopifyTimelineEvents(orderId),
    }),
    orderTaskEvents: (taskIds: string[]) => ({
        queryKey: [taskIds],
        queryFn: () => service.getOrderTaskEvents(taskIds),
    }),

    tags: () => ({
        queryKey: ['tags'],
        queryFn: () => service.getOrderTags(),
    }),
    emails: (orderId: string | undefined, purchaseOrderIds: number[]) => ({
        queryKey: [orderId, purchaseOrderIds],
        queryFn: () => service.getEmails(orderId, purchaseOrderIds),
    }),
    attachments: (orderId: string | undefined, table: string) => ({
        queryKey: [orderId, table],
        queryFn: () => service.getEmailAttachments(orderId, table),
    }),
})
export const fulfillmentOrders = createQueryKeys('fulfillmentOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getFulfillmentOrders(orderId),
    }),
})
export const purchaseOrders = createQueryKeys('purchaseOrders', {
    list: (orderId: string | undefined) => ({
        queryKey: [orderId],
        queryFn: () => service.getPurchaseOrders(orderId),
    }),
})
export const returns = createQueryKeys('returns', {
    list: (orderNumber: number | undefined) => ({
        queryKey: [orderNumber],
        queryFn: () => service.getReturns(orderNumber),
    }),
})

export default mergeQueryKeys(orders, fulfillmentOrders, purchaseOrders, returns)
