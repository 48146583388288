import { CalendarIcon } from '@radix-ui/react-icons'
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Button } from 'custom_components/component_Basics/Button'
import { addBusinessDays, addDays, addMonths, addWeeks, isSaturday, isSunday } from 'date-fns'
import { cn } from 'helpers'
import Select from 'procurement/components/Select'
import { useEffect, useState } from 'react'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import DueAtCalendar from './DueAtCalendar'
import { useSelector } from 'react-redux'

const HTMLInputFormatter = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
}).format

export default function TaskScreenDueAt({
    taskId,
    dueAt,
    mutation,
    className,
    setParentDate,
    setParentTime,
}: {
    taskId: number
    dueAt: string
    mutation: any
    className?: string
    setParentDate?: any
    setParentTime?: any
}) {
    const parsedDueAt = dueAt ? parseDateToHTMLInput(new Date(dueAt)) : ''
    const parsedTime = dueAt
        ? `${`0${new Date(dueAt).getHours()}`.slice(-2)}:${`0${new Date(dueAt).getMinutes()}`.slice(-2)}`
        : ''
    const [date, setDate] = useState(parsedDueAt)
    const [time, setTime] = useState(parsedTime)
    const [initRender, setInitRender] = useState(true)
    const settings = useSelector((state: any) => state.settings)

    function parseDateToHTMLInput(date: Date) {
        const dateString = HTMLInputFormatter(date)
        const [month, day, year] = dateString.split('/')
        return `${year}-${month}-${day}`
    }
    function parseHTMLInputToDate(htmlDate: string) {
        return new Date(`${htmlDate}T${time}:00Z`).toISOString()
    }
    const handleChangeDueAt = () => {
        if (mutation.isPending && mutation.variables.due_at) {
            return
        }
        const selectedDate = parseHTMLInputToDate(date)
        mutation.mutate({
            id: taskId,
            due_at: selectedDate,
        })
    }

    const thisMorning = new Date()
    thisMorning.setHours(0, 0, 0, 0)
    const formattedDate = HTMLDateInputFormatter(new Date(date?.replaceAll('-', '/') || 0))
    const today = HTMLDateInputFormatter(thisMorning)
    const tomorrow = HTMLDateInputFormatter(addBusinessDays(thisMorning, 1))
    const twoDays = HTMLDateInputFormatter(addBusinessDays(thisMorning, 2))
    const threeDays = HTMLDateInputFormatter(addBusinessDays(thisMorning, 3))
    const oneWeek = HTMLDateInputFormatter(addWeeks(thisMorning, 1))
    const twoWeeks = HTMLDateInputFormatter(addWeeks(thisMorning, 2))
    const oneMonthDate = addMonths(thisMorning, 1)
    const oneMonth = HTMLDateInputFormatter(
        isSaturday(oneMonthDate)
            ? addDays(oneMonthDate, 2)
            : isSunday(oneMonthDate)
              ? addDays(oneMonthDate, 1)
              : oneMonthDate
    )
    const threeMonthsDate = addMonths(thisMorning, 3)
    const threeMonths = HTMLDateInputFormatter(
        isSaturday(threeMonthsDate)
            ? addDays(threeMonthsDate, 2)
            : isSunday(threeMonthsDate)
              ? addDays(threeMonthsDate, 1)
              : threeMonthsDate
    )
    const sixMonthsDate = addMonths(thisMorning, 6)
    const sixMonths = HTMLDateInputFormatter(
        isSaturday(sixMonthsDate)
            ? addDays(sixMonthsDate, 2)
            : isSunday(sixMonthsDate)
              ? addDays(sixMonthsDate, 1)
              : sixMonthsDate
    )

    const isCustomDate = ![
        today,
        tomorrow,
        twoDays,
        threeDays,
        oneWeek,
        twoWeeks,
        oneMonth,
        threeMonths,
        sixMonths,
    ].includes(formattedDate)

    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        if (setParentDate) {
            if (target.value !== 'custom') {
                setParentDate(target.value)
            } else {
                setParentDate('')
            }
        }
        if (target.value !== 'custom') {
            setDate(target.value)
        } else {
            setDate('')
        }
    }
    useEffect(() => {
        setInitRender(false)
    }, [])

    useEffect(() => {
        if (!initRender) {
            if (setParentDate) {
                setParentDate(date)
            }
        }
    }, [date])

    useEffect(() => {
        setDate(parsedDueAt)
        setTime(parsedTime)
    }, [dueAt])

    return (
        <div className='flex flex-col gap-1'>
            <div className={cn('flex justify-between items-center min-h-[25px]', setParentDate && 'min-h-0')}>
                <h3
                    className={cn(
                        'font-bold place-content-center',
                        setParentDate && 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                    )}
                >
                    Due At
                </h3>
                {(date !== parsedDueAt || time !== parsedTime) && !setParentDate && (
                    <div className={cn('flex gap-3', mutation.isPending && 'opacity-50  pointer-events-none')}>
                        <Button onClick={handleChangeDueAt} variant={'outline'} size={'sm'} className='max-h-[25px]'>
                            Confirm
                        </Button>
                        <Button
                            onClick={() => {
                                setDate(parsedDueAt)
                                setTime(parsedTime)
                            }}
                            variant={'outline'}
                            size={'sm'}
                            className='max-h-[25px]'
                        >
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
            <div className='flex gap-1 items-center'>
                <Select
                    id='htmlDate'
                    name='htmlDate'
                    value={formattedDate}
                    onChange={handleChange}
                    className={cn('bg-bg1 dark:bg-darkbg1 dark:border dark:border-darkgrey ', className)}
                >
                    <option value={today}>Today</option>
                    <option value={tomorrow}>Next business day</option>
                    <option value={twoDays}>In 2 business days</option>
                    <option value={threeDays}>In 3 business days</option>
                    <option value={oneWeek}>In 1 week</option>
                    <option value={twoWeeks}>In 2 weeks</option>
                    <option value={oneMonth}>In 1 month</option>
                    <option value={threeMonths}>In 3 months</option>
                    <option value={sixMonths}>In 6 months</option>
                    <option value={isCustomDate ? formattedDate : ''}>Custom</option>
                </Select>
                <Popover open={undefined}>
                    <PopoverTrigger asChild>
                        <Button
                            variant={'ghost'}
                            size={'icon'}
                            className={cn('bg-bg1 h-7 w-7 dark:bg-darkbg1 dark:border dark:border-darkgrey', className)}
                        >
                            <CalendarIcon className=' h-4 w-4' />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className='w-auto p-0 z-50 ' align='start'>
                        <DueAtCalendar
                            selectedDate={date}
                            setSelectedDate={setDate}
                            className=' p-3 border border-primary rounded bg-bg1 dark:bg-darkbg1'
                        />
                    </PopoverContent>
                </Popover>
            </div>
            <div className='flex gap-1'>
                <input
                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                    className={cn(
                        'leading-none rounded p-1 w-full disabled:opacity-50 dark:bg-darkbg1 dark:border dark:border-darkgrey',
                        mutation.isPending && 'pointer-events-none',
                        className
                    )}
                    type='date'
                    id='due_at'
                    name='due_at'
                    value={date}
                    onChange={({ target }) => {
                        if (setParentDate) {
                            setParentDate(target.value || parseDateToHTMLInput(new Date()))
                        }
                        return setDate(target.value || parseDateToHTMLInput(new Date()))
                    }}
                />
                <input
                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                    className={cn(
                        'leading-none rounded p-1 w-full disabled:opacity-50 dark:bg-darkbg1 dark:border dark:border-darkgrey',
                        mutation.isPending && 'pointer-events-none',
                        className
                    )}
                    type='time'
                    id='due_at_time'
                    name='due_at_time'
                    value={time}
                    onChange={({ target }) => {
                        if (setParentTime) {
                            setParentTime(target.value || '08:00')
                        }
                        return setTime(target.value || '08:00')
                    }}
                />
            </div>
        </div>
    )
}
