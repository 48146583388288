import { vFetch } from 'helpers'

export const getRoles = () =>
    vFetch('/v2/roles', {
        cb: (res: any) => {
            if (res.success) {
                res.roles.forEach((role: any) => {
                    role.permission_set = JSON.parse(role.permission_set)
                })
            }
            return res
        },
    })
