import { TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'

export const TASK_STATUSES = ['To Do', 'In Progress', 'In Review', 'Blocked', 'Cancelled', 'Completed']
export const TASK_PRIORITIES = ['Low', 'Medium', 'High']
export const RECURRING_TASK_INTERVAL_TYPES = ['DAY', 'WEEK', 'MONTH', 'YEAR']
export const ACTIVE_BRIDGE_TABLE_RESOURCE_NAMES = [
    'order',
    'order_line_item',
    'draft_order',
    'draft_order_line_item',
    'project',
    'company',
    'customer',
    'customer_tax_exemption',
].sort()
export const ACTIVE_PROJECT_BRIDGE_TABLE_RESOURCE_NAMES = ['roadmap']
export const SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES = ['order', 'draft_order']

// Does not change procurement create task buttons
export const CREATE_TASK_BUTTON_CONTENT = <p>Create Task</p>

// admins + allowedToPin [akokko, ejarvis]
export const allowedToPin = [4, 9]

export const PRIORITY_MAP: { [key: string]: any } = {
    Low: {
        icon: TbAntennaBars3,
        className: '',
    },
    Medium: {
        icon: TbAntennaBars4,
        className: '',
    },
    High: {
        icon: TbAntennaBars5,
        className: 'text-red dark:text-lightred',
    },
    Urgent: {
        icon: TbAntennaBars5,
        className: 'text-red dark:text-lightred',
    },
}

export interface TaskV2 {
    id: number
    associations: any
    allow_descriptions: 0 | 1
    alert_offset: number | null
    department_id: number
    department_title: string
    description: string
    due_at: string
    estimate_id: number
    is_escalated: number
    parent_task_id: number
    pinned: number
    user_pinned: 0 | 1
    priority: string
    recurring_task_id: number
    send_email_alert: 0 | 1
    send_slack_alert: 0 | 1
    status: string
    title: string
    type_id: number
    created_by: number
    created_at: Date
    updated_at: Date
    string_due_at: string
    store_id: number
    prioritize_association_view: 0 | 1
}
export interface RecurringTask {
    id: number
    active: 0 | 1
    created_at: Date
    failure_count: number
    interval_type: string
    interval_value: number
    readable_interval: string
    starting_at: any
    task_data: TaskV2
    store_id: number
    updated_at: Date
}

export interface Project {
    id: number
    title: string
    description: string
    status: string
    start_date: Date
    target_date: Date
    project_lead: number
    pinned: number
    store_id: number
    created_at: Date
    updated_at: Date
    associations: any
}

export interface RoadMap {
    associations: any
    created_at: Date
    description: string
    id: number
    pinned: 0 | 1
    private: 0 | 1
    start_date: Date
    status: string
    store_id: number
    target_date: Date
    title: string
    updated_at: Date
}

export interface TaskDepartment {
    created_at: Date
    id: number
    private: 0 | 1
    allow_descriptions: 1 | 0
    store_id: number
    title: string
    updated_at: Date
    users: any
}
