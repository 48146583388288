import { useQueryClient } from '@tanstack/react-query'
import { CiWarning } from 'react-icons/ci'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useGetResourceTasks } from 'tasksV2/api/useQueries'
import { SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES } from 'tasksV2/constants/tasks'
import { getAssociationLink } from 'taxExemptions/helpers'

export default function ExistingTaskCheck({
    resource_name,
    resource_id,
    department_id,
    type_id,
}: {
    resource_name: string
    resource_id: number
    department_id: number
    type_id: number
}) {
    const user = useSelector((state: any) => state.user)
    const queryClient = useQueryClient()
    const tasksQuery = useGetResourceTasks(resource_name, resource_id)
    const tasks = (tasksQuery.data?.tasks || []).filter(
        (task: any) => task.department_id == department_id && task.type_id == type_id
    )
    const resourceLink =
        tasks.length > 0
            ? getAssociationLink(
                  JSON.parse(tasks[0].associations || '[]').filter(
                      (association: any) => association.resource_name !== 'user'
                  )[0],
                  user,
                  queryClient
              )
            : ''

    if (!SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES.includes(resource_name)) {
        return <></>
    }

    return (
        <>
            {tasks.length > 0 && (
                <div className='relative group'>
                    <CiWarning className='text-lg text-fire' />
                    <p className='hidden group-hover:block absolute top-full left-0 w-40 font-semibold z-40 bg-bg1 dark:bg-darkbg1 p-2 rounded shadow-md'>
                        <Link className='underline' to={resourceLink || `/tasks/${tasks[0]?.id}`}>
                            A task (#{tasks[0]?.id})
                        </Link>{' '}
                        with this department and type already exists for this resource
                    </p>
                </div>
            )}
        </>
    )
}
