import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { CalendarBase } from '../../../../custom_components/component_Basics/CalendarBase'
import { cn, dayMapArray } from '../../../../helpers'
import { useCreateTaskModal } from '../../../api/useQueries'
import { ProcurementContext, ProcurementContextType, getFilterBarUsers } from '../../../helpers'
import { useSearchParams } from 'react-router-dom'
import Textarea from '../../Textarea'
import { addBusinessDays, getDay } from 'date-fns'
import { FilterBarSelectorButton } from '../../../../custom_components/component_Basics/FilterBarSelectorButton'
import { useAllUsersQuery } from '../../../../users/api/useQueries'
import { UserInit } from '../../../../users/users.types'

export default function CreateTaskV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector<any, any>((state) => state.user)
    const [searchParams] = useSearchParams()
    const resourceView = searchParams.get('view')
    const settings = useSelector<any, any>((state) => state.settings)
    const [dueDate, setDueDate] = useState('in_1_day')
    const [selectedUsers, setSelectedUsers] = useState<any>({
        users: [user.id],
    })
    const [customDueDate, setCustomDueDate] = useState(
        [
            new Date().getFullYear(),
            `0${new Date().getMonth() + 1}`.slice(-2),
            `0${new Date().getDate()}`.slice(-2),
        ].join('-')
    )
    const [dueMinutes, setDueMinutes] = useState('08:00')
    const [alertOption, setAlertOption] = useState('at_time')
    const [task, setTask] = useState<CompanyTaskType>({
        title: '',
        status: 'OPEN',
        due_at: addBusinessDays(new Date(), 1).toISOString(),
        alert_at: 'at_time',
        send_slack_alert: 1,
        send_email_alert: 0,
        company_id: selectedCompany?.id,
        user_id: user.id,
        type: resourceView === 'products' ? 'products' : 'company',
    })
    const getAlertTime = (dateObject: Date, alertTime: string) => {
        const alertMap: { [key: string]: string } = {
            at_time: dateObject.toISOString(),
            '15_till': new Date(dateObject.getTime() - 15000 * 60).toISOString(),
            '30_till': new Date(dateObject.getTime() - 30000 * 60).toISOString(),
            '60_till': new Date(dateObject.getTime() - 60000 * 60).toISOString(),
            '24hrs_till': new Date(dateObject.getTime() - 1000 * 60 * 60 * 24).toISOString(),
        }
        return alertMap[alertTime]
    }

    const dueMap: { [key: string]: Date } = {
        today: new Date(),
        in_1_day: addBusinessDays(new Date(), 1),
        in_2_days: addBusinessDays(new Date(), 2),
        in_3_days: addBusinessDays(new Date(), 3),
        in_1_week: addBusinessDays(new Date(), 5),
        in_2_weeks: addBusinessDays(new Date(), 10),
        in_1_month: addBusinessDays(new Date(), 22),
        in_3_months: addBusinessDays(new Date(), 66),
        in_6_months: addBusinessDays(new Date(), 132),
    }

    const createTask = useCreateTaskModal(setShowQuickModal)

    const { isPending } = createTask

    const handleSubmit = () => {
        createTask.mutate(
            { task: { ...task, user_id: selectedUsers.users[0] || user.id } },
            {
                onSettled: () => {
                    return setShowQuickModal(undefined)
                },
            }
        )
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setTask((previousState) => ({
            ...previousState,
            [target.name]: target.type === 'checkbox' ? ((target as HTMLInputElement).checked ? 1 : 0) : target.value,
        }))
    }
    const getISOString = (dateObject: Date, time: string) => {
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth()
        const date = dateObject.getDate()
        const timeSplit = time ? time.split(':') : ['00', '00']
        let result

        const [hours, minutes] = timeSplit
        result = new Date(year, month, date, parseInt(hours), parseInt(minutes)).toISOString()

        return result
    }

    useEffect(() => {
        if (dueDate === 'custom') {
            const newDueAtString =
                getISOString(new Date(customDueDate.replaceAll('-', '/')), dueMinutes) || new Date().toISOString()
            setTask((previousState) => ({
                ...previousState,
                due_at: newDueAtString,
                alert_at: getAlertTime(new Date(newDueAtString), alertOption),
            }))
        } else {
            const newDueAtString = getISOString(dueMap[dueDate], dueMinutes) || new Date().toISOString()
            setTask((previousState) => ({
                ...previousState,
                due_at: newDueAtString,
                alert_at: getAlertTime(new Date(newDueAtString), alertOption),
            }))
        }
    }, [dueDate, dueMinutes, customDueDate])

    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsers(users),
                title: 'Users',
                field: 'users',
                values: selectedUsers.users,
                searchToggle: true,
                selectType: 'single',
                variant: 'default',
            },
        ],
        setFunction: setSelectedUsers,
        resetFunction: () => {
            setSelectedUsers({
                users: [],
            })
        },
    }

    useEffect(() => {
        setTask((previousState) => ({
            ...previousState,
            alert_at: getAlertTime(new Date(task.due_at), alertOption),
        }))
    }, [alertOption])

    return (
        <div className={cn(createTask.isPending && 'opacity-60', 'flex min-h-[385px]')}>
            <div className='flex flex-col w-full'>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>New Task</h3>
                </div>
                <div className='flex'>
                    <div className='p-[16px] h-fit flex flex-col gap-[8px]'>
                        <div className='flex flex-col gap-[4px] w-full max-h-[150px]'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Task
                            </label>
                            <Textarea
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='title'
                                name='title'
                                onChange={({ target }) =>
                                    setTask((previousState: CompanyTaskType) => {
                                        return {
                                            ...previousState,
                                            [target.name]:
                                                target.type === 'checkbox'
                                                    ? (target as unknown as HTMLInputElement).checked
                                                        ? 1
                                                        : 0
                                                    : target.value,
                                        }
                                    })
                                }
                                value={task.title}
                                disabled={isPending}
                            />
                        </div>
                        <div className='flex gap-[8px]'>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Due Date <br />
                                    (business days)
                                </label>
                                <select
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={dueDate}
                                    onChange={({ target }) => setDueDate(target.value)}
                                    disabled={isPending}
                                >
                                    <option className='dark:bg-darkness/90' value='today'>
                                        Today {`(${dayMapArray[getDay(new Date())]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_1_day'>
                                        In 1 day {`(${dayMapArray[getDay(addBusinessDays(new Date(), 1))]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_2_days'>
                                        In 2 days {`(${dayMapArray[getDay(addBusinessDays(new Date(), 2))]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_3_days'>
                                        In 3 days {`(${dayMapArray[getDay(addBusinessDays(new Date(), 3))]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_1_week'>
                                        In 1 week {`(${dayMapArray[getDay(addBusinessDays(new Date(), 5))]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_2_weeks'>
                                        In 2 weeks {`(${dayMapArray[getDay(addBusinessDays(new Date(), 10))]})`}
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_1_month'>
                                        In 1 month
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_3_months'>
                                        In 3 months
                                    </option>
                                    <option className='dark:bg-darkness/90' value='in_6_months'>
                                        In 6 months
                                    </option>
                                    <option className='dark:bg-darkness/90' value='custom'>
                                        Custom
                                    </option>
                                </select>
                                {dueDate === 'custom' && (
                                    <>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                            type='date'
                                            value={customDueDate}
                                            onChange={({ target }) => setCustomDueDate(target.value)}
                                            disabled={isPending}
                                        />
                                    </>
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full justify-between'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Time
                                </label>
                                <input
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] py-[2px] rounded-[4px]'
                                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                    type='time'
                                    value={dueMinutes}
                                    onChange={({ target }) => setDueMinutes(target.value)}
                                    disabled={isPending}
                                />
                            </div>
                        </div>
                        <div className='flex items-center gap-[16px]'>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Set Reminder
                                </label>
                                <select
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={alertOption}
                                    onChange={({ target }) => setAlertOption(target.value)}
                                    disabled={isPending}
                                >
                                    <option className='dark:bg-darkness/90' value=''>
                                        Do not remind
                                    </option>
                                    <option className='dark:bg-darkness/90' value='at_time'>
                                        When due
                                    </option>
                                    <option className='dark:bg-darkness/90' value='15_till'>
                                        15 minutes before
                                    </option>
                                    <option className='dark:bg-darkness/90' value='30_till'>
                                        30 minutes before
                                    </option>
                                    <option className='dark:bg-darkness/90' value='60_till'>
                                        1 hour before
                                    </option>
                                    <option className='dark:bg-darkness/90' value='24hrs_till'>
                                        1 day before
                                    </option>
                                </select>
                            </div>
                            <div className='flex items-center gap-[4px]'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Email
                                </label>
                                <input
                                    name='send_email_alert'
                                    id='send_email_alert'
                                    type='checkbox'
                                    checked={task.send_email_alert === 1}
                                    onChange={handleChange}
                                    disabled={isPending}
                                />
                            </div>
                            <div className='flex items-center gap-[4px]'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Slack
                                </label>
                                <input
                                    name='send_slack_alert'
                                    id='send_slack_alert'
                                    type='checkbox'
                                    checked={task.send_slack_alert === 1}
                                    onChange={handleChange}
                                    disabled={isPending}
                                />
                            </div>
                        </div>
                        <div className='flex gap-[4px] w-full'>
                            <div className='flex flex-col gap-1 w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Type
                                </label>
                                <select
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={task.type}
                                    onChange={({ target }) =>
                                        setTask((prev: any) => {
                                            return {
                                                ...prev,
                                                type: target.value,
                                            }
                                        })
                                    }
                                    disabled={isPending}
                                >
                                    <option className='dark:bg-darkness/90' value='products'>
                                        Products
                                    </option>
                                    <option className='dark:bg-darkness/90' value='company'>
                                        Company
                                    </option>
                                </select>
                            </div>
                            <div className='flex flex-col gap-1 w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    User
                                </label>
                                <div className='flex gap-[8px]'>
                                    {selectorButtonObject.params.length &&
                                        selectorButtonObject.params.map((param: any) => {
                                            return (
                                                <param.component
                                                    align={'start'}
                                                    key={param.field}
                                                    searchToggle={param.searchToggle}
                                                    title={param.title}
                                                    field={param.field}
                                                    options={param.options}
                                                    filterValues={param.values}
                                                    setFilterValues={selectorButtonObject.setFunction}
                                                    selectType={param.selectType}
                                                    variant={param.variant}
                                                />
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <CalendarBase />
                </div>
                <div className='p-[16px] mt-auto w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        onClick={handleSubmit}
                        className='py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                        disabled={isPending}
                    >
                        {createTask.isPending ? 'Creating..' : 'Create Task'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export type CompanyTaskType = {
    id?: string | number
    title: string
    status: 'OPEN' | 'COMPLETED'
    due_at: string
    alert_at?: string
    send_slack_alert?: 0 | 1
    send_email_alert?: 0 | 1
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    updated_at?: string
    name?: string
    type?: string
}
