import { Button } from 'custom_components/component_Basics/Button'
import { cn, parseResObject, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useCreateRecurringTask, useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TASK_PRIORITIES } from 'tasksV2/constants/tasks'
import TaskAssignmentsModal from './TaskAssignmentsModal'
import TaskAssociationsModal from './TaskAssociationsModal'
import TextareaV2 from 'procurement/components/TextareaV2'
import DueAtSelector from '../components/DueAtSelector'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'

export default function CreateRecurringTaskModal({
    selectedTask,
    closeModal,
}: {
    selectedTask?: any
    closeModal: () => void
}) {
    const settings = useSelector((state: any) => state.settings)
    const user = useSelector<any, any>((state) => state.user)
    const [showAssignments, setShowAssignments] = useState(false)
    const [showAssociations, setShowAssociations] = useState(false)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments = departmentConfigQuery.data?.departments || []
    const createRecurringTaskMutation = useCreateRecurringTask()
    const modalRef = useRef<HTMLDivElement>(null)
    const [task, setTask] = useState<any>(
        selectedTask
            ? parseResObject(structuredClone(selectedTask))
            : {
                  associations: [
                      {
                          resource_name: 'user',
                          readable_name: `${user.firstName} ${user.lastName}`,
                          resource_id: user.id,
                      },
                  ],
              }
    )
    const [recurringDetails, setRecurringDetails] = useState({
        interval_type: 'DAY',
        interval_value: 1,
        HTMLDateString: HTMLDateInputFormatter(new Date()),
        HTMLTimeString: `08:00`,
    })

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setTask((prev: any) => {
            const newState = structuredClone(prev)
            if (
                newState.associations?.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                return newState
            }
            if (!newState.associations) {
                newState.associations = []
            }
            newState.associations.push(association)
            return newState
        })
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setTask((prev: any) => {
            if (target.name === 'department_id') {
                return {
                    [target.name]: target.value,
                    associations: prev.associations,
                }
            }
            return {
                ...prev,
                [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
            }
        })
    }

    const handleChangeTaskAlert = ({ target }: { target: HTMLInputElement | HTMLSelectElement }) => {
        setTask((previousState: any) => {
            const newState = structuredClone(previousState)
            if (target.name === 'send_slack_alert' || target.name === 'send_email_alert') {
                newState[target.name] = (target as HTMLInputElement).checked === true ? 1 : 0
            }
            if (newState.send_slack_alert == 1 || newState.send_email_alert == 1) {
                newState.alert_offset = '0'
            } else {
                newState.alert_offset = ''
            }
            return {
                ...newState,
                [target.name]:
                    target.type === 'checkbox'
                        ? (target as HTMLInputElement).checked === true
                            ? 1
                            : 0
                        : target.value || null,
            }
        })
    }

    const handleCreate = () => {
        if (task.associations.filter((association: any) => association.resource_name === 'user').length < 1) {
            return sendToast({ message: 'Recurring task must be assigned to a user' })
        }
        createRecurringTaskMutation.mutate(
            {
                task_data: {
                    ...task,
                    id: undefined,
                    due_at: undefined,
                    is_escalated: undefined,
                    status: undefined,
                },
                created_from: task.id,
                ...recurringDetails,
                starting_at: new Date(
                    `${recurringDetails.HTMLDateString}T${recurringDetails.HTMLTimeString}:00.000Z`
                ).toISOString(),
                user_id: user.id,
            },
            {
                onSuccess: () => {
                    closeModal()
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    const selectedDepartment = departments.find((department: any) => department.id == task.department_id)
        ? parseResObject(departments.find((department: any) => department.id == task.department_id))
        : undefined
    const departmentTypes = selectedDepartment?.types || []
    const departmentEstimates = selectedDepartment?.estimates || []

    const setDate = (HTMLDateString: string) => {
        setRecurringDetails({
            ...recurringDetails,
            HTMLDateString: HTMLDateString,
        })
    }
    const setTime = (HTMLTimeString: string) => {
        setRecurringDetails({
            ...recurringDetails,
            HTMLTimeString: HTMLTimeString,
        })
    }

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md w-full max-w-[600px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Recurring Task</h2>

                <Select
                    label='Department'
                    id='department_id'
                    name='department_id'
                    onChange={handleChange}
                    value={task.department_id}
                >
                    <option value=''>Select a department</option>
                    {departments.map((department: any) => (
                        <option value={department.id}>{department.title}</option>
                    ))}
                </Select>
                <div className='flex gap-1 items-center mt-2'>
                    <p className='whitespace-nowrap font-bold'>Repeat every</p>
                    <Input
                        type='number'
                        id='interval_value'
                        name='interval_value'
                        onChange={({ target }) =>
                            setRecurringDetails({ ...recurringDetails, [target.name]: target.value })
                        }
                        value={recurringDetails.interval_value}
                    />
                    <Select
                        id='interval_type'
                        name='interval_type'
                        onChange={({ target }) =>
                            setRecurringDetails({ ...recurringDetails, [target.name]: target.value })
                        }
                        value={recurringDetails.interval_type}
                    >
                        <option value=''>Select an interval</option>
                        <option value='DAY'>Day(s)</option>
                        <option value='WEEK'>Week(s)</option>
                        <option value='MONTH'>Month(s)</option>
                        <option value='YEAR'>Year(s)</option>
                    </Select>
                </div>
                <p className='whitespace-nowrap font-bold'>Starting On</p>
                <DueAtSelector
                    hideLabel={true}
                    selectedDate={recurringDetails.HTMLDateString}
                    setSelectedDate={setDate}
                    selectedTime={recurringDetails.HTMLTimeString}
                    setSelectedTime={setTime}
                />
                <div className='flex flex-col gap-1 mt-1'>
                    <h3 className='font-bold leading-none'>Reminders</h3>
                    <div className='flex gap-2'>
                        <div className='flex gap-1 items-center'>
                            <label htmlFor='send_slack_alert' className='text-xs font-bold leading-none cursor-pointer'>
                                Slack
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='cursor-pointer'
                                type='checkbox'
                                id='send_slack_alert'
                                name='send_slack_alert'
                                checked={task.send_slack_alert == 1}
                                onChange={handleChangeTaskAlert}
                                value={task.send_slack_alert}
                            />
                        </div>
                        <div className='flex gap-1 items-center'>
                            <label htmlFor='send_email_alert' className='text-xs font-bold leading-none cursor-pointer'>
                                Email
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='cursor-pointer'
                                type='checkbox'
                                id='send_email_alert'
                                name='send_email_alert'
                                checked={task.send_email_alert == 1}
                                onChange={handleChangeTaskAlert}
                                value={task.send_email_alert}
                            />
                        </div>
                        <select
                            className='w-full p-1 rounded leading-none text-sm bg-lightgrey dark:bg-darkbg2 dark:border dark:border-darkgrey'
                            id='alert_offset'
                            name='alert_offset'
                            disabled
                            value={task.alert_offset || ''}
                            onChange={handleChangeTaskAlert}
                        >
                            <option value=''>Do not remind</option>
                            <option value='0'>When Due</option>
                        </select>
                    </div>
                </div>
                <div
                    className={`${
                        !task.department_id ? 'pointer-events-none opacity-50' : ''
                    } flex flex-col gap-2 mt-2`}
                >
                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-1'>
                            {departmentTypes.length > 0 && (
                                <Select
                                    label='Type'
                                    id='type_id'
                                    name='type_id'
                                    onChange={handleChange}
                                    value={task.type_id ?? ''}
                                >
                                    <option value=''>Select a Task Type</option>
                                    {departmentTypes.map((type: any) => (
                                        <option value={type.task_type_id}>{type.value}</option>
                                    ))}
                                </Select>
                            )}
                            {departmentEstimates.length > 0 && (
                                <Select
                                    label='Estimate'
                                    id='estimate_id'
                                    name='estimate_id'
                                    onChange={handleChange}
                                    value={task.estimate_id ?? ''}
                                >
                                    <option value=''>None</option>
                                    {departmentEstimates.map((type: any) => (
                                        <option value={type.task_estimate_id}>{type.value}</option>
                                    ))}
                                </Select>
                            )}
                            <Select
                                label='Priority'
                                id='priority'
                                name='priority'
                                onChange={handleChange}
                                value={task.priority ?? 'Low'}
                            >
                                <option value=''>None</option>
                                {TASK_PRIORITIES.map((priority: any) => (
                                    <option value={priority}>{priority}</option>
                                ))}
                            </Select>
                        </div>
                        <TextareaV2
                            className={cn(
                                ' w-full max-h-[60px]',
                                selectedDepartment?.allow_descriptions === 0 && 'max-h-[160px]'
                            )}
                            label={'Task'}
                            id='title'
                            name='title'
                            value={task.title ?? ''}
                            onChange={handleChange}
                        ></TextareaV2>
                        {selectedDepartment?.allow_descriptions === 1 && (
                            <TextareaV2
                                id='description'
                                name='description'
                                onChange={handleChange}
                                value={task.description ?? ''}
                                label='Additional Details'
                            />
                        )}
                    </div>
                    <div className='flex gap-1'>
                        <div className='flex flex-col gap-1 w-1/2'>
                            <label className='text-xs font-bold uppercase leading-none'>Assigned To</label>
                            <div className='flex gap-1 flex-wrap'>
                                {task.associations
                                    ?.filter((association: any) => association.resource_name === 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                            <span>{association.readable_name}</span>
                                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                                <button onClick={() => toggleAssociations(association)}>
                                                    <FaTimes />
                                                </button>
                                            )}
                                        </div>
                                    ))}
                            </div>
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                <div className='flex flex-wrap'>
                                    <Button variant={'outline'} size={'sm'} onClick={() => setShowAssignments(true)}>
                                        + Add / Edit Assignments
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Button className='mx-auto block mt-4' variant={'outline'} onClick={handleCreate}>
                    Create Task
                </Button>
            </div>
            {showAssignments && (
                <TaskAssignmentsModal
                    closeModal={() => setShowAssignments(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                    handleSave={() => setShowAssignments(false)}
                    handleReset={() => {
                        setTask((prev: any) => {
                            const newState = structuredClone(prev)

                            const originalAssignments = selectedTask
                                ? [
                                      ...selectedTask?.associations?.filter(
                                          (association: any) => association.resource_name === 'user'
                                      ),
                                  ]
                                : [
                                      {
                                          resource_name: 'user',
                                          readable_name: `${user.firstName} ${user.lastName}`,
                                          resource_id: user.id,
                                      },
                                  ]
                            newState.associations = [
                                ...originalAssignments,
                                ...newState.associations.filter(
                                    (association: any) => association.resource_name !== 'user'
                                ),
                            ]
                            return newState
                        })
                    }}
                />
            )}
            {showAssociations && (
                <TaskAssociationsModal
                    closeModal={() => setShowAssociations(false)}
                    associations={task.associations}
                    toggleAssociation={toggleAssociations}
                    handleSave={() => setShowAssociations(false)}
                    handleReset={() => {
                        setTask((prev: any) => {
                            const newState = structuredClone(prev)
                            const originalAssociations = selectedTask
                                ? [
                                      ...selectedTask?.associations?.filter(
                                          (association: any) => association.resource_name !== 'user'
                                      ),
                                  ]
                                : []

                            newState.associations = [
                                ...originalAssociations,
                                ...newState?.associations?.filter(
                                    (association: any) => association.resource_name === 'user'
                                ),
                            ]
                            return newState
                        })
                    }}
                />
            )}
        </div>
    )
}
