import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { cn } from 'helpers'
import { useCreatePrivateEvent, useGetPrivateEvents } from 'privateEvents/api/useQueries'
import { useCompanyEvent } from 'procurement/api/events'
import {
    useCompanyDetailQuery,
    useDeleteCompany,
    useGetScoreSetupGroups,
    useUpdateCompany,
} from 'procurement/api/useQueries'
import CompanyLogoV2 from 'procurement/components/CompanyLogoV2'
import Input from 'procurement/components/Input'
import {
    PROCUREMENT_PRIVATE_EVENT_ELEMENT,
    PROCUREMENT_PRIVATE_EVENT_URL,
    REQUIRED_LISTING_FIELDS,
    procurementDepartment,
    procurementEventType,
} from 'procurement/constants'
import { CompanyContext, CompanyContextType, ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import LabelWithInfo from 'productSetupV2/components/LabelWithInfo'
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { FaLink, FaTrash } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import PrivateEventModal from 'tasks/components/PrivateEventModal'
import VendorQuickActions from './VendorQuickActions'
import VendorResourcesV2 from './VendorResourcesV2'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'

export default function VendorModalV2() {
    const user = useSelector<any, any>((state) => state.user)
    const modalRef = useRef(null)
    const productTypesRef = useRef<HTMLDivElement>(null)
    const shopifyVendorsRef = useRef<HTMLDivElement>(null)
    const { selectedCompany, setSelectedCompany, toggleModal, productTypes, users } =
        useContext<ProcurementContextType>(ProcurementContext)
    const { resourceView, editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const [showDelete, setShowDelete] = useState(false)
    const [allowNewVendor, setAllowNewVendor] = useState(false)

    const getScoreSetupGroupsQuery = useGetScoreSetupGroups()
    const { groups } = getScoreSetupGroupsQuery?.data || {}

    let positionMap = undefined
    if (groups) {
        positionMap = groups[0]?.position_map
    }

    const [privateEventLogged, setPrivateEventLogged] = useState(false)
    const [showEventsPrivate, setShowEventsPrivate] = useState(false)
    const [selectedLifecycle, setSelectedLifecycle] = useState(undefined)

    const companyQuery = useCompanyDetailQuery(selectedCompany.id, selectedCompany)
    const company = companyQuery.data?.company || {}

    const companyEvent = useCompanyEvent(procurementDepartment, procurementEventType)
    useEffect(() => {
        companyEvent.view({
            company: selectedCompany,
        })
    }, [])

    const updateCompany = useUpdateCompany()
    const deleteCompany = useDeleteCompany()

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const handleCustomChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: 'custom:' + target.value,
        }))
    }

    const createPrivateEvent = useCreatePrivateEvent()

    const [editableFields, setEditableFields] = useState<any>({})

    const isEdited = Boolean(Object.keys(editableFields).length)
    const showCanadaShipsFrom =
        editableFields.sells_to_canada === 'Yes' ||
        (!editableFields.sells_to_canada && company.sells_to_canada === 'Yes')

    const sellsToCanadaSelectValue = ['Yes', 'No'].includes(
        editableFields.sells_to_canada ?? company.sells_to_canada ?? ''
    )
        ? editableFields.sells_to_canada ?? company.sells_to_canada
        : 'custom:'
    const acceptLogoSelectValue = ['Yes', 'No'].includes(editableFields.accept_logo ?? company.accept_logo ?? '')
        ? editableFields.accept_logo ?? company.accept_logo
        : 'custom:'
    const hasEdiSelectValue = ['Yes', 'No'].includes(editableFields.has_edi ?? company.has_edi ?? '')
        ? editableFields.has_edi ?? company.has_edi
        : 'custom:'
    const hasApiSelectValue = ['Yes', 'No'].includes(editableFields.has_api ?? company.has_api ?? '')
        ? editableFields.has_api ?? company.has_api
        : 'custom:'

    useEffect(() => {
        if (!privateEventLogged) {
            createPrivateEvent.mutate(
                {
                    privateEventUrl: PROCUREMENT_PRIVATE_EVENT_URL,
                    id: selectedCompany.id,
                    user_id: user.id,
                },
                {
                    onSuccess: () => {
                        setPrivateEventLogged(true)
                    },
                }
            )
        }
    }, [])

    const getPrivateEvents = useGetPrivateEvents({
        privateEventUrl: PROCUREMENT_PRIVATE_EVENT_URL,
        element: PROCUREMENT_PRIVATE_EVENT_ELEMENT,
        id: selectedCompany.id,
        enabled: true,
    })
    const privateEvents = getPrivateEvents.data?.privateEvents || {}

    const handleDelete = () => {
        deleteCompany.mutate(
            { company: selectedCompany },
            {
                onError: () => {
                    setShowDelete(false)
                },
                onSuccess: () => {
                    toggleModal()
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setSelectedCompany(undefined)
                toggleModal()
                setShowDelete(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    function handleUpdate() {
        let requiredFields = REQUIRED_LISTING_FIELDS.concat([])
        const editedCompany = {
            ...company,
            ...editableFields,
        }
        if (editedCompany['shipping_terms'] && editedCompany['shipping_terms'] !== 'free_shipping') {
            requiredFields.push('shipping_details')
        }
        if (editedCompany?.lifecycle_status.includes('Listing') || editedCompany?.lifecycle_status.includes('Listed')) {
            for (const field of requiredFields) {
                if (
                    editedCompany[field as keyof typeof editedCompany] === null ||
                    editedCompany[field as keyof typeof editedCompany] === undefined ||
                    editedCompany[field as keyof typeof editedCompany] === ''
                ) {
                    alert(`Must provide ${field.replaceAll('_', ' ')}!`)
                    return
                }
            }
        }
        updateCompany.mutate(
            { editableFields, id: company.id },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
                onSettled: () => {
                    setAllowNewVendor(false)
                },
            }
        )
    }

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center company-modal'
        >
            <div className='p-[26px] gap-[16px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative w-[95%] h-[95%] flex'>
                {privateEvents && showEventsPrivate && (
                    <PrivateEventModal
                        origin='company'
                        setViewModal={setShowEventsPrivate}
                        events={privateEvents}
                        url={PROCUREMENT_PRIVATE_EVENT_URL}
                        element={PROCUREMENT_PRIVATE_EVENT_ELEMENT}
                    />
                )}
                <button
                    onClick={() => {
                        toggleModal()
                        setShowDelete(false)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                {resourceView !== 'contacts' && resourceView !== 'unfulfilled orders' && (
                    <div
                        className={cn(
                            updateCompany.isPending && 'opacity-70 pointer-events-none',
                            'flex flex-col gap-[8px] overflow-auto pr-[16px] w-1/4 shrink-0'
                        )}
                    >
                        <div className='mb-[4px] flex gap-[8px] items-center'>
                            <CompanyLogoV2 updateCompany={updateCompany} />
                            <div>
                                <h2 className='text-darkgrey dark:text-offwhite text-[24px] font-bold uppercase leading-[1] mb-[4px]'>
                                    {editableFields.name ?? company.name ?? ''}
                                </h2>
                                {editableCompany.domain && (
                                    <a
                                        className='text-[14px] leading-[1] items-center flex gap-[4px] w-fit'
                                        href={`https://${editableCompany.domain}`}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {editableCompany.domain}
                                        <FaLink />
                                    </a>
                                )}
                            </div>
                        </div>
                        <VendorQuickActions isEdited={isEdited} setSelectedLifecycle={setSelectedLifecycle} />
                        <div className='w-full flex flex-col gap-[8px] '>
                            <div className='sticky top-[61px] p-1 pt-2 z-10 flex justify-between min-h-[38px] bg-bg1 dark:bg-darkbg1 items-center border-b dark:border-darkgrey pb-2 '>
                                <div className='flex min-h-[25px] items-center'>
                                    <p className='leading-[1] text-[14px] '>About this company </p>
                                </div>
                                {isEdited && (
                                    <div className='flex gap-3 justify-end items-center'>
                                        <button
                                            onClick={handleUpdate}
                                            className={`${
                                                updateCompany.isPending
                                                    ? 'opacity-[0.9] pointer-events-none'
                                                    : 'pointer-events-auto'
                                            } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                                        >
                                            {updateCompany.isPending ? 'SAVING...' : 'SAVE'}
                                        </button>
                                        <button
                                            onClick={() => {
                                                setAllowNewVendor(false)
                                                setEditableFields({})
                                            }}
                                            className={`${
                                                updateCompany.isPending
                                                    ? 'opacity-[0.9] pointer-events-none'
                                                    : 'pointer-events-auto'
                                            } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                )}
                            </div>
                            <Input
                                id='name'
                                name='name'
                                label='Name'
                                value={editableFields.name ?? company.name ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <div ref={shopifyVendorsRef} className='relative'>
                                {(company.lifecycle_status === 'Listed' || company.lifecycle_status === 'Listing') &&
                                !company?.shopify_vendor_name ? (
                                    <div className='group absolute top-[-2px] right-[72px] z-[5]'>
                                        <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                        <div className='hidden group-hover:flex border absolute top-[100%] right-[-40px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[100] '>
                                            Company listed but has no shopify vendor link
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}

                                <Input
                                    id='shopify_vendor_name'
                                    name='shopify_vendor_name'
                                    label='Shopify Vendor Name *'
                                    value={editableFields.shopify_vendor_name ?? company.shopify_vendor_name ?? ''}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    type='text'
                                    readOnly={!allowNewVendor}
                                    className={cn('', !company.shopify_vendor_name && 'border border-danger')}
                                />
                            </div>
                            <Input
                                id='parent_company'
                                name='parent_company'
                                label='Parent Company'
                                value={editableFields.parent_company ?? company.parent_company ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='domain'
                                name='domain'
                                label='Domain Name'
                                value={editableFields.domain ?? company.domain ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <div ref={productTypesRef} className='relative'>
                                <Input
                                    id='product_type'
                                    name='product_type'
                                    label='Product Type'
                                    value={editableFields.product_type ?? company.product_type ?? ''}
                                    onChange={(e) => {
                                        handleChange(e)
                                    }}
                                    type='text'
                                    readOnly={true}
                                />
                            </div>
                            <Input
                                id='phone'
                                name='phone'
                                label='Phone'
                                value={editableFields.phone ?? company.phone ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='work_phone'
                                name='work_phone'
                                label='Work Phone'
                                value={editableFields.work_phone ?? company.work_phone ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='email'
                                name='email'
                                label='Email'
                                value={editableFields.email ?? company.email ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='description'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Description
                                </label>
                                <textarea
                                    className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.description ?? company.description ?? ''}
                                    name='description'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='description'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Additional Details
                                </label>
                                <textarea
                                    className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.notes ?? company.notes ?? ''}
                                    name='notes'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                            <Input
                                id='city'
                                name='city'
                                label='City'
                                value={editableFields.city ?? company.city ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='state'
                                name='state'
                                label='State'
                                value={editableFields.state ?? company.state ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='zip'
                                name='zip'
                                label='Zip'
                                value={editableFields.zip ?? company.zip ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='timezone'
                                name='timezone'
                                label='Timezone'
                                value={editableFields.timezone ?? company.timezone ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='linkedin'
                                name='linkedin'
                                label='LinkedIn Profile'
                                value={editableFields.linkedin ?? company.linkedin ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <Input
                                id='facebook'
                                name='facebook'
                                label='Facebook Profile'
                                value={editableFields.facebook ?? company.facebook ?? ''}
                                onChange={handleChange}
                                type='text'
                                readOnly={true}
                            />
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    htmlFor='has_edi'
                                    info='EDI (Electronic Data Interchange) is a B2B connection that syncs inventory, prices, and more.'
                                    label='Has EDI'
                                />
                                <Select
                                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='has_edi'
                                    name='has_edi'
                                    onChange={handleChange}
                                    value={hasEdiSelectValue}
                                >
                                    <option value='No'>No</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='custom:'>Other</option>
                                </Select>
                                {hasEdiSelectValue === 'custom:' && (
                                    <Textarea
                                        className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='has_edi'
                                        name='has_edi'
                                        onChange={handleCustomChange}
                                        value={(editableFields.has_edi ?? company.has_edi ?? '').replace('custom:', '')}
                                    />
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    htmlFor='has_api'
                                    info='API (Application Programming Interface) is a system that allows communication with their system. This requires custom development but can be used to automated purchase orders, inventory, pricing.'
                                    label='Has API'
                                />
                                <Select
                                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='has_api'
                                    name='has_api'
                                    onChange={handleChange}
                                    value={hasApiSelectValue}
                                >
                                    <option value='No'>No</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='custom:'>Other</option>
                                </Select>
                                {hasApiSelectValue === 'custom:' && (
                                    <Textarea
                                        className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='has_api'
                                        name='has_api'
                                        onChange={handleCustomChange}
                                        value={(editableFields.has_api ?? company.has_api ?? '').replace('custom:', '')}
                                    />
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    htmlFor='accept_logo'
                                    info='Whether or not the company site has included our logo on the where to buy section.'
                                    label='Accepted Logo?'
                                />
                                <Select
                                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='accept_logo'
                                    name='accept_logo'
                                    onChange={handleChange}
                                    value={acceptLogoSelectValue}
                                >
                                    <option value='No'>No</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='custom:'>Other</option>
                                </Select>
                                {acceptLogoSelectValue === 'custom:' && (
                                    <Textarea
                                        className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='accept_logo'
                                        name='accept_logo'
                                        onChange={handleCustomChange}
                                        value={(editableFields.accept_logo ?? company.accept_logo ?? '').replace(
                                            'custom:',
                                            ''
                                        )}
                                    />
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    htmlFor='sells_to_canada'
                                    info='Whether or not the company sells products in Canada.'
                                    label='Sells To Canada'
                                />
                                <Select
                                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='sells_to_canada'
                                    name='sells_to_canada'
                                    onChange={handleChange}
                                    value={sellsToCanadaSelectValue}
                                >
                                    <option value='No'>No</option>
                                    <option value='Yes'>Yes</option>
                                    <option value='custom:'>Other</option>
                                </Select>
                                {sellsToCanadaSelectValue === 'custom:' && (
                                    <Textarea
                                        className=' max-h-[80px] w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='sells_to_canada'
                                        name='sells_to_canada'
                                        onChange={handleCustomChange}
                                        value={(
                                            editableFields.sells_to_canada ??
                                            company.sells_to_canada ??
                                            ''
                                        ).replace('custom:', '')}
                                    />
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Shipping Terms
                                </label>
                                <Input
                                    type='text'
                                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='shipping_terms'
                                    name='shipping_terms'
                                    onChange={handleChange}
                                    value={editableFields.shipping_terms ?? company.shipping_terms ?? ''}
                                    readOnly={true}
                                ></Input>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='shipping_details'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Shipping Details
                                </label>
                                <textarea
                                    id='shipping_details'
                                    className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.shipping_details ?? company.shipping_details}
                                    name='shipping_details'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='us_ships_from'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    US ships from
                                </label>
                                <textarea
                                    id='us_ships_from'
                                    className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.us_ships_from ?? company.us_ships_from ?? ''}
                                    name='us_ships_from'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                            {showCanadaShipsFrom && (
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label
                                        htmlFor='canada_ships_from'
                                        className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    >
                                        canada ships from
                                    </label>
                                    <textarea
                                        id='canada_ships_from'
                                        className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        value={editableFields.canada_ships_from ?? company.canada_ships_from ?? ''}
                                        name='canada_ships_from'
                                        onChange={handleChange}
                                        readOnly={true}
                                    ></textarea>
                                </div>
                            )}
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='processing_time'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    processing time
                                </label>
                                <textarea
                                    id='processing_time'
                                    className='w-full max-h-[100px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.processing_time ?? company.processing_time ?? ''}
                                    name='processing_time'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    htmlFor='warranty'
                                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    warranty
                                </label>
                                <textarea
                                    id='warranty'
                                    className='w-full max-h-[100px] !text-[16px] !leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={editableFields.warranty ?? company.warranty ?? ''}
                                    name='warranty'
                                    onChange={handleChange}
                                    readOnly={true}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                )}
                <div className='w-full flex flex-col max-w-full'>
                    <VendorResourcesV2
                        key={JSON.stringify(selectedCompany)}
                        company={selectedCompany}
                        setShowEventsPrivate={setShowEventsPrivate}
                        users={users}
                        companyEventsPrivate={privateEvents}
                        selectedLifecycle={selectedLifecycle}
                    />
                </div>
                {selectedCompany.id && (
                    <button className='absolute bottom-[8px] right-[8px] ' onClick={() => setShowDelete(true)}>
                        <FaTrash className='text-red dark:text-lightred' />
                    </button>
                )}

                {showDelete && (
                    <div className='absolute top-0 left-0 w-full h-full z-[42] bg-white dark:bg-darkaccent flex flex-col gap-[16px] items-center justify-center'>
                        <p className='font-bold dark:text-offwhite'>
                            Are you sure you want to delete this company? This action cannot be undone.
                        </p>
                        <div
                            className={cn(
                                deleteCompany.isPending && 'opacity-70',
                                'flex gap-[16px] justify-center items-center'
                            )}
                        >
                            <button
                                onClick={() => setShowDelete(false)}
                                className='block py-[8px] px-[24px] rounded-[4px] font-bold bg-grey dark:text-offwhite dark:bg-darkgrey uppercase'
                            >
                                Go Back
                            </button>
                            <button
                                onClick={handleDelete}
                                className='block py-[8px] px-[24px] rounded-[4px] font-bold bg-red text-white dark:text-white dark:bg-lightred uppercase'
                            >
                                {deleteCompany.isPending ? 'Deleting..' : 'Delete'}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
