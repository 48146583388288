import { useEffect, useState } from 'react'
import { ActiveModifiers, useDayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { useTasksQueryCalendar } from '../../procurement/api/useQueries'
import { cn } from '../../helpers'
import { useSearchParams } from 'react-router-dom'
import { useGetTasks, useGetTasksQueryCalendar } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'
import { useGetPathname } from 'productSetupV2/api/useQueries'

export interface DayContentProps {
    date: Date
    displayMonth: Date
    activeModifiers: ActiveModifiers
}

export function CustomDayContentTaskCounts(props: any): JSX.Element {
    const user = useSelector<any, any>((state) => state.user)
    const [searchParams, setSearchParams] = useSearchParams()
    const previewTypeString = searchParams.get('calendarPreviewType') || ''
    const previewUserString = searchParams.get('calendarPreviewUser') || ''
    const typeValue = decodeURIComponent(previewTypeString)
        .split(',')
        .filter((v) => v)
    const userValue = decodeURIComponent(previewUserString)
        .split(',')
        .filter((v) => v)

    const {
        locale,
        formatters: { formatDay },
    } = useDayPicker()

    const day = new Date(props.date)
    day.setHours(0, 0, 0, 0)

    const noPreviewSelected = userValue.length < 1 && typeValue.length < 1

    const filters = {
        statuses: [...TASK_STATUSES.filter((status) => status != 'Completed')],
    }

    const tasksQuery = useGetTasksQueryCalendar({
        filters: { ...filters, typeIds: typeValue, userIds: noPreviewSelected ? [user.id] : userValue, limit: 500 },
        day,
    })
    const { tasks } = tasksQuery?.data || {}
    const pathname = useGetPathname()

    return (
        <>
            <div className='p-0 flex flex-col w-full h-full'>
                <span className='self-start leading-3'>{formatDay(props.date, { locale })}</span>
                <div
                    className={cn(
                        tasks?.length &&
                            props.showTasks &&
                            pathname != '/orders' &&
                            pathname != '/ordersV2' &&
                            pathname != '/admin/logins'
                            ? 'opacity-100'
                            : 'opacity-0',
                        'mt-[2px] w-5 h-5 self-center rounded-full bg-accent2 dark:bg-darkaccent2 text-text1 dark:text-darktext2'
                    )}
                >
                    {tasks?.length &&
                    props.showTasks &&
                    pathname != '/orders' &&
                    pathname != '/ordersV2' &&
                    pathname != '/admin/logins'
                        ? tasks?.length
                        : ''}
                </div>
            </div>
        </>
    )
}
