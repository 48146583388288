import { CheckIcon } from '@radix-ui/react-icons'
import { cn } from 'helpers'
import { useSearchParams } from 'react-router-dom'

export default function ProductSearchSmall({
    crossSells,
    values,
    setProductOptions,
    type,
}: {
    crossSells: any
    values?: any
    setProductOptions: Function
    type: 'selected' | 'add'
}) {
    const selectedValues = new Set(values as string[])
    const [searchParams, setSearchParams] = useSearchParams()
    const field = 'products'

    return (
        <>
            {crossSells.map((cs: any) => {
                const isSelected = selectedValues.has(cs.id?.toString())

                return (
                    <div
                        onClick={() => {
                            if (isSelected) {
                                selectedValues.delete(cs.id?.toString())
                                setProductOptions((prev: any) => {
                                    return prev?.filter((option: any) => option?.value != cs.id?.toString())
                                })
                            } else {
                                selectedValues.add(cs.id?.toString())
                                setProductOptions((prev: any) =>
                                    [prev, { label: cs.title, value: cs.id?.toString(), icon: undefined }]
                                        .flat()
                                        .filter((v) => v)
                                )
                            }
                            const filterValues = Array.from(selectedValues)
                            if (filterValues.length < 1) {
                                setSearchParams((prev: URLSearchParams) => {
                                    prev.delete(field)
                                    prev.delete('after')
                                    prev.delete('before')
                                    return prev
                                })
                                return
                            }
                            searchParams.set(field, encodeURIComponent(filterValues.join(',')))
                            searchParams.delete('after')
                            searchParams.delete('before')
                            setSearchParams(searchParams)
                        }}
                        key={`${type}-${cs.id}`}
                        className='flex items-center gap-[16px] p-[8px] border-b-2 border-white last:border-none justify-between dark:border-darkaccent'
                    >
                        <div className='flex gap-2 items-center'>
                            <img
                                className='p-[4px] bg-white dark:bg-darkness rounded-[4px] border border-grey dark:border-darkgrey w-[48px] h-[48px] object-contain'
                                src={cs.featured_image}
                            />
                            <p className='text-[14px] dark:text-offwhite font-bold'>{cs.title}</p>
                        </div>
                        <div
                            className={cn(
                                'mr-2 flex h-4 w-4 bg-bg1 items-center justify-center rounded-sm border border-primary',
                                isSelected ? 'bg-bg1 text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                            )}
                        >
                            <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                    </div>
                )
            })}
        </>
    )
}
