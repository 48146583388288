import { useQuery } from '@tanstack/react-query'
import { salesQueries } from './queryKeys'

export const useGetSales = (filters: any) => {
    return useQuery({
        ...salesQueries.sales.detail(filters),
    })
}

export const useGetGoals = (filters: any) => {
    return useQuery({
        ...salesQueries.sales.goals(filters),
    })
}
