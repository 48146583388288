import { vFetch } from 'helpers'

export const getUsers = (filters: any) => {
    const queryParams = [
        filters.roles ? `roles=${filters.roles}` : null,
        filters.types ? `types=${filters.types}` : null,
    ]
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/users?${queryParams}`, {
        cb: (res: any) => {
            if (res.success) {
                res.users.forEach((user: any) => {
                    user.roles = JSON.parse(user.roles)
                    user.role_ids = JSON.parse(user.role_ids)
                    user.store_ids = JSON.parse(user.store_ids)
                })
            }
            return res
        },
        reThrowErr: true,
    })
}
export const getUserLogins = (filters: any) => {
    const queryParams = [
        filters.roles ? `roles=${filters.roles}` : null,
        filters.types ? `types=${filters.types}` : null,
        filters.userIds ? `userIds=${filters.userIds}` : null,
        filters.search ? `search=${filters.search}` : null,
        filters.from ? `from=${filters.from}` : null,
        filters.to ? `to=${filters.to}` : null,
    ]
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/users/logins?${queryParams}`, {
        reThrowErr: true,
    })
}

export const setupNewUser = (userSetupOptions: {
    email: string
    type: 'Client' | 'Admin' | 'SuperAdmin'
    role_ids: number[]
    store_ids: number[]
}) =>
    vFetch('/v2/users/setup', {
        method: 'POST',
        body: JSON.stringify(userSetupOptions),
    })

export const updateUser = (userUpdates: { role_ids: number[]; store_ids: number[] }) =>
    vFetch('/v2/users', {
        method: 'PUT',
        body: JSON.stringify(userUpdates),
    })
