import { useSelector } from 'react-redux'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TaskDepartment } from 'tasksV2/constants/tasks'
import { formatDateToLocale } from '../../helpers'
import { UserInit } from '../../users/users.types'
import '../manager/TaskScreen.css'
import { TaskTimelineEventV2 } from '../tasks.types'

type TaskTimelineEventProps = {
    event: TaskTimelineEventV2
    task_type: string | number
    user?: UserInit
    tasks: any
}

export default function TaskPublicTimelineEventV2({ event, tasks, task_type = '' }: TaskTimelineEventProps) {
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments: TaskDepartment[] = departmentConfigQuery.data?.departments || []
    let editedFields: any[] = []
    try {
        editedFields =
            event.type !== 'comment' && event.type !== 'email'
                ? event.message.split('(').length === 3
                    ? event.message.split('(')[2].split(')')[0].split(',')
                    : event.message.split('(')[1].split(')')[0].split(',')
                : []
    } catch {}
    function getInnerHTML(): any {
        const task = tasks.find((task: any) => {
            if (task.id === event.task_id) {
                return task
            }
        })
        let newHTMLString = event.message
        const nextState = event.new_value
        editedFields.forEach((field: any) => {
            if (nextState) {
                try {
                    if (field.trim() === 'title') {
                        newHTMLString = newHTMLString.replace(
                            event.message,
                            `<span class="task-screen__field"><span class="activity-field">Task #${
                                event.task_id
                            } Updated Activity:</span> ${
                                event.old_value[field.trim()]
                            } </span><pre class="task-screen__field-body whitespace-pre-wrap font-bai absolute text-[14px] top-[100%] left-0 w-[100%] max-h-[300px] overflow-y-scroll py-[8px] px-[16px] rounded border-[1px] border-[#a1a1a1] shadow-small bg-white text-black dark:text-grey  dark:bg-darkness dark:shadow-hot dark:border-darkaccent z-index-5">${event.message.replace(
                                'title',
                                'latest activity'
                            )}</pre>`
                        )
                    } else {
                        newHTMLString = newHTMLString.replaceAll('this task', `task #${event.task_id}`)
                    }
                    newHTMLString = newHTMLString.replace(
                        field,
                        `<span class="task-screen__field">${field}</span><pre class="task-screen__field-body whitespace-pre-wrap font-bai absolute text-[14px] top-[100%] left-0 w-[100%] max-h-[300px] overflow-y-scroll py-[8px] px-[16px] rounded border-[1px] border-[#a1a1a1] shadow-small bg-white text-black dark:text-grey dark:bg-darkness dark:shadow-hot dark:border-darkaccent z-index-5">${
                            field.trim() === 'order_notes'
                                ? `${nextState[field.trim()]}`
                                : field.trim() === 'due_at'
                                  ? `${
                                        new Date(event.old_value[field.trim()]).toUTCString().split(' 00:')[0]
                                    } <span class="inline-block text-[18px] text-lime font-black leading-[18px]  border-[1px] border-lime pt-[2px] rounded">→</span> ${
                                        new Date(nextState[field.trim()]).toUTCString().split(' 00:')[0]
                                    }`
                                  : `${
                                        event.old_value[field.trim()]
                                    } <span class="inline-block text-[18px] text-lime font-black leading-[18px]  border-[1px] border-lime pt-[2px] rounded">→</span> ${
                                        nextState[field.trim()]
                                    }`
                        }</pre>`
                    )
                } catch (err) {
                    console.log(err)
                }
            }
        })

        return newHTMLString
    }

    const date = new Date(event.created_at)
    const monthDateYear = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('/')
    const time = formatDateToLocale(event.created_at).split(' ').slice(-2).join(' ')
    const departmentName = departments?.find((department: TaskDepartment) => department.id === task_type)?.title
    const eventMessage = event.message.replaceAll(
        'this task',
        `${departmentName
            ?.split(' ')
            .filter((v) => v)
            .map((w) => w[0].toUpperCase())
            .join('')} task ${event.task_id}`
    )
    return (
        <div className='pl-[8px] basis-[100%] w-[100%]'>
            {event.type === 'comment' && event.user_id ? (
                <div
                    className={`flex gap-[8px] bg-white dark:bg-darkaccent items-center ${
                        event.type === 'comment' &&
                        'p-[8px] bg-white dark:bg-darkness shadow-small dark:shadow-cool rounded'
                    }`}
                >
                    <div className='flex flex-col basis-[100%] gap-[8px]'>
                        {user && event.type === 'comment' && (
                            <h3 className='font-bold text-[16px]'>
                                {user?.first_name} {user?.last_name} ({user.user_id})
                            </h3>
                        )}
                        <p className='relative dark:text-offwhite'>{eventMessage}</p>
                    </div>
                    <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                        <p className='dark:text-offwhite'>{monthDateYear}</p>
                        <p className='text-darkgrey dark:text-grey'>{time}</p>
                    </div>
                </div>
            ) : (
                <li className='flex gap-[8px] basis-[100%] items-center w-[100%]'>
                    <div className='basis-[100%]'>
                        <p
                            className='relative dark:text-offwhite break-words max-w-[400px]'
                            dangerouslySetInnerHTML={{
                                __html: editedFields.length > 0 ? getInnerHTML() : eventMessage,
                            }}
                        ></p>
                    </div>
                    <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                        <p className='dark:text-offwhite'>{monthDateYear}</p>
                        <p className='text-darkgrey dark:text-grey'>{time}</p>
                    </div>
                </li>
            )}
        </div>
    )
}
