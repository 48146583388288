import { useSelector } from 'react-redux'

export default function ProductMenu({
    step,
    handleStepChange,
    productIsSelected,
    showHistory,
    setShowHistory,
}: {
    step: string
    handleStepChange: Function
    productIsSelected: boolean
    showHistory: boolean
    setShowHistory: Function
}) {
    return (
        <div className='flex gap-6'>
            <div className='flex gap-1'>
                <button
                    onClick={() => handleStepChange('product')}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'product'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Product
                </button>
                <button
                    onClick={() => handleStepChange('identifiers')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'identifiers'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Variants
                </button>
            </div>
            <div className='flex gap-[2px]'>
                <button
                    onClick={() => handleStepChange('images')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'images'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Images
                </button>
                <button
                    onClick={() => handleStepChange('tags')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'tags'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Tags
                </button>
                <button
                    onClick={() => handleStepChange('checkmarks')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'checkmarks'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Checkmarks
                </button>
                <button
                    onClick={() => handleStepChange('plugs')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'plugs'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Plugs
                </button>
                <button
                    onClick={() => handleStepChange('features')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'features'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Features
                </button>
                <button
                    onClick={() => handleStepChange('specifications')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'specifications'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Specifications
                </button>
                <button
                    onClick={() => handleStepChange('warranty')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'warranty'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Warranty
                </button>
                <button
                    onClick={() => handleStepChange('manuals')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'manuals'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Manuals
                </button>
                <button
                    onClick={() => handleStepChange('cross-sells')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'cross-sells'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Cross Sells
                </button>
                <button
                    onClick={() => handleStepChange('filters')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'filters'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Filters
                </button>
                <button
                    onClick={() => handleStepChange('group')}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'group'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Group
                </button>
            </div>
            <div className='flex gap-[2px] ml-auto'>
                <button
                    onClick={() => {
                        if (!(step === 'preview')) {
                            showHistory ? setShowHistory(false) : setShowHistory(true)
                        }
                    }}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] ml-auto py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        showHistory
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    History
                </button>
                <button
                    onClick={() => {
                        setShowHistory(false)
                        handleStepChange('preview')
                    }}
                    disabled={!productIsSelected}
                    className={`disabled:opacity-[0.5] py-[6px] px-[8px] text-[14px] font-bold rounded-t-[4px] ${
                        step === 'preview'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'dark:border dark:border-darkgrey dark:border-b-0 bg-lightgrey text-black dark:bg-darkness dark:text-white'
                    }`}
                >
                    Preview
                </button>
            </div>
        </div>
    )
}
