import { subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'
import { getFilterBarUsers } from '../../procurement/helpers'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { DATE_PICKER_OPTIONS } from '../helpers'
import CompanyStatusAnalyticsReport from '../reports/CompanyStatusAnalyticsReport'
import { AnalyticsHeader } from './AnalyticsHeader'
import { getCompareDateRange } from './DatePickerPresetsCompare'
import { RiArrowGoBackLine } from 'react-icons/ri'

export default function AnalyticsReportContainer() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const toDifference = searchParams.get('to') || '-1'
    const fromDifference = searchParams.get('from') || '-7'
    const optionIndexParam: string = searchParams.get('option') || '2'
    const compareIndexParam: string = searchParams.get('compare') || '0'
    const [optionIndex, setOptionIndex] = useState<number>(parseInt(optionIndexParam))
    const user = useSelector<any, any>((state) => state.user)
    const [selectedUsers, setSelectedUsers] = useState<any>({
        users: [user.id],
    })

    const [period, setPeriod] = React.useState<{
        value: string
        function: Function
        rangeString: string
    }>({
        value: DATE_PICKER_OPTIONS[optionIndex].values.join(),
        function: DATE_PICKER_OPTIONS[optionIndex].function,
        rangeString: DATE_PICKER_OPTIONS[optionIndex].label,
    })

    const [date, setDate] = React.useState<DateRange | undefined>({
        from: new Date(subDays(new Date(), -parseInt(fromDifference)).toDateString()),
        to: new Date(subDays(new Date(), -parseInt(toDifference)).toDateString()),
    })
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    useEffect(() => {
        if (!(user.type === 'Admin' || user.type === 'SuperAdmin')) {
            navigate(`/`)
        }
    }, [])

    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsers(users),
                title: 'Users',
                field: 'users',
                values: selectedUsers.users,
                searchToggle: true,
            },
        ],
        setFunction: setSelectedUsers,
        resetFunction: () => {
            setSelectedUsers({
                users: [],
            })
        },
    }
    const [customCompareDate, setCustomCompareDate] = React.useState<DateRange | undefined>(undefined)

    const compareDate = getCompareDateRange(date, period, customCompareDate, parseInt(compareIndexParam))

    return (
        <>
            <div className='flex gap-3 items-center min-h-[48px] z-[31] bg-bg1 dark:bg-darkbg2 cursor-pointer sticky top-12'>
                <span
                    onClick={() => navigate(`/analytics?${searchParams.toString()}`)}
                    className='self-center cursor-pointer'
                >
                    <RiArrowGoBackLine />
                </span>
                <h1
                    onClick={() => navigate(`/analytics?${searchParams.toString()}`)}
                    className='self-center capitalize text-[24px] font-semibold dark:text-offwhite cursor-pointer'
                >
                    Analytics
                </h1>
                <AnalyticsHeader
                    selectorButtonObject={selectorButtonObject}
                    date={date}
                    setDate={setDate}
                    customCompareDate={customCompareDate}
                    setCustomCompareDate={setCustomCompareDate}
                    period={period}
                    setPeriod={setPeriod}
                    optionIndex={optionIndex}
                    setOptionIndex={setOptionIndex}
                    compare={compareIndexParam}
                />
            </div>
            <div className='relative flex flex-col gap-4'>
                <CompanyStatusAnalyticsReport
                    users={users}
                    user_ids={selectedUsers.users}
                    dateRange={date}
                    dateRangeCompare={compareDate}
                    rangeString={period.rangeString}
                />
            </div>
        </>
    )
}
