export default function DeleteDepartmentModal({
    handleDeleteDepartment,
    handleCancel,
}: {
    handleDeleteDepartment: any
    handleCancel: any
}) {
    return (
        <div className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-cols h-[1000px] w-[2000px] z-20 mb-2 justify-center backdrop-blur-sm backdrop-brightness-50'>
            <div className='relative p-6 self-center bg-white dark:bg-darkbg1 border dark:border-darkbg2 rounded'>
                <p
                    className='mb-3 text-2xl 
                    text:white dark:text-offwhite rounded hover:cursor-default text-left'
                >
                    Confirm Delete
                </p>
                <p
                    className='mb-1 text-xl font-light
                    text:white dark:text-offwhite rounded hover:cursor-default text-left'
                >
                    Deleting a department will delete all articles associated with<br></br> it and{' '}
                    <span className='font-medium'>CANNOT</span> be undone. Are you sure you want to delete?
                </p>
                <div className='p-2 border border-t-0 border-x-0 dark:border-darkgrey'></div>
                <div className='flex justify-end mt-5 text-xl'>
                    <button
                        onClick={handleDeleteDepartment}
                        className='py-4 px-6 font-light bg-blue dark:bg-accent text-offwhite  dark:text-darkaccent rounded'
                    >
                        Confirm
                    </button>
                    <button onClick={handleCancel} className='py-4 px-6 dark:text-offwhite rounded font-light'>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}
