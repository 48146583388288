import InventoryEmails from 'edi/InventoryEmails'
import FollowUpEmailScreen from 'followUpEmail/FollowUpEmailScreen'
import ProductListScreen from 'productSetupV2/ProductListScreen'
import InventoryEmailDetail from 'productSetupV2/inventoryEmails/InventoryEmailDetail'
import ProductGroupDetail from 'productSetupV2/productGroups/ProductGroupDetail'
import ProductGroups from 'productSetupV2/productGroups/ProductGroups'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import VendorScreen from 'vendors/VendorScreen'
import XeroScreen from 'xero/XeroScreen'
import AccountScreen from '../account/AccountScreen'
import ResetPasswordScreen from '../account/ResetPasswordScreen'
import AdminScreen from '../admin/AdminScreen'
import CacheScreenV2 from '../admin/cache/CacheScreenV2'
import ControlTowerScreen from '../admin/controlTower/ControlTowerScreen'
import EmailTemplateScreen from '../admin/emailTemplates/EmailTemplateScreen'
import MigrationsScreen from '../admin/migrations/MigrationsScreen'
import RedirectsScreen from '../admin/redirects/RedirectsScreen'
import SessionAnalyticsScreen from '../admin/sessionAnalytics/SessionAnalyticsScreen'
import AnalyticsReportContainer from '../analytics/components/AnalyticsReportContainer'
import AnalyticsUserReport from '../analytics/components/AnalyticsUserReport'
import CostAndPriceScreen from '../costAndPrice/v2/CostAndPriceScreen'
import ComponentList from '../custom_components/ComponentList'
import CustomersScreen from '../customersV2/CustomersScreen'
import EDIOrdersScreen from '../edi/EDIOrdersScreen'
import EDIScreen from '../edi/EDIScreen'
import { ErrorBoundary } from '../helpers'
import HomeScreen from '../home/HomeScreen'
import LoginScreen from '../login/LoginScreen'
import MoversScreen from '../movers/MoversScreen'
import DailyFreightScreen from '../orders/DailyFreightScreen'
import PriceCutsScreen from '../priceCuts/PriceCutsScreen'
import ProcessingTimesScreen from '../processingTimes/ProcessingTimesScreen'
import ProcurementAnalytics from '../procurement/ProcurementAnalytics'
import ProcurementCompanies from '../procurement/ProcurementCompanies'
import ProcurementEmailTemplates from '../procurement/ProcurementEmailTemplates'
import ProcurementScores from '../procurement/ProcurementScores'
import { ProcurementProvider } from '../procurement/helpers'
import ProductSetupV2 from '../productSetupV2/ProductSetup'
import PurchaseOrdersListScreen from '../purchaseOrders/PurchaseOrdersListScreen'
import CreatePurchaseOrdersScreen from '../purchaseOrdersV2/CreatePurchaseOrdersScreen'
import EditPurchaseOrdersScreen from '../purchaseOrdersV2/EditPurchaseOrderScreen'
import EditReturn from '../returns/EditReturnScreen'
import ReturnsList from '../returns/ReturnsList'
import GoalsScreen from '../sales/GoalsScreen'
import SalesScreen from '../salesV2/SalesScreen'
import HistoricalData from '../scrape/HistoricalData'
import ScrapeScreen from '../scrape/ScrapeScreen'
import SetupScreen from '../setup/SetupScreen'
import CompetitorCatalogsScreen from '../shopifyCompetitorCatalogs/CompetitorCatalogsScreen'
import UpdateStore from '../storeManager/StoreManager'
import TableSync from '../tableSync/TableSync'
import VendorsListScreenCompanyMapping from '../vendors/VendorsListScreenCompanyMapping'
import NotFound from './NotFound'
import TaxExemptionsScreen from 'taxExemptions/TaxExemptionsScreen'
import InventoryLeadTimes from 'productSetupV2/inventoryLeadTimes/InventoryLeadTimesList'
import TaskDepartmentsScreen from 'tasksV2/departments/TaskDepartmentsScreen'
import RecurringTasksScreen from 'tasksV2/recurringTasks/RecurringTasksScreen'
import ProcessListTableContainer from 'admin/migrations/ProcessListTableContainer'
import TaskScreenV2 from 'tasksV2/tasks/TaskScreenV2'
import ProjectsScreen from 'tasksV2/projects/ProjectsScreen'
import ProjectScreen from 'tasksV2/projects/ProjectScreen'
import RoadmapScreen from 'tasksV2/roadmaps/RoadmapScreen'
import RoadmapsScreen from 'tasksV2/roadmaps/RoadmapsScreen'
import SuppliersScreen from 'purchaseOrdersV2/SuppliersScreen'
import RecurringTaskScreen from 'tasksV2/recurringTasks/RecurringTaskScreen'
import TasksScreenV2 from 'tasksV2/tasks/TasksScreenV2'
import ChangelogListScreen from 'changelog/changelogs/ChangelogListScreen'
import ChangelogScreen from 'changelog/changelogs/ChangelogScreen'
import OrderHubScreenV2 from 'orderHubScreen/OrderHubScreenV2'
import TimesheetListScreen from 'timesheets/TimesheetListScreen'
import DraftOrderHubScreenV2 from 'orderHubScreenDraft/DraftOrderHubScreenV2'
import DraftOrdersListScreen from 'orderHubScreenDraft/DraftOrderListScreen'
import ChromeExtensionRedirect from 'tasksV2/tasks/components/ChromeExtensionRedirect'
import OrdersListScreenV2 from 'orders/OrdersListScreenV2'
import RolesScreen from 'roles/RolesScreen'
import UsersScreenV2 from 'usersV2/UsersScreenV2'
import VendorsListScreen from 'vendors/VendorsListScreen'
import UserLoginsScreen from 'userLogins/UserLoginsScreen'
import TicketsScreen from 'development/tickets/TicketsScreen'
import TicketScreen from 'development/tickets/TicketScreen'
import CreateTicket from 'tasksV2/tasks/components/CreateTicket'

function Forwarder({ newPath }) {
    const params = useParams()
    const foundParams = newPath.match(/:[^\/]*/g) || []
    for (const paramString of foundParams) {
        const paramKey = paramString.slice(1)
        const foundParam = params[paramKey]
        if (foundParam !== 0 && !foundParam) {
            continue
        }
        newPath = newPath.replace(paramString, foundParam)
    }
    return <Navigate to={newPath} />
}

export default function Router() {
    const user = useSelector((state) => state.user)
    const permissionSet = (user?.permission_sets || [])[localStorage.getItem('store_id')]
    const analytics = useAnalytics()

    const ROUTE_MAP = [
        <Route key={'home'} path='home' element={<HomeScreen />} />,
        <Route
            key={'home-redirect'}
            exact
            path={'/'}
            element={user ? <Navigate to={'/home'} /> : <Navigate to={'/login'} />}
        />,
        <Route key={'create'} path='create' element={<SetupScreen />} />,
        <Route key={'login'} path={'/login'} element={<LoginScreen />} />,
        <Route key={'products'} path={'products'}>
            <Route index={true} element={<ProductListScreen />} />
            <Route path={':productId'} element={<ProductSetupV2 />} />
            <Route path='product-groups'>
                <Route index={true} element={<ProductGroups />} />
                <Route path=':groupId' element={<ProductGroupDetail />} />
            </Route>
            <Route path='price-cuts' element={<PriceCutsScreen />} />
            <Route path='processing-times' element={<ProcessingTimesScreen />} />
            <Route path='top-movers' element={<MoversScreen />} />
            <Route path='inventory-emails'>
                <Route index={true} element={<InventoryEmails />} />
                <Route path=':emailId' element={<InventoryEmailDetail />} />
            </Route>
            <Route path='inventory-lead-times'>
                <Route index={true} element={<InventoryLeadTimes />} />
                <Route path=':leadTimeId' element={<InventoryLeadTimes />} />
            </Route>
            <Route path='price-scrape'>
                <Route index={true} element={<ScrapeScreen />} />,
                <Route path='historical-data' element={<HistoricalData />} />
            </Route>
            ,
            <Route key='cost-and-price' path='cost-and-price' element={<CostAndPriceScreen />} />,
        </Route>,
        <Route key='orders' path='orders'>
            <Route index={true} element={<OrdersListScreenV2 />} />
            <Route key='draft-orders' path='draft-orders'>
                <Route index={true} element={<DraftOrdersListScreen />} />
                <Route path={':orderId'} element={<DraftOrderHubScreenV2 />} />
            </Route>
            ,
            <Route path={':orderId'} element={<OrderHubScreenV2 />} />
            <Route path='purchase-orders'>
                <Route index={true} element={<PurchaseOrdersListScreen />} />
                <Route path='new' element={<CreatePurchaseOrdersScreen />} />
                <Route path=':po_id' element={<EditPurchaseOrdersScreen />} />
            </Route>
            <Route path='daily-freight' element={<DailyFreightScreen />} />
            <Route path='suppliers' element={<SuppliersScreen />} />
            <Route path='returns'>
                <Route index={true} element={<ReturnsList />} />
                <Route path='edit' element={<EditReturn />} />
            </Route>
        </Route>,
        <Route key='order_line_item' path='order_line_item'>
            <Route path={':orderLineItemId'} element={<OrderHubScreenV2 />} />
        </Route>,
        <Route key='tasks' path='tasks'>
            <Route index={true} element={<TasksScreenV2 />} />
            <Route path='new' element={<ChromeExtensionRedirect />} />

            <Route path='projects'>
                <Route index={true} element={<ProjectsScreen />} />
                <Route path={':project_id'} element={<ProjectScreen />} />
            </Route>
            <Route path='roadmaps'>
                <Route index={true} element={<RoadmapsScreen />} />
                <Route path={':roadmap_id'} element={<RoadmapScreen />} />
            </Route>
            <Route path=':task_id' element={<TaskScreenV2 />} />
            <Route path='recurring-tasks'>
                <Route index={true} element={<RecurringTasksScreen />} />
                <Route path={':recurring_task_id'} element={<RecurringTaskScreen />} />
            </Route>
            <Route path={'departments'} element={<TaskDepartmentsScreen />} />
        </Route>,
        <Route key='sales' path='sales'>
            <Route index={true} element={<SalesScreen />} />
            <Route path={'goals'} element={<GoalsScreen />} />
        </Route>,
        <Route key={'admin'} path='admin'>
            <Route
                index={true}
                element={
                    <Navigate to={permissionSet?.admin?.refresh?.read ? '/admin/refresh' : '/admin/email-templates'} />
                }
            />
            <Route path='users' element={<UsersScreenV2 />} />
            <Route path='roles' element={<RolesScreen />} />
            <Route path='logins' element={<UserLoginsScreen />} />
            <Route path='refresh' element={<AdminScreen />} />
            <Route path='redirects' element={<RedirectsScreen />} />
            <Route path='email-templates' element={<EmailTemplateScreen />} />
            <Route path='control-tower' element={<ControlTowerScreen />} />
            <Route path='cache-information' element={<CacheScreenV2 />} />
        </Route>,
        <Route key='tax-exemptions' path='tax-exemptions'>
            <Route index={true} element={<Navigate to={'/customers/tax-exemptions'} />} />
            <Route path=':id' element={<Forwarder newPath={'/customers/tax-exemptions/:id'} />} />
        </Route>,
        <Route key='customers' path='customers'>
            <Route index={true} element={<CustomersScreen />} />
            <Route path='tax-exemptions'>
                <Route index={true} element={<TaxExemptionsScreen />} />
                <Route path=':taxExemptionId' element={<TaxExemptionsScreen />} />
            </Route>
        </Route>,
        <Route key='vendors' path='vendors'>
            <Route
                index={true}
                element={
                    <ProcurementProvider>
                        <VendorScreen />
                    </ProcurementProvider>
                }
            />
            <Route path='legacy' element={<VendorsListScreen />} />
        </Route>,
        <Route key='vendors-map' path='vendors-map'>
            <Route index={true} element={<VendorsListScreenCompanyMapping />} />
        </Route>,
        <Route key='account' path='account'>
            <Route index={true} element={<AccountScreen />} />
        </Route>,
        <Route key='edi' path='edi'>
            <Route index={true} element={<EDIScreen />} />
            <Route path='orders' element={<EDIOrdersScreen />} />
        </Route>,
        <Route key='development' path='development'>
            <Route index={true} element={<Navigate to='/development/tickets' />} />
            <Route path='tickets'>
                <Route index={true} element={<TicketsScreen />} />
                <Route path=':ticket_id' element={<TicketScreen />} />
            </Route>
            <Route key='changelogs' path='changelogs'>
                <Route index={true} element={<ChangelogListScreen />} />
                <Route path=':changelog_id'>
                    <Route index={true} element={<ChangelogScreen />} />
                </Route>
            </Route>
            <Route path='migrations' element={<MigrationsScreen />} />
            <Route path='process-list' element={<ProcessListTableContainer />} />
            <Route path='table-sync' element={<TableSync />} />
            <Route path={'component-list'} element={<ComponentList />} />
        </Route>,
        <Route key='procurement' path='procurement'>
            <Route
                index={true}
                element={
                    <ProcurementProvider>
                        <ProcurementCompanies />
                    </ProcurementProvider>
                }
            />
            <Route path='email-templates' element={<ProcurementEmailTemplates />} />
            <Route path='score-settings' element={<ProcurementScores />} />
            <Route path=':report_name' element={<AnalyticsReportContainer />} />
        </Route>,
        <Route key='analytics' path='analytics'>
            <Route index={true} element={<ProcurementAnalytics />} />
            <Route path={':reportName'} element={<AnalyticsReportContainer />} />
            <Route path={'user-reports'} element={<AnalyticsUserReport />} />
            <Route path='sessions' element={<SessionAnalyticsScreen />} />
        </Route>,
        <Route key='store-manager' path='store-manager'>
            <Route index={true} element={<UpdateStore />} />
        </Route>,
        <Route key='competitors' path='competitors'>
            <Route index={true} element={<CompetitorCatalogsScreen />} />
        </Route>,
        <Route key='follow-up-email' path='marketing'>
            <Route index={true} element={<Navigate to={'email'} />} />
            <Route path='email' element={<FollowUpEmailScreen />} />
        </Route>,
        <Route key='xero' path='xero'>
            <Route index={true} element={<XeroScreen />} />
        </Route>,
        <Route key='timesheets' path='timesheets'>
            <Route index={true} element={<TimesheetListScreen />} />
        </Route>,
        <Route key={'notFound'} path='*' element={<NotFound />} />,
    ]
    const location = useLocation()
    const ref = useRef()

    useEffect(() => {
        analytics.page()
    }, [location])

    const Sorry = () => {
        const [colors, setColors] = useState({
            s: { r: 100, g: 10, b: 10, dir: 'up' },
            o: { r: 100, g: 30, b: 30, dir: 'up' },
            r: { r: 100, g: 50, b: 50, dir: 'up' },
            r: { r: 100, g: 70, b: 70, dir: 'up' },
            y: { r: 100, g: 90, b: 90, dir: 'up' },
        })
        useEffect(() => {
            const colorChanger = setInterval(() => {
                setColors((previousState) => {
                    const newState = { ...previousState }
                    Object.entries(newState).forEach(([key, val]) => {
                        if (val.g > 100 && val.dir === 'up') {
                            val.dir = 'down'
                        } else if (val.g < 10 && val.dir === 'down') {
                            val.dir = 'up'
                        }
                        val.g = val.dir === 'up' ? val.g + 10 : val.g - 10
                    })
                    return newState
                })
            }, 100)

            return () => clearInterval(colorChanger)
        }, [])

        return (
            <p>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    S
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    O
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    R
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    R
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    Y
                </span>
            </p>
        )
    }
    const Fallback = () => {
        const [quickModal, setQuickModal] = useState()
        return (
            <div className='w-full h-[calc(100vh-250px)] grid place-items-center'>
                <div className='text-center p-[16px] rounded-[4px] w-fit mx-auto'>
                    <Sorry />
                    <h1 className='dark:text-white font-bold text-[32px] mb-4'>Something went wrong!</h1>
                    <button className='font-semibold underline' onClick={() => setQuickModal('ticket')}>
                        Report this bug?
                    </button>
                </div>
                {quickModal === 'ticket' && <CreateTicket setQuickModal={setQuickModal} />}
            </div>
        )
    }
    return (
        <>
            {user ? (
                <ErrorBoundary key={location.pathname} fallback={<Fallback />}>
                    <Routes>{ROUTE_MAP.map((item) => item)}</Routes>
                </ErrorBoundary>
            ) : (
                <ErrorBoundary key={location.pathname} fallback={<Fallback />}>
                    <Routes>
                        <Route path={'/resetPassword/:token'} element={<ResetPasswordScreen />} />
                        <Route path={'/login'} element={<LoginScreen />} />
                        <Route path={'/create'} element={<SetupScreen />} />
                    </Routes>
                </ErrorBoundary>
            )}
        </>
    )
}
