import { CheckCircledIcon } from '@radix-ui/react-icons'
import {
    addDays,
    endOfDay,
    format,
    startOfDay,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
} from 'date-fns'
import * as React from 'react'
import { DateRange } from 'react-day-picker'
import { CalendarBaseSelector } from '../../custom_components/component_Basics/CalendarBaseSelector'
import { RangeSelectorPopover } from './RangeSelectorPopover'

export function DatePickerPresets({
    date,
    setDate,
    period,
    setPeriod,
    setOptionIndex,
}: {
    date: DateRange | undefined
    setDate: React.Dispatch<React.SetStateAction<any>>
    period: { value: string | undefined; function: Function | undefined; rangeString: string | undefined }
    setPeriod: React.Dispatch<React.SetStateAction<any>>
    setOptionIndex: React.Dispatch<React.SetStateAction<any>>
}) {
    const [rangeString, setRangeString] = React.useState<string | undefined>(period.rangeString)

    function handleRangeToPresent(target: any, dateFunction: Function, rangeString: string, option: number) {
        setOptionIndex(option)
        setRangeString(rangeString)
        setPeriod({
            value: target.value,
            function: dateFunction,
            rangeString,
        })
        const [fromValue, toValue] = target.value.split(',')
        setDate({
            from: new Date(dateFunction(new Date(), parseInt(fromValue)).toDateString()),
            to: new Date(subDays(new Date(), parseInt(toValue)).toDateString()),
        })
    }
    function handleRangePrevious(target: any, dateFunction: Function, rangeString: string, option: number) {
        setOptionIndex(option)
        setRangeString(rangeString)
        const [fromValue, toValue] = target.value.split(',')
        let from = startOfDay(new Date())
        let to = endOfDay(new Date())
        from.setDate(1)
        to.setDate(1)
        from.setMonth(from.getMonth() - fromValue)
        to.setDate(0)

        setDate({
            from,
            to: new Date(to.toDateString()),
        })
        setPeriod({
            value: target.value,
            function: dateFunction,
            rangeString,
        })
    }
    function handleRangeToDate(
        target: any,
        dateFunction: Function,
        compareDateFunction: Function,
        rangeString: string,
        option: number
    ) {
        setOptionIndex(option)
        setRangeString(rangeString)
        const [fromValue, toValue] = target.value.split(',')
        dateFunction(new Date())
        setDate({
            from: dateFunction(new Date(), { weekStartsOn: 1 }),
            to: addDays(new Date(), parseInt(toValue)),
        })
        setPeriod({
            value: target.value,
            function: compareDateFunction,
            rangeString,
        })
    }
    function CalendarProp() {
        return (
            <CalendarBaseSelector
                initialFocus
                mode='range'
                defaultMonth={date?.from}
                setRangeString={setRangeString}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
                showOutsideDays={false}
            />
        )
    }
    function ButtonContent() {
        return (
            <>
                {rangeString ? (
                    <span>{rangeString}</span>
                ) : date?.from ? (
                    date.to ? (
                        <>
                            {format(date.from, 'LLL dd')} - {format(date.to, 'LLL dd, y')}
                        </>
                    ) : (
                        format(date.from, 'LLL dd, y')
                    )
                ) : (
                    <span>Pick a date</span>
                )}
            </>
        )
    }
    return (
        <>
            <RangeSelectorPopover CalendarProp={CalendarProp} ButtonContent={ButtonContent}>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Today', 0)}
                    value={['0', '0']}
                >
                    Today
                    <CheckCircledIcon className='ml-auto self-center' />
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Yesterday', 1)}
                    value={['1', '1']}
                >
                    Yesterday
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Last 7 days', 2)}
                    value={['7', '1']}
                >
                    Last 7 days
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Last 30 days', 3)}
                    value={['30', '1']}
                >
                    Last 30 days
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Last 90 days', 4)}
                    value={['90', '1']}
                >
                    Last 90 days
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToPresent(target, subDays, 'Last 365 days', 5)}
                    value={['365', '1']}
                >
                    Last 365 days
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangePrevious(target, subMonths, 'Last month', 6)}
                    value={['1', '0']}
                >
                    Last month
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangePrevious(target, subMonths, 'Last 3 months', 7)}
                    value={['3', '0']}
                >
                    Last 3 months
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangePrevious(target, subMonths, 'Last 6 months', 1)}
                    value={['6', '0']}
                >
                    Last 6 months
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToDate(target, startOfWeek, subWeeks, 'Week to date', 8)}
                    value={['1', '0']}
                >
                    Week to date
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) => handleRangeToDate(target, startOfMonth, subMonths, 'Month to date', 8)}
                    value={['1', '0']}
                >
                    Month to date
                </button>
                <button
                    className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                    onClick={({ target }) =>
                        handleRangeToDate(target, startOfQuarter, subQuarters, 'Quarter to date', 9)
                    }
                    value={['1', '0']}
                >
                    Quarter to date
                </button>
            </RangeSelectorPopover>
        </>
    )
}
