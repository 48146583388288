import { createColumnHelper } from '@tanstack/react-table'
import { formatDateSimple } from 'procurement/helpers'
import React from 'react'
import { PRIORITY_MAP, TaskV2 } from 'tasksV2/constants/tasks'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import { useSelector } from 'react-redux'
import TaskStatus from 'tasksV2/tasks/components/TaskStatus'
import Association from 'tasksV2/tasks/components/Association'
import Assignment from 'tasksV2/tasks/components/Assignment'
import { useTickets } from 'development/api/useQueries'
import TicketsTable from './TicketsTable'

export default function TicketsListGrouped({ filters }: { filters: any }) {
    const user = useSelector<any, any>((state) => state.user)
    const ticketsQuery = useTickets(filters)
    const tasks: TaskV2[] = ticketsQuery?.data?.tickets || []

    const filteredTasks =
        user.type === 'Admin' || user.type === 'SuperAdmin'
            ? tasks
            : tasks?.filter((task) => task.status !== 'Archived')

    const tableData = filteredTasks?.length > 0 ? filteredTasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskV2>()

    const taskColumns = [
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: '',
            cell: (info) => {
                return <div className='flex font-semibold'>{info.getValue()}</div>
            },
            maxSize: 60,
        }),
        columnHelper.accessor('priority', {
            header: '',
            cell: (info) => {
                const priorityMap = PRIORITY_MAP
                const { icon: Priority, className: priorityClassName } = info.getValue()
                    ? priorityMap[info.getValue() as keyof typeof priorityMap]
                    : { icon: undefined, className: '' }
                return (
                    <div className='flex gap-2 items-center'>
                        {Priority && <Priority className={`text-[20px] ${priorityClassName}`} />}
                    </div>
                )
            },
            size: 30,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            maxSize: 120,
            cell: (info) => {
                const due_at = info.cell.row.original.due_at
                return (
                    <div>
                        <TaskStatus
                            taskId={info.row.original.id}
                            dueAt={due_at}
                            status={info.getValue()}
                            editable={false}
                        />
                    </div>
                )
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group'>
                        <div>{truncate(info.getValue(), 55)}</div>
                        {info.getValue().length > 55 && (
                            <div className='z-10 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 440,
        }),
        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {info
                            .getValue()
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <React.Fragment
                                    key={`${info.row.original.id}+${association.resource_id}` + '-association'}
                                >
                                    <Association id={info.row.original.id} association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            minSize: 320,
        }),
        {
            header: 'Assigned',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {row.original.associations
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <React.Fragment key={`${row.original.id}+${association.resource_id}` + '-assignment'}>
                                    <Assignment association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            size: 90,
        },
        columnHelper.accessor('due_at', {
            maxSize: 130,
            header: 'Due',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
        columnHelper.accessor('created_at', {
            maxSize: 130,
            header: 'Created',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
    ]

    const columnCount = taskColumns?.length.toString() || 10
    return (
        <>
            <div className='h-full min-h-0'>
                <>
                    <div className='w-full h-full min-h-0 rounded-md'>
                        {tableDataMemo && (
                            <div className='relative w-full h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                                <TicketsTable
                                    columns={taskColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                    showHeaders={true}
                                    size={'auto'}
                                />
                                {!ticketsQuery.isLoading && tableData?.length < 1 && (
                                    <div className='pointer-events-none absolute top-0 left-0 flex justify-center items-center w-full h-full z-[60] text-black dark:text-offwhite'>
                                        <div className='p-2 px-4 rounded-md border mt-3 '>No tasks</div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </>
            </div>
        </>
    )
}
