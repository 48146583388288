import { ChangeEvent, ReactNode } from 'react'
import { cn } from '../../helpers'

// V2 for uncontrolled inputs with react hook form

export default function SelectV2({
    required,
    field,
    register,
    errors,
    className,
    id,
    name,
    onChange,
    children,
    label,
    disabled = false,
}: {
    required?: boolean
    field: string
    register: any
    errors: any
    className?: string
    id: string
    name: string
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void
    children?: ReactNode
    label?: string
    disabled?: boolean
}) {
    return (
        <div className='flex flex-col gap-[4px] w-full'>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'
                >
                    {label}
                </label>
            )}
            <select
                {...register(field, { required: required })}
                disabled={disabled}
                className={cn(
                    `w-full text-[16px] leading-none bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`,
                    className
                )}
                id={id}
                name={name}
                onChange={onChange}
            >
                {children}
            </select>
            {errors && errors[field] && errors[field].type === 'required' && (
                <span className='text-danger dark:text-lightred'>This is required</span>
            )}
        </div>
    )
}
