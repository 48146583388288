import React, { useEffect, useRef, useState } from 'react'
import Editor from './QuillEditor'
import 'quill/dist/quill.core.css'
import { modules } from 'knowledge/constants'

const QuillEditorApp = ({ value, onChange, setEdited }) => {
    const [range, setRange] = useState()
    // Use a ref to access the quill instance directly
    const quillRef = useRef()
    const delta = quillRef.current?.clipboard.convert({ html: value })

    // prevents page from displaying "unsaved changes" modal when there are none
    useEffect(() => {
        if (value && delta) {
            quillRef.current.setContents(delta)
            setTimeout(() => {
                setEdited(false)
            }, 10)
        }
    }, [quillRef.current])

    function onTextChange() {
        const HTML = quillRef.current.root.innerHTML
        onChange(HTML)
    }

    return (
        <div>
            <Editor
                ref={quillRef}
                readOnly={false}
                onSelectionChange={setRange}
                onTextChange={onTextChange}
                modules={modules}
                setEdited={setEdited}
            />
        </div>
    )
}

export default QuillEditorApp
