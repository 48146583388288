import { ShopifyProduct } from '../ProductSetup.types'
import { DEFAULT_PLUGS } from '../constants/plugs'

export default function Preview({ product }: { product: ShopifyProduct }) {
    const midpoint =
        product && product.specifications
            ? (product.specifications.length + (product.package_contents || []).length) / 2 + 2
            : 0

    const plugs = (product.plugs || [])
        .map((plug) => {
            const [quantity, type] = plug
                .replaceAll('(', '')
                .split(')')
                .map((p) => p.trim())
            return {
                ...DEFAULT_PLUGS.find((defaultPlug) => defaultPlug.type === type),
                quantity: parseInt(quantity),
            }
        })
        .filter((v) => v)

    const parsedManuals = (product.manuals || []).map((manual) => {
        const name = manual.substring(0, manual.indexOf(':'))
        const href = manual.substring(manual.indexOf(':') + 1)
        return {
            name,
            href,
        }
    })
    return (
        <div
            id='product-content'
            className='js-product-wrapper shadow-small !rounded-[4px] overflow-hidden dark:opacity-[0.95] dark:text-black'
        >
            {product.body_html && product.body_html.length > 0 && (
                <section className='pdp__description'>
                    <div id='overview-section' className='pdp__hidden-anchor'></div>
                    <div className='pdp__description__wrapper'>
                        <h2 className='pdp__description__heading'>Product Overview</h2>
                        <div className='pdp__description__accordion js-accordion'>
                            <div className='pdp__description__content-row'>
                                <div
                                    className='pdp__description__left'
                                    dangerouslySetInnerHTML={{ __html: product.body_html }}
                                ></div>
                                <div className='pdp__description__right'>
                                    <div className='pdp__description__authorized-row'>
                                        <div className='pdp__description__authorized-row__image-container'>
                                            <img
                                                className='pdp__description__authorized-row__image-container__img'
                                                src='https://cdn.vectorstock.com/i/1000x1000/06/00/vendor-rubber-stamp-vector-12410600.webp'
                                                alt='vendor logo'
                                                width='auto'
                                                height='auto'
                                                loading='lazy'
                                            />
                                        </div>
                                        <img
                                            className='pdp__description__authorized-row__divider'
                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/warranty-line.png?v=1605396259'
                                            alt='divider'
                                            width='2'
                                            height='auto'
                                            loading='lazy'
                                        />
                                        <div className='pdp__description__authorized-row__image-container'>
                                            <img
                                                className='pdp__description__authorized-row__image-container__img'
                                                src='https://cdn.shopify.com/s/files/1/1163/1976/files/authorized-dealer-stacked.png?v=1605396129'
                                                alt='authorized dealer'
                                                width='auto'
                                                height='auto'
                                                loading='lazy'
                                            />
                                        </div>
                                    </div>
                                    <div className='pdp__description__checkmarks'>
                                        {product.checkmarks &&
                                            product.checkmarks.map((c) => (
                                                <div className='pdp__description__checkmarks__row'>
                                                    <div className='pdp__description__checkmarks__row__image-container'>
                                                        <img
                                                            className='pdp__description__checkmarks__row__image-container__img'
                                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/checkmark.svg?v=1604691291'
                                                            alt='checkmark'
                                                            width='auto'
                                                            height='auto'
                                                            loading='lazy'
                                                        />
                                                    </div>
                                                    <p className='pdp__description__checkmarks__row__text'>{c}</p>
                                                </div>
                                            ))}
                                    </div>
                                    {plugs.length > 0 && (
                                        <div className='pdp__description__plugs pdp__description__plugs--desktop'>
                                            <h2 className='pdp__description__plugs__heading'>Plug Types</h2>
                                            {plugs.map((plug) => (
                                                <div className='pdp__description__plugs__row'>
                                                    <div className='pdp__description__plugs__row__container'>
                                                        <div className='pdp__description__plugs__row__container__image-container'>
                                                            <img
                                                                className='pdp__description__plugs__row__container__image-container__img'
                                                                src={plug.image_src}
                                                                alt='checkmark'
                                                                width='auto'
                                                                height='auto'
                                                                loading='lazy'
                                                            />
                                                        </div>
                                                        <p className='pdp__description__plugs__row__container__text'>
                                                            &times;{plug.quantity}
                                                        </p>
                                                    </div>
                                                    <div className='pdp__description__plugs__row__details'>
                                                        <p className='pdp__description__plugs__row__details__name'>
                                                            {plug.name}
                                                        </p>
                                                        <p className='pdp__description__plugs__row__details__type'>
                                                            ({plug.type})
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {product.features && product.features.length > 0 && (
                <section className='pdp__features'>
                    <div id='features-section' className='pdp__hidden-anchor'></div>
                    <div className='pdp__features__wrapper'>
                        <h2 className='pdp__features__heading'>Features</h2>
                        <div className='pdp__features__accordion js-accordion'>
                            <div className='pdp__features__row'>
                                <div className='pdp__features__left'>
                                    <ul className='pdp__features__left__list'>
                                        {product.features.slice(0, Math.ceil(product.features.length / 2)).map((f) => (
                                            <li dangerouslySetInnerHTML={{ __html: f }}></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='pdp__features__right'>
                                    <ul className='pdp__features__right__list'>
                                        {product.features.slice(Math.ceil(product.features.length / 2)).map((f) => (
                                            <li dangerouslySetInnerHTML={{ __html: f }}></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {((product.specifications && product.specifications.length > 0) ||
                (product.package_contents && product.package_contents.length > 0)) && (
                <section className='pdd__specs'>
                    <div id='specifications-section' className='pdp__hidden-anchor'></div>
                    <div className='pdp__specs__wrapper'>
                        <h2 className='pdp__specs__heading'>Specifications</h2>
                        <div className='pdp__specs__accordion js-accordion'>
                            <div className='pdp__specs__row'>
                                {product.specifications && product.specifications.length > 0 && (
                                    <div className='pdp__specs__row__left'>
                                        <table className='pdp__specs__row__left__table'>
                                            <tbody className='pdp__specs__row__left__table__tbody'>
                                                {product.specifications.map((s, index) => {
                                                    const cells = s.includes('::') ? s.split('::') : s.split(':')
                                                    let heading = false
                                                    if (cells.length === 1) {
                                                        cells.push('')
                                                        heading = true
                                                    }
                                                    if (index < midpoint) {
                                                        return (
                                                            <tr>
                                                                <td
                                                                    className={
                                                                        heading
                                                                            ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                            : 'pdp__specs__row--bold'
                                                                    }
                                                                >
                                                                    {cells[0]}
                                                                </td>
                                                                <td>{cells[1]}</td>
                                                            </tr>
                                                        )
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {((product.specifications && product.specifications.length > 0) ||
                                    (product.package_contents && product.package_contents.length > 0)) && (
                                    <div className='pdp__specs__row__right'>
                                        {product.specifications && product.specifications.length >= midpoint && (
                                            <table className='pdp__specs__row__right__table'>
                                                <tbody className='pdp__specs__row__right__table__tbody'>
                                                    {product.specifications.map((s, index) => {
                                                        const cells = s.includes('::') ? s.split('::') : s.split(':')
                                                        let heading = false
                                                        if (cells.length === 1) {
                                                            cells.push('')
                                                            heading = true
                                                        }
                                                        if (index >= midpoint) {
                                                            return (
                                                                <tr>
                                                                    <td
                                                                        className={
                                                                            heading
                                                                                ? 'pdp__specs__row--bold pdp__specs__row--green'
                                                                                : 'pdp__specs__row--bold'
                                                                        }
                                                                    >
                                                                        {cells[0]}
                                                                    </td>
                                                                    <td>{cells[1]}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        )}
                                        <div className='pdp__specs__row__right__package'>
                                            <h3 className='pdp__specs__row__right__package__heading'>
                                                Package Contents
                                            </h3>
                                            <div className='pdp__specs__row__right__package__container'>
                                                {product.package_contents &&
                                                    product.package_contents.map((p) => (
                                                        <p className='pdp__specs__row__right__package__text'>{p}</p>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {product.warranty && (
                <section className='pdp__warranty'>
                    <div id='warranty-section' className='pdp__hidden-anchor'></div>
                    <div className='pdp__warranty__wrapper'>
                        <h2 className='pdp__warranty__heading'>Warranty</h2>
                        <div className='pdp__warranty__accordion js-accordion'>
                            <div className='pdp__warranty__authorized'>
                                <div className='pdp__warranty__authorized__row'>
                                    <div className='pdp__warranty__authorized__row__image-container'>
                                        <img
                                            className='pdp__warranty__authorized__row__image-container__img'
                                            src='https://cdn.vectorstock.com/i/1000x1000/06/00/vendor-rubber-stamp-vector-12410600.webp'
                                            alt='vendor logo'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                    <img
                                        className='pdp__warranty__authorized__row__divider'
                                        src='https://cdn.shopify.com/s/files/1/1163/1976/files/warranty-line.png?v=1605396259'
                                        alt='divider'
                                        width='2'
                                        height='auto'
                                        loading='lazy'
                                    />
                                    <div className='pdp__warranty__authorized__row__image-container'>
                                        <img
                                            className='pdp__warranty__authorized__row__image-container__img'
                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/authorized-dealer-stacked.png?v=1605396129'
                                            alt='authorized dealer'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                </div>
                                <p id='warranty'>{product.warranty}</p>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {product.manuals && product.manuals.length > 0 && (
                <section className='pdp__manuals'>
                    <div id='manuals-section' className='pdp__hidden-anchor'></div>
                    <div className='pdp__manuals__wrapper'>
                        <h2 className='pdp__manuals__heading'>Manuals &amp; Documentation</h2>
                        <div className='pdp__manuals__accordion js-accordion'>
                            <div className='pdp__manuals__grid'>
                                {parsedManuals.map((m) => (
                                    <a
                                        className='pdp__manuals__grid__manual'
                                        href={m.href}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <div className='pdp__manuals__grid__manual__image-container'>
                                            <img
                                                className='pdp__manuals__grid__manual__image-container__img'
                                                src='https://cdn.shopify.com/s/files/1/1163/1976/files/pdf-icon.png?v=1611684687'
                                                alt='manual icon'
                                                width='auto'
                                                height='auto'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p className='pdp__manuals__grid__manual__text'>{m.name}</p>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
            )}
        </div>
    )
}
