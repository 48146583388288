import { cn } from 'helpers'
import { FaTimes } from 'react-icons/fa'
// displays attachment list and handles attachment removal
export default function CurrentAttachments({
    attachments,
    setAttachments,
    loadingWidth,
    loadingWidthWrapper,
}: {
    attachments: any
    setAttachments: any
    loadingWidth: string
    loadingWidthWrapper: string
}) {
    const handleFileRemoval = (selectedFileIndex: any) => {
        setAttachments(attachments.filter((a: any, index: number) => index !== selectedFileIndex))
    }
    function sizeConverter(fileSize: any) {
        var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
            i = 0
        while (fileSize > 900) {
            fileSize /= 1024
            i++
        }
        var exactSize = Math.round(fileSize * 100) / 100 + ' ' + fSExt[i]
        return exactSize
    }
    function attachmentsSizeTotal() {
        let sizes = []
        let sum = 0
        sizes = attachments.map((a: any) => a.size)
        for (const value of sizes) {
            sum += value
        }
        return sizeConverter(sum)
    }

    function download({ file }: { file: any }) {
        const link = document.createElement('a')
        const url = URL.createObjectURL(file)

        link.href = url
        link.download = file.name
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    return (
        <div>
            {attachments.length > 0 &&
                attachments.map((attachment: any, index: number) => {
                    return (
                        <div
                            className='flex border-lightgrey dark:border-darkgrey bg-lightgrey dark:bg-darkness
                 dark:text-offwhite rounded gap-4 p-2 mb-2 max-w-[560px] justify-between'
                            key={'emailAttachment' + index}
                        >
                            <div className='text-ellipsis overflow-hidden whitespace-nowrap cursor-default'>
                                {attachment?.name}
                            </div>
                            <div className='flex gap-2'>
                                <div className={cn(index == attachment.length - 1 && `${loadingWidthWrapper} my-auto`)}>
                                    <div
                                        className={cn(
                                            index == attachment.length - 1 &&
                                                `${loadingWidth} bg-[#015275] dark:bg-fire text-white dark:text-darkaccent1 h-[14px]`
                                        )}
                                    ></div>
                                </div>
                                <button
                                    className='font-bold text-blue dark:text-fire whitespace-nowrap my-auto'
                                    onClick={() =>
                                        download({
                                            file: attachment,
                                        })
                                    }
                                >
                                    ({sizeConverter(attachment.size)})
                                </button>
                                <button onClick={() => handleFileRemoval(index)} aria-label='remove attachment'>
                                    <FaTimes size={'20px'} />
                                </button>
                            </div>
                        </div>
                    )
                })}
            {attachments.length > 1 && (
                <div className='flex dark:text-offwhite rounded gap-4 p-2 mb-2 max-w-[560px] justify-end'>
                    <div className='font-semibold'>Total: {attachmentsSizeTotal()}</div>
                </div>
            )}
        </div>
    )
}
