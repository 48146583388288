import { useEffect, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { cn } from '../../../helpers'
import { useDeleteNote, useUpdateNote } from '../../api/useQueries'
import { formatDate } from '../../helpers'
import Textarea from '../Textarea'
import { EventType } from 'procurement/Procurements.types'

export default function ParentNote({ note }: { note: EventType }) {
    const noteRef = useRef<HTMLDivElement>(null)
    const [editableNote, setEditableNote] = useState<string | undefined>(undefined)
    const [edit, setEdit] = useState(false)

    const isEdited = Boolean(editableNote !== undefined && editableNote !== note.value)

    const deleteNote = useDeleteNote()
    const updateNote = useUpdateNote()

    const handleDelete = async (id: number | string) => {
        deleteNote.mutate({ note })
    }

    const handleUpdate = async () => {
        updateNote.mutate(
            { note: { ...note, value: editableNote, companyName: undefined } },
            {
                onSuccess: () => {
                    setEditableNote(undefined)
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!noteRef.current?.contains(e.target as Element)) {
                setTimeout(() => {
                    setEdit(false)
                }, 100)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            className={cn(
                (deleteNote.isPending || updateNote.isPending) && 'opacity-50',
                'w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'
            )}
        >
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                Note - {(note as any).company_name}
            </p>
            <div className='flex justify-between mb-[8px]'>
                <p className='font-bold text-[14px]'>{note.message}</p>
                <p className='font-bold text-[12px]'>{formatDate(new Date(note.created_at))}</p>
            </div>
            <div className='flex gap-[8px] items-center'>
                <div
                    ref={noteRef}
                    onClick={() => setEdit(true)}
                    className={`w-full relative
                        ${
                            edit
                                ? ''
                                : 'overflow-hidden max-h-[70px] after:w-full after:h-[32px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#05111C,transparent)] after:absolute after:bottom-0 after:left-0'
                        }`}
                >
                    <Textarea
                        id={`note-${note.id}`}
                        name={`note-${note.id}`}
                        value={editableNote ?? note.value}
                        onChange={({ target }) => setEditableNote(target.value || '')}
                    />
                </div>
                {isEdited ? (
                    <button
                        onClick={handleUpdate}
                        className='flex shrink-0 items-center justify-center font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent py-[2px] px-[4px] rounded-[4px] self-center uppercase'
                    >
                        Save Note
                    </button>
                ) : (
                    <button onClick={() => handleDelete(note.id!)} className='flex items-center justify-center'>
                        <FaTrash className='text-red dark:text-lightred' />
                    </button>
                )}
            </div>
        </div>
    )
}
