import { Button } from 'custom_components/component_Basics/Button'
import { cn, sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useGetRoles } from 'roles/api/useQueries'
import { useGetStores } from 'storeManager/api/useQueries'
import { useSetupUser } from 'usersV2/api/useQueries'

export default function CreateUserModal({ closeModal }: { closeModal: () => void }) {
    const rolesQuery = useGetRoles({})
    const roles: any[] = rolesQuery.data?.roles || []
    const storesQuery = useGetStores({})
    const stores: any[] = storesQuery.data?.allStoresInfo || []
    const setupMutation = useSetupUser()
    const [user, setUser] = useState({
        email: '',
        type: 'Client',
        role_ids: [] as number[],
        store_ids: [] as number[],
    })

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setUser({
            ...user,
            [target.name]: target.value,
        })
    }

    const handleToggleRole = (roleId: number) => {
        setUser((prev) => {
            const newState = structuredClone(prev)
            if (newState.role_ids.includes(roleId)) {
                newState.role_ids = newState.role_ids.filter((id) => id !== roleId)
                return newState
            }
            newState.role_ids.push(roleId)
            return newState
        })
    }

    const handleToggleStore = (storeId: number) => {
        setUser((prev) => {
            const newState = structuredClone(prev)
            if (newState.store_ids.includes(storeId)) {
                newState.store_ids = newState.store_ids.filter((id) => id !== storeId)
                return newState
            }
            newState.store_ids.push(storeId)
            return newState
        })
    }

    const handleSubmit = () => {
        if (setupMutation.isPending) {
            return
        }
        if (!user.email) {
            return sendToast({ message: 'Please include email' })
        }

        setupMutation.mutate(user, {
            onSuccess: () => {
                closeModal()
            },
        })
    }

    return (
        <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'>
            <div className='p-6 rounded shadow-md bg-bg1 dark:bg-darkbg1 relative w-full max-w-[400px]'>
                <button
                    onClick={() => closeModal()}
                    className='absolute top-1 right-1 text-red dark:text-lightred text-lg font-bold'
                >
                    <FaTimes />
                </button>
                <h2 className='font-bold mb-2'>Create New User</h2>
                <div className='flex flex-col gap-2'>
                    <Input
                        id='email'
                        name='email'
                        type='email'
                        label='Email'
                        value={user.email}
                        onChange={handleChange}
                    />
                    <Select id='type' name='type' label='Type' value={user.type} onChange={handleChange}>
                        <option value='Client'>Client</option>
                        <option value='Admin'>Admin</option>
                        <option value='SuperAdmin'>SuperAdmin</option>
                    </Select>
                    <div className='flex flex-col gap-1'>
                        <label className='text-xs text-darkgrey dark:text-offwhite font-bold leading-none uppercase'>
                            Roles
                        </label>
                        <div className='max-h-[200px] overflow-auto'>
                            {roles.map((role) => (
                                <button
                                    onClick={() => handleToggleRole(role.id)}
                                    className='w-full p-1 border-b border-lightgrey dark:border-darkgrey flex gap-2 items-center'
                                >
                                    <div
                                        className={cn(
                                            'w-4 h-4 rounded border border-lightgrey dark:border-darkgrey',
                                            user.role_ids.includes(role.id) && 'bg-accent1 dark:bg-darkaccent1'
                                        )}
                                    ></div>
                                    <span>{role.title}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <label className='text-xs text-darkgrey dark:text-offwhite font-bold leading-none uppercase'>
                            Stores
                        </label>
                        <div className='max-h-[200px] overflow-auto'>
                            {stores.map((store) => (
                                <button
                                    onClick={() => handleToggleStore(store.id)}
                                    className='w-full p-1 border-b border-lightgrey dark:border-darkgrey flex gap-2 items-center'
                                >
                                    <div
                                        className={cn(
                                            'w-4 h-4 rounded border border-lightgrey dark:border-darkgrey',
                                            user.store_ids.includes(store.id) && 'bg-accent1 dark:bg-darkaccent1'
                                        )}
                                    ></div>
                                    <span>{store.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                    <Button onClick={handleSubmit} className='w-fit mx-auto' size='sm' variant='outline'>
                        Send Setup Email
                    </Button>
                </div>
            </div>
        </div>
    )
}
