import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './AdminScreen.css'
import { vFetch } from '../helpers'
import Input from '../procurement/components/Input'
import { Button } from 'custom_components/component_Basics/Button'
import DeleteTokenModal from './DeleteTokenModal'

export default function AdminScreen() {
    const user = useSelector((state) => state.user)
    const [emailSyncInfo, setEmailSyncInfo] = useState({
        email: '',
        nextPageToken: '',
    })
    const [cacheResetPage, setCacheResetPage] = useState('')
    const [_cacheResetDate, setCacheResetDate] = useState()
    const [showDeleteToken, setShowDeleteToken] = useState(false)

    async function handleVersionRefresh() {
        await vFetch('/v1/appVersions', { method: 'PUT', body: JSON.stringify({ version: 0 }) })
    }

    const viewRefresh = () => {
        vFetch('/v1/stores/viewRefresh', {})
    }

    const handleChange = ({ target }) => {
        setEmailSyncInfo({ ...emailSyncInfo, [target.name]: target.value })
    }
    const restartEmailSync = () => {
        vFetch(`/v2/google/initialFullSync?email=${emailSyncInfo.email}&nextPageToken=${emailSyncInfo.nextPageToken}`)
    }

    const purgeCache = () => {
        vFetch('/v1/nostra', {
            method: 'POST',
            body: JSON.stringify({
                path: cacheResetPage || undefined,
            }),
        }).then(() => {
            setCacheResetDate(undefined)
            setCacheResetPage('')
        })
    }

    return (
        <div className='admin text-black dark:text-offwhite'>
            <h1 className='text-[24px] tracking-[2px] mb-6 font-[300] dark:text-offwhite uppercase'>Refresh</h1>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex flex-col gap-4'>
                    <p style={{ fontSize: '20px' }}>Views</p>
                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={viewRefresh}
                    >
                        REFRESH VIEWS
                    </button>
                </div>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-[20px]'>Nostra Cache</h2>
                    <div className='p-[8px] bg-white dark:bg-darkaccent border border-darkgrey max-w-[400px]'>
                        <Input
                            label='Path to refresh (leave blank for all paths)'
                            id='cache-path'
                            name='cache-path'
                            value={cacheResetPage}
                            onChange={({ target }) => setCacheResetPage(target.value)}
                        />
                    </div>
                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={purgeCache}
                    >
                        PURGE CACHE
                    </button>
                </div>
                <div className='flex flex-col gap-4'>
                    <div class='flex gap-4 justify-between items-center w-fit'>
                        <p style={{ fontSize: '20px' }}>Email Sync</p>
                        <Button onClick={() => setShowDeleteToken(true)} variant={'outline'} size={'sm'}>
                            DELETE TOKEN
                        </Button>
                    </div>
                    <div className='flex flex-col gap-2 max-w-[300px] dark:bg-darkbg2'>
                        <input
                            onChange={handleChange}
                            name='email'
                            className=' bg-grey dark:bg-darkbg1 p-1'
                            value={emailSyncInfo.email}
                            type='text'
                            placeholder='email'
                        />
                        <input
                            onChange={handleChange}
                            name='nextPageToken'
                            className=' bg-grey dark:bg-darkbg1 p-1'
                            value={emailSyncInfo.nextPageToken}
                            type='text'
                            placeholder='nextPageToken (optional)'
                        />
                    </div>

                    <button
                        className=' mt-auto w-fit block py-[4px] px-[16px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkness uppercase font-bold'
                        onClick={restartEmailSync}
                    >
                        BEGIN SYNC
                    </button>
                </div>
                <div>
                    <p style={{ fontSize: '20px' }}>App refreshing continuously?</p>
                    <button
                        style={{ marginBottom: '16px' }}
                        className='admin__reset-products'
                        onClick={handleVersionRefresh}
                    >
                        REFRESH APP VERSION
                    </button>
                </div>
            </div>
            {showDeleteToken && <DeleteTokenModal setShowDeleteToken={setShowDeleteToken}></DeleteTokenModal>}
        </div>
    )
}
