import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import FilterToolBar from 'procurement/components/FilterToolBar'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { Link, useSearchParams } from 'react-router-dom'
import { useGetRoadmaps } from 'tasksV2/api/useQueries'
import { RoadMap, TASK_STATUSES } from 'tasksV2/constants/tasks'
import Assignment from 'tasksV2/tasks/components/Assignment'
import Association from 'tasksV2/tasks/components/Association'
import TasksV2TableSimple from 'tasksV2/tasks/lists/TasksV2TableSimple'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'

export default function RoadMapListV2({}: {}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''
    const [debouncedSearch, setDebouncedSearch] = useState('')

    const [initRender, setInitRender] = useState(true)

    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const dateFormat = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }).format

    const roadmapsQuery = useGetRoadmaps({
        statuses: statusValues.length ? statusValues : undefined,
        userIds: assignedToValues.length ? assignedToValues : undefined,
        search: debouncedSearch,
    })
    const roadmaps = roadmapsQuery.data?.roadmaps || []

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: userOptions,
                title: 'Assigned To',
                field: 'assigned_to',
                values: assignedToValues,
                searchToggle: true,
                editSearchParams: true,
            },

            {
                component: FilterBarSelectorButton,
                options: statusOptions,
                title: 'Status',
                field: 'statuses',
                values: statusValues,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('assigned_to')
                prev.delete('statuses')
                prev.set('page', '1')
                return prev
            }),
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setDebouncedSearch(searchParam)
        }
    }, [searchParam])

    function handlePageIncrease() {
        if (roadmaps?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', roadmaps[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', '1')
                return prev
            })
        }
    }
    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('search')
            prev.set('page', '1')
            return prev
        })
        setDebouncedSearch('')
    }

    const tableData = roadmaps?.length > 0 ? roadmaps : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<RoadMap>()

    const columns = [
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: 'id',
            cell: (info) => {
                return <div className='flex '>{info.getValue()}</div>
            },
            size: 40,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            size: 70,
            cell: (info) => {
                const due_at = info.cell.row.original.target_date
                const overdue = new Date(due_at) < new Date() && info.row.original.status != 'Completed'
                return (
                    <div className='flex gap-2 items-center'>
                        {overdue && <ExclamationTriangleIcon className='text-danger' />}
                        <p>{info.getValue()}</p>
                    </div>
                )
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            minSize: 300,
            cell: (info) => {
                return <Link to={`/tasks/${info.row.original.id}`}>{info.getValue()}</Link>
            },
        }),

        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {JSON.parse(info.getValue())
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <Association id={info.row.original.id} association={association} />
                            ))}
                    </div>
                )
            },
        }),
        {
            header: 'Assigned To',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {JSON.parse(row.original.associations)
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <Assignment association={association} />
                            ))}
                    </div>
                )
            },
        },

        columnHelper.accessor('start_date', {
            maxSize: 50,
            header: 'Start',
            cell: (info) => {
                return <p>{dateFormat(new Date(info.getValue()))}</p>
            },
        }),
        columnHelper.accessor('target_date', {
            maxSize: 50,
            header: 'Target',
            cell: (info) => {
                return <p>{dateFormat(new Date(info.getValue()))}</p>
            },
        }),
    ]

    return (
        <>
            <div className='flex justify-between mb-4'>
                <div className='flex gap-6 items-center w-5/12'>
                    <h1 className='text-2xl font-semibold min-w-fit'>Road Maps</h1>
                    <TasksScreenV2SearchContainer setDebouncedSearch={setDebouncedSearch} />
                </div>
                <Button variant={'outline'} size={'sm'} onClick={toggleCreateModal}>
                    + New Road Map
                </Button>
            </div>
            <FilterToolBar
                className='dark:bg-darkbg2'
                handleClearSearch={handleClearSearch}
                filterBarObject={companyFilterBarObject}
                showSearch={false}
            >
                <FetchingSpinner isFetching={roadmapsQuery.isPending} />

                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                editSearchParams={param.editSearchParams}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <TasksV2TableSimple
                                    columns={columns}
                                    tableData={tableDataMemo}
                                    showHeaders={true}
                                    link={`/tasks/roadmaps/`}
                                />
                            </div>
                        )}
                        {!roadmapsQuery.isLoading && roadmaps?.length < 1 && (
                            <div className='flex justify-center items-center w-full h-2/3 mt-28'>
                                <div className='p-2 px-4 rounded-md border text-lg'>No results found</div>
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={roadmapsQuery.isLoading || roadmapsQuery.isFetching || roadmaps?.length < 50}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </>
    )
}
