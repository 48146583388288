import { useEffect, useState } from 'react'
import { SetupEventType, ShopifyProduct } from '../ProductSetup.types'
import { formatDateToLocale, parseResObject } from '../../helpers'
import { UserInit } from '../../users/users.types'
import RenderProductSetupValue from '../components/RenderProductSetupValue'

export default function ProductSetupEvents({
    events,
    setShowHistory,
    step,
    userList,
}: {
    events: SetupEventType[]
    product: ShopifyProduct
    setShowHistory: Function
    step: string
    userList: UserInit[]
}) {
    const [filteredEvents, setFilteredEvents] = useState(events)
    const [filterStatus, setFilterStatus] = useState('step')

    useEffect(() => {
        if (filterStatus === 'step') {
            setFilteredEvents(
                structuredClone(events).filter((event: SetupEventType) => {
                    if (event.step.toLowerCase().replace('_', '-') === step.toLowerCase()) {
                        return formatEvent(event)
                    }
                })
            )
        } else {
            setFilteredEvents(structuredClone(events).filter((event: SetupEventType) => formatEvent(event)))
        }
    }, [step, events])
    function formatEvent(event: SetupEventType) {
        const user = userList.filter((user) => {
            if (user.id === event.user_id) {
                return user
            }
        })

        parseResObject(event)
        if (event.step === 'identifiers') {
            event.field = `${event.variant_name}: ${event.field}`
        }
        if (event.field === 'filter values') {
            event.field = `${event.filter_group_name} filter values`
        }
        if (event.field === 'custom_custom_note') {
            event.field = 'Notes'
        }
        if (event.field === 'body_html') {
            event.field = 'Overview'
        }
        event.field = event.field.replace('_', ' ')
        const date = new Date(event.created_at)
        event.monthDateYear = [date.getMonth() + 1, date.getDate(), date.getFullYear()].join('/')
        event.time = formatDateToLocale(event.created_at).split(' ').slice(-2).join(' ')
        event.user_name = user[0].user_id
        return event
    }

    return (
        <div className='w-full h-fit  sticky top-[66px] dark:bg-darkbg1 p-[16px] rounded-[4px] flex flex-col shadow-small overflow-visible'>
            <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit text-[12px] uppercase font-bold bg-blue dark:bg-darkaccent1 text-text2 dark:text-darktext2 rounded-tl'>
                History
            </p>
            <button
                onClick={() => setShowHistory(false)}
                className='leading-[30px] text-[34px] text-danger dark:text-darkdanger absolute font-bold top-0 right-[8px]'
            >
                &times;
            </button>
            <div className='flex gap-3 mt-[-15px] mb-2 m-auto'>
                <button
                    onClick={() => {
                        setFilterStatus('step')
                        setFilteredEvents(
                            structuredClone(events).filter((event: SetupEventType) => {
                                if (event.step.toLowerCase().replace('_', '-') === step.toLowerCase()) {
                                    return formatEvent(event)
                                }
                            })
                        )
                    }}
                    className={`border-[1px] py-[2px] px-1 ${
                        filterStatus === 'step'
                            ? 'text-accent2 dark:text-darkaccent2 border-accent2 dark:border-darkaccent2'
                            : 'text-text1 dark:text-darktext1'
                    }  rounded capitalize text-sm`}
                >
                    {step}
                </button>
                <button
                    onClick={() => {
                        setFilterStatus('all')
                        setFilteredEvents(structuredClone(events).filter((event: SetupEventType) => formatEvent(event)))
                    }}
                    className={`border-[1px] ${
                        filterStatus === 'all'
                            ? 'text-accent2 dark:text-darkaccent2 border-accent2 dark:border-darkaccent2'
                            : 'text-text1 dark:text-darktext1'
                    } rounded py-[2px] px-2 capitalize text-sm`}
                >
                    All
                </button>
            </div>
            <div className='flex flex-col gap-1 divide-y-[1px] divide-grey '>
                {filteredEvents.length > 0 &&
                    userList &&
                    filteredEvents.map((event: SetupEventType) => {
                        return (
                            <div className={`flex text-sm gap-[8px] bg-bg1 dark:bg-darkbg1 items-center `}>
                                <div className='flex flex-col relative  basis-[100%] gap-[8px]'>
                                    <p className='capitalize dark:text-offwhite '>
                                        {event.user_name} updated{' '}
                                        <span className='task-screen__field text-accent2 dark:text-darkaccent2 hover:underline'>
                                            {event.field}
                                        </span>
                                        <p
                                            style={{ wordBreak: 'break-word' }}
                                            className='task-screen__field-body normal-case whitespace-p-wrap font-bai absolute text-[14px] top-[100%] left-0 w-[140%] max-w-[170%] max-h-[300px]  overflow-y-scroll py-[8px] px-[16px] rounded border-[1px] border-[#a1a1a1] shadow-small bg-bg1 text-text1 dark:text-darktext1 dark:bg-darkbg1 dark:shadow-darkbg1 dark:border-darkbg2 z-index-5'
                                        >
                                            <RenderProductSetupValue
                                                value={event.updated_value}
                                                state='Updated Value -'
                                            />
                                            <br />
                                            <RenderProductSetupValue
                                                value={event.previous_value}
                                                state='Previous Value -'
                                            />
                                        </p>
                                    </p>
                                </div>
                                <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                                    <p className='dark:text-offwhite'>{event.monthDateYear}</p>
                                    <p className='text-darkgrey dark:text-grey'>{event.time}</p>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}
