import { CheckIcon } from '@radix-ui/react-icons'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { cn } from 'helpers'
import { getDateToday } from 'procurement/constants'
import React, { useRef, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { TASK_PRIORITIES, TASK_STATUSES } from 'tasksV2/constants/tasks'
import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
import {
    MdOutlineSignalCellularAlt,
    MdOutlineSignalCellularAlt1Bar,
    MdOutlineSignalCellularAlt2Bar,
} from 'react-icons/md'
import { FaTimes } from 'react-icons/fa'
import TicketsListGrouped from './lists/TicketsListGrouped'
import { TasksScreenV2SearchContainer } from 'tasksV2/tasks/TasksScreenV2SearchContainer'

export default function TicketsScreen() {
    const searchRef: any = useRef()
    const [searchParams, setSearchParams] = useSearchParams()
    const searchParam = searchParams.get('search') || ''
    const user = useSelector<any, any>((state) => state.user)
    const fromString = searchParams.get('from') || ''
    const toString = searchParams.get('to') || ''
    const [taskDueDateRange, setTaskDueDateRange] = useState<DateRange | undefined>({
        from: fromString ? new Date(fromString) : getDateToday(),
        to: toString ? new Date(toString) : undefined,
    })
    const prioritiesString = searchParams.get('priorities') || ''
    const assignedToString = searchParams.get('assigned_to') || ''
    const statusString = searchParams.get('statuses') || ''

    const stringGroupFilterValues = localStorage.getItem('groupFilterValues')
    const [groupFilterValues, setGroupFilterValues] = useState<any>(
        stringGroupFilterValues ? JSON.parse(stringGroupFilterValues) : {}
    )
    const stringGroupTasksSetupOptions = localStorage.getItem('groupTasksSetupOptions')
    const [groupTasksSetupOptions, setGroupTasksSetupOptions] = useState(
        stringGroupTasksSetupOptions ? JSON.parse(stringGroupTasksSetupOptions) : []
    )

    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)
    const assignedToValues = decodeURIComponent(assignedToString)
        .split(',')
        .filter((v) => v)

    const priorityIcons = [MdOutlineSignalCellularAlt1Bar, MdOutlineSignalCellularAlt2Bar, MdOutlineSignalCellularAlt]
    const priorityOptions = TASK_PRIORITIES?.map((priority: any, index) => {
        return {
            label: priority,
            value: priority,
            icon: priorityIcons[index],
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const selectionOptions = [{ title: 'Closed', id: 'closed' }]
    const userFilterOptions = [
        { title: 'Myself', id: 'showMyself' },
        { title: 'All', id: 'showAll' },
    ]

    const handleTableSetupEdit = (tableSetup: any) => {
        if (Object.keys(tableSetup)?.length) {
            searchParams.delete('Pinned_page')
            setSearchParams(searchParams)
            localStorage.setItem('groupTasksSetupOptions', JSON.stringify(tableSetup))
        }
    }

    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('groupFilterValues', JSON.stringify(newValue))
        }
    }

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const activeUsers = users?.filter((user) => user.type !== 'Archived')
    const userOptions = activeUsers.map((user) => {
        return {
            label: user.user_id,
            value: user.id.toString(),
            icon: undefined,
        }
    })

    const filters = {
        userIds: assignedToValues.length ? assignedToValues : groupFilterValues['showMyself'] ? [user.id] : undefined,
        priorities: priorityValues,
        statuses:
            statusValues.length > 0
                ? statusValues
                : groupFilterValues['closed']
                  ? [...TASK_STATUSES, 'Archived']
                  : [
                        ...TASK_STATUSES.filter(
                            (status) => status != 'Completed' && status != 'Archived' && status != 'Cancelled'
                        ),
                    ],
        search: searchParam,
        dateRange: taskDueDateRange,
        overdue: true,
        limit: 2000,
    }

    const filterValuesMap: any = {
        priorities: priorityValues,
        statuses: statusValues,
        assigned_to: assignedToValues,
    }
    const filterOptionsMap: any = {
        priorities: priorityOptions,
        statuses: statusOptions,
        assigned_to: userOptions,
    }
    const hasFilterOptions = Object.entries(filterValuesMap).filter(([_key, val]: any) => val.length > 0).length > 0

    const clearFilterValue = (groupName: string, value: string) => {
        const newValues = filterValuesMap[groupName as keyof typeof filterValuesMap].filter((v: any) => v !== value)
        if (!newValues.length) {
            searchParams.delete(groupName)
            return setSearchParams(searchParams)
        }
        searchParams.set(groupName, encodeURIComponent(newValues.join(',')))
        setSearchParams(searchParams)
    }

    return (
        <div className='text-sm'>
            <div className='flex gap-4 justify-between mb-2 w-1/3'>
                <h1 className='text-2xl font-semibold'>Tickets</h1>
                <TasksScreenV2SearchContainer ref={searchRef} />
            </div>
            <div className='flex flex-col min-h-0 h-[calc(100vh-120px)] text-sm'>
                <div className='flex justify-between mb-2'>
                    <div className='flex gap-4 items-center'>
                        <div className='flex flex-col '></div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <div className='grid grid-cols-[auto_auto_auto_auto_auto] grid-rows-2 gap-x-4 gap-y-1 items-center h-fit'>
                            <div className='grid grid-rows-subgrid row-span-2'>
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={statusOptions}
                                    title={'Status'}
                                    field={'statuses'}
                                    filterValues={statusValues}
                                    searchToggle={false}
                                    editSearchParams={true}
                                    preview={false}
                                />
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={priorityOptions}
                                    title={'Priority'}
                                    field={'priorities'}
                                    filterValues={priorityValues}
                                    searchToggle={false}
                                    editSearchParams={true}
                                    preview={false}
                                />
                            </div>
                            <div className='grid grid-rows-subgrid border h-fit rounded-md p-1 px-2  text-sm'>
                                {selectionOptions?.map((option: any) => {
                                    return (
                                        <div
                                            key={option.id}
                                            onClick={() => {
                                                setGroupFilterValues((prev: any) => {
                                                    const newValue = {
                                                        ...prev,
                                                    }
                                                    newValue[option.id] = !newValue[option.id]
                                                    handleFilterValuesEdit(newValue)
                                                    return newValue
                                                })
                                            }}
                                            className='flex gap-1 cursor-pointer items-center'
                                        >
                                            <div
                                                className={cn(
                                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                    groupFilterValues[option.id]
                                                        ? 'bg-primary text-primary-foreground'
                                                        : 'opacity-50 [&_svg]:invisible'
                                                )}
                                            >
                                                <CheckIcon className={cn('h-4 w-4')} />
                                            </div>
                                            <p>{option.title}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='grid grid-rows-subgrid row-span-2'>
                                <div className=' h-fit  p-1 flex justify-end gap-2 text-sm'>
                                    {userFilterOptions?.map((option: any) => {
                                        return (
                                            <div
                                                key={option.id}
                                                onClick={() => {
                                                    if (groupFilterValues[option.id]) {
                                                        return
                                                    }
                                                    setGroupFilterValues((prev: any) => {
                                                        const newValue = {
                                                            ...prev,
                                                        }
                                                        if (option.id === 'showAll') {
                                                            newValue['showMyself'] = false
                                                        }
                                                        if (option.id === 'showMyself') {
                                                            newValue['showAll'] = false
                                                        }
                                                        newValue[option.id] = !newValue[option.id]
                                                        handleFilterValuesEdit(newValue)
                                                        return newValue
                                                    })
                                                }}
                                                className='flex gap-1 cursor-pointer items-center'
                                            >
                                                <div
                                                    className={cn(
                                                        ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                                        groupFilterValues[option.id]
                                                            ? 'bg-primary text-primary-foreground'
                                                            : 'opacity-50 [&_svg]:invisible'
                                                    )}
                                                >
                                                    <CheckIcon className={cn('h-4 w-4')} />
                                                </div>
                                                <p>{option.title}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <FilterBarSelectorButton
                                    variant='outline'
                                    align='end'
                                    setFilterValues={() => {}}
                                    options={userOptions}
                                    title={'Assigned To'}
                                    field={'assigned_to'}
                                    filterValues={assignedToValues}
                                    searchToggle={true}
                                    editSearchParams={true}
                                    preview={false}
                                />
                            </div>
                        </div>
                        {hasFilterOptions && (
                            <div className='flex items-center gap-2'>
                                <h3 className='text-xs font-semibold'>Active Filters:</h3>
                                {Object.entries(filterValuesMap)
                                    .filter(([_fvGroup, values]: any) => values.length > 0)
                                    .map(([fvGroup, values]: any) => (
                                        <React.Fragment key={fvGroup}>
                                            <span className='text-xs font-medium capitalize'>{fvGroup} = </span>
                                            {values.map((val: any) => (
                                                <button
                                                    key={val}
                                                    className='flex gap-1 items-center text-xs font-medium rounded border border-lightgrey dark:border-darkgrey p-1 leading-none bg-bg1 dark:bg-darkbg1'
                                                    onClick={() => clearFilterValue(fvGroup, val)}
                                                >
                                                    {(
                                                        filterOptionsMap[fvGroup as keyof typeof filterOptionsMap] || []
                                                    ).find((option: any) => option.value === val)?.label ||
                                                        'Unknown Filter'}
                                                    <FaTimes className='text-darkgrey' />{' '}
                                                </button>
                                            ))}
                                        </React.Fragment>
                                    ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className='flex flex-col gap-3 h-full min-h-0'>
                    <TicketsListGrouped filters={filters} />
                </div>
            </div>
        </div>
    )
}
