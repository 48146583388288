import { vFetch } from '../../helpers'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ArticleView from './ArticleView'
import { articleCardDateFormatter } from '../helpers'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'

export default function AllArticlesView({
    setView,
    setSelectedArticle,
    selectedArticle,
    departments,
}: {
    setView: any
    setSelectedArticle: any
    selectedArticle: any
    departments: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const articleID = searchParams.get('articleID')
    const [articleList, setArticleList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const articleMap = articleList?.filter((article: any) => article.status === 'Published' && article.hidden === 0)

    const getArticles = async () => {
        vFetch(
            `/v1/knowledge/articles?&fields=title,excerpt,featured_image,pinned,id,status,hidden,created_at,department_id`,
            {
                cb: (res: any) => {
                    setIsLoading(false)
                    if (!res.success) {
                        setIsError(true)
                    }
                    if (res.success) {
                        const foundArticle = res.articles.find((article: any) => article.id == 'articleID')
                        if (foundArticle) {
                            setSelectedArticle(foundArticle)
                        }
                        setArticleList(res.articles)
                    }
                },
            }
        )
    }
    const handleSetArticle = (article: any) => {
        setSelectedArticle(article)
        setSearchParams((prev: any) => {
            prev.set('articleID', article.id)
            prev.delete('departmentID')
            return prev
        })
    }

    // DEPARTMENTS FILTER //
    const [departmentFilterValues, setDepartmentFilterValues] = useState<{ departmentIDs: any }>({
        departmentIDs: departments.map((department: any) => department.id),
    })
    const departmentOptions = departments.map((department: any) => {
        return {
            label: department.label,
            value: department.id,
            icon: undefined,
        }
    })
    const selectorButtonObjectDept = {
        params: [
            {
                component: FilterBarSelectorButton,
                title: 'Departments',
                options: departmentOptions,
                field: 'departmentIDs',
                values: departmentFilterValues?.departmentIDs,
                searchToggle: false,
            },
        ],
        setFunction: setDepartmentFilterValues,
        resetFunction: () => {
            setDepartmentFilterValues({
                departmentIDs: [],
            })
        },
    }
    // PINNED FILTER //
    const [filterValues, setFilterValues] = useState<{ pinned: any }>({
        pinned: [],
    })
    const pinnedOptions = [
        {
            label: 'Pinned',
            value: 1,
            icon: undefined,
        },
    ]
    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                title: 'Pinned',
                options: pinnedOptions,
                field: 'pinned',
                values: filterValues.pinned,
                searchToggle: false,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                pinned: [],
            })
        },
    }
    const filteredArticleMap = articleMap.filter((article: any) => {
        if (filterValues.pinned.length && !filterValues.pinned.includes(article.pinned)) {
            return
        }
        if (!departmentFilterValues.departmentIDs.includes(article.department_id)) {
            return
        }
        return article
    })
    useEffect(() => {
        getArticles()
    }, [])

    return (
        <>
            {articleID && selectedArticle && (
                <ArticleView
                    selectedArticle={selectedArticle}
                    setSelectedArticle={setSelectedArticle}
                    setView={setView}
                />
            )}
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}

            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}

            {!selectedArticle && !selectedArticle && !isError && !isLoading && (
                <div className='h-full'>
                    <div className='flex justify-between'>
                        <div className='flex my-6'>
                            <p className='font-bold text-2xl'>All Articles</p>
                        </div>
                        <div className='flex gap-2 my-6 mr-6'>
                            <div className=''>
                                {selectorButtonObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={selectorButtonObject.setFunction}
                                            align={'start'}
                                            preview={false}
                                        />
                                    )
                                })}
                            </div>
                            <div>
                                {selectorButtonObjectDept.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={selectorButtonObjectDept.setFunction}
                                            align={'end'}
                                            preview={false}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='overflow-auto pr-2'>
                        {articleMap.length > 0 && (
                            <div>
                                <div className='grid grid-cols-3 gap-5 pb-4'>
                                    {filteredArticleMap?.map((article: any, index: number) => (
                                        <div
                                            key={`all-articles-main-${article.id}`}
                                            className='flex flex-col border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'
                                        >
                                            <button
                                                onClick={() => handleSetArticle(article)}
                                                className='h-full rounded'
                                            >
                                                <img
                                                    src={article.featured_image ? article.featured_image : '/logo.png'}
                                                    className='object-contain max-h-[250px] h-full w-full rounded my-auto'
                                                />
                                            </button>
                                            <div className='pt-4'>
                                                <button
                                                    className='text-md font-semibold leading-tight text-left'
                                                    onClick={() => handleSetArticle(article)}
                                                >
                                                    {article.title}
                                                </button>
                                                <div className='opacity-50 text-sm'>{article.department_label}</div>
                                                <div className='mt-1 leading-tight line-clamp-3 text-left text-sm'>
                                                    {article.excerpt}
                                                </div>
                                                <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                                                <div className='flex items-center gap-2'>
                                                    <div className='mt-3 flex shrink-0 rounded-full items-center justify-center w-[34px] h-[34px] text-lg font-bold text-white dark:text-offwhite bg-orange-500'>
                                                        {article.user_firstName[0]}
                                                        {article.user_lastName[0]}
                                                    </div>
                                                    <div>
                                                        <div className='mt-4 font-semibold text-sm leading-none'>
                                                            {article.user_firstName} {article.user_lastName}
                                                        </div>
                                                        <div className='mt-0.5 flex flex-row items-center gap-2'>
                                                            <div className='text-sm'>
                                                                {articleCardDateFormatter.format(
                                                                    new Date(article.created_at)
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {articleMap.length === 0 && (
                            <div className='font-bold text-2xl mt-8'>
                                <p>There are no articles yet</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
