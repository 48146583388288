import { useGetChangelogs, useGetEntries, useGetReleases, useUpdateRelease } from 'changelog/api/useQueries'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import NotFound from 'routes/NotFound'
import { ChangelogType } from './Changelog.types'
import { Button } from 'custom_components/component_Basics/Button'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CreateEntryModal from './modals/CreateEntryModal'
import Select from 'procurement/components/Select'
import { FaPen } from 'react-icons/fa'
import CreateReleaseModal from './modals/CreateReleaseModal'
import EditEntryModal from './modals/EditEntryModal'

const dateFormatter = (dateString: string) => {
    if (!dateString) {
        return 'Never'
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(new Date(dateString))
}

export default function ChangelogScreen() {
    const navigate = useNavigate()
    const user = useSelector((state: any) => state.user)
    const isDeveloper = user.roles.includes('developer')
    const [showCreateEntry, setShowCreateEntry] = useState(false)
    const [showCreateRelease, setShowCreateRelease] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedReleaseId = searchParams.get('release_id')
    const selectedEntryId = searchParams.get('entry_id')
    const { changelog_id } = useParams()
    const changelogsQuery = useGetChangelogs({})
    const changelog =
        (changelogsQuery.data?.changelogs || []).find((cl) => cl.id.toString() === changelog_id) ||
        ({} as ChangelogType)
    const releasesQuery = useGetReleases({
        changelog_id,
    })
    const releases = releasesQuery.data?.releases || []
    const selectedRelease = !selectedReleaseId
        ? releases[0]
        : releases.find((release) => release.id.toString() === selectedReleaseId)
    const updateReleaseMutation = useUpdateRelease()
    const entriesQuery = useGetEntries({
        changelog_id,
        release_id: selectedReleaseId === '-2' ? undefined : selectedReleaseId === '-1' ? -1 : selectedRelease?.id,
    })
    const entries = entriesQuery.data?.entries || []

    const handleChangeReleaseStatus = ({ target }: any, releaseId: number) => {
        updateReleaseMutation.mutate({
            id: releaseId,
            status: target.value,
        })
    }

    const handleSetReleaseId = ({ target }: { target: HTMLSelectElement }) => {
        searchParams.set('release_id', target.value)
        setSearchParams(searchParams)
    }

    const handleSetEntryId = (entryId?: number) => {
        if (!entryId) {
            searchParams.delete('entry_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('entry_id', entryId.toString())
        return setSearchParams(searchParams)
    }

    if (!changelogsQuery.isLoading && !changelog.id) {
        return <NotFound />
    }
    return (
        <div className='text-sm max-w-[1000px] mx-auto'>
            <Button onClick={() => navigate('/development/changelogs')} className='h-fit p-1 mb-2'>
                &lt;- Back
            </Button>
            <div className='flex justify-between mb-4'>
                <h1 className='text-2xl font-semibold'>{changelog.app_name} Changelog</h1>
                {isDeveloper && (
                    <div className='flex gap-2'>
                        <Button variant='outline' onClick={() => setShowCreateEntry(true)}>
                            + New Entry
                        </Button>
                        <Button variant='outline' onClick={() => setShowCreateRelease(true)}>
                            + New Release
                        </Button>
                    </div>
                )}
            </div>
            <div className='flex gap-2 justify-between'>
                <Select
                    outerClassName='w-fit'
                    id='release'
                    name='release'
                    value={selectedReleaseId ?? selectedRelease?.id ?? ''}
                    onChange={handleSetReleaseId}
                    label='Release'
                >
                    <option value='-2'>All Changes</option>
                    <option value='-1'>Unreleased</option>
                    {releases.map((release, index) =>
                        index === 0 ? (
                            <option value={release.id}>
                                {releases[0].major_version}.{releases[0].minor_version}.{releases[0].patch_version}{' '}
                                (Latest)
                            </option>
                        ) : (
                            <option value={release.id}>
                                {release.major_version}.{release.minor_version}.{release.patch_version}
                            </option>
                        )
                    )}
                </Select>
                {selectedRelease && isDeveloper && (
                    <Select
                        disabled={selectedRelease.status === 'published'}
                        outerClassName='w-fit'
                        id='status'
                        name='status'
                        value={selectedRelease.status}
                        onChange={(e) => handleChangeReleaseStatus(e, selectedRelease.id)}
                        label='Status'
                    >
                        <option value='pending'>Pending Approval</option>
                        <option value='published'>Published</option>
                    </Select>
                )}
            </div>
            <div className='flex flex-col gap-2 mt-4'>
                {!entriesQuery.isFetching && entries.length === 0 && (
                    <p className='p-4'>This release currently has no entries.</p>
                )}
                {releasesQuery.isFetching || entriesQuery.isFetching ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {entries.map((entry) => (
                            <div>
                                <p className='grid grid-cols-2 border-b border-lightgrey dark:border-darkgrey pb-1'>
                                    <span className='flex gap-2 items-center'>
                                        <span className='font-bold'>{entry.title}</span> (
                                        {entry.status === 'pending' ? 'Pending Approval' : 'Published'})
                                        {isDeveloper && (
                                            <button
                                                onClick={() => handleSetEntryId(entry.id)}
                                                className='text-xs text-darkgrey dark:text-offwhite'
                                            >
                                                <FaPen />
                                            </button>
                                        )}
                                    </span>
                                    <span className='justify-self-end'>{dateFormatter(entry.created_at)}</span>
                                </p>
                                <ul className='p-2 pl-6 flex flex-col gap-2 mb-2'>
                                    {entry.description
                                        ?.split('\n')
                                        .map((bullet) => <li className='list-disc'>{bullet}</li>)}
                                </ul>
                            </div>
                        ))}
                    </>
                )}
            </div>

            {showCreateEntry && (
                <CreateEntryModal changelog_id={parseInt(changelog_id!)} closeModal={() => setShowCreateEntry(false)} />
            )}
            {showCreateRelease && (
                <CreateReleaseModal
                    changelog_id={parseInt(changelog_id!)}
                    closeModal={() => setShowCreateRelease(false)}
                />
            )}
            {selectedEntryId && (
                <EditEntryModal entry_id={parseInt(selectedEntryId)} closeModal={() => handleSetEntryId()} />
            )}
        </div>
    )
}
