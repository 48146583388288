import { useQuery } from '@tanstack/react-query'
import { formatDateToLocale, vFetch } from 'helpers'
import CreateRoleModal from './CreateRoleModal'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'custom_components/component_Basics/Button'
import EditRoleModal from './EditRoleModal'
import { useGetRoles } from './api/useQueries'
import React from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import UserRolesTable from './UserRolesTable'

type RoleType = {
    id: number
    title: string
    created_at: any
    updated_at: any
}

export default function RolesScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedRoleId = searchParams.get('role_id')
    const rolesQuery = useGetRoles({})
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }
    const toggleEditModal = (id?: number) => {
        if (!id) {
            searchParams.delete('role_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('role_id', id?.toString())
        return setSearchParams(searchParams)
    }
    const roles: any[] = rolesQuery.data?.roles || []

    const data = roles?.length > 0 ? roles : []
    const dataMemo = React.useMemo(() => data ?? [], [data])
    const columnHelper = createColumnHelper<RoleType>()

    const columns = [
        columnHelper.accessor('id', {
            header: 'Id',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            size: 500,
        }),
        columnHelper.accessor('created_at', {
            header: 'Created',
            cell: (info) => {
                const createdAt = new Date(info.getValue())
                return (
                    <div className='flex flex-wrap gap-x-[4px]'>
                        <p>
                            {[
                                createdAt.getMonth() + 1,
                                createdAt.getDate(),
                                String(createdAt.getFullYear()).slice(-2),
                            ].join('/')}
                        </p>
                        <p>{formatDateToLocale(createdAt).split(' ').slice(-2).join(' ')}</p>
                    </div>
                )
            },
            size: 200,
        }),
        columnHelper.accessor('updated_at', {
            header: 'Updated',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            minSize: 260,
        }),
    ]

    const handleRowClick = (id: number) => {
        toggleEditModal(id)
    }
    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='font-semibold text-2xl'>Roles</h1>
                <Button onClick={toggleCreateModal} size='sm' variant='outline'>
                    + Create Role
                </Button>
            </div>
            <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none h-full min-h-0'>
                <div className='w-full h-full min-h-0 rounded-md'>
                    {dataMemo && (
                        <div className='relative w-full h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                            <UserRolesTable
                                handleRowClick={handleRowClick}
                                columns={columns}
                                tableData={dataMemo}
                                showHeaders={true}
                                size={'auto'}
                            />
                            {!rolesQuery.isLoading && data?.length < 1 && (
                                <div className='pointer-events-none absolute top-0 left-0 flex justify-center items-center w-full h-full z-[60] text-black dark:text-offwhite'>
                                    <div className='p-2 px-4 rounded-md border mt-3 '>No roles found</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {selectedRoleId && !rolesQuery.isLoading && (
                <EditRoleModal roleId={parseInt(selectedRoleId)} closeModal={toggleEditModal} />
            )}
            {showCreateModal && <CreateRoleModal closeModal={toggleCreateModal} />}
        </div>
    )
}
