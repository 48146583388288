import { cn } from 'helpers'
import { ActiveModifiers, useDayPicker } from 'react-day-picker'
import { useSelector } from 'react-redux'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'

export interface DayContentProps {
    date: Date
    displayMonth: Date
    activeModifiers: ActiveModifiers
}

export function TaskCountCalendarDayContent(props: any): JSX.Element {
    const user = useSelector<any, any>((state) => state.user)
    const {
        locale,
        formatters: { formatDay },
    } = useDayPicker()

    const day = new Date(props.date)
    day.setHours(0, 0, 0, 0)

    const tasksQuery = useGetTasks({
        userIds: [user.id],
        statuses: [...TASK_STATUSES.filter((status) => status != 'Completed')],
    })
    const tasks = tasksQuery.data?.tasks || []
    const todaysTasks = tasks.filter((task: any) => {
        const currentTaskDate = new Date(task.due_at)
        currentTaskDate.setHours(0, 0, 0, 0)
        if (currentTaskDate.toISOString() === day.toISOString()) {
            return true
        }
    })

    return (
        <>
            <div className='p-0 flex flex-col w-full h-full relative'>
                <span className='self-start leading-3'>{formatDay(props.date, { locale })}</span>
                {!props.hideTaskCount && (
                    <div
                        className={cn(
                            todaysTasks?.length ? 'opacity-100' : 'opacity-0',
                            'absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-35%] w-5 h-5 self-center rounded-full bg-accent2 dark:bg-darkaccent2 text-text2 dark:text-darktext2'
                        )}
                    >
                        {todaysTasks?.length || ''}
                    </div>
                )}
            </div>
        </>
    )
}
