import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { DEFAULT_PERMISSION_SET } from './constants'
import PermissionRow from './PermissionRow'

export default function CreateRoleModal({ closeModal }: { closeModal: () => void }) {
    const defaultSet = DEFAULT_PERMISSION_SET
    const [expandMap, setExpandMap] = useState<any>({})
    const [role, setRole] = useState({
        title: '',
        permission_set: defaultSet,
    })
    const queryClient = useQueryClient()
    const createRoleMutation = useMutation({
        mutationFn: (role: { title: string; permission_set: string }) =>
            vFetch('/v2/roles', {
                method: 'POST',
                body: JSON.stringify({
                    title: role.title,
                    permission_set: JSON.parse(role.permission_set),
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })

    function updateExpandMap(group: any, newMap: any, value: number) {
        for (const key of Object.keys(group)) {
            if (typeof group[key] != 'boolean') {
                newMap[key] = value
                updateExpandMap(group[key], newMap, value)
            }
        }
        return newMap
    }
    const handleCreate = () => {
        createRoleMutation.mutate(
            { title: role.title.trim(), permission_set: JSON.stringify(role.permission_set) },
            {
                onSuccess: () => closeModal(),
            }
        )
    }
    const edited =
        role.title.trim() !== '' &&
        JSON.stringify(role.permission_set) !== JSON.stringify(DEFAULT_PERMISSION_SET, null, 2)

    const handleExpandAll = () => {
        setExpandMap(updateExpandMap(role.permission_set, structuredClone(expandMap), 1))
    }
    const handleCollapseAll = () => {
        setExpandMap(updateExpandMap(role.permission_set, structuredClone(expandMap), 0))
    }
    return (
        <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
            <div className='p-6 pt-0 pb-0 flex min-h-0 flex-col rounded shadow-md relative bg-bg1 dark:bg-darkbg1 w-full h-full max-w-[90%] max-h-[90%] overflow-auto'>
                <div className='sticky top-0 bg-bg1 dark:bg-darkbg1 pt-4'>
                    <button
                        className='text-red dark:text-lightred font-bold absolute text-lg -top-0 -right-5'
                        onClick={() => closeModal()}
                    >
                        <FaTimes />
                    </button>
                    <div className='flex justify-between'>
                        <h2 className='font-bold'>Create New Role</h2>
                        <div className='flex gap-2 items-center'>
                            <Button onClick={handleExpandAll} size='sm' variant='outline'>
                                Expand
                            </Button>
                            <Button onClick={handleCollapseAll} size='sm' variant='outline'>
                                Collapse
                            </Button>
                        </div>
                        <Button onClick={handleCreate} size='sm' variant='outline' disabled={!edited}>
                            Create Role
                        </Button>
                    </div>
                    <Input
                        className='mb-2'
                        id='title'
                        name='title'
                        type='text'
                        label='Role Name'
                        value={role.title}
                        onChange={({ target }) => setRole({ ...role, title: target.value })}
                    />
                </div>

                <div className='flex flex-col gap-2 w-full'>
                    {Object.entries(defaultSet).map(([key, value]: any, index) => {
                        return (
                            <PermissionRow
                                section={key}
                                objectKey={key}
                                objectValue={value}
                                role={role}
                                setRole={setRole}
                                level={0}
                                expandMap={expandMap}
                                setExpandMap={setExpandMap}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
        // <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
        //     <div className='p-6 flex min-h-0 flex-col rounded shadow-md relative bg-bg1 dark:bg-darkbg1 w-full h-full max-w-[90%] max-h-[90%]'>
        //         <button
        //             className='text-red dark:text-lightred font-bold absolute text-lg top-1 right-1'
        //             onClick={closeModal}
        //         >
        //             <FaTimes />
        //         </button>
        //         <div className='flex justify-between'>
        //             <h2 className='font-bold'>Create New Role</h2>
        //             <Button onClick={handleCreate} size='sm' variant='outline' disabled={!edited}>
        //                 + Create Role
        //             </Button>
        //         </div>
        //         <Input
        //             id='title'
        //             name='title'
        //             type='text'
        //             label='Role Name'
        //             value={role.title}
        //             onChange={({ target }) => setRole({ ...role, title: target.value })}
        //         />
        //     </div>
        // </div>
    )
}
