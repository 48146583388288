import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { sendToast, vFetch } from '../helpers'

export default function ResetPasswordScreen() {
    const { token } = useParams()
    const navigate = useNavigate()
    const [password, setPassword] = useState<string>('')
    const [cPassword, setCPassword] = useState<string>('')
    const [showPassword, setShowPassword] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState('')

    const passwordConditions: any = {
        '8 - 40 Characters': (password: string) => password.length > 5 && password.length < 40,
        '1 Uppercase letter': (password: string) => password.length && password.match(/[A-Z]/),
        '1 Lowercase letter': (password: string) => password.length && password.match(/[a-z]/),
        '1 Number': (password: string) => password.length && password.match(/\d/),
        'No spaces': (password: string) => password.length && !password.match(/ /),
        'Does not include & < > / \\ " \' ? +': (password: string) =>
            password.length && !password.match(/[&<>\/\\"'?+]/),
    }

    useEffect(() => {
        vFetch(`/v1/users/validateTemporaryPasswordToken`, {
            Authorization: token,
            method: 'POST',
            body: JSON.stringify({ token }),
            cb: (res: any) => {
                if (!res.success) {
                    sendToast({ message: 'Navigating back to home screen...' })
                    setTimeout(() => {
                        navigate(`/`)
                    }, 4000)
                }
            },
        })
    }, [])
    useEffect(() => {
        if (password && cPassword) {
            if (password !== cPassword) setPasswordErrorMessage('Passwords must match.')
            else setPasswordErrorMessage('')
        } else setPasswordErrorMessage('')
    }, [password, cPassword])

    function handleSubmit() {
        if (!Object.keys(passwordConditions).every((c) => passwordConditions[c](password)))
            return sendToast({ message: 'Password is malformed. Check the requirements.' })
        if (password && cPassword && password !== cPassword)
            return sendToast({ message: 'Passwords do not match. Please check your password again.' })
        sendToast({ message: 'Updating password! ' })
        vFetch(`/v1/users/password/updateWithToken`, {
            Authorization: token,
            method: 'PUT',
            body: JSON.stringify({ token, password }),
            cb: (res: any) => {
                if (res.success || res.message === 'Temporary password has expired. Please request another.') {
                    sendToast({ message: 'Navigating back to home screen...' })
                    setTimeout(() => {
                        navigate(`/`)
                    }, 4000)
                }
            },
        })
    }

    return (
        <div className='absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] flex justify-center items-center dark:text-offwhite'>
            <div className='grid min-w-[460px] gap-[32px] dark:bg-darkaccent shadow-small p-[16px] rounded'>
                <div>
                    <h1 className='text-[20px]'>Please enter a new password for your account.</h1>
                    <div className='p-[16px]'>
                        <h2>Requirements:</h2>
                        <ul className='list-disc'>
                            {Object.keys(passwordConditions).map((key) => (
                                <li
                                    className={`ml-[16px] ${
                                        passwordConditions[key](password)
                                            ? 'text-green dark:text-lime'
                                            : 'text-red dark:text-fire'
                                    }`}
                                >
                                    {key}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='grid grid-col-[100%] gap-[16px] px-[16px] w-[100%]'>
                    <div className='relative'>
                        <label className='block' htmlFor='New Password'>
                            New Password
                        </label>
                        <div className='flex absolute top-0 right-0 gap-[8px] items-center justify-end'>
                            <label htmlFor='show password'>Show Password</label>
                            <input
                                id='show password'
                                type='checkbox'
                                checked={showPassword}
                                onChange={({ target }) => setShowPassword(target.checked)}
                            />
                        </div>
                        {showPassword ? (
                            <input
                                id='new password'
                                type='text'
                                value={password}
                                onChange={({ target }) => setPassword(target.value)}
                                className='dark:bg-darkness w-[100%] rounded px-[2px] text-[16px] py-[3px]'
                            />
                        ) : (
                            <input
                                id='new password'
                                type='password'
                                value={password}
                                onChange={({ target }) => setPassword(target.value)}
                                className='dark:bg-darkness w-[100%] rounded px-[2px] text-[20px]'
                            />
                        )}
                    </div>
                    <div className='relative'>
                        <label className='block' htmlFor='Confirm New Password'>
                            Confirm Password
                        </label>
                        {showPassword ? (
                            <input
                                id='confirm new password'
                                type='text'
                                value={cPassword}
                                onChange={({ target }) => setCPassword(target.value)}
                                className='dark:bg-darkness w-[100%] rounded px-[2px] text-[16px] py-[3px]'
                            />
                        ) : (
                            <input
                                id='confirm new password'
                                type='password'
                                value={cPassword}
                                onChange={({ target }) => setCPassword(target.value)}
                                className='dark:bg-darkness w-[100%] rounded px-[2px] text-[20px]'
                            />
                        )}
                        <span className='absolute top-[calc(100%+4px)] left-[0] text-[darkred] dark:text-[red]'>
                            {passwordErrorMessage}
                        </span>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <button
                        className={`dark:text-black ${
                            !passwordErrorMessage ? 'bg-blue dark:bg-accent' : 'bg-grey cursor-default'
                        } duration-200 rounded px-[8px] py-[4px] font-bold uppercase text-[20px]`}
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}
