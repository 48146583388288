import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { CompanyTaskType } from 'procurement/Procurements.types'
import React, { useState } from 'react'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { useParentCompanyDetailTasksQuery } from '../../api/useQueries'
import FilterToolBar from '../FilterToolBar'
import { useDebounce } from 'helpers'

export default function ParentTaskList({
    parentCompany,
    companyNameMap,
    companyIds,
}: {
    parentCompany: any
    companyNameMap: any
    companyIds: any
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)

    const companyTasksQuery = useParentCompanyDetailTasksQuery({ parentCompany, companyNameMap })
    const { isLoading } = companyTasksQuery
    const { tasks }: { tasks: CompanyTaskType[] } = companyTasksQuery?.data || {}
    const queryClient = useQueryClient()
    const tasksQueryV2 = useGetTasks(
        {
            resourceTable: 'company_tasks',
            resourceIds: companyIds?.filter((v: any) => v),
            search: debouncedSearch,
            limit: 300,
        },
        keepPreviousData
    )
    const { tasks: tasksV2 } = tasksQueryV2?.data || {}
    tasksV2?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const filteredTasks = tasks?.filter((task) => {
        return task.title.toLowerCase().includes(search.toLowerCase())
    })
    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={undefined}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px]'}
                />
            </div>
            <div className='flex flex-col gap-[8px] overflow-visible'>
                {!tasksQueryV2.isLoading &&
                    tasksV2
                        ?.filter((task: any) => task.status != 'Archived')
                        ?.map((task: any) => (
                            <React.Fragment key={task.id.toString() + 'procurementTask'}>
                                <TaskEditCreateModalV2 taskId={task.id.toString()} createEvent={false} />
                            </React.Fragment>
                        ))}
            </div>
        </div>
    )
}
