import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const sales = createQueryKeys('sales', {
    detail: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getSales(filters),
    }),
    goals: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getGoals(filters),
    }),
})

export const salesQueries = mergeQueryKeys(sales)
