import { keepPreviousData, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { addBusinessDays } from 'date-fns'
import OrderHubSkeletonBody from 'orderHubScreen/OrderHubSkeletonBody'
import Customer from 'orders/components/Customer'
import { Events } from 'orders/components/Events'
import OrderHeader from 'orders/components/OrderHeader'
import Tags from 'orders/components/Tags'
import { useCreatePrivateEvent, useGetPrivateEvents } from 'privateEvents/api/useQueries'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import TaskEditCreateModalV2 from 'tasks/components/TaskEditCreateModalV2'
import * as taskQueryKeys from 'tasksV2/api/queryKeys'
import { useGetAllDepartmentConfigs, useGetTasks } from 'tasksV2/api/useQueries'
import { CREATE_TASK_BUTTON_CONTENT, TaskV2 } from 'tasksV2/constants/tasks'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import CreateTaskModal from 'tasksV2/tasks/modals/CreateTaskModal'
import { useAllUsersQuery } from 'users/api/useQueries'
import { cn, createOrderNotesArray } from '../helpers'
import * as orderQueryKeys from '../orderHubScreen/api/queryKeys'
import { TaskEditInit } from '../tasks/tasks.types'
import { UserInit } from '../users/users.types'
import draftOrderQueryKeys from './api/draftOrderQueryKeys'
import { useGetDraftOrder, useGetOrderEmails, useGetOrderTaskEvents } from './api/useQueries'
import DraftOrderOverView from './components/DraftOrderOverview'

export default function DraftOrderHubScreenV2() {
    const DRAFT_ORDER_PRIVATE_EVENTS_URL = `/v1/draftOrderEventsPrivate`
    const { orderId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const queryClient = useQueryClient()
    const [showActivity, setShowActivity] = useState(true)
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [selectedTab, setSelectedTab] = useState<string>('tasks')
    const [adminEventLogged, setAdminEventLogged] = useState(false)

    const createPrivateEvent = useCreatePrivateEvent()

    const draftOrderQuery = useGetDraftOrder(orderId)
    const { draft_order: draftOrder, events: orderEvents } = draftOrderQuery?.data || {}

    const privateEventsQuery = useGetPrivateEvents({
        privateEventUrl: DRAFT_ORDER_PRIVATE_EVENTS_URL,
        element: 'draft_order_id',
        id: parseInt(orderId ? orderId : ''),
        enabled: true,
    })
    const { draftOrderEventsPrivate } = privateEventsQuery?.data || {}

    const draftOrderTags = draftOrder?.tags?.split(',')

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const tasksQuery = useGetTasks(
        { resourceTable: 'draft_order_tasks', resourceIds: [orderId].filter((v: any) => v) },
        keepPreviousData
    )
    const { tasks: tasksFromOrder } = tasksQuery?.data || {}
    const tasksLineItemQuery = useGetTasks(
        {
            resourceTable: 'draft_order_line_item_tasks',
            resourceIds: draftOrder?.line_items?.map((lineItem: any) => lineItem.id) || [],
        },
        keepPreviousData
    )
    const { tasks: tasksFromLineItems } = tasksLineItemQuery?.data || {}

    const allTasks: any = [tasksFromOrder, tasksFromLineItems]?.flat().filter((v: any) => v)

    const taskIds: string[] = Array.from(new Set(allTasks?.map((taskV2: any) => taskV2.id.toString())))
    const tasks: any = taskIds?.map((id: string) => allTasks.find((task: TaskV2) => task.id.toString() == id))
    const taskEventsQuery = useGetOrderTaskEvents(taskIds)
    const { events: taskEvents } = taskEventsQuery?.data || {}

    tasks?.forEach((task: any) => {
        queryClient.setQueryData(taskQueryKeys.tasks.detail(task.id.toString() || '').queryKey, (oldData: any) => {
            if (!oldData) {
                return { success: true, task }
            }
        })
    })

    const emailsQuery = useGetOrderEmails('draft_orders', orderId, [])
    const { emails } = emailsQuery?.data || {}

    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const { departments } = departmentsQuery?.data || {}

    const types = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()

    const allLoaded =
        !draftOrderQuery.isLoading &&
        !privateEventsQuery.isLoading &&
        !tasksQuery.isLoading &&
        !taskEventsQuery.isLoading &&
        !usersQuery.isLoading

    const eventSource: {
        foreign_id: number | undefined | null
        foreign_table: string
    } = {
        foreign_id: orderId ? parseInt(orderId) : undefined,
        foreign_table: 'draft_orders',
    }
    const tabs: any = {
        middle: {
            tasks: tasks,
        },
        right: {},
    }

    useEffect(() => {
        if (!adminEventLogged && allLoaded) {
            if (user && typeof orderId === 'string') {
                createPrivateEvent.mutate(
                    {
                        id: parseInt(orderId),
                        user_id: user.id,
                        privateEventUrl: '/v1/draftOrderEventsPrivate',
                    },
                    {
                        onSuccess: () => {
                            setAdminEventLogged(true)
                        },
                    }
                )
            }
        }
    }, [allLoaded])

    const events = [orderEvents, taskEvents, ...createOrderNotesArray(draftOrder?.note || '', users), emails]
        .flat()
        .filter((v) => v)

    const eventsLoaded = !privateEventsQuery.isLoading && !taskEventsQuery.isLoading && !emailsQuery.isLoading

    function onEmailSubmit(): any {
        queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders.emails._def })
        queryClient.invalidateQueries({ queryKey: orderQueryKeys.orders.attachments._def })
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    const sortedTasks = tasks?.toSorted((a: any, b: any) => {
        if (a.id < b.id) {
            return -1
        } else {
            return 1
        }
    })

    return (
        <div className='flex flex-col gap-[8px] text-text1 dark:text-darktext1 h-[89vh]'>
            {!orderId && <div className='h-[120px]'></div>}
            {orderId && (
                <OrderHeader
                    orderId={orderId}
                    orderEventsPrivate={draftOrderEventsPrivate}
                    order={draftOrder}
                    tasks={tasks?.filter((t: any) => !!(t as TaskEditInit).id) as TaskEditInit[]}
                    addNewTask={toggleCreateModal}
                    showActivity={showActivity}
                    setShowActivity={setShowActivity}
                    eventSource={eventSource}
                    onEmailSubmit={onEmailSubmit}
                    origin='draft_order'
                />
            )}
            {/* Info columns */}
            {draftOrderQuery.isSuccess && draftOrder?.id && (
                <div className='grid lg:grid-cols-3 grid-cols-2 gap-[4px] items-start justify-start h-full min-h-1'>
                    <div
                        className={cn(
                            'grid grid-cols-subgrid items-start gap-[8px] basis-[calc(33.3%-32px)] grow min-w-[400px] p-1 max-h-full overflow-scroll md:max-lg:row-span-2',
                            !showActivity && 'col-span-2'
                        )}
                    >
                        <div className='flex flex-col gap-2'>
                            {draftOrderQuery.isSuccess && draftOrder?.id && (
                                <React.Fragment>
                                    <DraftOrderOverView order={draftOrder} events={events} />
                                </React.Fragment>
                            )}
                            <Customer
                                key={draftOrder?.customer.id}
                                order={draftOrder}
                                eventSource={eventSource}
                                onEmailSubmit={onEmailSubmit}
                            />
                        </div>

                        <div className='flex flex-col gap-2'>
                            <Tags tags={draftOrderTags} />
                        </div>
                    </div>
                    {/* middle column  */}
                    {draftOrder?.id && showActivity && (
                        <div className='flex flex-col gap-[8px] basis-[calc(33%-32px)] min-w-[400px] p-1 grow shrink max-h-full overflow-x-scroll col-span-1 lg:col-span-1'>
                            <Events
                                events={events}
                                tasks={tasks}
                                order={draftOrder}
                                eventsLoaded={eventsLoaded}
                                type='draftOrders'
                            />
                        </div>
                    )}

                    {/* right column */}
                    <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start pr-[2px]  max-h-full overflow-scroll md:max-lg:col-start-2'>
                        <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                            <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                                {Object.keys(tabs.middle).map((tab, index) => {
                                    const count =
                                        tab === 'tasks'
                                            ? draftOrder?.task_count || tabs.middle[tab]?.length || 0
                                            : tabs.middle[tab]?.length || 0
                                    return (
                                        <button
                                            key={index}
                                            className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                                selectedTab === tab
                                                    ? 'bg-white dark:bg-darkness z-index-1'
                                                    : '!border-transparent'
                                            } rounded-t px-[4px] capitalize`}
                                            onClick={() => setSelectedTab(tab)}
                                        >
                                            {tab} ({tabs.middle[tab]?.length || 0})
                                        </button>
                                    )
                                })}
                            </div>
                            <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px] h-full'>
                                {selectedTab === 'tasks' && (
                                    <div className='flex flex-col gap-[8px]'>
                                        {tasks?.length > 0 &&
                                            sortedTasks
                                                ?.filter((task: any) => task.status != 'Archived')
                                                ?.map((task: any, i: number) => (
                                                    <React.Fragment key={task.id}>
                                                        <TaskEditCreateModalV2
                                                            createEvent={true}
                                                            taskId={task.id.toString()}
                                                        />
                                                    </React.Fragment>
                                                ))}
                                        <div className='flex justify-start items-center gap-[8px]'>
                                            <Button
                                                variant={'outline'}
                                                size={'sm'}
                                                onClick={() => toggleCreateModal()}
                                                className='flex gap-1 items-center'
                                            >
                                                <p>{CREATE_TASK_BUTTON_CONTENT}</p>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!draftOrderQuery.isSuccess && !draftOrder?.id && (
                <OrderHubSkeletonBody tabs={tabs} events={events} showActivity={showActivity} />
            )}

            {showCreateModal && draftOrder?.id && (
                <CreateTaskModal
                    prePopulatedValues={{
                        department_id: departments?.find((department: any) => department.title === 'Sales').id,
                        priority: 'Low',
                        status: 'To Do',
                        type_id: types?.find((type: any) => type.value === 'Lead').id,
                        associations: [
                            {
                                resource_name: 'user',
                                readable_name: `${user.firstName} ${user.lastName}`,
                                resource_id: user.id,
                            },
                            {
                                resource_name: 'draft_order',
                                readable_name: `${draftOrder?.name}`,
                                resource_id: draftOrder.id,
                            },
                        ],
                    }}
                    closeModal={toggleCreateModal}
                />
            )}
        </div>
    )
}
