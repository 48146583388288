import { useContext } from 'react'
import { FaFile, FaTasks } from 'react-icons/fa'
import { FaPerson, FaRegNoteSticky } from 'react-icons/fa6'
import { cn } from '../../helpers'
import { CompanyContext, ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useSearchParams } from 'react-router-dom'

export default function VendorQuickActions({ resourceView }: any) {
    const { selectedCompany, toggleModal } = useContext<ProcurementContextType>(ProcurementContext)
    const { showQuickModal, setShowQuickModal } = useContext<any>(CompanyContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    return (
        <>
            <div
                className={cn(
                    'sticky top-0 bg-white dark:bg-darkaccent z-40 flex items-center gap-1 pb-[16px] border-b border-lightgrey dark:border-darkgrey ',
                    (resourceView === 'contacts' || resourceView === 'unfulfilled orders') &&
                        'pb-0 border-none mt-[1px] mb-[-1px] mr-2 '
                )}
            >
                <>
                    <button
                        onClick={() => {
                            return showQuickModal === 'note' ? setShowQuickModal(undefined) : setShowQuickModal('note')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaRegNoteSticky className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite overflow-visible' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Note</p>
                    </button>
                </>
                <button
                    onClick={() => {
                        toggleCreateModal()
                    }}
                    className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all overflow-visible mr-[8px]'
                >
                    <FaTasks className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite overflow-visible' />
                    <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                </button>
                <>
                    <button
                        onClick={() => {
                            return showQuickModal === 'contact'
                                ? setShowQuickModal(undefined)
                                : setShowQuickModal('contact')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaPerson className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Contact</p>
                    </button>
                    <button
                        onClick={() => {
                            return showQuickModal === 'file' ? setShowQuickModal(undefined) : setShowQuickModal('file')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaFile className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>File</p>
                    </button>
                </>
            </div>
            {resourceView !== 'contacts' && resourceView !== 'unfulfilled orders' && (
                <>
                    {selectedCompany.parent_company && (
                        <div className='pb-[8px] border-b border-lightgrey dark:border-darkgrey'>
                            <button
                                className='font-bold uppercase text-[14px] leading-[1] py-[4px] px-[8px] w-full rounded-[4px] border border-blue text-blue dark:border-fire dark:text-fire'
                                onClick={() => toggleModal('parent', selectedCompany)}
                            >
                                View Parent Company
                            </button>
                        </div>
                    )}
                    <div>
                        <p className='font-bold'>Products Listed: {selectedCompany.product_count}</p>
                    </div>
                </>
            )}
        </>
    )
}
