import { useState } from 'react'
import { FirstRiskEventInit, OrderInit } from '../orders.types'

type FraudAnalysisProps = {
    order: OrderInit
}

export default function FraudAnalysisV2({ order }: FraudAnalysisProps) {
    const [showFullAnalysis, setShowFullAnalysis] = useState<keyof FirstRiskEventInit | undefined>()

    const shopifyRiskAssessment = order.risk.assessments.find((assessment) => assessment.provider === null)
    const noFraudRiskAssessment = order.risk.assessments.find(
        (assessment) => assessment.provider?.title === 'NoFraud Fraud Protection'
    )

    const selectedFullAnalysis =
        showFullAnalysis === 'Shopify'
            ? shopifyRiskAssessment
            : showFullAnalysis === 'NoFraud'
              ? noFraudRiskAssessment
              : undefined

    return (
        <>
            {showFullAnalysis && (
                <div
                    id='fraud-modal'
                    className='flex flex-col items-center fixed w-[calc(100%-216px)] h-[calc(100%-50px)] left-[216px] top-[50px] duration-300 dark:text-offwhite bg-[rgba(0,0,0,0.2)] z-50'
                    onClick={(event) =>
                        (event.target as HTMLElement).id === 'fraud-modal' && setShowFullAnalysis(undefined)
                    }
                >
                    <div className='flex flex-col min-w-[450px] w-[60%] max-h-[100%] overflow-auto my-[32px] py-[32px] shadow-small bg-white dark:bg-darkaccent rounded [&>*]:border-b-[1px] dark:[&>*]:border-darkgrey [&>*:last-child]:border-0'>
                        <div className='relative px-[32px]'>
                            <h2 className='text-[24px] font-bold pb-[16px]'>Fraud analysis</h2>
                            <button
                                className='absolute right-[8px] top-[-24px] text-[32px] hover:bg-offwhite dark:hover:bg-darkgrey w-[32px] h-[32px] rounded'
                                onClick={() => setShowFullAnalysis(undefined)}
                            >
                                <span className='absolute top-[-12px] right-[8px]'>x</span>
                            </button>
                        </div>
                        <div className='flex gap-[32px] px-[32px] text-[16px] items-center min-h-[60px]'>
                            <div
                                className={`grid h-[100%] px-[16px] items-center cursor-pointer border-b-[3px] border-[rgba(0,0,0,0)] hover:dark:border-grey ${
                                    showFullAnalysis === 'Shopify' && 'font-semibold border-b-[3px] dark:border-accent'
                                }`}
                                onClick={() => {
                                    setShowFullAnalysis('Shopify')
                                }}
                            >
                                <span>Shopify</span>
                            </div>
                            <div
                                className={`grid h-[100%] px-[16px] items-center cursor-pointer border-b-[3px] border-[rgba(0,0,0,0)] hover:dark:border-grey ${
                                    showFullAnalysis === 'NoFraud' && 'font-semibold border-b-[3px] dark:border-accent'
                                }`}
                                onClick={() => {
                                    setShowFullAnalysis('NoFraud')
                                }}
                            >
                                <span>NoFraud Fraud Protection</span>
                            </div>
                        </div>
                        <div className='pb-[16px] pt-[8px]'>
                            <div className='flex w-[calc(100%-64px)] m-auto rounded-full justify-between bg-[lightgrey] dark:bg-grey h-[10px] my-[8px]'>
                                <div
                                    className={`w-[33%] h-[100%] rounded-full ${
                                        selectedFullAnalysis?.riskLevel === 'LOW' && 'bg-[#2aacbb] dark:bg-accent'
                                    }`}
                                ></div>
                                <div
                                    className={`w-[33%] h-[100%] rounded-full ${
                                        selectedFullAnalysis?.riskLevel === 'MEDIUM' && 'bg-[#b77e0b]'
                                    }`}
                                ></div>
                                <div
                                    className={`w-[33%] h-[100%] rounded-full ${
                                        selectedFullAnalysis?.riskLevel === 'HIGH' && 'bg-[#da0000]'
                                    }`}
                                ></div>
                            </div>
                            <div className='flex justify-around'>
                                <span
                                    className={`${
                                        selectedFullAnalysis?.riskLevel === 'LOW' && 'text-[#2aacbb] dark:text-accent'
                                    }`}
                                >
                                    LOW
                                </span>
                                <span
                                    className={`${
                                        selectedFullAnalysis?.riskLevel === 'MEDIUM' &&
                                        'text-[#b77e0b] dark:text-[#ffca60]'
                                    }`}
                                >
                                    MEDIUM
                                </span>
                                <span className={`${selectedFullAnalysis?.riskLevel === 'HIGH' && 'text-[#da0000]'}`}>
                                    HIGH
                                </span>
                            </div>
                        </div>
                        <div className='mx-[32px] my-[16px] text-[14px]'>
                            <h3 className='text-[16px] font-semibold'>Indicators</h3>
                            {selectedFullAnalysis?.facts.map((fact, i) => {
                                return (
                                    <div
                                        key={`order__event-${i}`}
                                        className='flex gap-[16px] my-[16px] items-center border-none'
                                    >
                                        <div className='grid items-center'>
                                            <div
                                                className={`rounded-full border-[4px] ${
                                                    fact.sentiment === 'NEGATIVE'
                                                        ? 'border-[#da0000]'
                                                        : fact.sentiment === 'NEUTRAL'
                                                          ? 'border-grey'
                                                          : 'border-[#16a679]'
                                                }`}
                                            ></div>
                                        </div>
                                        <span>{fact.description}</span>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='mx-[32px] grid gap-[8px]'>
                            <h3 className='text-[16px] font-semibold'>Additional Information</h3>
                            {order.browser_ip && (
                                <span>This order was placed from an IP address {order.browser_ip}</span>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                <div className='grid gap-[16px]'>
                    <h2 className='text-[20px] font-bold mb-[4px]'>Fraud Analysis</h2>
                    <div className='relative'>
                        <h3 className='text-[14px] font-bold'>Shopify</h3>
                        <div className='flex rounded-full justify-between bg-[lightgrey] dark:bg-grey h-[10px] my-[8px]'>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    shopifyRiskAssessment?.riskLevel === 'LOW' && 'bg-[#2aacbb] dark:bg-accent'
                                }`}
                            ></div>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    shopifyRiskAssessment?.riskLevel === 'MEDIUM' && 'bg-[#b77e0b]'
                                }`}
                            ></div>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    shopifyRiskAssessment?.riskLevel === 'HIGH' && 'bg-[#da0000]'
                                }`}
                            ></div>
                        </div>
                        <div className='flex justify-around'>
                            <span
                                className={`${
                                    shopifyRiskAssessment?.riskLevel === 'LOW' && 'text-[#2aacbb] dark:text-accent'
                                }`}
                            >
                                LOW
                            </span>
                            <span
                                className={`${
                                    shopifyRiskAssessment?.riskLevel === 'MEDIUM' &&
                                    'text-[#b77e0b] dark:text-[#ffca60]'
                                }`}
                            >
                                MEDIUM
                            </span>
                            <span className={`${shopifyRiskAssessment?.riskLevel === 'HIGH' && 'text-[#da0000]'}`}>
                                HIGH
                            </span>
                        </div>
                        <div className='flex gap-[16px] my-[16px] items-center'>
                            <div className='grid items-center'>
                                <div
                                    className={`rounded-full border-[4px] ${
                                        shopifyRiskAssessment?.facts[0].sentiment === 'NEGATIVE'
                                            ? 'border-[#da0000]'
                                            : shopifyRiskAssessment?.facts[0].sentiment === 'NEUTRAL'
                                              ? 'border-grey'
                                              : 'border-[#16a679]'
                                    }`}
                                ></div>
                            </div>
                            <span>{shopifyRiskAssessment?.facts[0].description}</span>
                        </div>
                    </div>
                </div>
                <div className='grid gap-[16px]'>
                    <div className='relative'>
                        <h3 className='text-[14px] font-bold'>NoFraud Fraud Protection</h3>
                        <div className='flex rounded-full justify-between bg-[lightgrey] dark:bg-grey h-[10px] my-[8px]'>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    noFraudRiskAssessment?.riskLevel === 'LOW' && 'bg-[#2aacbb] dark:bg-accent'
                                }`}
                            ></div>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    noFraudRiskAssessment?.riskLevel === 'MEDIUM' && 'bg-[#b77e0b]'
                                }`}
                            ></div>
                            <div
                                className={`w-[33%] h-[100%] rounded-full ${
                                    noFraudRiskAssessment?.riskLevel === 'HIGH' && 'bg-[#da0000]'
                                }`}
                            ></div>
                        </div>
                        <div className='flex justify-around'>
                            <span
                                className={`${
                                    noFraudRiskAssessment?.riskLevel === 'LOW' && 'text-[#2aacbb] dark:text-accent'
                                }`}
                            >
                                LOW
                            </span>
                            <span
                                className={`${
                                    noFraudRiskAssessment?.riskLevel === 'MEDIUM' &&
                                    'text-[#b77e0b] dark:text-[#ffca60]'
                                }`}
                            >
                                MEDIUM
                            </span>
                            <span className={`${noFraudRiskAssessment?.riskLevel === 'HIGH' && 'text-[#da0000]'}`}>
                                HIGH
                            </span>
                        </div>
                        <div className='flex gap-[16px] my-[16px] items-center'>
                            <div className='grid items-center'>
                                <div
                                    className={`rounded-full border-[4px] ${
                                        noFraudRiskAssessment?.facts[0].sentiment === 'NEGATIVE'
                                            ? 'border-[#da0000]'
                                            : noFraudRiskAssessment?.facts[0].sentiment === 'NEUTRAL'
                                              ? 'border-grey'
                                              : 'border-[#16a679]'
                                    }`}
                                ></div>
                            </div>
                            <span>{noFraudRiskAssessment?.facts[0].description}</span>
                        </div>
                    </div>
                </div>
                <span
                    className='text-blue dark:text-accent hover:underline cursor-pointer'
                    onClick={() => {
                        setShowFullAnalysis('Shopify')
                    }}
                >
                    View full analysis
                </span>
            </div>
        </>
    )
}
