import { Dispatch, SetStateAction, useEffect } from 'react'

export default function Paginate({
    items,
    page,
    setPage,
    pageMap,
    setPageMap,
    refetch,
}: {
    items: any[]
    page: number
    setPage: Dispatch<SetStateAction<number>>
    pageMap: any
    setPageMap: Dispatch<SetStateAction<any>>
    refetch: Function
}) {
    const handleDecrementPage = () => {
        if (page > 1) {
            refetch({ after: pageMap[page - 1] })
            setPage((previousState) => previousState - 1)
        }
    }
    const handleIncrementPage = () => {
        if (items.length === 50) {
            setPage((previousState) => previousState + 1)
            refetch({ after: pageMap[page + 1] })
        }
    }

    useEffect(() => {
        setPageMap({
            ...pageMap,
            [page + 1]: (items[items.length - 1] || {}).id || Number.MAX_SAFE_INTEGER,
        })
    }, [items])

    return (
        <div className='flex gap-[8px] items-center justify-center w-full my-[16px]'>
            <button
                className='py-[4px] px-[8px] font-bold bg-blue text-white dark:bg-fire dark:text-darkness rounded-[4px]'
                onClick={handleDecrementPage}
            >
                PREV
            </button>
            <div className='w-[32px] h-[32px] rounded-[4px] border border-lightgrey dark:border-darkgrey dark:text-offwhite grid place-items-center'>
                {page}
            </div>
            <button
                className='py-[4px] px-[8px] font-bold bg-blue text-white dark:bg-fire dark:text-darkness rounded-[4px]'
                onClick={handleIncrementPage}
            >
                NEXT
            </button>
        </div>
    )
}
