import { CheckCircledIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import * as React from 'react'
import { DateRange } from 'react-day-picker'
import { CalendarBaseSelector } from '../../custom_components/component_Basics/CalendarBaseSelector'
import { RangeSelectorPopover } from './RangeSelectorPopover'
import { COMPARE_PICKER_OPTIONS } from '../helpers'
import { useSearchParams } from 'react-router-dom'

// Do not change order - compareOptions
const compareOptions = ['No selection', 'Previous period', 'Previous year']

export function getCompareDateRange(
    date: DateRange | undefined,
    period: any,
    customCompareDate: DateRange | undefined,
    selectedCompareIndex: number | undefined
) {
    if (customCompareDate) {
        return customCompareDate
    }
    if (selectedCompareIndex === 1) {
        return handlePreviousPeriod(date, period)
    }
    if (selectedCompareIndex === 2) {
        return handlePreviousYear(date)
    }
}

function handlePreviousPeriod(date: DateRange | undefined, period: any) {
    if (!date?.from || !date.to) {
        return
    }
    if (period.value && period.function) {
        const [fromValue, toValue] = period.value?.split(',')
        const dateFunction = period.function
        return {
            from: dateFunction(date.from, parseInt(fromValue)),
            to: dateFunction(date.to, parseInt(fromValue)),
        }
    }
}
function handlePreviousYear(date: DateRange | undefined) {
    if (!date?.from || !date.to) {
        return
    }
    let from = new Date(date?.from)
    let to = new Date(date?.to)
    from.setFullYear(from.getFullYear() - 1)
    to.setFullYear(to.getFullYear() - 1)
    return {
        from,
        to,
    }
}
export function DatePickerPresetsCompare({
    date,
    period,
    customCompareDate,
    setCustomCompareDate,
}: {
    date: DateRange | undefined
    period: { value: string | undefined; function: Function | undefined }
    customCompareDate: DateRange | undefined
    setCustomCompareDate: React.Dispatch<React.SetStateAction<any>>
}) {
    let [searchParams, setSearchParams] = useSearchParams()
    const compareIndexParam: string = searchParams.get('compare') || '0'
    const [selectedCompareIndex, setSelectedCompareIndex] = React.useState<number>(parseInt(compareIndexParam))
    const rangeString = COMPARE_PICKER_OPTIONS[selectedCompareIndex]?.label

    let compareDate = getCompareDateRange(date, period, customCompareDate, selectedCompareIndex)

    function handleSelectionChange(index: number) {
        setSearchParams((prev: any) => {
            prev.set('compare', index.toString())
            return prev
        })
        setCustomCompareDate(undefined)
        setSelectedCompareIndex(index)
    }

    function CalendarProp() {
        return (
            <CalendarBaseSelector
                initialFocus
                mode='range'
                selectedRange={date}
                defaultMonth={compareDate?.from}
                setSelectedCompare={setSelectedCompareIndex}
                selected={compareDate}
                onSelect={setCustomCompareDate}
                numberOfMonths={2}
                showOutsideDays={false}
            />
        )
    }

    function ButtonContent() {
        return (
            <>
                {selectedCompareIndex === 0 ? '' : 'Compare to: '}
                {rangeString ? (
                    <>{rangeString}</>
                ) : compareDate?.from ? (
                    compareDate.to ? (
                        <>
                            {format(compareDate.from, 'LLL dd')} - {format(compareDate.to, 'LLL dd, y')}
                        </>
                    ) : (
                        format(compareDate.from, 'LLL dd, y')
                    )
                ) : (
                    ''
                )}
            </>
        )
    }

    return (
        <>
            <RangeSelectorPopover ButtonContent={ButtonContent} CalendarProp={CalendarProp}>
                {compareOptions.map((option, index) => {
                    return (
                        <button
                            key={option}
                            className='p-1 rounded-[4px] flex w-full hover:bg-lightgrey dark:hover:bg-darkbg2'
                            onClick={() => handleSelectionChange(index)}
                        >
                            {option}
                            <CheckCircledIcon className='ml-auto self-center' />
                        </button>
                    )
                })}
            </RangeSelectorPopover>
        </>
    )
}
