import { useRef } from 'react'
import { useAllUsersQuery } from 'users/api/useQueries'
import { formatDateToLocale } from '../../helpers'
import { UserInit } from '../../users/users.types'
import { taskVariables } from 'tasks/TaskVariables'

export const TaskLatestActivity = ({ index, event }: any) => {
    const noteRef = useRef<HTMLDivElement>(null)
    const { taskTypeColors }: any = taskVariables

    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []

    const foundUser = users?.find((u) => u.id === event.user_id)
    const authorName = foundUser ? `${foundUser.first_name} ${foundUser.last_name}` : `Unknown Author: ${event.author}`

    function From() {
        return (
            <div>
                <h4 className={`text-[18px] font-bold ${taskTypeColors[event.new_value.department_title ?? '']} `}>
                    {event.new_value.department_title} #{event?.new_value?.id || 0}
                </h4>
                <p className='text-[14px] text-darkgrey dark:text-grey'>{authorName}</p>
            </div>
        )
    }
    function Timestamp() {
        const created_at = new Date(event.created_at)
        return (
            <div className='text-[13px] text-end mt-[4px]'>
                <p>{[created_at.getMonth() + 1, created_at.getDate(), created_at.getFullYear()].join('/')}</p>
                <p className='text-darkgrey dark:text-grey'>
                    {formatDateToLocale(created_at).split(' ').slice(-2).join(' ')}
                </p>
            </div>
        )
    }
    const preStyle = 'whitespace-pre-wrap font-normal font-bai'

    return (
        <div className={`text-black dark:text-offwhite flex gap-[8px] items-center grow orderNote-${index}`}>
            <div
                className='w-[100%] p-[8px] border-[1px] dark:border-darkgrey bg-white dark:bg-darkness dark:text-offwhite shadow-md rounded'
                ref={noteRef}
            >
                <div className='flex justify-between mb-[8px]'>
                    <From />
                    <div className='flex gap-[8px] duration-200'>{<Timestamp />}</div>
                </div>

                <pre style={{ wordBreak: 'break-word' }} className={preStyle}>
                    {event.new_value.title}
                </pre>
            </div>
        </div>
    )
}
