import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn, useDebounce } from 'helpers'
import { forwardRef, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const UserLoginsScreenSearchContainer = forwardRef<any, any>(
    function UserLoginsScreenSearchContainer(props, ref) {
        const [searchParams, setSearchParams] = useSearchParams()
        const searchParam = searchParams.get('search') || ''
        const [search, setSearch] = useState(searchParam)
        const [initRender, setInitRender] = useState(true)
        const debouncedSearch = useDebounce(search.trim(), 300)

        const handleClearSearch = () => {
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', '1')
                prev.delete('search')
                return prev
            })
            setSearch('')
        }

        useEffect(() => {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }, [debouncedSearch])
        useEffect(() => {
            if (initRender) {
                setInitRender(false)
            }
        }, [])
        useEffect(() => {
            if (!initRender) {
                setSearch(searchParam)
            }
        }, [searchParam])

        return (
            <div
                className={cn(
                    'w-full border h-fit  border-lightgrey  dark:border-darkgrey items-center rounded-md dark:bg-darkaccent max-w-[500px]'
                )}
            >
                <div className='w-full flex items-center h-fit  relative px-1 '>
                    <input
                        ref={ref}
                        type='text'
                        className='w-full bg-transparent dark:text-white focus:outline-none px-2'
                        placeholder={'Search'}
                        value={search}
                        onChange={({ target }) => {
                            setSearch(target.value)
                        }}
                    />
                    <div className='self-center py-[2px]'>
                        <Button
                            disabled={search ? false : true}
                            variant='ghost'
                            onClick={handleClearSearch}
                            className={`${cn(
                                !search && 'text-darkgrey dark:text-darkgrey opacity-50 bg-bg1 dark:bg-darkbg1',
                                search && 'text-danger'
                            )} h-6 px-1 flex items-center `}
                        >
                            <Cross2Icon className='h-4 w-4' />
                        </Button>
                    </div>
                </div>
                <div className='relative shrink-0 flex gap-[8px] justify-end items-center'></div>
            </div>
        )
    }
)
