import { useEffect, useState } from 'react'
import { LoadingGear, useDebounce, vFetch } from '../helpers'
import MoreActions from '../processingTimes/components/MoreActions'
import Refresh from '../processingTimes/components/Refresh'
import AS2Stations from './components/AS2Stations'
import StationModal from './components/StationModal'
import { EDIAcknowledgementType, EDIMessageType, StationType } from './EDIScreens.types'
import Messages from './components/Messages'
import Paginate from './components/Paginate'
import MessageFilters from './components/MessageFilters'
import FunctionalAcknowledgements from './components/FunctionalAcknowledgements'
import RawEDIModal from './components/RawEDIModal'

export default function EDIScreen() {
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [stations, setStations] = useState<StationType[]>([])
    const [messages, setMessages] = useState<EDIMessageType[]>([])
    const [acknowledgements, setAcknowledgements] = useState<EDIAcknowledgementType[]>([])
    const [page, setPage] = useState(1)
    const [pageMap, setPageMap] = useState<any>({})
    const [view, setView] = useState('station')
    const [showModal, setShowModal] = useState<string | undefined>(undefined)
    const [search, setSearch] = useState('')
    const [filter, SetFilter] = useState('')
    const [loading, setLoading] = useState(true)
    const [selectedStation, setSelectedStation] = useState<StationType | undefined>(undefined)
    const [selectedEDI, setSelectedEDI] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const items: ItemsType = {
        stations,
        messages,
        acknowledgements: acknowledgements,
    }
    const paginateItems = items[(view + 's') as keyof ItemsType]

    const fetchStations = async () => {
        setLoading(true)
        vFetch('/v2/edi/stations', {
            cb: (res: any) => {
                if (res.success) {
                    setStations(res.stations || [])
                }
            },
        }).then(() => {
            setLoading(false)
            setNeedsRefresh(false)
        })
    }

    const fetchMessages = async ({ order_name, transaction_type, after, before }: any = {}) => {
        setLoading(true)
        const query = [
            order_name ? `order_name=${order_name}` : null,
            transaction_type ? `transaction_type=${transaction_type}` : null,
            after ? `after=${after}` : null,
            before ? `before=${before}` : null,
        ]
            .filter((v) => v)
            .join('&')

        vFetch(`/v2/edi/messages${query.length ? `?${query}` : ''}`, {
            cb: (res: any) => {
                if (res.success) {
                    setMessages(res.messages || [])
                }
            },
        }).then(() => {
            setLoading(false)
            setNeedsRefresh(false)
        })
    }

    const fetchAcknowledgements = async ({ acknowledged_functional_group, after, before }: any = {}) => {
        setLoading(true)
        const query = [
            acknowledged_functional_group ? `acknowledged_functional_group=${acknowledged_functional_group}` : null,
            after ? `after=${after}` : null,
            before ? `before=${before}` : null,
        ]
            .filter((v) => v)
            .join('&')

        vFetch(`/v2/edi/acknowledgements${query.length ? `?${query}` : ''}`, {
            cb: (res: any) => {
                if (res.success) {
                    setAcknowledgements(res.acknowledgements || [])
                }
            },
        }).then(() => {
            setLoading(false)
            setNeedsRefresh(false)
        })
    }

    const refetch: RefetchMapType = {
        stations: fetchStations,
        messages: fetchMessages,
        acknowledgements: fetchAcknowledgements,
    }

    useEffect(() => {
        if (view === 'message') {
            setPage(1)
            setPageMap({})
            fetchMessages({ order_name: debouncedSearch, transaction_type: filter })
        }
        if (view === 'acknowledgement') {
            setPage(1)
            setPageMap({})
            fetchAcknowledgements({ acknowledged_functional_group: debouncedSearch, transaction_type: filter })
        }
    }, [debouncedSearch, filter])

    useEffect(() => {
        if (needsRefresh) {
            if (view === 'station') {
                setPage(1)
                setPageMap({})
                fetchStations()
            }
            if (view === 'message') {
                setPage(1)
                setPageMap({})
                fetchMessages()
            }
            if (view === 'acknowledgement') {
                setPage(1)
                setPageMap({})
                fetchAcknowledgements()
            }
        }
    }, [needsRefresh, view])
    return (
        <div>
            <div className='flex justify-between mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>EDI {view}s</h1>
                <div className='flex gap-[8px]'>
                    <MoreActions options={[]} />
                    <Refresh setNeedsRefresh={setNeedsRefresh} />
                </div>
            </div>
            <div className='flex gap-[4px] mb-[8px]'>
                <button
                    onClick={() => {
                        setLoading(true)
                        setView('station')
                        setNeedsRefresh(true)
                    }}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'station'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Stations
                </button>
                <button
                    onClick={() => {
                        setLoading(true)
                        setView('message')
                        setNeedsRefresh(true)
                    }}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'message'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Messages
                </button>
                <button
                    onClick={() => {
                        setLoading(true)
                        setView('acknowledgement')
                        setNeedsRefresh(true)
                    }}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'acknowledgement'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Functional Acknowledgements
                </button>
                {view !== 'order' && (
                    <button
                        onClick={() => setShowModal(view)}
                        className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                    >
                        + New {view[0].toUpperCase() + view.substring(1)}
                    </button>
                )}
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                    <div className='w-full p-[8px] flex'>
                        <input
                            type='text'
                            className='w-full bg-transparent dark:text-white focus:outline-none'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => {
                                setSearch(target.value)
                            }}
                        />
                        {view === 'message' && (
                            <MessageFilters
                                filter={filter}
                                setFilter={SetFilter}
                                search={search}
                                setSearch={setSearch}
                            />
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className='w-full h-[40vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        {view === 'station' && (
                            <AS2Stations
                                stations={stations}
                                setSelectedStation={setSelectedStation}
                                setShowModal={setShowModal}
                            />
                        )}
                        {view === 'message' && (
                            <>
                                <Messages
                                    messages={messages}
                                    setSelectedEDI={setSelectedEDI}
                                    setShowModal={setShowModal}
                                />
                            </>
                        )}
                        {view === 'acknowledgement' && (
                            <>
                                <FunctionalAcknowledgements
                                    messages={acknowledgements}
                                    setSelectedEDI={setSelectedEDI}
                                    setShowModal={setShowModal}
                                />
                            </>
                        )}
                    </>
                )}
            </div>
            {paginateItems && (
                <Paginate
                    items={paginateItems}
                    page={page}
                    setPage={setPage}
                    pageMap={pageMap}
                    setPageMap={setPageMap}
                    refetch={refetch[(view + 's') as keyof RefetchMapType]}
                />
            )}
            {showModal === 'station' && (
                <StationModal
                    station={selectedStation || { name: '' }}
                    setSelectedStation={setSelectedStation}
                    setNeedsRefresh={setNeedsRefresh}
                    setShowModal={setShowModal}
                />
            )}
            {showModal === 'rawEDI' && (
                <RawEDIModal rawEDI={selectedEDI} setShowModal={setShowModal} setSelectedEDI={setSelectedEDI} />
            )}
        </div>
    )
}

type ItemsType = {
    stations: StationType[]
    messages: EDIMessageType[]
    acknowledgements: EDIAcknowledgementType[]
}

type RefetchMapType = {
    stations: Function
    messages: Function
    acknowledgements: Function
}
