import * as service from "./service"
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import developmentQueryKeys from "./queryKeys";

export const useTasks = () => {
    return useQuery({
        ...developmentQueryKeys.tasks()
    })
}

export const useTickets = (filters: any) => {
    return useQuery({
        ...developmentQueryKeys.tickets(filters),
        placeholderData: keepPreviousData,
    })
}

export const useCreateTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.createTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tasks._def })
        },
    })
}

export const useCreateTicket = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.createTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tickets._def })
        },
    })
}

export const useUpdateTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.updateTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tasks._def })
        },
    })
}

export const useUpdateTicket = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.updateTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tickets._def })
        },
    })
}

export const useDeleteTask = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.deleteTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tasks._def })
        },
    })
}

export const useDeleteTicket = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.deleteTask,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.tickets._def })
        },
    })
}

export const useProjects = () => {
    return useQuery({
        ...developmentQueryKeys.projects()
    })
}

export const useUpdateProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.updateProject,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.projects._def })
        },
    })
}

export const useDeleteProject = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.deleteProject,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.projects._def })
        },
    })
}

export const useRoadmaps = () => {
    return useQuery({
        ...developmentQueryKeys.roadmaps()
    })
}

export const useUpdateRoadmap = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.updateRoadmap,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.roadmaps._def })
        },
    })
}

export const useDeleteRoadmap = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: service.deleteRoadmap,
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: developmentQueryKeys.roadmaps._def })
        },
    })
}