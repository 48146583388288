import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { useSelector } from 'react-redux'
import queryKeys from 'tasksV2/api/queryKeys'
import { allowedToPin } from 'tasksV2/constants/tasks'

export default function PinnedEscalatedIcons({
    taskId,
    mutation,
    pinned,
    is_escalated,
}: {
    taskId: string
    mutation: any
    pinned: any
    is_escalated: any
}) {
    const user = useSelector<any, any>((state) => state.user)
    const queryClient = useQueryClient()

    const handleChangePinned = () => {
        if (mutation.isPending && mutation.variables.pinned) {
            return
        }
        queryClient.setQueryData(queryKeys.tasks.detail(taskId || '').queryKey, (oldData: any) => {
            if (oldData) {
                return {
                    ...oldData,
                    task: { ...oldData.task, user_pinned: pinned ? 0 : 1 },
                }
            }
        })
        mutation.mutate({
            id: taskId,
            pinned: pinned ? 0 : 1,
        })
    }

    return (
        <div className='flex gap-1 text-xl'>
            {(user.type.includes('Admin') || allowedToPin.includes(user.id)) && (
                <Button
                    size='sm'
                    variant='outline'
                    className='h-fit leading-none p-1 dark:border-darkgrey'
                    title={pinned === 1 ? 'Task is pinned' : 'Pin Task'}
                    onClick={handleChangePinned}
                >
                    {pinned === 1 ? 'Unpin' : 'Pin'}
                </Button>
            )}
        </div>
    )
}
