import { MetafieldProps } from '../ProductSetup.types'
import ProductMetafieldImporter from '../components/ProductMetafieldImporter'

export default function Warranty({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const handleChange = ({ target }: { target: HTMLTextAreaElement }) => {
        setProduct((previousState) => ({
            ...previousState,
            warranty: target.value.replaceAll('\n', ''),
        }))
    }
    const handleSwap = (metafieldString: string) => {
        setProduct((previousState) => ({
            ...previousState,
            warranty: metafieldString,
        }))
    }
    return (
        <>
            <div className='w-full h-fit sticky top-[66px] dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Warranty
                </p>
                <ProductMetafieldImporter namespace='custom' metafieldKey='warranty' handleSetField={handleSwap} />
                <textarea
                    className='w-full block max-w-none border-none p-[8px] rounded-[4px] dark:text-white bg-lightgrey dark:bg-faintplus focus:outline-none'
                    spellCheck='true'
                    name='package-contents'
                    onChange={handleChange}
                    value={product.warranty}
                ></textarea>
                {edited && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                        onClick={() =>
                            handleUpdateMetafield([
                                {
                                    ownerId: `gid://shopify/Product/${product.id}`,
                                    namespace: 'custom',
                                    key: 'warranty',
                                    type: 'single_line_text_field',
                                    value: product.warranty,
                                },
                            ])
                        }
                    >
                        Save Changes
                    </button>
                )}
            </div>
            {product.warranty && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>Warranty Preview</h2>
                    <div className='bg-white description-preview dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__warranty__authorized'>
                            <div className='pdp__warranty__authorized__row'>
                                <div className='pdp__warranty__authorized__row__image-container'>
                                    <img
                                        className='pdp__warranty__authorized__row__image-container__img'
                                        src='https://cdn.vectorstock.com/i/1000x1000/06/00/vendor-rubber-stamp-vector-12410600.webp'
                                        alt='vendor logo'
                                        width='auto'
                                        height='auto'
                                        loading='lazy'
                                    />
                                </div>
                                <img
                                    className='pdp__warranty__authorized__row__divider'
                                    src='https://cdn.shopify.com/s/files/1/1163/1976/files/warranty-line.png?v=1605396259'
                                    alt='divider'
                                    width='2'
                                    height='auto'
                                    loading='lazy'
                                />
                                <div className='pdp__warranty__authorized__row__image-container'>
                                    <img
                                        className='pdp__warranty__authorized__row__image-container__img'
                                        src='https://cdn.shopify.com/s/files/1/1163/1976/files/authorized-dealer-stacked.png?v=1605396129'
                                        alt='authorized dealer'
                                        width='auto'
                                        height='auto'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                            <p id='warranty'>{product.warranty}</p>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
