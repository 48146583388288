import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import { TbLetterR } from 'react-icons/tb'
import SelectedReturnsActions from './components/selectedReturnsActions'
import { NavLink, useNavigate } from 'react-router-dom'
import {
    closePopout,
    notIntegerRegex,
    parseResObject,
    secondsRegex,
    sendToast,
    sortByAlphanumeric,
    sortRelevantSearch,
    vFetch,
} from '../helpers'
import { ReturnEditInit } from './returns.types'
import FiltersMenu, { Filter, FilterSearch } from '../custom_components/FiltersMenu'
import { returnVariables } from './returnsVariables'

type gridHeadersType = {
    id: string
    po_number: string
    task_id: string
    info_icons: string
    customer: string
    manufacturer: string
    refund_type: string
    quantity: string
    reason: string
    status: string
    rep: string
    updated: string
    reviewed: string
}
const gridHeaders: gridHeadersType = {
    id: '',
    po_number: 'PO',
    task_id: 'Task',
    info_icons: '',
    customer: 'Customer',
    manufacturer: 'Manu / Model',
    quantity: 'Qty.',
    refund_type: 'Refund',
    reason: 'Reason',
    status: 'Status',
    rep: 'Rep',
    updated: 'Updated',
    reviewed: 'Reviewed',
}
const gridStyle = `grid grid-cols-[29px_64px_64px_20px_100px_200px_60px_80px_1fr_1fr_95px_98px_98px] [&:nth-child(odd)]:bg-lightgrey [&:nth-child(odd)]:dark:bg-darkaccent`
const cellStyle = 'p-[8px] flex items-center'
const iconDiv = 'h-full grow flex items-center justify-center'
const iconStyle = 'stroke-black dark:stroke-accent hover:underline'
const preStyle = 'whitespace-pre-wrap font-normal font-bai text-[14px] max-w-[30vw]'

export default function ReturnsList() {
    const navigate = useNavigate()

    const [allReturns, setAllReturns] = useState<ReturnEditInit[]>([])
    const [returns, setReturns] = useState<ReturnEditInit[]>([])
    const [selectedReturns, setSelectedReturns] = useState<number[]>([])
    const [iconMessage, setIconMessage] = useState<[number, string, string] | []>([])

    const [startDate, setStartDate] = useState<Date>(new Date())
    const [endDate, setEndDate] = useState<Date>(new Date())
    const [sortMode, setSortMode] = useState<string>('updated_at_reverse')

    const [filterByManu, setFilterByManu] = useState<string[]>([])
    const [filterByManuOptions, setFilterByManuOptions] = useState<string[]>([])
    const [showFilterByManuOptions, setShowFilterByManuOptions] = useState<boolean>(false)
    const manufacturerFilter = new Filter(
        'Filter by Manu',
        filterByManu,
        setFilterByManu,
        filterByManuOptions,
        showFilterByManuOptions,
        setShowFilterByManuOptions,
        useRef(null)
    )

    const [filterByReason, setFilterByReason] = useState<string[]>([])
    const [showFilterByReasonOptions, setShowFilterByReasonOptions] = useState<boolean>(false)
    const reasonFilter = new Filter(
        'Filter by Reason',
        filterByReason,
        setFilterByReason,
        returnVariables.reasons,
        showFilterByReasonOptions,
        setShowFilterByReasonOptions,
        useRef(null)
    )

    const [filterByStatus, setFilterByStatus] = useState<string[]>(['ready for processing'])
    const [showFilterStatusOptions, setShowFilterByStatusOptions] = useState<boolean>(false)
    const statusFilter = new Filter(
        'Filter by Status',
        filterByStatus,
        setFilterByStatus,
        returnVariables.statuses,
        showFilterStatusOptions,
        setShowFilterByStatusOptions,
        useRef(null)
    )

    const [filterByType, setFilterByType] = useState<string[]>([])
    const [showFilterTypeOptions, setShowFilterByTypeOptions] = useState<boolean>(false)
    const typeFilter = new Filter(
        'Filter by Type',
        filterByType,
        setFilterByType,
        ['return', 'damage', 'cancellation'],
        showFilterTypeOptions,
        setShowFilterByTypeOptions,
        useRef(null)
    )

    const [filterByRefundType, setFilterByRefundType] = useState<string[]>([])
    const [showFilterRefundTypeOptions, setShowFilterByRefundTypeOptions] = useState<boolean>(false)
    const refundTypeFilter = new Filter(
        'Filter by Refund Type',
        filterByRefundType,
        setFilterByRefundType,
        ['full', 'partial', 'exchange'],
        showFilterRefundTypeOptions,
        setShowFilterByRefundTypeOptions,
        useRef(null)
    )

    const [searchByOrderNumber, setSearchByOrderNumber] = useState<string>('')
    const formattedOrderNumberSearch = searchByOrderNumber.replaceAll(notIntegerRegex, '')
    const orderNumberSearch = new FilterSearch('Order # Search', searchByOrderNumber, setSearchByOrderNumber)

    const filters = [manufacturerFilter, reasonFilter, statusFilter, typeFilter, refundTypeFilter]
    const filterSearches = [orderNumberSearch]

    useEffect(() => {
        vFetch(`/v1/returns/all?filters=${encodeURIComponent(`submitted = 1`)}`, {
            cb: (res: any) => {
                const resReturns = res.returns.map((r: any) => {
                    return {
                        ...parseResObject(r),
                        created_at: new Date(r.created_at as Date),
                        updated_at: isNaN(new Date(r.updated_at as Date).getTime())
                            ? undefined
                            : new Date(r.updated_at as Date),
                        info_icons: true,
                    }
                })
                vFetch(
                    `/v1/customers/multipleById?ids=${resReturns
                        .filter((r: ReturnEditInit) => typeof r.customer === 'number')
                        .map((r: ReturnEditInit) => r.customer)}`,
                    {
                        cb: (res: any) => {
                            if (res.success) {
                                setAllReturns(
                                    resReturns.map((r: ReturnEditInit) => {
                                        return {
                                            ...r,
                                            customer:
                                                typeof r.customer === 'number'
                                                    ? res.customers.find((c: any) => c.id == r.customer) || r.customer
                                                    : r.customer,
                                        }
                                    })
                                )
                                const manuOptions = new Set(resReturns.map((r: ReturnEditInit) => r.manufacturer))
                                const manuWithoutDupes: any[] = []
                                for (let value of manuOptions.values()) manuWithoutDupes.push(value)
                                setFilterByManuOptions(manuWithoutDupes.sort((a, b) => sortByAlphanumeric(a, b)))
                            }
                        },
                    }
                )
            },
        })
    }, [])

    const filteredReturnsForSort = (returnsToFilter: ReturnEditInit[]) => {
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toDateString()
        let filteredReturns = [...returnsToFilter]

        // filters by status
        if (filterByManu.length) filteredReturns = filteredReturns.filter((r) => filterByManu.includes(r.manufacturer))
        if (filterByStatus.length) filteredReturns = filteredReturns.filter((r) => filterByStatus.includes(r.status))
        if (filterByReason.length) filteredReturns = filteredReturns.filter((r) => filterByReason.includes(r.reason))
        if (filterByType.length) filteredReturns = filteredReturns.filter((r) => filterByType.includes(r.type))
        if (filterByRefundType.length)
            filteredReturns = filteredReturns.filter((r) => filterByRefundType.includes(r.refund_type))
        if (formattedOrderNumberSearch)
            filteredReturns = filteredReturns.filter((r) => String(r.po_number).includes(formattedOrderNumberSearch))

        return filteredReturns
    }
    useEffect(() => {
        const reversed = sortMode.includes('reverse')

        // Sort Draft #
        if (formattedOrderNumberSearch) {
            const sortedReturns = filteredReturnsForSort(allReturns).sort((a, b) =>
                sortRelevantSearch(formattedOrderNumberSearch, String(a.po_number), String(b.po_number), true)
            )
            setReturns(sortedReturns)
        } else if (sortMode.includes('updated_at')) {
            const sortedReturns = filteredReturnsForSort(allReturns).sort((a, b) =>
                sortByAlphanumeric(a.updated_at?.getTime() || 100000000000, b.updated_at?.getTime() || 100000000000)
            )
            setReturns(reversed ? sortedReturns.reverse() : sortedReturns)
        } else if (sortMode.includes('po_number')) {
            const sortedReturns = filteredReturnsForSort(allReturns).sort((a, b) =>
                sortByAlphanumeric(a.po_number, b.po_number)
            )
            setReturns(reversed ? sortedReturns.reverse() : sortedReturns)
        } else if (sortMode.includes('status')) {
            const sortedReturns = filteredReturnsForSort(allReturns).sort((a, b) =>
                sortByAlphanumeric(a, b, 'status', 'po_number')
            )
            setReturns(reversed ? sortedReturns.reverse() : sortedReturns)
        } else if (sortMode.includes('task_id')) {
            const sortedReturns = filteredReturnsForSort(allReturns).sort((a, b) =>
                sortByAlphanumeric(a.task_id, b.task_id)
            )
            setReturns(reversed ? sortedReturns.reverse() : sortedReturns)
        } else {
            setReturns(filteredReturnsForSort(allReturns))
        }
    }, [
        allReturns,
        sortMode,
        filterByManu,
        filterByReason,
        filterByStatus,
        filterByType,
        filterByRefundType,
        searchByOrderNumber,
    ])

    function handleNavigate(e: MouseEvent, poNumber: number | undefined, returnId?: number) {
        e.preventDefault()
        const target = e.target as HTMLElement
        if (
            closePopout(
                e,
                ['js-returns-checkbox', 'js-items-dropdown', 'js-tags-dropdown', 'js-dont-navigate'],
                () => {}
            )
        ) {
            if (poNumber !== undefined && !returnId) {
                vFetch(`/v1/orders/all?filters=order_number=${poNumber}`, {
                    cb: (res: any) => {
                        if (res.success && res.orders?.length > 0) {
                            if (e.ctrlKey) window.open(`/orders/${res.orders[0].id}`, '_blank')
                            else if (e.type === 'contextmenu') window.open(`/orders/${res.orders[0].id}`, '_blank')
                            else navigate(`/orders/${res.orders[0].id}`)
                        } else sendToast({ message: 'Order page cannot be found. Please contact the developers.' })
                    },
                })
            }
            if (returnId !== undefined && closePopout(e, ['js-return-po-number', 'js-return-checkbox'], () => {})) {
                if (e.ctrlKey) window.open(`/orders/returns/edit?returns=${returnId}`)
                else if (e.type === 'contextmenu') window.open(`/orders/returns/edit?returns=${returnId}`, '_blank')
                else navigate(`/orders/returns/edit?returns=${returnId}`)
            }
        }
    }
    function handleReturnCheckbox(r: ReturnEditInit) {
        setSelectedReturns(
            selectedReturns.includes(r.id)
                ? selectedReturns.filter((id) => id !== r.id)
                : [...selectedReturns, r.id].sort()
        )
    }
    return (
        <div className='dark:text-offwhite'>
            {selectedReturns.length > 0 && (
                <SelectedReturnsActions selected={selectedReturns} setSelected={setSelectedReturns} />
            )}
            <div className='flex justify-between'>
                <div>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Returns</h1>
                </div>
            </div>
            <div className='text-[14px] mb-[64px] border-[1px] border-grey dark:border-darkgrey rounded'>
                <div className='sticky top-[51px] z-index-1 text-[16px]'>
                    <FiltersMenu
                        style={'rounded-b-none border-x-0 border-t-0 border-b-[1px]'}
                        filters={filters.map((filter, index: number) => {
                            return { ...filter, zIndex: filters.length - index }
                        })}
                        searches={filterSearches}
                        ex={{
                            startDate,
                            setStartDate,
                            endDate,
                            setEndDate,
                            items: returns,
                            removemb: true,
                        }}
                    />
                    <div
                        className={`${gridStyle} text-[14px]  !border-t-[0px] border-b-[1px] border-grey dark:border-darkgrey text-white dark:text-offwhite !bg-blue dark:!bg-darkaccent`}
                    >
                        {Object.keys(gridHeaders).map((k: string) => {
                            if (k === 'id')
                                return (
                                    <div className={`${cellStyle}`}>
                                        <input
                                            type='checkbox'
                                            onClick={({ target }: MouseEvent) =>
                                                (target as HTMLInputElement).checked
                                                    ? setSelectedReturns(allReturns.map((r) => r.id!))
                                                    : setSelectedReturns([])
                                            }
                                            onChange={({ target }: ChangeEvent) =>
                                                (target as HTMLInputElement).checked
                                                    ? setSelectedReturns(allReturns.map((r) => r.id!))
                                                    : setSelectedReturns([])
                                            }
                                            checked={allReturns.every((r: ReturnEditInit) =>
                                                selectedReturns.includes(r.id!)
                                            )}
                                        />
                                    </div>
                                )
                            if (k === 'po_number')
                                return (
                                    <div
                                        className={`${cellStyle} font-bold cursor-pointer`}
                                        onClick={() => (sortMode === k ? setSortMode(k + '_reverse') : setSortMode(k))}
                                    >
                                        {gridHeaders[k as keyof gridHeadersType]}
                                        {sortMode.includes(k) ? '*' : ''}
                                    </div>
                                )
                            if (k === 'status')
                                return (
                                    <div
                                        className={cellStyle + ' font-bold cursor-pointer'}
                                        onClick={() => setSortMode(sortMode === 'status' ? 'status_reverse' : 'status')}
                                    >
                                        {gridHeaders[k as keyof gridHeadersType]}
                                        {sortMode.includes(k) ? '*' : ''}
                                    </div>
                                )
                            if (k === 'task_id')
                                return (
                                    <div
                                        className={cellStyle + ' font-bold cursor-pointer'}
                                        onClick={() =>
                                            setSortMode(sortMode === 'task_id' ? 'task_id_reverse' : 'task_id')
                                        }
                                    >
                                        {gridHeaders[k as keyof gridHeadersType]}
                                        {sortMode.includes(k) ? '*' : ''}
                                    </div>
                                )
                            if (k === 'updated')
                                return (
                                    <div
                                        className={cellStyle + ' font-bold cursor-pointer'}
                                        onClick={() =>
                                            setSortMode(sortMode === 'updated_at' ? 'updated_at_reverse' : 'updated_at')
                                        }
                                    >
                                        {gridHeaders[k as keyof gridHeadersType]}
                                        {sortMode.includes(k) ? '*' : ''}
                                    </div>
                                )
                            return (
                                <div className={`${cellStyle} font-bold`}>
                                    {gridHeaders[k as keyof gridHeadersType]}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {returns.map((r: any, i: number) => (
                    <NavLink
                        className={`${gridStyle} hover:!bg-grey/40 dark:hover:!bg-blue cursor-pointer`}
                        onMouseMove={(event: MouseEvent) =>
                            event.ctrlKey
                                ? ((event.target as HTMLElement).style.cursor = 'cell')
                                : ((event.target as HTMLElement).style.cursor = 'pointer')
                        }
                        onClick={(event: MouseEvent) =>
                            event.ctrlKey && !(event.target as HTMLElement).classList.contains('js-return-po-number')
                                ? handleReturnCheckbox(r)
                                : handleNavigate(event, undefined, r.id)
                        }
                        to={`/orders/returns/edit?returns=${r.id}`}
                    >
                        {Object.keys(gridHeaders).map((k) => {
                            if (k === 'customer')
                                return (
                                    <div className={cellStyle + ' capitalize'}>
                                        {r.customer?.full_name ? r.customer?.full_name : r.customer?.unparsedCustomer}
                                    </div>
                                )
                            if (k === 'id')
                                return (
                                    <div
                                        className={`${cellStyle} flex justify-center items-center js-returns-checkbox`}
                                        onClick={() => handleReturnCheckbox(r)}
                                    >
                                        <input
                                            className=''
                                            type='checkbox'
                                            onChange={() => handleReturnCheckbox(r)}
                                            checked={selectedReturns.includes(r.id)}
                                        />
                                    </div>
                                )
                            if (k === 'info_icons') {
                                return (
                                    <div
                                        className={`relative js-orders-icon-list ${cellStyle} !p-[0] flex justify-between`}
                                    >
                                        {r.notes ? (
                                            <div
                                                className={iconDiv}
                                                onMouseOver={() => {
                                                    setIconMessage([r.id, 'Notes', r.notes])
                                                }}
                                            >
                                                <svg
                                                    className={iconStyle}
                                                    stroke='currentColor'
                                                    fill='currentColor'
                                                    strokeWidth='0'
                                                    viewBox='0 0 24 24'
                                                    height='1em'
                                                    width='1em'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <path
                                                        className='pointer-events-none'
                                                        fill='none'
                                                        strokeWidth='2'
                                                        d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                                    />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div
                                                className={iconDiv + ' opacity-0'}
                                                onMouseOver={() => setIconMessage([])}
                                            >
                                                <TbLetterR className='stroke-blue dark:stroke-accent hover:underline' />
                                            </div>
                                        )}
                                        {iconMessage[0] === r.id && (
                                            <div className='absolute hidden hover:flex js-orders-icon-message p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px] js-dont-navigate z-index-1 !cursor-auto [&>*]:!cursor-auto'>
                                                <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate [&>*]:!cursor-auto'>
                                                    <h2 className='text-[16px] font-bold'>{iconMessage[1]}</h2>
                                                    <pre className={preStyle}>{iconMessage[2]}</pre>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            }
                            if (k === 'po_number')
                                return (
                                    <div
                                        className={`${cellStyle} hover:underline js-return-po-number !cursor-pointer`}
                                        onClick={(event: MouseEvent) => handleNavigate(event, r.po_number)}
                                        onContextMenu={(event: MouseEvent) => {
                                            handleNavigate(event, r.po_number)
                                        }}
                                    >
                                        {r[k]}
                                    </div>
                                )
                            if (k === 'manufacturer')
                                return (
                                    <div className={`${cellStyle} relative`}>
                                        <span>
                                            {r[k]} - {r.model?.sku || r.model?.unparsedModel}
                                        </span>
                                    </div>
                                )
                            if (k === 'rep')
                                return (
                                    <div className={cellStyle + ' !items-baseline flex flex-col justify-center'}>
                                        {r[k]?.split(', ').map((rep: string) => <div>{rep}</div>)}
                                    </div>
                                )
                            if (k === 'task_id')
                                return (
                                    <div
                                        className={`${cellStyle} hover:underline js-return-po-number !cursor-pointer`}
                                        onClick={(event: MouseEvent) =>
                                            event.ctrlKey
                                                ? window.open(`/tasks/${r[k]}`, '_blank')
                                                : navigate(`/tasks/${r[k]}`)
                                        }
                                        onContextMenu={(event: MouseEvent) => window.open(`/tasks/${r[k]}`, '_blank')}
                                    >
                                        {r[k]}
                                    </div>
                                )
                            if (k === 'updated')
                                return (
                                    <div className={cellStyle}>
                                        {r.updated_at?.toLocaleString().replace(secondsRegex, ' ')}
                                    </div>
                                )
                            if (k === 'reviewed')
                                return <div className={`${cellStyle}`}>{r.reviewed === 1 ? 'Yes' : 'No'}</div>
                            return <div className={`${cellStyle} capitalize`}>{r[k]}</div>
                        })}
                    </NavLink>
                ))}
            </div>
        </div>
    )
}
