import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useCreateTimesheetEntry } from 'timesheets/api/useQueries'
import { WorkingTimesheetEntryType } from 'timesheets/Timesheets.types'

export default function CreateEntryRow({ htmlDate, disabled }: { htmlDate: string; disabled: boolean }) {
    const [showCreate, setShowCreate] = useState(false)
    const createEntryMutation = useCreateTimesheetEntry()
    const user = useSelector((state: any) => state.user)
    const [entry, setEntry] = useState<WorkingTimesheetEntryType>({
        type: 'overtime',
        user_id: user.id,
    })

    const handleSave = () => {
        const starts_on = entry.starts_on_time
            ? new Date(
                  new Date(`${htmlDate}T${entry.starts_on_time || '00:00'}:00.000Z`).getTime() +
                      new Date().getTimezoneOffset() * 60 * 1000
              ).toISOString()
            : new Date().toISOString()
        const ends_on = entry.ends_on_time
            ? new Date(
                  new Date(`${htmlDate}T${entry.ends_on_time || '00:00'}:00.000Z`).getTime() +
                      new Date().getTimezoneOffset() * 60 * 1000
              ).toISOString()
            : undefined

        createEntryMutation.mutate(
            {
                ...entry,
                timezone_offset: new Date().getTimezoneOffset(),
                starts_on,
                ends_on,
            },
            {
                onSuccess: () => {
                    setShowCreate(false)
                    setEntry({
                        type: 'overtime',
                        user_id: user.id,
                    })
                },
            }
        )
    }

    const handleCancel = () => {
        setShowCreate(false)
        setEntry({
            type: 'overtime',
            user_id: user.id,
        })
    }

    if (disabled) {
        return <></>
    }

    return (
        <>
            {!showCreate ? (
                <Button className='mt-2' size='sm' variant='outline' onClick={() => setShowCreate(true)}>
                    + New Entry
                </Button>
            ) : (
                <div className='flex gap-2 p-1 border rounded'>
                    <Select
                        outerClassName='w-fit'
                        id='type'
                        name='type'
                        value={entry.type}
                        onChange={({ target }) => setEntry({ ...entry, [target.name]: target.value })}
                    >
                        <option value=''>Select a type</option>
                        <option value='overtime'>Overtime</option>
                    </Select>
                    <Input
                        outerClassName='w-fit ml-auto'
                        type='time'
                        id='starts_on_time'
                        name='starts_on_time'
                        value={entry.starts_on_time ?? ''}
                        onChange={({ target }) => setEntry({ ...entry, [target.name]: target.value })}
                    />
                    <Input
                        outerClassName='w-fit'
                        type='time'
                        id='ends_on_time'
                        name='ends_on_time'
                        value={entry.ends_on_time ?? ''}
                        onChange={({ target }) => setEntry({ ...entry, [target.name]: target.value })}
                    />
                    <Button onClick={handleSave} variant='outline' size='sm'>
                        Save
                    </Button>
                    <Button onClick={handleCancel} variant='outline' size='sm'>
                        Cancel
                    </Button>
                </div>
            )}
        </>
    )
}
