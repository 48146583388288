import { Button } from 'custom_components/component_Basics/Button'
import { sendToast } from 'helpers'
import Input from 'procurement/components/Input'
import { useState } from 'react'

export default function RecurringTaskInterval({
    interval,
    handleChange,
}: {
    interval?: number
    handleChange: (...args: any) => void
}) {
    const [newInterval, setNewInterval] = useState(interval?.toString() || '')
    const edited = newInterval !== interval?.toString()
    const handleSubmit = () => {
        if (!newInterval || parseInt(newInterval) < 1) {
            return sendToast({ message: 'Invalid interval, must be greater than 0!' })
        }
        handleChange({ target: { name: 'interval_value', value: parseInt(newInterval) } })
    }
    return (
        <div className='flex gap-2'>
            <Input
                className='bg-bg1 dark:bg-darkbg2 dark:border dark:border-darkgrey px-2 text-sm'
                type='number'
                id='interval_value'
                name='interval_value'
                value={newInterval}
                onChange={({ target }) => setNewInterval(target.value)}
            />
            {edited && (
                <Button onClick={handleSubmit} className='h-fit p-1'>
                    Confirm
                </Button>
            )}
        </div>
    )
}
