export type PurchaseOrderType = {
    name: string
    is_default_order?: boolean
    id: number | string
    order_name: string
    notes: string
    shipping_address: AddressType
    status: string
    supplier: string
    supplier_email: string[]
    total: number
    email_timeline: EmailTimelineEvent[]
    created_at: string
    updated_at: string
    line_items: PurchaseOrderLineItem[]
    show_cost: 1 | 0
    edi_timeline: EDITimelineEvent[]
    supplier_edi_info?: EDISupplierInfoType
    ship_from_address: AddressType
    carrier_scac: string
    carrier_name: string
    fp_carrier_account_number: string
    destination_code: string
    requires_liftgate: 1 | 0
    insurance_cost: number
    st_control_number: bigint
    test_order: 1 | 0
    gs_control_number: bigint
    supplier_id?: number
    vendor?: string
    accepts_email?: 1 | 0
    accepts_edi?: 1 | 0
    dropship?: boolean
    stocking?: boolean
    store_id: string
    attachments: any
}

export type AddressType = {
    zip: string
    city: string
    phone: string
    company: string
    country_code: string
    address1: string
    address2?: string
    last_name?: string
    first_name?: string
    province_code: string
}
export const defaultAddress = {
    zip: '',
    city: '',
    phone: '',
    company: '',
    country_code: '',
    address1: '',
    address2: '',
    last_name: '',
    first_name: '',
    province_code: '',
}
export type EDISupplierInfoType = {
    test_edi_id: string
    production_edi_id: string
    test_as2_station_id: string
    production_as2_station_id: string
}

export type EmailTimelineEvent = {
    cc: string
    pdf: any
    body: string
    subject: string
    recipient: string[]
    time_sent: string
    delivery_success: 1 | 0
}

export type PurchaseOrderLineItem = {
    id: number | string
    sku: string
    name: string
    price: number
    title: string
    vendor: string
    quantity: number
    item_cost?: number | null
    product_id: number
    variant_id: number
    supplier_sku?: string
    supplier_model_number?: string
    upc?: string
    custom_not_carb_compliant?: 1 | 0
    product_title: string
    variant_title?: string
    fulfillment_status: string | null
    fulfillable_quantity: number
}

export type EDITimelineEvent = {
    STCode: string
    isTest: 1 | 0
    vendor: string
    senderID: string
    createdAt: string
    receiverID: string
    GSControlNumber: string
    STControlNumber: string
    senderQualifier: string
    ISAControlNumber: string
    receiverQualifier: string
    totalTransactionSets: number
    acknowledgedGroupCode: string
    acknowledgedGroupType: string
    acceptedTransactionSets: number
    functionalGroupAccepted: 1 | 0
    receivedTransactionSets: number
    acknowledgedTransactionSets: { type: string; accepted: 1 | 0; controlNumber: string }[]
    acknowledgedGroupControlNumber: string
}

export type SupplierType = {
    id?: number
    contact_name: string
    country: string
    currency: string
    email_addresses?: string
    edi_isa_qualifier?: string
    edi_isa_identifier?: string
    edi_gs_identifier?: string
    edi_as2_station?: string
    edi_test_isa_qualifier?: string
    edi_test_isa_identifier?: string
    edi_test_gs_identifier?: string
    edi_test_as2_station?: string
    name: string
    po_note?: string
    po_type: 'email' | 'edi'
    show_cost: 0 | 1
    test_mode: 0 | 1
    created_at?: string
    updated_at?: string
    store_id?: number
}

export const defaultSupplier: SupplierType = {
    name: '',
    po_type: 'email',
    email_addresses: '',
    contact_name: '',
    po_note: '',
    country: 'USA',
    currency: 'USD',
    show_cost: 0,
    test_mode: 0,
}
