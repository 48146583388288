import { keepPreviousData } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { ToggleSwitch } from 'custom_components/ToggleSwitch'
import { FetchOptionSelector } from 'development/components/FetchOptionSelector'
import { cn } from 'helpers'
import MoreActions, { Option } from 'processingTimes/components/MoreActions'
import { formatDateSimple } from 'procurement/helpers'
import React, { useEffect, useRef, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { FaGear } from 'react-icons/fa6'
import { useSearchParams } from 'react-router-dom'
import { useGetTasks } from 'tasksV2/api/useQueries'
import { PRIORITY_MAP, TaskV2 } from 'tasksV2/constants/tasks'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import Assignment from '../components/Assignment'
import Association from '../components/Association'
import Department from '../components/Department'
import TaskStatus from '../components/TaskStatus'
import TaskType from '../components/TaskType'
import TasksTable from './TasksTable'
import { useSelector } from 'react-redux'

export default function TaskListSmallPinned({
    department,
    filters,
    tableSetup,
    setTableSetup,
    position,
    handleTableSetupEdit,
    setGroupTasksSetupOptions,
}: {
    department: any
    filters: any
    tableSetup: any
    setTableSetup: any
    position: number
    handleTableSetupEdit: any
    setGroupTasksSetupOptions: any
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const priorityRef = useRef<HTMLDivElement>(null)
    const typesString = searchParams.get(`${department?.title}_types`) || ''
    const stringGroupTasksSetupOptions = localStorage.getItem('groupTasksSetupOptions')
    const groupTasksSetupOptions = stringGroupTasksSetupOptions ? JSON.parse(stringGroupTasksSetupOptions) : []

    const hidden = groupTasksSetupOptions.find((option: any) => option.id === -1)?.hidden === true

    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)

    const searchFilters = {
        search: filters.search,
        pinned: true,
        limit: 2000,
        dateRange: undefined,
        overdue: undefined,
    }

    const tasksQuery = useGetTasks(
        filters.search
            ? searchFilters
            : {
                  pinned: true,
                  limit: 2000,
              },
        keepPreviousData
    )
    const { tasks }: { tasks: TaskV2[] } = tasksQuery?.data || {}

    const filteredTasks =
        user.type === 'Admin' || user.type === 'SuperAdmin'
            ? tasks
            : tasks?.filter((task) => task.status !== 'Archived')

    const companyFilterBarObject = {
        params: [],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('assigned_to')
                prev.delete('departments')
                prev.delete('types')
                prev.delete('priorities')
                prev.delete('statuses')
                prev.delete('estimates')
                prev.set(`${department?.title}_page`, '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    const tableData = filteredTasks?.length > 0 ? filteredTasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskV2>()

    const taskColumns = [
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: 'id',
            cell: (info) => {
                return <div className='flex font-semibold'>{info.getValue()}</div>
            },
            maxSize: 60,
        }),

        columnHelper.accessor('priority', {
            cell: (info) => {
                const priorityMap = PRIORITY_MAP
                const { icon: Priority, className: priorityClassName } =
                    priorityMap[info.getValue() as keyof typeof priorityMap]
                return (
                    <div
                        id={info.row.original.id.toString()}
                        ref={priorityRef}
                        className='flex gap-2 items-center relative'
                    >
                        <Priority className={`text-[20px] ${priorityClassName}`} />
                    </div>
                )
            },
            size: 30,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            maxSize: 120,
            cell: (info) => {
                const due_at = info.cell.row.original.due_at
                return (
                    <div>
                        <TaskStatus
                            taskId={info.row.original.id}
                            dueAt={due_at}
                            status={info.getValue()}
                            editable={false}
                        />
                    </div>
                )
            },
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group ml-4'>
                        <div>{truncate(info.getValue(), 70)}</div>
                        {info.getValue().length > 70 && (
                            <div className='z-40 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-40 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 600,
        }),
        columnHelper.accessor('department_id', {
            header: 'Department',
            enableHiding: department?.title === 'Pinned' ? false : true,
            cell: (info) => {
                return (
                    <div key={info.row.original.id}>
                        <Department departmentId={info.getValue()} />
                    </div>
                )
            },
        }),

        columnHelper.accessor('type_id', {
            header: 'Type',
            cell: (info) => {
                return (
                    <div>
                        <TaskType
                            taskId={info.row.original.id}
                            departmentId={info.row.original.department_id}
                            typeId={info.getValue()}
                            editable={false}
                        />
                    </div>
                )
            },
            maxSize: 140,
        }),
        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {info
                            .getValue()
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <React.Fragment
                                    key={`${info.row.original.id}+${association.resource_id}` + '-association-pinned'}
                                >
                                    <Association id={info.row.original.id} association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            minSize: 400,
        }),
        {
            header: 'Assigned To',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {row.original.associations
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <React.Fragment
                                    key={`${row.original.id}+${association.resource_id}` + '-assignment-pinned'}
                                >
                                    <Assignment association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            size: 90,
        },
        columnHelper.accessor('due_at', {
            maxSize: 130,
            header: 'Due',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
        columnHelper.accessor('created_at', {
            maxSize: 130,
            header: 'Created',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
        }),
    ]

    const sizeOptions: Option[] = [
        {
            displayText: 'Hide',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    hidden: true,
                }
                localStorage.setItem('tasksTableSetup', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
    ]

    const columnCount = taskColumns?.length.toString() || 10

    return (
        <>
            <div
                key={department.id}
                className='rounded shadow-sm w-full h-fit border border-lightgrey dark:border-darkaccent cursor-pointer overflow-visible'
            >
                <div
                    onClick={() => {
                        setGroupTasksSetupOptions((prev: any) => {
                            const newValue = [...prev]
                            newValue[0].hidden = !newValue[0].hidden
                            handleTableSetupEdit(newValue)
                            return newValue
                        })
                    }}
                    className='flex justify-between dark:bg-darkaccent rounded-t  px-4 pr-2 bg-lightgrey'
                >
                    <div className='flex w-full gap-2 items-center justify-between'>
                        <div className='flex gap-2 items-center'>
                            <div className={cn('font-semibold text-[18px] leading-tight', hidden && 'opacity-50')}>
                                {department.title}
                            </div>
                            <ToggleSwitch checked={!hidden} />
                        </div>
                        <div className={cn('flex gap-2 items-center', hidden && 'opacity-40')}>
                            <FetchingSpinner isFetching={tasksQuery.isFetching} />

                            {companyFilterBarObject.params.length > 0 &&
                                companyFilterBarObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.title + param.field}
                                            searchToggle={param.searchToggle}
                                            editSearchParams={param.editSearchParams}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={companyFilterBarObject.setFunction}
                                            pageString={param.pageString}
                                        />
                                    )
                                })}
                            <div className='opacity-0'>
                                <MoreActions options={sizeOptions} displayContent={{ icon: FaGear }} />
                            </div>
                        </div>
                    </div>
                    <div className='font-bold text-[18px] leading-tight'></div>
                </div>
                <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
                {!hidden && (
                    <div className='w-full min-h-[60px]'>
                        {!tasksQuery.isLoading && filteredTasks?.length < 1 && (
                            <div className='flex justify-center items-center w-full h-2/3'>
                                <div className='p-2 px-4 rounded-md border mt-3 '>No tasks</div>
                            </div>
                        )}
                        {tableDataMemo && tableDataMemo.length > 0 && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <TasksTable
                                    columns={taskColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                    showDepartment={department.title === 'Pinned'}
                                    size={'pinned'}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}
