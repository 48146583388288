import { FaCheck, FaTimes } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useGetTaxForms } from 'taxExemptions/api/useQueries'

export default function TaxFormList({ taxFormsQuery, taxExemptionId }: { taxFormsQuery: any; taxExemptionId: string }) {
    // const taxFormsQuery = useGetTaxForms({ taxExemptionId })

    const taxExemptionForms = taxFormsQuery.data?.taxForms || []
    return (
        <div className='mb-3'>
            <h2 className='font-bold mb-2'>Files</h2>
            <div className='border border-lightgrey dark:border-darkgrey rounded max-h-[300px] overflow-auto'>
                <div className='rounded-t grid grid-cols-4 text-xs font-bold bg-accent1 dark:bg-darkbg2 text-white dark:text-offwhite'>
                    <p className='p-2 col-span-2'>File Name</p>
                    <p className='p-2 col-span-1'>File Type</p>
                    <p className='p-2 text-center col-span-1'>Passed Malware Check</p>
                </div>
                {(taxFormsQuery.isLoading || (!taxFormsQuery.isLoading && taxExemptionForms.length < 1)) && (
                    <div className='h-[36px]'></div>
                )}
                {taxExemptionForms.map((form: any) => (
                    <Link
                        className='grid grid-cols-4 items-center text-sm border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkgrey'
                        to={form.dangerous_file ? '' : form.file_url}
                        target='_blank'
                    >
                        <span className='p-2 col-span-2 text-ellipsis overflow-hidden'>
                            {form.s3_key.split('/')[form.s3_key.split('/').length - 1]}
                        </span>
                        <span className='p-2 col-span-1'>{form.file_type}</span>
                        <span className='p-2 col-span-1 self-center justify-self-center'>
                            {form.dangerous_file ? <FaTimes className='text-red' /> : <FaCheck className='text-lime' />}
                        </span>
                    </Link>
                ))}
            </div>
        </div>
    )
}
