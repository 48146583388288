import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteRecurringTask, useGetRecurringTasks, useUpdateRecurringTask } from 'tasksV2/api/useQueries'
import Department from 'tasksV2/tasks/components/Department'
import RecurringTaskType from './components/RecurringTaskType'
import RecurringTaskPriority from './components/RecurringTaskPriority'
import RecurringTaskEstimate from './components/RecurringTaskEstimate'
import TaskAssignmentsModal from 'tasksV2/tasks/modals/TaskAssignmentsModal'
import RecurringTaskActive from './components/RecurringTaskActive'
import { useSelector } from 'react-redux'
import RecurringTaskIntervalType from './components/RecurringTaskIntervalType'
import RecurringTaskInterval from './components/RecurringTaskInterval'
import RecurringTaskStartingAt from './components/RecurringTaskDueAt'
import RecurringTaskAlert from './components/RecurringTaskAlertAt'

export default function RecurringTaskScreen() {
    const user = useSelector((state: any) => state.user)
    const { recurring_task_id } = useParams()
    const navigate = useNavigate()
    const [showDelete, setShowDelete] = useState(false)
    const [showAssignments, setShowAssignments] = useState(false)
    const [editableTask, setEditableTask] = useState<any>({})
    const updateRecurringTaskMutation = useUpdateRecurringTask()
    const deleteRecurringTaskMutation = useDeleteRecurringTask()
    const recurringTaskQuery = useGetRecurringTasks({ id: recurring_task_id })
    const recurringTasks = recurringTaskQuery.data?.recurringTasks || []
    const recurringTask = (recurringTasks || [])[0] || {}
    const task = recurringTask?.task_data || '{}'
    const edited = JSON.stringify(task) !== JSON.stringify(editableTask)

    const handleInstantTaskChange = ({ target }: { target: any }) => {
        updateRecurringTaskMutation.mutate({
            id: recurringTask.id,
            task_data: {
                ...editableTask,
                [target.name]: target.value,
            },
        })
    }
    const handleInstantRecurringTaskChange = ({ target }: { target: any }) => {
        updateRecurringTaskMutation.mutate({
            id: recurringTask.id,
            [target.name]: target.value,
        })
    }
    const handleChangeActive = ({ target }: { target: HTMLSelectElement }) => {
        updateRecurringTaskMutation.mutate({
            id: recurringTask.id,
            active: parseInt(target.value),
            failure_count: target.value === '1' ? 0 : undefined,
        })
    }
    const handleChange = ({ target }: { target: any }) => {
        setEditableTask({
            ...editableTask,
            [target.name]: target.value,
        })
    }
    const handleSave = () => {
        updateRecurringTaskMutation.mutate({
            id: recurringTask.id,
            task_data: editableTask,
        })
    }
    const handleDisassociation = (association: any) => {
        const newState = structuredClone(editableTask)
        if (!newState.associations) {
            newState.associations = editableTask.associations || []
        }
        if (!newState.disassociations) {
            newState.disassociations = []
        }
        if (
            newState.associations.find(
                (resource: any) =>
                    resource.resource_id === association.resource_id &&
                    resource.resource_name === association.resource_name
            )
        ) {
            newState.associations = newState.associations.filter(
                (resource: any) =>
                    !(
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                    )
            )
            newState.disassociations.push(association)
        } else {
            newState.disassociations = newState.disassociations.filter(
                (resource: any) =>
                    !(
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                    )
            )
            newState.associations.push(association)
        }
        updateRecurringTaskMutation.mutate({
            id: recurringTask.id,
            task_data: newState,
        })
    }
    const handleDeleteRecurringTask = () => {
        deleteRecurringTaskMutation.mutate(recurringTask.id, {
            onSuccess: () => {
                navigate('/tasks/recurring-tasks')
            },
        })
    }

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setEditableTask((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = editableTask.associations || []
            }
            if (!newState.disassociations) {
                newState.disassociations = []
            }
            if (
                newState.associations.find(
                    (resource: any) =>
                        resource.resource_id === association.resource_id &&
                        resource.resource_name === association.resource_name
                )
            ) {
                newState.associations = newState.associations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.disassociations.push(association)
                return newState
            } else {
                newState.disassociations = newState.disassociations.filter(
                    (resource: any) =>
                        !(
                            resource.resource_id === association.resource_id &&
                            resource.resource_name === association.resource_name
                        )
                )
                newState.associations.push(association)
                return newState
            }
        })
    }

    useEffect(() => {
        setEditableTask(task)
    }, [recurringTask])
    return (
        <div className='text-sm'>
            <div className='max-w-[1000px] flex gap-4 mx-auto'>
                <div className='w-2/3 ml-auto'>
                    <Button
                        variant={'outline'}
                        className='mb-2 h-fit p-1 text-sm dark:border-darkgrey'
                        onClick={() => navigate(-1)}
                    >
                        &lt;- Back
                    </Button>
                    <div className='mb-4'>
                        <div className='flex justify-between items-end border-b border-lightgrey dark:border-darkgrey pb-2 mb-4'>
                            <div className='flex gap-4 items-center'>
                                <h1 className='font-semibold text-2xl'>Recurring Task {recurringTask.id}</h1>
                                <FetchingSpinner isFetching={updateRecurringTaskMutation.isPending} />
                            </div>
                            <div className='flex gap-3 font-semibold text-base'>
                                {editableTask.department_id && (
                                    <Department departmentId={parseInt(editableTask.department_id)} />
                                )}
                                {editableTask.department_id && (
                                    <RecurringTaskType
                                        departmentId={parseInt(editableTask.department_id)}
                                        typeId={parseInt(editableTask.type_id)}
                                        handleChange={handleInstantTaskChange}
                                    />
                                )}
                            </div>
                        </div>

                        <Input
                            className='bg-transparent border border-lightgrey dark:border-darkgrey mb-2 text-base'
                            label='Task'
                            id='title'
                            name='title'
                            value={editableTask.title}
                            onChange={handleChange}
                            type='text'
                        />
                        <Textarea
                            className='text-base w-full max-h-[100px] text-[16px] leading-1 bg-transparent border-lightgrey dark:border-darkgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            label={'Additional Details'}
                            id='description'
                            name='description'
                            value={editableTask.description}
                            onChange={handleChange}
                        ></Textarea>
                        <div className='flex gap-4'>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={handleSave}
                            >
                                {updateRecurringTaskMutation.isPending ? 'Saving Changes..' : 'Save Changes'}
                            </Button>
                            <Button
                                className={cn(
                                    'mt-2 opacity-50 pointer-events-none',
                                    edited && 'opacity-100 pointer-events-auto'
                                )}
                                variant={'outline'}
                                onClick={() => setEditableTask(structuredClone(task))}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='w-1/3 rounded bg-lightgrey dark:bg-darkbg1 p-4 h-fit'>
                    <div className='flex justify-between'>
                        <div className='flex gap-4 items-center'>
                            <h2 className='font-semibold mb-2 text-base'>Recurring Task Details</h2>
                        </div>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <div>
                            <h3 className='font-bold'>Active</h3>
                            <RecurringTaskActive active={recurringTask.active} handleChange={handleChangeActive} />
                        </div>
                        <div>
                            <h3 className='font-bold'>Interval Type</h3>
                            <RecurringTaskIntervalType
                                intervalType={recurringTask.interval_type}
                                handleChange={handleInstantRecurringTaskChange}
                            />
                        </div>
                        <div>
                            <h3 className='font-bold'>Interval</h3>
                            <RecurringTaskInterval
                                key={recurringTask.interval_value}
                                interval={recurringTask.interval_value}
                                handleChange={handleInstantRecurringTaskChange}
                            />
                        </div>
                        <RecurringTaskStartingAt
                            recurringTaskId={recurringTask.id || -1}
                            dueAt={recurringTask.starting_at}
                            mutation={updateRecurringTaskMutation}
                        />
                        <div>
                            <h3 className='font-bold'>Priority</h3>
                            <RecurringTaskPriority
                                priority={editableTask.priority}
                                handleChange={handleInstantTaskChange}
                            />
                        </div>
                        <div>
                            <h3 className='font-bold'>Estimate</h3>
                            <RecurringTaskEstimate
                                departmentId={parseInt(editableTask.department_id)}
                                estimateId={parseInt(editableTask.estimate_id)}
                                handleChange={handleInstantTaskChange}
                            />
                        </div>
                        <RecurringTaskAlert
                            recurringTaskId={recurringTask.id || -1}
                            taskData={editableTask}
                            alertOffset={editableTask.alert_offset}
                            sendEmailAlert={editableTask.send_email_alert}
                            sendSlackAlert={editableTask.send_slack_alert}
                            mutation={updateRecurringTaskMutation}
                        />
                        <div className='flex flex-col gap-1'>
                            <label className='text-xs font-bold uppercase leading-none'>Assigned To</label>
                            <div className='flex gap-1 flex-wrap'>
                                {(editableTask.associations || [])?.filter(
                                    (association: any) => association.resource_name === 'user'
                                ).length === 0 && <p>No Assignments</p>}
                                {(editableTask.associations || [])
                                    ?.filter((association: any) => association.resource_name === 'user')
                                    .map((association: any) => (
                                        <div className='flex items-center gap-2 p-1 rounded bg-bg1 dark:bg-darkbg2'>
                                            <span>{association.readable_name}</span>
                                            <button
                                                onClick={() => {
                                                    handleDisassociation(association)
                                                }}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                            </div>
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                <div className='flex flex-wrap'>
                                    <Button
                                        className='h-fit p-1 mt-1 dark:border-darkgrey'
                                        variant='outline'
                                        onClick={() => setShowAssignments(true)}
                                    >
                                        + Add / Edit Assignments
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className='flex gap-1 items-center'>
                            {showDelete ? (
                                <>
                                    <Button
                                        onClick={handleDeleteRecurringTask}
                                        variant={'destructive'}
                                        className='h-fit p-1 block ml-auto text-xs text-danger dark:text-darktext1 dark:border-danger'
                                    >
                                        Delete recurring task
                                    </Button>
                                    <Button
                                        onClick={() => setShowDelete(false)}
                                        variant={'outline'}
                                        className='h-fit p-1 block text-xs'
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <button onClick={() => setShowDelete(true)} className='h-fit p-1 block ml-auto'>
                                    <FaTrash className='text-red dark:text-lightred' />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {showAssignments && (
                <TaskAssignmentsModal
                    closeModal={() => {
                        setShowAssignments(false)
                    }}
                    associations={editableTask.associations ?? []}
                    toggleAssociation={toggleAssociations}
                    handleSave={handleSave}
                    handleReset={() => {
                        setEditableTask((prev: any) => {
                            const newState = structuredClone(prev)
                            newState.associations = structuredClone(task?.associations)
                            return newState
                        })
                    }}
                />
            )}
        </div>
    )
}
