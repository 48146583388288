import { useState, useEffect } from 'react'
import { OrderInit } from '../orders.types'
import { ReturnEditInit } from '../../returns/returns.types'
import CloseButton from '../../custom_components/CloseButton'
import CreateReturnModule from '../../returns/components/CreateReturnModule'
import EditReturnModule from '../../returns/components/EditReturnModule'
import { returnVariables } from '../../returns/returnsVariables'
import { parseResObject, vFetch } from '../../helpers'
import { useQueryClient } from '@tanstack/react-query'
import queryKeys from 'orderHubScreen/api/queryKeys'

type ReturnsBlockProps = {
    propReturns?: ReturnEditInit[]
    setPropReturns?: Function
    order: OrderInit
    alwaysOpen?: boolean
}

export default function ReturnsBlock({ propReturns, setPropReturns, order, alwaysOpen = false }: ReturnsBlockProps) {
    const { statuses } = returnVariables
    const queryClient = useQueryClient()
    const [showReturnsSection, setShowReturnsSection] = useState<boolean>(false)
    const [showReturnsModal, setShowReturnsModal] = useState<string>('')
    const [selectedReturn, setSelectedReturn] = useState<ReturnEditInit | null>(null)
    const [returns, setReturns] = useState<ReturnEditInit[]>(propReturns || [])
    const [showWhichSections, setShowWhichSections] = useState<string[]>(['need attention'])

    function refreshReturns() {
        queryClient.invalidateQueries({ queryKey: queryKeys.returns.list._def })
        if (order.order_number) {
            vFetch(`/v1/returns?orderNumbers=${order.order_number}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const resReturns = res.returns.map((r: any) => parseResObject(r))
                        setReturns(resReturns)
                        if (setPropReturns) setPropReturns(resReturns)
                    }
                },
            })
        }
    }
    useEffect(() => {
        if (!propReturns) refreshReturns()
    }, [])

    function renderReturnPreview(returns: ReturnEditInit[]) {
        return returns.map((r) => (
            <div>
                <div
                    className='p-[8px] hover:bg-grey/50 hover:dark:bg-darkness rounded cursor-pointer bg-[whitesmoke] dark:bg-darkness/25'
                    onClick={() => {
                        setShowReturnsModal('editReturn')
                        setSelectedReturn(r)
                    }}
                >
                    {r.model.id ? (
                        <div className='flex gap-[16px] items-center'>
                            <div className='relative'>
                                <img
                                    className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                    src={window.IMAGE_MAP[`gid://shopify/Product/${r.model.product_id}`]}
                                    alt={r.model.name}
                                    height='100px'
                                    width='100px'
                                />
                                <span className='js-items-dropdown absolute flex top-[-8px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                    {r.model.quantity}
                                </span>
                            </div>
                            <div className='js-items-dropdown my-[12px]'>
                                <p className='font-semibold'>{r.model.name}</p>
                                <p className='pt-[4px]'>
                                    <strong className='js-items-dropdown'>SKU:</strong> {r.model.sku}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>{r.model.unparsedModel}</div>
                    )}
                </div>
            </div>
        ))
    }

    const sectionLabelStyle =
        'text-[16px] uppercase font-extrabold flex justify-between items-center p-[8px] text-center font-bold text-white dark:text-offwhite bg-blue/50 dark:bg-darkness rounded cursor-pointer'
    const sectionExpanderStyle =
        'flex justify-center items-center bg-blue w-full rounded-b p-[4px] bg-blue/50 dark:bg-darkness cursor-pointer hover:pt-[12px] duration-300'

    return (
        <div className={`relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small`}>
            {!alwaysOpen && (
                <h3
                    className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                    onClick={() => setShowReturnsSection(!showReturnsSection)}
                    style={
                        showReturnsSection
                            ? { color: 'white' }
                            : {
                                  width: '100%',
                                  background: 'none',
                                  color: '',
                                  marginBottom: '12px',
                                  fontSize: '16px',
                                  position: 'static',
                              }
                    }
                >
                    Returns {!showReturnsSection ? `(${returns.length})` : ''}
                    {!showReturnsSection && <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>}
                </h3>
            )}
            {(showReturnsSection || alwaysOpen) && (
                <div>
                    <div className='flex gap-[16px]'>
                        <button
                            className='my-[8px] py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                            onClick={() => setShowReturnsModal('create return')}
                        >
                            New Return
                        </button>
                        <button
                            className='my-[8px] py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                            onClick={() => setShowReturnsModal('create damage')}
                        >
                            New Damage
                        </button>
                    </div>
                    <div className='grid gap-[16px]'>
                        {returns.filter((r) => !statuses.includes(r.status)).length > 0 && (
                            <div>
                                <h3
                                    className={
                                        sectionLabelStyle +
                                        `${showWhichSections.includes('unparsed returns') ? ' rounded-b-none' : ''}`
                                    }
                                    onClick={() =>
                                        setShowWhichSections(
                                            showWhichSections.includes('unparsed returns')
                                                ? showWhichSections.filter((s) => s !== 'unparsed returns')
                                                : [...showWhichSections, 'unparsed returns']
                                        )
                                    }
                                >
                                    Unparsed Returns ({returns.filter((r) => !statuses.includes(r.status)).length})
                                    <span className='text-[24px] leading-[1]'>
                                        {showWhichSections.includes('unparsed returns') ? '-' : '+'}
                                    </span>
                                </h3>
                                <div className='[&>*]:border-b-[1px] [&>*:last-child]:border-none'>
                                    {showWhichSections.includes('unparsed returns') &&
                                        renderReturnPreview(
                                            returns.filter((r) => !statuses.includes(r.status))
                                        ).reverse()}
                                </div>
                            </div>
                        )}
                        {statuses.map((status) => {
                            return (
                                returns.filter((r) => status === r.status).length > 0 && (
                                    <div>
                                        <h3
                                            className={
                                                sectionLabelStyle +
                                                `${showWhichSections.includes(status) ? ' rounded-b-none' : ''}`
                                            }
                                            onClick={() =>
                                                setShowWhichSections(
                                                    showWhichSections.includes(status)
                                                        ? showWhichSections.filter((s) => s !== status)
                                                        : [...showWhichSections, status]
                                                )
                                            }
                                        >
                                            {status} ({returns.filter((r) => r.status === status).length})
                                            <span className='text-[24px] leading-[1]'>
                                                {showWhichSections.includes(status) ? '-' : '+'}
                                            </span>
                                        </h3>
                                        <div className='[&>*]:border-b-[1px] [&>*:last-child]:border-none'>
                                            {showWhichSections.includes(status) &&
                                                renderReturnPreview(
                                                    returns.filter((r) => r.status === status)
                                                ).reverse()}
                                        </div>
                                    </div>
                                )
                            )
                        })}
                    </div>
                    {showReturnsModal && (
                        <div className='fixed top-[51px] pb-[32px] left-[216px] font-extrabold text-[30px] w-[calc(100%-216px)] h-[calc(100%-51px)] bg-black/75 z-50'>
                            <div className='my-[32px] relative mx-auto max-w-[950px] h-[calc(100%-64px)] grid justify-center'>
                                <div className='relative mx-[32px] h-[calc(100vh-51px-64px)]'>
                                    <CloseButton onClick={() => setShowReturnsModal('')} />
                                    {showReturnsModal.includes('create') && (
                                        <CreateReturnModule
                                            returnOrder={order}
                                            setShowReturnsModal={setShowReturnsModal}
                                            refreshReturns={refreshReturns}
                                            type={showReturnsModal.includes('return') ? 'return' : 'damage'}
                                        />
                                    )}
                                    {showReturnsModal.includes('edit') && (
                                        <EditReturnModule
                                            selectedReturn={selectedReturn}
                                            returnOrder={order}
                                            setShowReturnsModal={setShowReturnsModal}
                                            refreshReturns={refreshReturns}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
