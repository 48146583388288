import React, { useState } from 'react'
import { useAllUsersQuery } from 'users/api/useQueries'
import { useGetGoals, useGetSales } from './api/useQueries'
import {
    addMonths,
    addQuarters,
    addYears,
    endOfMonth,
    endOfQuarter,
    endOfYear,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subDays,
    subMonths,
    subQuarters,
    subYears,
} from 'date-fns'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import Select from 'procurement/components/Select'
import { createColumnHelper } from '@tanstack/react-table'
import SalesTable from './SalesTable'

const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
}).format
const dateFormat = new Intl.DateTimeFormat('en-us', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
}).format

type SalesType = {
    gross: number
    discounts: number
    shipping: number
    refunds: number
    volume: number
    representative_name: string
}

export default function SalesScreen() {
    const [selectedRepName, setSelectedRepName] = useState('')
    const [filters, setFilers] = useState({
        start_date: startOfMonth(new Date()).toISOString(),
    })
    const [dateMode, setDateMode] = useState('Month')
    const endDate =
        dateMode === 'Year'
            ? endOfYear(filters.start_date).toISOString()
            : dateMode === 'Quarter'
              ? endOfQuarter(filters.start_date).toISOString()
              : endOfMonth(filters.start_date).toISOString()
    const salesQuery = useGetSales({
        ...filters,
        end_date: endDate,
    })
    const sales = salesQuery?.data?.sales || {}
    const goalsQuery = useGetGoals({})
    const goals = goalsQuery.data?.goals || []
    const usersQuery = useAllUsersQuery()
    const salesReps: any[] = (usersQuery.data?.users || []).filter((user: any) => user.roles.includes('sales'))
    const selectedRep: any = sales.current?.find((row: any) => row.representative_name === selectedRepName)
    const foundGoal = selectedRep
        ? goals
              .filter((goal: any) => goal.assigned_to === selectedRepName)
              .find((goal: any) => {
                  const goalStart = goal.start_date.split('T')[0]
                  const periodStart = filters.start_date.split('T')[0]
                  const goalEnd = goal.end_date.split('T')[0]
                  const periodEnd = subDays(new Date(endDate), 1).toISOString().split('T')[0]
                  return goalStart === periodStart && goalEnd === periodEnd
              })
        : null

    const average = (sales.current as any[])
        ?.filter((row) => {
            if (
                !row.representative_name ||
                !salesReps.find((rep) => {
                    return rep.shopify_rep_name === row.representative_name
                })
            ) {
                return false
            }
            return true
        })
        .reduce(
            (acc: any, cur: any) => {
                acc.count++
                const net = (cur.gross || 0) - (cur.shipping || 0) - (cur.discounts || 0) - (cur.refunds || 0)
                acc.gross += cur.gross
                acc.discounts += cur.discounts
                acc.shipping += cur.shipping
                acc.refunds += cur.refunds
                acc.volume += cur.volume
                acc.net += net
                return acc
            },
            { gross: 0, discounts: 0, shipping: 0, refunds: 0, net: 0, volume: 0, count: 0 }
        )
    if (average) {
        average.gross = average.gross / average.count
        average.discounts = average.discounts / average.count
        average.shipping = average.shipping / average.count
        average.refunds = average.refunds / average.count
        average.volume = average.volume / average.count
        average.net = average.net / average.count
        average.aov = average.net / average.volume
    }

    const data = sales?.current?.length > 0 ? sales.current : []
    const dataMemo = React.useMemo(() => data ?? [], [data])
    const columnHelper = createColumnHelper<SalesType>()

    const columns = [
        columnHelper.accessor('representative_name', {
            header: 'Representative',
            cell: (info) => {
                return <p>{info.getValue() || 'Online Store'}</p>
            },
        }),
        columnHelper.accessor('gross', {
            header: 'Gross',
            cell: (info) => {
                return <p>{moneyFormatter(info.getValue() || 0)}</p>
            },
        }),
        columnHelper.accessor('discounts', {
            header: 'Discounts',
            cell: (info) => {
                return <p>{moneyFormatter(info.getValue() || 0)}</p>
            },
        }),
        columnHelper.accessor('shipping', {
            header: 'Shipping',
            cell: (info) => {
                return <p>{moneyFormatter(info.getValue() || 0)}</p>
            },
        }),
        columnHelper.accessor('refunds', {
            header: 'Refunds',
            cell: (info) => {
                return <p>{moneyFormatter(info.getValue() || 0)}</p>
            },
        }),
        {
            header: 'Net Sales',
            cell: (row: any) => {
                const rep = row.row.original
                return (
                    <p>
                        {moneyFormatter(
                            (rep.gross || 0) - (rep.shipping || 0) - (rep.discounts || 0) - (rep.refunds || 0)
                        )}
                    </p>
                )
            },
        },
        columnHelper.accessor('volume', {
            header: 'Volume',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        {
            header: 'AOV',
            cell: (row: any) => {
                const rep = row.row.original
                return (
                    <p>
                        {moneyFormatter(
                            ((rep.gross || 0) - (rep.shipping || 0) - (rep.discounts || 0) - (rep.refunds || 0)) /
                                rep.volume
                        )}
                    </p>
                )
            },
        },
    ]

    const handleChangeRep = ({ target }: any) => {
        setSelectedRepName(target.value)
    }

    return (
        <div className='text-sm pb-16'>
            <div className='flex justify-between'>
                <h1 className='text-2xl font-semibold mb-4'>Sales</h1>
                <div className='flex items-center gap-2'>
                    <Button
                        size='sm'
                        variant='outline'
                        className={cn(dateMode === 'Month' && `border-fire`)}
                        onClick={() => setDateMode('Month')}
                    >
                        Month
                    </Button>
                    <Button
                        size='sm'
                        variant='outline'
                        className={cn(dateMode === 'Quarter' && `border-fire`)}
                        onClick={() => {
                            setDateMode('Quarter')
                            setFilers({
                                ...filters,
                                start_date: startOfQuarter(filters.start_date).toISOString(),
                            })
                        }}
                    >
                        Quarter
                    </Button>
                    <Button
                        size='sm'
                        variant='outline'
                        className={cn(dateMode === 'Year' && `border-fire`)}
                        onClick={() => {
                            setDateMode('Year')
                            setFilers({
                                ...filters,
                                start_date: startOfYear(filters.start_date).toISOString(),
                            })
                        }}
                    >
                        Year
                    </Button>
                </div>
                <div className='flex items-center gap-2'>
                    <Button
                        size='sm'
                        variant='outline'
                        onClick={() =>
                            setFilers({
                                ...filters,
                                start_date:
                                    dateMode === 'Year'
                                        ? subYears(startOfYear(filters.start_date), 1).toISOString()
                                        : dateMode === 'Quarter'
                                          ? startOfMonth(
                                                subQuarters(startOfQuarter(filters.start_date), 1)
                                            ).toISOString()
                                          : startOfMonth(subMonths(filters.start_date, 1)).toISOString(),
                            })
                        }
                    >
                        Prev {dateMode}
                    </Button>
                    <p>{dateFormat(new Date(filters.start_date))}</p>
                    <p>-</p>
                    <p>{dateFormat(new Date(endDate))}</p>
                    <Button
                        size='sm'
                        variant='outline'
                        onClick={() =>
                            setFilers({
                                ...filters,
                                start_date:
                                    dateMode === 'Year'
                                        ? addYears(startOfYear(filters.start_date), 1).toISOString()
                                        : dateMode === 'Quarter'
                                          ? addQuarters(startOfQuarter(filters.start_date), 1).toISOString()
                                          : startOfMonth(addMonths(filters.start_date, 1)).toISOString(),
                            })
                        }
                    >
                        Next {dateMode}
                    </Button>
                </div>
            </div>
            <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none h-full min-h-0 mb-3'>
                <div className='w-full h-full min-h-0 rounded-md'>
                    {dataMemo && (
                        <div className='relative w-full h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                            <SalesTable
                                columns={columns}
                                tableData={dataMemo}
                                showHeaders={true}
                                size={'auto'}
                                setSelectedRepName={setSelectedRepName}
                            />
                            {!salesQuery.isLoading && data?.length < 1 && (
                                <div className='pointer-events-none absolute top-0 left-0 flex justify-center items-center w-full h-full z-[60] text-black dark:text-offwhite'>
                                    <div className='p-2 px-4 rounded-md border mt-3 '>No users</div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div>
                <div className='flex gap-2 items-end mb-4'>
                    <h2 className='font-bold mb-0 leading-0'>Individual Breakdown</h2>
                    <Select
                        outerClassName='w-fit text-sm'
                        className='text-sm'
                        id='selected_user'
                        name='selected_user'
                        value={selectedRepName}
                        onChange={handleChangeRep}
                    >
                        <option value=''>Select a rep</option>
                        {salesReps
                            .sort((a, b) => (a.shopify_rep_name > b.shopify_rep_name ? 1 : -1))
                            .map((rep) => (
                                <option value={rep.shopify_rep_name}>{rep.shopify_rep_name}</option>
                            ))}
                    </Select>
                </div>
                {!selectedRep ? (
                    <>
                        {selectedRepName ? (
                            <p>There are no sales for this rep during this period</p>
                        ) : (
                            <p>Please select a sales rep</p>
                        )}
                    </>
                ) : (
                    <div>
                        {foundGoal && (
                            <div className='w-fit p-2 border border-lightgrey dark:border-darkgrey shadow-md mb-2'>
                                <h3 className='font-bold mb-1'>Goals</h3>
                                <div className='flex gap-2'>
                                    {foundGoal.total && (
                                        <div>
                                            <h4 className='font-semibold'>Sales</h4>
                                            <div className='flex gap-1'>
                                                <p>
                                                    {moneyFormatter(
                                                        (selectedRep.gross || 0) -
                                                            (selectedRep.shipping || 0) -
                                                            (selectedRep.discounts || 0) -
                                                            (selectedRep.refunds || 0)
                                                    )}
                                                </p>
                                                <p>/</p>
                                                <p>{moneyFormatter(foundGoal.total)}</p>
                                            </div>
                                        </div>
                                    )}
                                    {foundGoal.volume && (
                                        <div>
                                            <h4 className='font-semibold'>Volume</h4>
                                            <div className='flex gap-1'>
                                                <p>{selectedRep.volume}</p>
                                                <p>/</p>
                                                <p>{foundGoal.volume}</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className='flex gap-2'>
                            <div className='w-full p-2 border border-lightgrey dark:border-darkgrey shadow-md'>
                                <h3 className='font-bold mb-1'>Sales This Period</h3>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='font-semibold mb-1'>Net Sales</p>
                                        <p>
                                            {moneyFormatter(
                                                (selectedRep.gross || 0) -
                                                    (selectedRep.shipping || 0) -
                                                    (selectedRep.discounts || 0) -
                                                    (selectedRep.refunds || 0)
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>Volume</p>
                                        <p>{selectedRep.volume}</p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>AOV</p>
                                        <p>
                                            {moneyFormatter(
                                                ((selectedRep.gross || 0) -
                                                    (selectedRep.shipping || 0) -
                                                    (selectedRep.discounts || 0) -
                                                    (selectedRep.refunds || 0)) /
                                                    selectedRep.volume
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full p-2 border border-lightgrey dark:border-darkgrey shadow-md'>
                                <h3 className='font-bold mb-1'>Sales vs. Team</h3>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='font-semibold mb-1'>Net Sales</p>
                                        <p className='flex gap-2'>
                                            <span>{moneyFormatter(average?.net || 0)}</span>
                                            <span className='font-bold'>
                                                {(
                                                    (((selectedRep.gross || 0) -
                                                        (selectedRep.shipping || 0) -
                                                        (selectedRep.discounts || 0) -
                                                        (selectedRep.refunds || 0)) /
                                                        (average?.net || 1)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>Volume</p>
                                        <p className='flex gap-2'>
                                            <span>{average?.volume?.toFixed(2)}</span>
                                            <span className='font-bold'>
                                                {(((selectedRep.volume || 0) / (average?.volume || 1)) * 100).toFixed(
                                                    2
                                                )}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>AOV</p>
                                        <p className='flex gap-2'>
                                            <span>{moneyFormatter(average?.aov)}</span>
                                            <span className='font-bold'>
                                                {(
                                                    (((selectedRep.gross || 0) -
                                                        (selectedRep.shipping || 0) -
                                                        (selectedRep.discounts || 0) -
                                                        (selectedRep.refunds || 0)) /
                                                        (selectedRep.volume || 1) /
                                                        (average?.aov || 1)) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full p-2 border border-lightgrey dark:border-darkgrey shadow-md'>
                                <h3 className='font-bold mb-1'>Sales vs. Previous Period</h3>
                                <div className='flex gap-2'>
                                    <div>
                                        <p className='font-semibold mb-1'>Net Sales</p>
                                        <p className='flex gap-2'>
                                            <span>
                                                {moneyFormatter(
                                                    (sales.previous?.find(
                                                        (row: any) =>
                                                            row.representative_name === selectedRep.representative_name
                                                    )?.gross || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.shipping || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.discounts || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.refunds || 0)
                                                )}
                                            </span>
                                            <span className='font-bold'>
                                                {(
                                                    (((selectedRep.gross || 0) -
                                                        (selectedRep.shipping || 0) -
                                                        (selectedRep.discounts || 0) -
                                                        (selectedRep.refunds || 0)) /
                                                        ((sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.gross || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.shipping || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.discounts || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.refunds || 0))) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>Volume</p>
                                        <p className='flex gap-2'>
                                            <span>
                                                {
                                                    sales.previous?.find(
                                                        (row: any) =>
                                                            row.representative_name === selectedRep.representative_name
                                                    )?.volume
                                                }
                                            </span>
                                            <span className='font-bold'>
                                                {(
                                                    ((selectedRep.volume || 0) /
                                                        sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.volume) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className='font-semibold mb-1'>AOV</p>
                                        <p className='flex gap-2'>
                                            <span>
                                                {moneyFormatter(
                                                    ((sales.previous?.find(
                                                        (row: any) =>
                                                            row.representative_name === selectedRep.representative_name
                                                    )?.gross || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.shipping || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.discounts || 0) -
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.refunds || 0)) /
                                                        (sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.volume || 1)
                                                )}
                                            </span>
                                            <span className='font-bold'>
                                                {(
                                                    (((selectedRep.gross || 0) -
                                                        (selectedRep.shipping || 0) -
                                                        (selectedRep.discounts || 0) -
                                                        (selectedRep.refunds || 0)) /
                                                        (selectedRep.volume || 1) /
                                                        (((sales.previous?.find(
                                                            (row: any) =>
                                                                row.representative_name ===
                                                                selectedRep.representative_name
                                                        )?.gross || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.shipping || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.discounts || 0) -
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.refunds || 0)) /
                                                            (sales.previous?.find(
                                                                (row: any) =>
                                                                    row.representative_name ===
                                                                    selectedRep.representative_name
                                                            )?.volume || 1))) *
                                                    100
                                                ).toFixed(2)}
                                                %
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
