import { cn, parseResObject } from 'helpers'
import { useUpdateTask } from 'tasksV2/api/useQueries'
import { useSelector } from 'react-redux'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function TaskType({
    taskId,
    departmentId,
    typeId,
    className,
    editable = true,
}: {
    taskId: number
    departmentId: number
    typeId: number
    className?: string
    editable?: boolean
}) {
    const updateTaskMutation = useUpdateTask(taskId.toString())
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigs = useGetAllDepartmentConfigs(user.id)
    const configs = departmentConfigs.data?.departments || []
    const foundConfig = parseResObject(configs.find((config: any) => config.id === departmentId) || {})
    const foundTypeOption = foundConfig.types?.find((type: any) => type.id === typeId)
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        updateTaskMutation.mutate({
            id: taskId,
            type_id: target.value,
        })
    }

    return (
        <div className='flex gap-2 items-center '>
            {editable ? (
                <select
                    onClick={(e) => e.stopPropagation()}
                    className={cn(
                        'border border-lightgrey dark:border-darkgrey rounded dark:bg-darkbg1 cursor-pointer',
                        updateTaskMutation.isPending && 'opacity-50',
                        className
                    )}
                    id='task_type_id'
                    name='task_type_id'
                    value={foundTypeOption?.id}
                    onChange={handleChange}
                >
                    {!typeId && <option value=''> </option>}
                    {foundConfig?.types?.map((type: any) => (
                        <option key={type.id} value={type.id}>
                            {type.value}
                        </option>
                    ))}
                </select>
            ) : (
                <p>{foundTypeOption?.value}</p>
            )}
        </div>
    )
}
