import { addDays, endOfWeek, format, isThisWeek, startOfWeek, subDays } from 'date-fns'
import { useGetTimesheetEntries, useGetTimesheets, useUpdateTimesheet } from './api/useQueries'
import { useState } from 'react'
import { Button } from 'custom_components/component_Basics/Button'
import { useSelector } from 'react-redux'
import { buildWeekArrayFromEntries, getTotalsFromEntries } from './helpers'
import EntryRow from './components/EntryRow'
import CreateEntryRow from './components/CreateEntryRow'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import UserSelector from './components/UserSelector'
import { useSearchParams } from 'react-router-dom'
import { sendToast } from 'helpers'
import { FaCheckCircle } from 'react-icons/fa'
import { TbCircleDotted } from 'react-icons/tb'

export default function TimesheetListScreen() {
    const updateTimesheetMutation = useUpdateTimesheet()
    const user = useSelector((state: any) => state.user)
    const [searchParams] = useSearchParams()
    const selectedUserId =
        user.type === 'Client' ? user.id.toString() : searchParams.get('user_id') || user.id.toString()
    const [weekStartDate, setWeekStartDate] = useState(startOfWeek(new Date(), { weekStartsOn: 1 }))
    const weekEndDate = endOfWeek(weekStartDate, { weekStartsOn: 1 })
    const timesheetQuery = useGetTimesheets({
        starts_on: weekStartDate.toISOString(),
        ends_on: weekStartDate.toISOString(),
        user_id: selectedUserId,
    })
    const currentTimeSheet = (timesheetQuery.data?.timesheets || [])[0] || {}
    const timesheetEntriesQuery = useGetTimesheetEntries({
        starts_on: weekStartDate.toISOString(),
        ends_on: weekEndDate.toISOString(),
        user_id: selectedUserId,
    })
    const timesheetEntries = timesheetEntriesQuery.data?.timesheetEntries || []

    const weekdayArray = buildWeekArrayFromEntries(weekStartDate, timesheetEntries)
    const { overtime: totalOvertimeMinutes } = getTotalsFromEntries(timesheetEntries)
    const isEditable =
        (isThisWeek(weekStartDate, { weekStartsOn: 1 }) &&
            user.type === 'Client' &&
            currentTimeSheet.status !== 'submitted') ||
        user.type !== 'Client'
    const isUnsubmitable = user.type === 'Client' && !isThisWeek(weekStartDate, { weekStartsOn: 1 })

    const handleToggleSubmitted = () => {
        if (!currentTimeSheet.id) {
            return sendToast({ message: 'Could not process because there are no entries in this sheet!' })
        }
        updateTimesheetMutation.mutate({
            id: currentTimeSheet.id,
            status: currentTimeSheet.status === 'open' ? 'submitted' : 'open',
        })
    }

    return (
        <div className='text-sm max-w-[1000px] mx-auto'>
            <div className='flex justify-between mb-4'>
                <div>
                    <div className='flex justify-between'>
                        <h1 className='text-2xl font-semibold'>Timesheets</h1>
                    </div>
                    <div className='flex gap-2 mb-4'>
                        <h2 className='font-base font-medium items-center flex gap-2'>
                            {currentTimeSheet.status === 'submitted' ? (
                                <FaCheckCircle title='Submitted' className='text-lime' />
                            ) : (
                                <TbCircleDotted title='Not Submitted' className='text-grey' />
                            )}
                            <span>
                                {format(weekStartDate, 'MMM dd yyyy')} - {format(weekEndDate, 'MMM dd yyyy')}{' '}
                            </span>
                        </h2>
                        <Button
                            onClick={() => setWeekStartDate(subDays(weekStartDate, 7))}
                            variant='outline'
                            className='text-xs h-fit p-1 leading-none'
                        >
                            Prev
                        </Button>
                        <Button
                            onClick={() => setWeekStartDate(addDays(weekStartDate, 7))}
                            variant='outline'
                            className='text-xs h-fit p-1 leading-none'
                        >
                            Next
                        </Button>
                    </div>
                </div>
                {user.type !== 'Client' && <UserSelector selectedUserId={selectedUserId} />}
                <div className='border rounded border-lightgrey dark:border-darkgrey shadow-sm p-2'>
                    <div className='flex justify-between gap-8 pb-1 mb-1 border-b border-lightgrey dark:border-darkgrey'>
                        <h3 className='text-base font-semibold'>Totals</h3>
                        <Button
                            onClick={handleToggleSubmitted}
                            disabled={isUnsubmitable}
                            className='text-xs font-bold h-fit p-1 leading-none'
                            variant='outline'
                        >
                            {currentTimeSheet.status === 'submitted' ? 'Unsubmit Timesheet' : 'Submit Timesheet'}
                        </Button>
                    </div>
                    <div className='grid grid-cols-[auto_auto] gap-x-4'>
                        <p>Overtime Hours:</p>
                        <p className='font-bold justify-self-end'>{(totalOvertimeMinutes / 60).toFixed(2)}</p>
                    </div>
                </div>
            </div>

            {weekdayArray.map(([day, entries, date]) => (
                <div className='border rounded p-2 mb-2 shadow-sm' key={date.toISOString()}>
                    <h2 className='font-bold text-lg'>{day}</h2>
                    <div className='flex flex-col gap-2'>
                        {entries.map((e) => (
                            <EntryRow entry={e} disabled={!isEditable} key={`timesheetEntry-${e.id}`} />
                        ))}
                    </div>
                    <CreateEntryRow htmlDate={HTMLDateInputFormatter(date)} disabled={!isEditable} />
                </div>
            ))}
        </div>
    )
}
