import { useContext, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn } from '../../../helpers'
import { useCompanyDetailQuery, useCompanyProductsQuery, useOptionValuesQuery } from '../../api/useQueries'
import { PROCUREMENT_PRODUCT_SORT_OPTIONS } from '../../constants'
import { ProcurementContext, ProcurementContextType, formatVariantOptions } from '../../helpers'
import { CompanyProductType } from '../CompanyProduct'

export default function ShopifyCreateAreYouSure({
    product,
    setShowShopifyCreate,
    setShowAreYouSure,
}: {
    product: any
    setShowShopifyCreate: any
    setShowAreYouSure: any
}) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const modalRef = useRef(null)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)

    const companyQuery = useCompanyDetailQuery(selectedCompany.id, selectedCompany)
    const company = companyQuery.data?.company || {}

    const allCompanyProductIdsQuery = useCompanyProductsQuery(
        {
            filterVariants: false,
            companyId: selectedCompany.id,
            sortBy: PROCUREMENT_PRODUCT_SORT_OPTIONS[0].value,
            limit: 10000,
        },
        {}
    )
    const {
        products: allProducts,
    }: {
        products: CompanyProductType[]
    } = allCompanyProductIdsQuery?.data || { products: [] }

    const optionValuesQuery = useOptionValuesQuery(product.id)
    const { optionValues }: { optionValues: any } = optionValuesQuery?.data || {}

    const variantMapOptions = formatVariantOptions(optionValues)
    const relatedVariants = allProducts.filter((relatedProduct: any) => relatedProduct.variant_of === product.id)
    const requiredFields = [
        { label: 'product type', value: 'product_type' },
        { label: 'cost', value: 'cost' },
        { label: 'list price', value: 'list_price' },
    ]
    let missingFields: { label: string; value: string; sku: string }[] = []
    requiredFields.forEach((field: { label: string; value: string }) => {
        ;[product, ...relatedVariants].forEach((product) => {
            if (typeof product[field.value] === 'number' && product[field.value] === 0) {
                missingFields.push({ ...field, sku: product.sku })
                return
            }
            if (!product[field.value]) {
                missingFields.push({ ...field, sku: product.sku })
            }
        })
    })
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowAreYouSure(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-[40] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center company-modal'
        >
            <div
                className={cn(
                    'p-5 gap-4 bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative',
                    'w-3/7 h-fit'
                )}
            >
                <button
                    onClick={() => {
                        setShowAreYouSure(false)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[6px]'
                >
                    &times;
                </button>
                <div className='flex flex-col gap-6 items-center mr-2 mt-4'>
                    <div className='flex flex-col gap-3 items-center'>
                        {missingFields.length > 0 &&
                            missingFields.map((field) => {
                                return (
                                    <p className='leading-none text-danger dark:text-darkdanger font-semibold'>
                                        {field.sku} is missing {field.label}*
                                    </p>
                                )
                            })}
                        {company.shopify_vendor_name && missingFields.length < 1 && (
                            <>
                                <p>This process may only be completed once for each product.</p>
                                <p> Please make sure everything is correct before proceeding.</p>
                            </>
                        )}
                        {!company.shopify_vendor_name && (
                            <div className='flex flex-col gap-8'>
                                <p className='leading-none text-danger dark:text-darkdanger font-semibold'>
                                    Company has no assigned shopify vendor. *
                                </p>
                            </div>
                        )}
                        {!optionValuesQuery.isLoading &&
                            variantMapOptions &&
                            variantMapOptions.length < relatedVariants.length && (
                                <p className='leading-none text-danger dark:text-darkdanger font-bold'>
                                    There are too few option combinations to map all related variants *
                                </p>
                            )}
                    </div>
                    {company.shopify_vendor_name && (
                        <div className='w-full border-t border-darkgrey'>
                            <div className='flex gap-3 justify-center pt-2'>
                                {missingFields.length < 1 && (
                                    <Button
                                        variant={'outline'}
                                        onClick={() => {
                                            setShowAreYouSure(false)
                                            return setShowShopifyCreate(true)
                                        }}
                                        className={` shrink-0 text-[14px] leading-none font-bold uppercase`}
                                    >
                                        <p>continue</p>
                                    </Button>
                                )}
                                <Button
                                    variant={'ghost'}
                                    onClick={() => {
                                        setShowAreYouSure(false)
                                    }}
                                    className={`self-center shrink-0 text-[14px] leading-none font-bold uppercase`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                    {!company.shopify_vendor_name && (
                        <div className='w-full border-t border-darkgrey'>
                            <div className='flex gap-3 justify-center pt-2'>
                                <Button
                                    variant={'outline'}
                                    onClick={() =>
                                        setSearchParams((prev: any) => {
                                            prev.set('view', 'activity')
                                            return prev
                                        })
                                    }
                                    className={` shrink-0 text-[14px] leading-none font-bold uppercase`}
                                >
                                    <p>add vendor</p>
                                </Button>
                                <Button
                                    variant={'ghost'}
                                    onClick={() => {
                                        setShowAreYouSure(false)
                                    }}
                                    className={`self-center shrink-0 text-[14px] leading-none font-bold uppercase`}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
