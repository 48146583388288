import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const roles = createQueryKeys('roles', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getRoles(),
    }),
})

export const rolesQueries = mergeQueryKeys(roles)
