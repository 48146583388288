import { CheckIcon } from '@radix-ui/react-icons'
import { cn } from 'helpers'
import { formatDateSimple } from 'procurement/helpers'
import { useState } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

export default function RelatedLeadTimeList({
    relatedLeadTimes,
}: {
    relatedLeadTimes: { id: number; quantity: number; available_at: any; status: string }[] | []
}) {
    const [searchParams] = useSearchParams()
    const [showCompleted, setShowCompleted] = useState(false)
    const location = useLocation()
    const filteredRelatedLeadTimes = relatedLeadTimes
        .map((leadTime) => {
            if (showCompleted) {
                return leadTime
            } else if (leadTime.status === 'Pending') {
                return leadTime
            }
        })
        .filter((v) => v)
    return (
        <div className='mb-3'>
            <div className='flex gap-4 mb-2 items-center'>
                <h2 className='font-bold'>Related Lead Times</h2>
                <div className='flex gap-2 items-center'>
                    <p className='text-sm'>Show Completed</p>
                    <div
                        onClick={() => {
                            setShowCompleted(!showCompleted)
                        }}
                        className={cn(
                            ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                            showCompleted ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                        )}
                    >
                        <CheckIcon className={cn('h-4 w-4')} />
                    </div>
                </div>
            </div>
            <div className='border border-lightgrey dark:border-darkgrey rounded max-h-[300px] overflow-auto'>
                <div className='rounded-t grid grid-cols-4 text-xs font-bold bg-accent1 dark:bg-darkbg2 text-white dark:text-offwhite'>
                    <p className='p-2'>Id</p>
                    <p className='p-2'>Status</p>
                    <p className='p-2'>Quantity</p>
                    <p className='p-2 text-center'>Available At</p>
                </div>
                {!filteredRelatedLeadTimes.length && <div className='h-[36px]'></div>}
                {filteredRelatedLeadTimes.map((leadTime: any) => (
                    <Link
                        draggable={false}
                        to={`/products/inventory-lead-times/${leadTime.id}?${searchParams.toString()}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className='grid grid-cols-4 items-center text-sm border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkgrey'
                    >
                        <span className='p-2'>{leadTime.id}</span>
                        <span className='p-2'>{leadTime.status}</span>
                        <span className='p-2'>{leadTime.quantity}</span>
                        <span className='p-2 self-center justify-self-center'>
                            {formatDateSimple(new Date(leadTime?.available_at.replace('Z', '')))}
                        </span>
                    </Link>
                ))}
            </div>
        </div>
    )
}
