import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { buttonVariants } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { DayPicker } from 'react-day-picker'
import { TaskCountCalendarDayContent } from './TaskCountCalendarDayContent'
import { TaskCalendarDay } from './TaskCalendarDay'

export default function DueAtCalendar({
    selectedDate,
    setSelectedDate,
    hideTaskCount = false,
    className,
}: {
    selectedDate: string
    setSelectedDate: (ISOString: string) => void
    hideTaskCount?: boolean
    className?: string
}) {
    return (
        <DayPicker
            showOutsideDays={true}
            components={{
                Day: ({ ...props }) => (
                    <TaskCalendarDay
                        selectedDate={selectedDate}
                        onClick={setSelectedDate}
                        displayMonth={props.displayMonth}
                        date={props.date}
                    />
                ),
                DayContent: ({ ...props }) => (
                    <>
                        <TaskCountCalendarDayContent
                            activeModifiers={props.activeModifiers}
                            date={props.date}
                            displayMonth={props.displayMonth}
                            hideTaskCount={hideTaskCount}
                        />
                    </>
                ),

                IconLeft: ({ ...props }) => <ChevronLeftIcon className='h-4 w-4' />,
                IconRight: ({ ...props }) => <ChevronRightIcon className='h-4 w-4' />,
            }}
            className={className}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center text-text dark:text-darktext1',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center ',
                nav_button: cn(
                    buttonVariants({ variant: 'outline' }),
                    'text-text dark:text-darktext1 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
                ),
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex justify-between',
                head_cell:
                    ' text-muted-foreground rounded-md w-12 font-normal text-text1 dark:text-darktext1 text-[0.8rem]',
                row: 'flex w-full gap-[2px]',
                cell: cn(
                    'relative h-12 w-12 p-0 mt-[2px] text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-bg1  dark:[&:has([aria-selected])]:bg-darkbg1 [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
                    '[&:has([aria-selected])]:rounded-md'
                ),
                day: cn(
                    buttonVariants({
                        variant: 'ghost',
                        className:
                            'text-text1 dark:text-darktext1 border-[1px] p-[2px] border-darkgrey hover:bg-lightgrey h-4 hover:dark:bg-darkbg2 hover:text-accent1 dark:hover:text-darktext1',
                    }),
                    'h-12 w-12  font-normal aria-selected:opacity-100 '
                ),
                day_range_start: 'day-range-start',
                day_range_end: 'day-range-end',
                day_selected:
                    'bg-accent1 dark:bg-darkaccent1 text-text2 dark:text-darktext2 text-primary-foreground hover:bg-darkgrey hover:text-text1 focus:bg-primary focus:text-primary-foreground',
                day_today: 'bg-lightgrey dark:bg-darkbg2 text-text1 dark:text-text2 text-accent-foreground',
                day_outside:
                    'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                day_disabled: 'text-muted-foreground opacity-50',
                day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
                day_hidden: 'invisible',
            }}
        />
    )
}
