import { vFetch } from 'helpers'

export const getSales = async (filters: any) => {
    const params = Object.entries(filters)
        .filter(([_key, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    return vFetch(`/v2/orders/sales${params ? `?${params}` : ''}`)
}

export const getGoals = async (filters: any) => {
    return vFetch('/v1/sales')
}
