import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { CreateChangelogEntryOptions } from '../Changelog.types'
import Textarea from 'procurement/components/Textarea'
import Select from 'procurement/components/Select'
import { useCreateEntry } from 'changelog/api/useQueries'

export default function CreateEntryModal({
    changelog_id,
    closeModal,
}: {
    changelog_id: number
    closeModal: () => void
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [entry, setEntry] = useState<CreateChangelogEntryOptions>({
        title: '',
        description: '',
        status: 'pending',
        type: 'patch',
        changelog_id,
    })
    const createEntryMutation = useCreateEntry()

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEntry((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleCreate = () => {
        createEntryMutation.mutate(entry)
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                // closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 rounded shadow-md relative max-w-[90%] max-h-[90%] min-w-[400px]'>
                <button onClick={closeModal} className='text-lg text-red dark:text-lightred absolute top-1 right-1'>
                    <FaTimes />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Changelog Entry</h2>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-1'>
                        <Select id='type' name='type' value={entry.type} onChange={handleChange} label='Type'>
                            <option value=''>Select a type</option>
                            <option value='patch'>Patch</option>
                            <option value='minor'>Feature</option>
                            <option value='major'>Major / Breaking Change</option>
                        </Select>
                        <Select id='status' name='status' value={entry.status} onChange={handleChange} label='Status'>
                            <option value=''>Select a status</option>
                            <option value='pending'>Pending Review</option>
                            <option value='published'>Published</option>
                        </Select>
                    </div>
                    <Input
                        id='title'
                        name='title'
                        type='text'
                        value={entry.title}
                        onChange={handleChange}
                        label='Title'
                    />
                    <Textarea
                        id='description'
                        name='description'
                        value={entry.description}
                        onChange={handleChange}
                        label='Description'
                    />
                </div>
                <Button className='block mx-auto mt-4' onClick={handleCreate} disabled={entry.title.length === 0}>
                    Create Entry
                </Button>
            </div>
        </div>
    )
}
