import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { useGetProductsQuery } from 'productSetupV2/api/useQueries'
import ProductSearchSmall from 'productSetupV2/components/ProductSearchSmall'
import { forwardRef, useEffect, useState } from 'react'
import { cn, useDebounce } from '../../helpers'

export const ProductSearchPopover = forwardRef<any, any>(function ProductSearchPopover(props, ref) {
    const {
        values,
        isPending,
        className,
        dismountingPopover,
        setProductOptions,
    }: {
        values: any
        isPending: boolean
        className?: string
        dismountingPopover: boolean
        setProductOptions: any
    } = props
    const [activated, setActivated] = useState(false)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)

    const productsQuery = useGetProductsQuery({}, debouncedSearch, Boolean(debouncedSearch), true)
    const { products } = productsQuery?.data || {}
    // const filteredOptions = options.filter((option) => {
    //     if (typeof option === 'string') {
    //         return option.toLowerCase().includes(search.toLowerCase())
    //     }
    //     return (
    //         option.label.toLowerCase().includes(search.toLowerCase()) ||
    //         option.value?.toLowerCase().includes(search.toLowerCase())
    //     )
    // })
    useEffect(() => {
        if (dismountingPopover) {
            setActivated(false)
        } else {
            setActivated(true)
        }
    }, [dismountingPopover])
    return (
        <div
            className={`${cn(
                'transition-all absolute top-[calc(100%+8px)] left-1/2 translate-x-[-50%] bg-white dark:bg-darkaccent p-2 rounded border border-darkgrey z-[51] flex flex-col gap-2 min-h-[330px]',
                className || ''
            )} ${
                isPending
                    ? 'after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-white dark:after:bg-darkaccent after:opacity-50 pointer-events-none'
                    : ''
            } ${activated ? '' : 'opacity-0 scale-90'}`}
        >
            <div className='flex items-center gap-2'>
                <input
                    className='py-[2px] px-[8px] rounded-[4px] w-full bg-lightgrey dark:bg-faintplus focus:outline-none dark:text-white'
                    placeholder='Search'
                    type='text'
                    value={search}
                    ref={ref}
                    onChange={({ target }) => setSearch(target.value)}
                />
                <FetchingSpinner
                    className={cn(
                        'mx-auto opacity-0',
                        (productsQuery.isFetching || productsQuery.isLoading) && 'opacity-100'
                    )}
                    isFetching={productsQuery.isFetching}
                />
            </div>
            <div className='bg-lightgrey dark:bg-darkness max-h-[272px] overflow-auto'>
                {search != '' && products?.length > 0 && (
                    <ProductSearchSmall
                        crossSells={products}
                        values={values}
                        type={'add'}
                        setProductOptions={setProductOptions}
                    />
                )}
            </div>
            {(search == '' || products?.length < 1) && (
                <div className='flex items-center justify-center border rounded w-fit mx-auto px-2 '>
                    {search === '' && <div>Search for a product</div>}
                    {search != '' && products?.length < 1 && <div>No results</div>}
                </div>
            )}
        </div>
    )
})

type OptionType = string | { label: string; value: string | null; icon: any }
