import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Cross2Icon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'
import MoreActions, { Option } from 'processingTimes/components/MoreActions'
import { formatDateSimple } from 'procurement/helpers'
import React, { useRef } from 'react'
import { FaGear } from 'react-icons/fa6'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { useGetAllDepartmentConfigs, useGetTasks } from 'tasksV2/api/useQueries'
import { PRIORITY_MAP, TASK_PRIORITIES, TASK_STATUSES, TaskV2 } from 'tasksV2/constants/tasks'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import Assignment from '../components/Assignment'
import Association from '../components/Association'
import Department from '../components/Department'
import TaskStatus from '../components/TaskStatus'
import TaskType from '../components/TaskType'
import TasksTable from './TasksTable'
import { useSelector } from 'react-redux'

export default function TaskListSmall({
    department,
    filters,
    tableSetup,
    setTableSetup,
    position,
    dragging,
    setDragging,
    size,
}: {
    department: any
    filters: any
    tableSetup: any
    setTableSetup: any
    position: number
    dragging: boolean
    setDragging: any
    size: string
}) {
    const user = useSelector<any, any>((state) => state.user)
    const [searchParams, setSearchParams] = useSearchParams()
    const priorityRef = useRef<HTMLDivElement>(null)
    const typesString = searchParams.get(`${department?.title}_types`) || ''
    const prioritiesString = searchParams.get(`${department?.title}_priorities`) || ''
    const statusString = searchParams.get(`${department?.title}_statuses`) || ''

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: department?.id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const typeValues = decodeURIComponent(typesString)
        .split(',')
        .filter((v) => v)
    const priorityValues = decodeURIComponent(prioritiesString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)

    const { departments } = departmentConfigQuery?.data || {}

    const pinnedTypes = departments
        ?.map((department: any) => {
            return department.types.flat()
        })
        .flat()
    const pinnedTypeOptions = pinnedTypes?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })

    const types = department?.types?.flat()
    const typeOptions = types?.map((type: any) => {
        return {
            label: type.value,
            value: type.id.toString(),
            icon: undefined,
        }
    })
    const priorityOptions = TASK_PRIORITIES?.map((priority: any) => {
        return {
            label: priority,
            value: priority,
            icon: undefined,
        }
    })
    const statusOptions = TASK_STATUSES?.map((status: any) => {
        return {
            label: status,
            value: status,
            icon: undefined,
        }
    })

    const searchFilters = {
        ...filters,
        search: filters.search,
        departmentIds: department?.id === -1 ? undefined : [department?.id],
        pinned: department?.id == -1 ? true : false,
        typeIds: typeValues,
        statuses: statusValues.length > 0 ? statusValues : filters.statuses,
        priorities: priorityValues.length > 0 ? priorityValues : filters.priorities,
        dateRange: undefined,
        overdue: undefined,
    }
    const pinnedFilters = {
        search: filters.search,
        typeIds: typeValues,
        statuses: statusValues,
        priorities: priorityValues,
        pinned: true,
    }

    const tasksQuery = useGetTasks(
        department?.id === -1
            ? pinnedFilters
            : filters.search
              ? searchFilters
              : {
                    ...filters,
                    typeIds: typeValues,
                    statuses: statusValues.length > 0 ? statusValues : filters.statuses,
                    priorities: priorityValues.length > 0 ? priorityValues : filters.priorities,
                    departmentIds: department?.id === -1 ? undefined : [department?.id],
                    pinned: department?.id == -1 ? true : false,
                }
    )
    const { tasks }: { tasks: TaskV2[] } = tasksQuery?.data || {}
    const filteredTasks =
        user.type === 'Admin' || user.type === 'SuperAdmin'
            ? tasks
            : tasks?.filter((task) => task.status !== 'Archived')

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options:
                    department?.id == -1
                        ? pinnedTypeOptions.filter(
                              (option: any) =>
                                  filteredTasks?.map((task: any) => task.type_id?.toString())?.includes(option.value)
                          )
                        : typeOptions,
                title: 'Types',
                field: `${department?.title}_types`,
                values: typeValues,
                searchToggle: true,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: statusOptions,
                title: 'Status',
                field: `${department?.title}_statuses`,
                values: statusValues,
                searchToggle: true,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: priorityOptions,
                title: 'Priority',
                field: `${department?.title}_priorities`,
                values: priorityValues,
                searchToggle: true,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('assigned_to')
                prev.delete('departments')
                prev.delete('types')
                prev.delete('priorities')
                prev.delete('statuses')
                prev.delete('estimates')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    const tableData = tasks?.length > 0 ? filteredTasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<TaskV2>()

    const taskColumns = [
        columnHelper.accessor('priority', {
            cell: (info) => {
                const priorityMap = PRIORITY_MAP
                const { icon: Priority, className: priorityClassName } =
                    priorityMap[info.getValue() as keyof typeof priorityMap]
                return (
                    <div
                        id={info.row.original.id.toString()}
                        ref={priorityRef}
                        className='flex gap-2 items-center relative'
                    >
                        <Priority className={`text-[20px] ${priorityClassName}`} />
                    </div>
                )
            },
            size: 20,
        }),
        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => {
                const due_at = info.cell.row.original.due_at
                return (
                    <div>
                        <TaskStatus
                            taskId={info.row.original.id}
                            dueAt={due_at}
                            status={info.getValue()}
                            editable={false}
                        />
                    </div>
                )
            },
            maxSize: 130,
        }),
        columnHelper.accessor('title', {
            header: 'Title',
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group z-50'>
                        <div>{truncate(info.getValue(), size === 'wide' || size === 'lg' ? 70 : 30)}</div>
                        {info.getValue().length > (size === 'wide' || size === 'lg' ? 70 : 30) && (
                            <div className='z-50 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-50 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: size === 'wide' || size === 'lg' ? 600 : 300,
        }),
        columnHelper.accessor('department_id', {
            header: 'Department',
            enableHiding: department?.title === 'Pinned' ? false : true,
            cell: (info) => {
                return (
                    <div key={info.row.original.id}>
                        <Department departmentId={info.getValue()} />
                    </div>
                )
            },
        }),

        columnHelper.accessor('type_id', {
            header: 'Type',
            cell: (info) => {
                return (
                    <div className=''>
                        <TaskType
                            taskId={info.row.original.id}
                            departmentId={info.row.original.department_id}
                            typeId={info.getValue()}
                            editable={false}
                        />
                    </div>
                )
            },
            size: 200,
        }),
        columnHelper.accessor('associations', {
            header: 'Associations',
            cell: (info) => {
                return (
                    <div key={info.row.original.id} className='flex gap-1 flex-wrap'>
                        {info
                            .getValue()
                            .filter((association: any) => association.resource_name !== 'user')
                            .map((association: any) => (
                                <React.Fragment
                                    key={`${info.row.original.id}+${association.resource_id}` + '-association'}
                                >
                                    <Association id={info.row.original.id} association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            size: size === 'wide' || size === 'lg' ? 600 : 150,
        }),
        {
            header: 'Assigned',
            cell: ({ row }: { row: any }) => {
                return (
                    <div className='flex gap-1 flex-wrap'>
                        {row.original.associations
                            .filter((association: any) => association.resource_name === 'user')
                            .map((association: any) => (
                                <React.Fragment key={`${row.original.id}+${association.resource_id}` + '-assignment'}>
                                    <Assignment association={association} />
                                </React.Fragment>
                            ))}
                    </div>
                )
            },
            size: 90,
        },
        columnHelper.accessor('due_at', {
            header: 'Due At',
            cell: (info) => {
                return <p>{info.getValue() ? formatDateSimple(new Date(info.getValue())) : ''}</p>
            },
            size: 170,
        }),
    ]

    const columnCount = taskColumns?.length.toString() || 10

    const sizeOptions: Option[] = [
        {
            displayText: 'Hide',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    hidden: true,
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },

        {
            displayText: 'Small',
            icon: undefined,
            font: ' border-t border-darkgrey',
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'sm',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Wide',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'wide',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Tall',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'tall',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
        {
            displayText: 'Large',
            icon: undefined,
            action: () => {
                const newValue = structuredClone(tableSetup)
                newValue[position] = {
                    ...newValue[position],
                    size: 'lg',
                }
                localStorage.setItem('splitTasksSetupOptions', JSON.stringify(newValue))
                setTableSetup(newValue)
            },
        },
    ]

    const isFiltered = statusValues.length > 0 || priorityValues.length > 0 || typeValues.length > 0

    return (
        <>
            <div
                ref={setNodeRef}
                style={style}
                {...attributes}
                key={department?.id}
                className={cn(
                    'rounded shadow-sm w-full h-full border border-lightgrey dark:border-darkaccent ',
                    size === 'wide' && 'col-span-2',
                    size === 'tall' && 'row-span-2',
                    size === 'lg' && 'col-span-2 row-span-2'
                )}
            >
                <div className='flex justify-between dark:bg-darkaccent cursor-default rounded-t py-1 px-4 pr-2 bg-lightgrey'>
                    <div className='flex w-full gap-2 items-center justify-between'>
                        <div className='flex gap-2 items-center'>
                            <div className='h-full cursor-grab'>
                                <MdOutlineDragIndicator
                                    {...listeners}
                                    onMouseDown={() => setDragging(true)}
                                    className='h-full w-[20px]'
                                />
                            </div>
                            <div className='font-bold text-[18px] leading-tight'>{department?.title}</div>
                            {filteredTasks?.length > 0 && <div className=''>({filteredTasks?.length})</div>}
                        </div>
                        <div className='flex gap-2 items-center'>
                            <FetchingSpinner isFetching={tasksQuery.isFetching} />

                            {companyFilterBarObject.params.length &&
                                companyFilterBarObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.title + param.field}
                                            searchToggle={param.searchToggle}
                                            editSearchParams={param.editSearchParams}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={companyFilterBarObject.setFunction}
                                            pageString={param.pageString}
                                            preview={false}
                                        />
                                    )
                                })}
                            <Button
                                variant={'outline'}
                                size={'icon'}
                                onClick={() => {
                                    searchParams.delete(`${department?.title}_priorities`)
                                    searchParams.delete(`${department?.title}_statuses`)
                                    searchParams.delete(`${department?.title}_types`)
                                    setSearchParams(searchParams)
                                }}
                                className={cn(
                                    'flex h-fit w-fit p-[2px] items-center bg-lightgrey cursor-default',
                                    isFiltered && 'border-danger text-danger cursor-pointer'
                                )}
                            >
                                <Cross2Icon className=' h-4 w-4' />
                            </Button>
                            <MoreActions options={sizeOptions} displayContent={{ icon: FaGear }} />
                        </div>
                    </div>
                    <div className='font-bold text-[18px] leading-tight'></div>
                </div>
                <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
                <div className={cn('w-full min-h-[340px]', dragging && 'h-[400px]')}>
                    {!tasksQuery.isLoading && filteredTasks?.length < 1 && (
                        <div className='flex justify-center items-center w-full h-2/3'>
                            <div className='p-2 px-4 rounded-md border mt-8'>No tasks</div>
                        </div>
                    )}
                    {tableDataMemo && tableDataMemo.length > 0 && !dragging && (
                        <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <TasksTable
                                columns={taskColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                                showDepartment={department.title === 'Pinned'}
                                size={size}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}
