import { useCreateNote } from 'orderHubScreen/api/useQueries'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { TaskDepartment, TaskV2 } from 'tasksV2/constants/tasks'
import {
    authorAndTypeRegex,
    formatCSTTimestampToDate,
    formatISOToCSTTimestamp,
    sendToast,
    taskIdRegex,
    timestampRegex,
} from '../../helpers'
import { OrderInit } from '../orders.types'

type NewNoteFieldProps = {
    order: OrderInit
    tasks: TaskV2[]
    task_type?: string
    department_id?: number
    addNewTask?: Function
}

// todo set behavior for default departments

export default function NewNoteField({ order, tasks, department_id }: NewNoteFieldProps) {
    const user = useSelector<any, any>((state) => state.user)
    const location = useLocation()
    const [newNote, setNewNote] = useState<string>('')
    const departmentConfigQuery = useGetAllDepartmentConfigs(user.id)
    const departments: TaskDepartment[] = departmentConfigQuery.data?.departments || []
    const createNote = useCreateNote()

    useEffect(() => {
        const newNoteTextArea = document.getElementById('new_note') as HTMLElement
        const minHeight = parseInt(
            window.getComputedStyle(newNoteTextArea, null).getPropertyValue('min-height').replace('px', '')
        )

        if (newNoteTextArea.scrollHeight > minHeight) {
            newNoteTextArea.style.height = '0'
            newNoteTextArea.style.height = `${newNoteTextArea.scrollHeight + 2}px`
        }
    }, [newNote])

    const inputStyle =
        'bg-lightgrey dark:bg-darkness p-[4px] border-[1px] border-transparent focus:border-darkgrey focus:dark:border-accent outline-0 rounded'

    function handleSubmit() {
        if (!newNote) return sendToast({ message: 'Please enter a note before clicking submit.' })
        const origin = location.pathname.toLowerCase().includes('draft-orders') ? 'draft_order' : 'order'
        const oldNotes = (order.note || '').slice().trim()
        let addedNote = newNote.trim()

        const relatedTask = tasks.find((t) => t.department_id == department_id)
        const timestampIncluded = addedNote.match(timestampRegex)

        const departmentName = departments?.find((department: TaskDepartment) => department.id == department_id)?.title

        const abbreviatedType = departmentName
            ? `${departmentName
                  .split(' ')
                  .filter((v) => v)
                  .map((w) => w[0].toUpperCase())
                  .join('')}`
            : ``

        const notesWithoutEndTimestamp = addedNote
            .slice(0, addedNote.length - (timestampIncluded?.slice(-1)[0].length || 0))
            .trim()
        const timestampRemoved =
            timestampIncluded &&
            (notesWithoutEndTimestamp.match(timestampRegex) || []).length !== timestampIncluded.length

        const originalTimestamp = timestampRemoved
            ? (timestampIncluded || [''])[(timestampIncluded || []).length - 1]
            : ''

        if (!timestampRemoved) {
            addedNote += ` ${formatISOToCSTTimestamp()} ${user.user_id} ${abbreviatedType}`
        } else if (originalTimestamp) {
            const [author, type] = (originalTimestamp.match(authorAndTypeRegex) || [''])[0]
                .trim()
                .split(' ')
                .filter((v) => v)
            const originalDate = formatCSTTimestampToDate(originalTimestamp)
            const timestampedToday =
                (originalDate.getFullYear() === new Date().getFullYear() &&
                    originalDate.getMonth() == new Date().getMonth() &&
                    originalDate.getDate() === new Date().getDate()) ||
                (originalDate.getTime() / 1000 > Date.now() - 600 && originalDate.getTime() / 1000 < Date.now() + 600)
            const timestampByOriginalUser = author.toLowerCase() === user.user_id.toLowerCase()
            if (timestampedToday && timestampByOriginalUser)
                addedNote =
                    notesWithoutEndTimestamp + ` ${formatISOToCSTTimestamp()} ${user.user_id} ${abbreviatedType}`
        }

        const noteTasks = tasks.map((t) =>
            [
                t.department_title
                    ?.split(' ')
                    .map((w) => w[0].toUpperCase())
                    .join(''),
                t.id,
            ].join(':')
        )
        if (
            abbreviatedType &&
            !noteTasks?.find((noteTask) => noteTask === `${abbreviatedType}:${relatedTask?.id}`) &&
            !abbreviatedType.match(/^[0-9]/)
        ) {
            noteTasks.push(`${abbreviatedType}:${relatedTask?.id}`)
        }

        const oldNotesWithoutTasks = oldNotes.replaceAll(taskIdRegex, '').trim()
        const orderNotes = [noteTasks.join('\n'), oldNotesWithoutTasks.trim(), addedNote].join('\n\n')

        if (order.id) {
            createNote.mutate(
                {
                    orderId: order.id?.toString(),
                    body: { note: orderNotes, task_id: relatedTask?.id, task_type: relatedTask?.department_title },
                    origin,
                },
                {
                    onSuccess: () => {
                        setNewNote('')
                    },
                }
            )
        }
    }

    return (
        <div className='w-[100%] dark:bg-darkaccent relative bg-white rounded'>
            <textarea
                id='new_note'
                className='order-hub-screen__notes__textarea block min-h-[39px] h-[39px] w-[100%] p-[8px] max-w-none border-[1px] border-[#cdcdcd] rounded dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness dark:text-offwhite dark:border-blue shadow-md'
                name='new_order_note'
                value={newNote}
                placeholder='New order note...'
                onChange={({ target }: any) => setNewNote(target.value)}
            />
            <div className='flex justify-between'>
                {newNote && (
                    <button
                        className='block relative mr-0 ml-[auto] top-0 py-[4px] px-[8px] bg-blue text-white font-bold dark:bg-accent dark:text-darkness border-none text-center uppercase rounded-b cursor-pointer shadow-small'
                        onClick={handleSubmit}
                    >
                        Submit Note
                    </button>
                )}
            </div>
        </div>
    )
}
