import { parseResObject, vFetch } from 'helpers'

export const getOrderList = async ({
    payStatuses,
    fulfillmentStatuses,
    timelineComments,
    invoicePaid,
    productIds,
    search,
    vendors,
    tags,
    date,
    dateRange,
    page,
    limit,
}: any) => {
    const queryParams = [
        payStatuses?.length ? `payStatuses=${payStatuses.join(',')}` : null,
        fulfillmentStatuses?.length ? `fulfillmentStatuses=${fulfillmentStatuses.join(',')}` : null,
        timelineComments?.length ? `timelineComments=${timelineComments.join(',')}` : null,
        invoicePaid?.length ? `invoicePaid=${invoicePaid.join(',')}` : null,
        productIds?.length ? `productIds=${productIds.join(',')}` : null,
        vendors?.length ? `vendors=${encodeURIComponent(vendors.join(','))}` : null,
        tags?.length ? `tags=${tags.join(',')}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
        date ? `date=${encodeURIComponent(date)}` : null,
        dateRange && dateRange.from ? `from=${encodeURIComponent(dateRange.from)}` : null,
        dateRange && dateRange.to ? `to=${encodeURIComponent(dateRange.to)}` : null,
        page ? `page=${page}` : null,
        limit ? `limit=${limit}` : null,
    ]
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/orders${queryParams.length ? `?${queryParams}` : ''}`, {
        cb: (res: any) => {
            if (res.success) {
                const resOrders = res.orders.map((order: any) => {
                    parseResObject(order)
                    return order
                })
                res.orders = resOrders
                return res
            }
        },
        reThrowErr: true,
    })
}
export const getOrder = async (orderId: string | undefined) => {
    return vFetch(`/v2/orders/${orderId}`, {
        cb: (res: any) => {
            if (res.success) {
                res.order = parseResObject({ ...res.order })
                res.order.line_items = res?.order?.line_items.map((item: any) => {
                    return parseResObject(item)
                })
                return res
            }
        },
        reThrowErr: true,
    })
}
export const getOrderTags = async () => {
    return vFetch(`/v2/orders/tags`, {
        cb: (res: any) => {
            if (res.success) {
                res.tags = res.tags?.map((tag: any) => tag.tag)
                return res
            }
        },
        reThrowErr: true,
    })
}
export const getOrderEventsPrivate = async (orderId: string | undefined) => {
    return vFetch(`/v1/orderEventsPrivate?order_id=${orderId}`, {
        reThrowErr: true,
    })
}
export const getShopifyTimelineEvents = async (orderId: string | undefined) => {
    return vFetch(`/v1/orders/${orderId}/events`, {
        cb: (res: any) => {
            res.orderEvents = res.events.map((event: any) => {
                return { ...event.node, normalized_event_type: 'Shopify Timeline' }
            })
            return res
        },
        reThrowErr: true,
    })
}
export const getOrderTaskEvents = async (taskIds: string[]) => {
    return vFetch(`/v2/tasks/events/orderScreen?taskIds=${taskIds}`, {
        cb: (res: any) => {
            if (res.success) {
                res.events.forEach((event: any) => {
                    event.old_value = JSON.parse(event.old_value)
                    event.new_value = JSON.parse(event.new_value)
                    if (event.type !== 'private') {
                        event.normalized_event_type = 'Task Events Public'
                    }
                })
                return res
            }
        },
        reThrowErr: true,
    })
}

export const getFulfillmentOrders = async (orderId: string | undefined) => {
    return vFetch(`/v1/orders/${orderId}/fulfillment-orders`, {
        reThrowErr: true,
    })
}
export const getReturns = async (orderNumber: number | undefined) => {
    return vFetch(`/v1/returns?orderNumbers=${orderNumber}`, {
        cb: (res: any) => {
            res.returns = res.returns.map((r: any) => parseResObject(r))
            return res
        },
        reThrowErr: true,
    })
}
export const getPurchaseOrders = async (orderId: string | undefined) => {
    return vFetch(`/v1/orders/purchase-orders/search?order_id=${orderId}`, {
        cb: (res: any) => {
            if (res.success) {
                res.purchase_orders = res.purchase_orders.map((po: any) => parseResObject(po))
                return res
            }
        },
        reThrowErr: true,
    })
}
export const getEmails = async (orderId: string | undefined, purchaseOrdersIds: number[]) => {
    return vFetch(
        `/v1/email?table_id=${[`orders=${orderId}`, purchaseOrdersIds?.map((id) => `purchase_orders=${id}`)]
            .flat()
            .join(',')}`,
        {
            cb: (res: any) => {
                if (res.success) {
                    res.emails = res.emails.map((email: any) => {
                        return { ...parseResObject(email), normalized_event_type: 'Email' }
                    })
                    return res
                }
            },
            reThrowErr: true,
        }
    )
}
export const getEmailAttachments = async (orderId: string | undefined, table: string) => {
    return vFetch(`/v1/email/emailAttachments?foreign_table=${table}s&foreign_id=${orderId}`, {
        cb: (res: any) => {
            if (res.success) {
                res.attachments = res.attachments?.map((attachment: any) => {
                    return { ...parseResObject(attachment) }
                })
                return res
            }
        },
    })
}

export const getRecurringTasks = async (filters: {
    user_id: number
    department_id: number
    statuses: string[]
    departmentsIds: string[]
}) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return vFetch(`/v2/tasks/recurring${params ? `?${params}` : ''}`, {
        cb: (res: any) => {
            res.recurringTasks.forEach((task: any) => {
                task.task_data = task.task_data ? JSON.parse(task.task_data) : task.task_data
                return task
            })
            return res
        },
        reThrowErr: true,
    })
}

// MUTATIONS

export const createNote = async (orderId: string | undefined, body: any, origin: string) => {
    const url = origin === 'order' ? `/v1/orders/${orderId}` : `/v1/draftOrders/${orderId}`
    return await vFetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        reThrowErr: true,
    })
}
export const updateTags = async (orderId: string | undefined, orderTags: any, origin: string) => {
    const url = origin === 'order' ? `/v2/orders/${orderId}` : `/v1/draftOrders/${orderId}`
    return await vFetch(url, {
        method: 'PUT',
        body: JSON.stringify(orderTags),
        reThrowErr: true,
    })
}
