import { ShopifyProduct } from '../ProductSetup.types'
import { FaPlus, FaTrash } from 'react-icons/fa'

export default function CrossSellList({
    crossSells,
    handleAdd,
    handleRemove,
    type,
}: {
    crossSells: ShopifyProduct[]
    handleAdd: Function
    handleRemove: Function
    type: 'selected' | 'add'
}) {
    return (
        <>
            {crossSells.map((cs) => (
                <div
                    key={`${type}-${cs.id}`}
                    className='flex items-center gap-[16px] p-[8px] border-b-2 border-white last:border-none dark:border-darkaccent'
                >
                    <img
                        className='p-[4px] bg-white dark:bg-darkness rounded-[4px] border border-grey dark:border-darkgrey w-[48px] h-[48px] object-contain'
                        src={cs.featured_image}
                    />
                    <p className='text-[14px] dark:text-offwhite font-bold'>{cs.title}</p>
                    {type === 'selected' ? (
                        <FaTrash
                            onClick={() => handleRemove(cs)}
                            className='cursor-pointer ml-auto text-red dark:text-lightred shrink-0 w-[24px] h-[24px]'
                        />
                    ) : (
                        <FaPlus
                            onClick={() => handleAdd(cs)}
                            className='cursor-pointer ml-auto text-blue dark:text-accent shrink-0 w-[24px] h-[24px]'
                        />
                    )}
                </div>
            ))}
        </>
    )
}
