import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'
import { useDeleteTimesheetEntry, useUpdateTimesheetEntry } from 'timesheets/api/useQueries'
import { TimesheetEntryType } from 'timesheets/Timesheets.types'

export default function EntryRow({ entry, disabled }: { entry: TimesheetEntryType; disabled: boolean }) {
    const [showDelete, setShowDelete] = useState(false)
    const updateEntryMutation = useUpdateTimesheetEntry()
    const deleteEntryMutation = useDeleteTimesheetEntry()
    const entryHtmlStartTime = `${`0${new Date(entry.starts_on).getHours()}`.slice(-2)}:${`0${new Date(
        entry.starts_on
    ).getMinutes()}`.slice(-2)}`
    const entryHtmlEndTime = `${`0${new Date(entry.ends_on).getHours()}`.slice(-2)}:${`0${new Date(
        entry.ends_on
    ).getMinutes()}`.slice(-2)}`
    const [editedFields, setEditedFields] = useState<any>({})
    const htmlDate = HTMLDateInputFormatter(new Date(entry.starts_on))
    const edited = Object.keys(editedFields).length > 0

    const handleUpdate = () => {
        const starts_on = editedFields.starts_on_time
            ? new Date(
                  new Date(`${htmlDate}T${editedFields.starts_on_time || '00:00'}:00.000Z`).getTime() +
                      new Date().getTimezoneOffset() * 60 * 1000
              ).toISOString()
            : undefined
        const ends_on = editedFields.ends_on_time
            ? new Date(
                  new Date(`${htmlDate}T${editedFields.ends_on_time || '00:00'}:00.000Z`).getTime() +
                      new Date().getTimezoneOffset() * 60 * 1000
              ).toISOString()
            : undefined

        updateEntryMutation.mutate(
            {
                id: entry.id,
                timezone_offset: new Date().getTimezoneOffset(),
                type: editedFields.type,
                starts_on,
                ends_on,
            },
            {
                onSuccess: () => {
                    setEditedFields({})
                },
            }
        )
    }

    const handleDelete = () => {
        deleteEntryMutation.mutate(entry.id)
    }

    return (
        <div className='flex gap-2 p-1 border rounded'>
            <Select
                disabled={disabled}
                outerClassName='w-fit'
                id='type'
                name='type'
                value={editedFields.type ?? entry.type}
                onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
            >
                <option value=''>Select a type</option>
                <option value='overtime'>Overtime</option>
            </Select>
            <Input
                disabled={disabled}
                outerClassName='w-fit ml-auto'
                type='time'
                id='starts_on_time'
                name='starts_on_time'
                value={editedFields.starts_on_time ?? entryHtmlStartTime}
                onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
            />
            <Input
                disabled={disabled}
                outerClassName='w-fit'
                type='time'
                id='ends_on_time'
                name='ends_on_time'
                value={editedFields.ends_on_time ?? entryHtmlEndTime}
                onChange={({ target }) => setEditedFields({ ...editedFields, [target.name]: target.value })}
            />
            {!disabled && (
                <>
                    <Button disabled={!edited} onClick={handleUpdate} variant='outline' size='sm'>
                        Save
                    </Button>
                    <div className='flex gap-2'>
                        {showDelete ? (
                            <>
                                <Button onClick={handleDelete} size='sm' variant='destructive'>
                                    Delete
                                </Button>
                                <Button onClick={() => setShowDelete(false)} size='sm' variant='outline'>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <button onClick={() => setShowDelete(true)} className='text-red dark:text-lightred'>
                                <FaTrash />
                            </button>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
