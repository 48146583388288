import { Link } from 'react-router-dom'
import { TaskV2 } from 'tasksV2/constants/tasks'
import TaskType from 'tasksV2/tasks/components/TaskType'
import { taskVariables } from '../TaskVariables'

type TaskPreviewNavBlockProps = {
    task: TaskV2
    handleClick?: Function
    orderId?: any
}

export default function TaskPreviewNavBlock({ task, handleClick, orderId }: TaskPreviewNavBlockProps) {
    const { taskTypeColors }: any = taskVariables
    const labelStyle = 'block font-bold text-[12px] uppercase'

    const associations = task.associations?.filter(
        (association: any) =>
            association.resource_name !== 'user' &&
            association.resource_name !== 'order_line_item' &&
            association.resource_name !== 'draft_order_line_item'
    )

    return (
        <Link
            className='flex flex-col js-topbar__order__task  hover:bg-darkness/20 dark:hover:bg-darkness/40 cursor-pointer p-[4px] rounded truncate max-w-[500px] font-normal'
            onClick={(event) => {
                event.stopPropagation()
            }}
            to={`/orders/${orderId}`}
        >
            <div className='flex gap-[4px]'>
                <label className={`${taskTypeColors[task.type_id]} ${labelStyle} cursor-pointer`}>
                    <TaskType
                        taskId={task.id}
                        departmentId={task.department_id}
                        typeId={task.type_id}
                        editable={false}
                    />
                </label>
                <p className='text-[12px]'>{task.status}</p>
            </div>
            <p className='text-[12px] truncate'>{task.title}</p>
            <a className='text-[12px] font-semibold text-blue dark:text-accent'>{task.id}</a>
        </Link>
    )
}
