import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'

export default function TopISPs() {
    const sessionsQuery = useQuery({
        queryKey: ['sessions', 'top_isps'],
        queryFn: () => vFetch(`/v1/analytics/sessions/topISPs?days=1`),
    })
    const sessions = sessionsQuery?.data?.sessions || []
    return (
        <div className='w-full'>
            <h2 className='text-xl font-bold'>Top ISPs For The Last 24 Hours</h2>
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-[3fr_4fr_1fr] gap-2 text-sm font-bold p-2 bg-lightgrey dark:bg-darkbg2 rounded shadow-md break-all sticky top-[50px] z-10'>
                    <p>ISP</p>
                    <p>User Agent</p>
                    <p>Count</p>
                </div>
                {sessions.map((session: any) => (
                    <div className='grid grid-cols-[3fr_4fr_1fr] gap-2 text-sm font-bold p-2 dark:bg-darkbg1 rounded shadow-md break-all'>
                        <p>{session.isp}</p>
                        <p>{session.user_agent}</p>
                        <p>{session.count}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
