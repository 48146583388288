import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import { Dispatch, SetStateAction } from 'react'
import { FaFlag, FaMap, FaTag, FaTimes } from 'react-icons/fa'
import FilterButton from './FilterButton'
import { Button } from 'custom_components/component_Basics/Button'

export default function CustomerFilters({
    filters,
    setFilters,
}: {
    filters: any
    setFilters: Dispatch<SetStateAction<any>>
}) {
    const provincesQuery = useQuery({
        queryKey: ['customers', 'provinces'],
        queryFn: () => vFetch(`/v2/customers/provinces`),
    })
    const provinces = provincesQuery?.data?.provinces || []
    const countriesQuery = useQuery({
        queryKey: ['customers', 'countries'],
        queryFn: () => vFetch(`/v2/customers/countries`),
    })
    const countries = countriesQuery?.data?.countries || []
    const tagsQuery = useQuery({
        queryKey: ['customers', 'tags'],
        queryFn: () => vFetch(`/v2/customers/tags`),
    })
    const tags = tagsQuery?.data?.tags || []

    const hasFilters = Object.values(filters).find((val: any) => val.length > 0)

    const FILTER_BAR_OPTIONS = [
        {
            key: 'state',
            icon: FaFlag,
            label: 'State / Province',
            values: provinces.map((p: any) => p.province_code),
        },
        {
            key: 'country',
            icon: FaMap,
            label: 'Country',
            values: countries.map((c: any) => c.country_code),
        },
        {
            key: 'tags',
            icon: FaTag,
            label: 'Tagged With',
            values: tags.map((t: any) => t.tag),
        },
    ]

    const handleClearFilters = () => {
        setFilters((prev: any) => {
            const newState = structuredClone(prev)
            Object.keys(newState).forEach((key) => (newState[key].length = 0))
            return newState
        })
    }

    return (
        <>
            {hasFilters && (
                <Button className='flex gap-1 items-center' variant={'destructive'} onClick={handleClearFilters}>
                    <FaTimes /> Clear Filters
                </Button>
            )}
            {FILTER_BAR_OPTIONS.map((option) => (
                <FilterButton selectedValues={filters[option.key]} filterOptions={option} setFilters={setFilters} />
            ))}
        </>
    )
}
