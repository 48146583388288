import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import Quill from 'quill'

const Editor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange, modules }, ref) => {
    const containerRef = useRef(null)
    const defaultValueRef = useRef(defaultValue)
    const onTextChangeRef = useRef(onTextChange)
    const onSelectionChangeRef = useRef(onSelectionChange)

    useLayoutEffect(() => {
        onTextChangeRef.current = onTextChange
        onSelectionChangeRef.current = onSelectionChange
    })

    useEffect(() => {
        ref.current?.enable(!readOnly)
    }, [ref, readOnly])

    useEffect(() => {
        const container = containerRef.current
        const editorContainer = container.appendChild(container.ownerDocument.createElement('div'))
        const quill = new Quill(editorContainer, {
            theme: 'snow',
            modules,
        })

        ref.current = quill

        if (defaultValueRef.current) {
            quill.setContents(defaultValueRef.current)
        }

        quill.on(Quill.events.TEXT_CHANGE, (...args) => {
            onTextChangeRef.current?.(...args)
        })

        quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
            onSelectionChangeRef.current?.(...args)
        })

        // Custom image handler
        quill.getModule('toolbar').addHandler('image', () => {
            let fileInput = document.createElement('input')
            fileInput.setAttribute('type', 'file')
            fileInput.setAttribute('accept', 'image/webp, image/png, image/gif, image/jpeg, image/bmp, image/x-icon')
            fileInput.click()

            fileInput.addEventListener('change', () => {
                let file = fileInput.files[0]
                let reader = new FileReader()

                reader.addEventListener(
                    'load',
                    () => {
                        let range = quill.getSelection(true) || 0
                        let img = document.createElement('img')
                        img.src = reader.result
                        img.alt = ' '
                        quill.clipboard.dangerouslyPasteHTML(range?.index, img?.outerHTML)
                    },
                    false
                )

                if (file) {
                    reader.readAsDataURL(file)
                }
            })
        })

        return () => {
            ref.current = null
            container.innerHTML = ''
        }
    }, [ref])

    return <div ref={containerRef}></div>
})

Editor.displayName = 'Editor'

export default Editor
