import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAssociationLink } from 'taxExemptions/helpers'

export default function Association({
    association,
    id,
    className,
    children,
}: {
    association: any
    id: any
    className?: string
    children?: any
}) {
    const queryClient = useQueryClient()
    const user = useSelector((state: any) => state.user)
    let name = `${association.resource_name} ${association.resource_id}`
    let link = getAssociationLink(association, user, queryClient)
    return (
        <Button
            size={'sm'}
            variant={'outline'}
            className={cn(
                'px-1 py-0 h-6 hover:bg-bg1 dark:hover:bg-darkbg1 hover:scale-[1.03] transition-all',
                className
            )}
        >
            <Link
                onClick={(e) => e.stopPropagation()}
                key={`${id}` + `${association.resource_id}`}
                to={link}
                draggable={false}
            >
                {association.readable_name || name}
            </Link>
            {(!association.locked || user.type === 'Admin' || user.type === 'SuperAdmin') && children}
        </Button>
    )
}
