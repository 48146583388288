import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { Button } from '../../custom_components/component_Basics/Button'
import { vFetch } from '../../helpers'
import { FaTrashCan } from 'react-icons/fa6'
import { MdEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { isUserOrAdmin } from '../helpers'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { articleCardDateFormatter } from '../helpers'
import { useQueryClient } from '@tanstack/react-query'

export default function ArticleView({
    selectedArticle,
    setSelectedArticle,
    setView,
}: {
    selectedArticle: any
    setSelectedArticle: any
    setView: any
}) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [article, setArticle] = useState<any>({})
    const currentUser = useSelector((state: any) => state.user)
    const canEdit = isUserOrAdmin(currentUser, selectedArticle)
    const [showEditButton, setShowEditButton] = useState(true)
    const [editArticle, setEditArticle] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const queryClient = useQueryClient()

    const getArticles = async () => {
        vFetch(`/v1/knowledge/articles?id=${selectedArticle.id}`, {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    setArticle(res.articles[0])
                }
            },
        })
    }

    function handleCancelArticleEdit() {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }
    const handleEditArticleClick = () => {
        setShowEditButton(false)
    }
    const handleDeleteArticle = (id: any) => {
        return vFetch('/v1/knowledge/articles', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {
                if (res.success) {
                    setView('departmentView')
                    setSearchParams((prev) => {
                        prev.delete(`article${selectedArticle.id}`)
                        prev.delete('articleID')
                        prev.delete('departmentView')
                        return prev
                    })
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'global'],
                    })
                }
            },
        })
    }
    const handleDeleteAttempt = () => {
        setConfirmCancel(true)
    }
    const handleCancelArticleDelete = () => {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }

    const handleClickEditButton = () => {
        setView('editArticleView')
        setSelectedArticle(article)
        setSearchParams((prev: any) => {
            prev.set('editArticleView', article.id)
            return prev
        })
    }
    const handleBackButton = () => {
        setSelectedArticle(undefined)
        setSearchParams((prev) => {
            prev.delete('articleID')
            prev.delete('departmentView')
            return prev
        })
    }

    useEffect(() => {
        getArticles()
    }, [selectedArticle])

    return (
        <div className=' relative flex flex-col items-center px-2 mt-4 mb-4'>
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='self-start'>
                    <div className=''>
                        <Button onClick={handleBackButton} variant={'outline'} className='mx-auto mt-2 text-xs'>
                            BACK
                        </Button>
                    </div>
                    {canEdit && !editArticle && (
                        <div className='absolute right-5 top-2'>
                            {showEditButton ? (
                                <button
                                    className='text-black dark:text-offwhite text-xl font-bold'
                                    onClick={handleEditArticleClick}
                                >
                                    <BsThreeDotsVertical />
                                </button>
                            ) : (
                                <div>
                                    <button
                                        onClick={handleCancelArticleEdit}
                                        className='shrink-0 whitespace-nowrap
                                 dark:text-offwhite text-xl font-bold'
                                    >
                                        <FaTimes className='text-red w-[22px] h-[22px]' />
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    {!showEditButton && !editArticle && !confirmCancel && (
                        <div className='absolute right-6 top-9 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                            <button
                                onClick={handleClickEditButton}
                                value='Edit'
                                className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
                            >
                                <MdEdit className='w-[20px]' />
                                Edit Article
                            </button>
                            <button
                                value='Delete'
                                onClick={handleDeleteAttempt}
                                className='flex gap-2 items-center mb-2 mx-2 text-red/75 dark:text-offwhite'
                            >
                                <FaTrashCan className='w-[20px] text-red/75 dark:text-lightred text-sm' />
                                Delete Article
                            </button>
                        </div>
                    )}
                </div>
            )}

            {confirmCancel && (
                <div className='absolute right-6 top-9 items-center flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                    <div className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>Are you sure?</div>
                    <button
                        value='Yes - Delete'
                        onClick={() => handleDeleteArticle(selectedArticle.id)}
                        className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                    >
                        Yes
                    </button>
                    <button
                        value='No - Cancel'
                        onClick={handleCancelArticleDelete}
                        className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                    >
                        No
                    </button>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='max-w-[80%] mx-auto'>
                    <div>
                        <h1 className='mt-5 font-semibold text-4xl'>{article.title}</h1>
                        <h3 className='mt-4 text-lg'>{article.excerpt}</h3>
                        <p></p>
                    </div>
                    {article.featured_image ? (
                        <img className='w-full object-contain mt-5 mb-5 rounded' src={article.featured_image} />
                    ) : (
                        ''
                    )}
                    <div className='mt-3 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='mt-3 flex self-start justify-between'>
                        <div>
                            <div className='mr-1 font-semibold leading-none'>
                                By {article.user_firstName} {article.user_lastName}
                            </div>
                            <div className='self-start text-grey'>
                                Updated: {articleCardDateFormatter.format(new Date(article.updated_at))}
                            </div>
                        </div>
                    </div>
                    <div className='ql-snow'>
                        <div
                            style={{ padding: '0px', overflow: 'unset' }}
                            className='ql-editor mt-7'
                            dangerouslySetInnerHTML={{ __html: article.content }}
                        ></div>
                    </div>
                </div>
            )}
        </div>
    )
}
