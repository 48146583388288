export default function AreYouSure({
    // message,
    onConfirm,
    onDeny,
}: {
    // message?: string
    onConfirm: () => void
    onDeny: () => void
}) {
    return (
        <div className='z-10 absolute items-center right-2 top-2 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
            <p className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2 font-normal'>Are you sure?</p>
            <button
                value='Yes - Delete'
                onClick={onConfirm}
                className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
            >
                Yes
            </button>
            <button
                value='No - Cancel'
                onClick={onDeny}
                className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
            >
                No
            </button>
        </div>
    )
}
