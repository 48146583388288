import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { sendToast, vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { useGetRoles } from './api/useQueries'
import { DEFAULT_PERMISSION_SET } from './constants'
import PermissionRow from './PermissionRow'

export default function EditRoleModal({ roleId, closeModal }: { roleId: number; closeModal: () => void }) {
    const [showDelete, setShowDelete] = useState(false)
    const rolesQuery = useGetRoles({})
    const [expandMap, setExpandMap] = useState<any>({})
    const defaultSet = DEFAULT_PERMISSION_SET
    const foundRole = rolesQuery.data?.roles.find((role: any) => role.id === roleId)

    const mergedPermissionSet: any = structuredClone(DEFAULT_PERMISSION_SET)
    for (const key of Object.keys(foundRole.permission_set)) {
        updatePermissionGroupLeafNodes(mergedPermissionSet[key], foundRole.permission_set[key])
    }

    function updatePermissionGroupLeafNodes(targetGroup: any, incomingGroup: any) {
        if (!targetGroup || !incomingGroup) {
            return
        }
        for (const key of Object.keys(incomingGroup)) {
            if (typeof incomingGroup[key] === 'boolean') {
                targetGroup[key] = incomingGroup[key]
            } else {
                updatePermissionGroupLeafNodes(targetGroup[key], incomingGroup[key])
            }
        }
    }
    function updateExpandMap(group: any, newMap: any, value: number) {
        for (const key of Object.keys(group)) {
            if (typeof group[key] != 'boolean') {
                newMap[key] = value
                updateExpandMap(group[key], newMap, value)
            }
        }
        return newMap
    }

    const [role, setRole] = useState({
        title: foundRole.title,
        permission_set: mergedPermissionSet,
    })

    const queryClient = useQueryClient()
    const createRoleMutation = useMutation({
        mutationFn: (role: { title: string; permission_set: any }) =>
            vFetch('/v2/roles', {
                method: 'PUT',
                body: JSON.stringify({
                    id: roleId,
                    title: role.title,
                    permission_set: role.permission_set,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })
    const deleteRoleMutation = useMutation({
        mutationFn: (roleId: number) =>
            vFetch('/v2/roles', {
                method: 'DELETE',
                body: JSON.stringify({
                    id: roleId,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
    })
    const handleCreate = () => {
        if (role.title.trim() === '') {
            return sendToast({ message: 'Must include title' })
        }
        createRoleMutation.mutate(
            { title: role.title.trim(), permission_set: role.permission_set },
            {
                onSuccess: () => closeModal(),
            }
        )
    }
    const handleDelete = () => {
        deleteRoleMutation.mutate(roleId, {
            onSuccess: () => closeModal(),
        })
    }
    const handleCancel = () => {
        setRole({ title: foundRole.title.trim(), permission_set: mergedPermissionSet })
    }
    const handleExpandAll = () => {
        setExpandMap(updateExpandMap(mergedPermissionSet, structuredClone(expandMap), 1))
    }
    const handleCollapseAll = () => {
        setExpandMap(updateExpandMap(mergedPermissionSet, structuredClone(expandMap), 0))
    }

    const edited =
        role.title.trim() !== foundRole.title.trim() ||
        JSON.stringify(role.permission_set) !== JSON.stringify(mergedPermissionSet)

    return (
        <div className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center'>
            <div className='p-6 pt-0 pb-0 flex min-h-0 flex-col rounded shadow-md relative bg-bg1 dark:bg-darkbg1 w-full h-full max-w-[90%] max-h-[90%] overflow-auto'>
                <div className='sticky top-0 bg-bg1 dark:bg-darkbg1 pt-4'>
                    <button
                        className='text-red dark:text-lightred font-bold absolute text-lg -top-0 -right-5'
                        onClick={() => closeModal()}
                    >
                        <FaTimes />
                    </button>
                    <div className='flex justify-between'>
                        <h2 className='font-bold'>Edit Role</h2>
                        <div className='flex gap-2 items-center'>
                            <Button onClick={handleExpandAll} size='sm' variant='outline'>
                                Expand
                            </Button>
                            <Button onClick={handleCollapseAll} size='sm' variant='outline'>
                                Collapse
                            </Button>
                        </div>
                        <div className='flex gap-2 items-center'>
                            <Button onClick={handleCreate} size='sm' variant='outline' disabled={!edited}>
                                Update Role
                            </Button>
                            <Button onClick={handleCancel} size='sm' variant='outline' disabled={!edited}>
                                Reset
                            </Button>
                        </div>
                    </div>
                    <Input
                        className='mb-2'
                        id='title'
                        name='title'
                        type='text'
                        label='Role Name'
                        value={role.title}
                        onChange={({ target }) => setRole({ ...role, title: target.value })}
                    />
                </div>

                <div className='flex flex-col gap-2 w-full'>
                    {Object.entries(defaultSet).map(([key, value]: any, index) => {
                        return (
                            <PermissionRow
                                section={key}
                                objectKey={key}
                                objectValue={value}
                                role={role}
                                setRole={setRole}
                                level={0}
                                expandMap={expandMap}
                                setExpandMap={setExpandMap}
                            />
                        )
                    })}
                </div>

                <div className='flex w-full ml-auto gap-2 pt-4 pb-4 sticky bg-bg1 dark:bg-darkbg1 bottom-0 justify-end'>
                    {!showDelete && (
                        <button onClick={() => setShowDelete(true)} className='text-red dark:text-lightred'>
                            <FaTrash />
                        </button>
                    )}
                    {showDelete && (
                        <>
                            <Button onClick={handleDelete} size='sm' variant='destructive'>
                                Delete
                            </Button>
                            <Button onClick={() => setShowDelete(false)} size='sm' variant='outline'>
                                Cancel
                            </Button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
