export const isUserOrAdmin = (user:any, resource:any)  => {
if (user.type === 'Admin' || user.type === 'SuperAdmin')
 {
    return true
 }
 return user.id === resource.user_id
}

export const articleCardDateFormatter = new Intl.DateTimeFormat('en-US', {
   month: "long",
   day: "numeric",
   year: "numeric"
})