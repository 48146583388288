import { createColumnHelper } from '@tanstack/react-table'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import React, { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS } from '../../procurement/constants'
import { UserInit } from '../../users/users.types'
import { useCompanyStatusAnalyticsQueryByUserTotal } from '../api/useQueries'
import { BarChart } from '../components/BarChart'
import { GraphContainer } from '../components/GraphContainer'
import LineChart from '../components/LineChart'
import { PLACEHOLDER_DATA_INSERT, formatChartDataByStatus, formatTableDataByStatus } from '../helpers'
import Table from './Table'
import { cn } from '../../helpers'

export type StatusDataByUser = {
    user_id: number | string
    user_alias: string
    Active: number
    'High Interest': number
    'Low / Medium Interest': number
    New: number
    Staging: number
    Unqualified: number
    'Unqualified - Follow Up': number
    'No Contact': number
    Contacted: number
    'Need to Submit Dealer Form': number
    'Awaiting Dealer Forms': number
    'Submitted Dealer Forms': number
    'Awaiting Price List / Intake': number
    Listing: number
    'Waiting to be Listed': number
    Listed: number
}

Chart.register(CategoryScale)

export default function CompanyStatusAnalyticsReport({
    users,
    user_ids,
    dateRange,
    dateRangeCompare,
    rangeString = '',
}: {
    users: UserInit[]
    user_ids: any
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    rangeString: string | undefined
}) {
    const [optionsIndex, setOptionsIndex] = useState(0)

    const lead_types = LEAD_TYPE_OPTIONS.map((option: any) => {
        return {
            tag: option.tag,
            value: [option.value],
            title: option.label,
        }
    })
    const lifecycle_statuses = LIFECYCLE_STATUS_OPTIONS.map((option: any) => {
        return {
            tag: option.tag,
            value: [option.value],
            title: option.label,
        }
    })
    const options = [
        {
            tag: 'lead_type',
            title: 'Lead Type',
            value: ['lead_type'],
        },
        {
            tag: 'lifecycle_status',
            title: 'Lifecycle Status',
            value: ['lifecycle_status'],
        },
    ]
    const statusOptions = [lead_types, lifecycle_statuses]
    const [chartType, setChartType] = useState<'bar' | 'line'>('bar')

    const chartTitle = `Company Status Changes - ${rangeString}`
    const companyStatusAnalyticsQuery = useCompanyStatusAnalyticsQueryByUserTotal({
        user_ids,
        dateRange,
        dateRangeCompare,
        fields: statusOptions[optionsIndex],
    })
    const { analytics: companyStatusAnalytics }: { analytics: any } = companyStatusAnalyticsQuery?.data || {}

    const relevantUsers =
        users?.filter((user) => {
            if (user.roles.includes('procurement')) {
                return user
            } else {
                return
            }
        }) ?? []

    const columnHelper = createColumnHelper<StatusDataByUser>()

    const tableData: any =
        formatTableDataByStatus(
            companyStatusAnalytics,
            users,
            [...relevantUsers?.map((user) => user.id)],
            statusOptions[optionsIndex].map((status: any) => status.title)
        ) ?? []

    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const lifecycle_status_titles = lifecycle_statuses.map((status: any) => status.title)
    const lead_type_titles = lead_types.map((status: any) => status.title)

    const lifecycleStatusColumns = [
        columnHelper.accessor('user_alias', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        <span className='font-semibold'>{info.getValue()}</span>
                        <span className='text-sm'>compare</span>
                        <span className='text-sm'>% change</span>
                    </div>
                )
            },
        }),
        ...lifecycle_status_titles.map((title) => {
            return columnHelper.accessor(title, {
                header: () => title,
                cell: (info) => {
                    const [data, compareData] = info.renderValue().split(',')
                    const percentChange = ((compareData - data) / Math.abs(compareData)) * 100 || 0

                    return (
                        <div className='flex flex-col gap-2 '>
                            <span className='font-semibold'>{data}</span>
                            <span>{compareData}</span>
                            {dateRangeCompare && percentChange !== 0 ? (
                                <span
                                    className={cn(
                                        Math.sign(percentChange) >= 0 ? 'text-red' : 'text-emerald-700',
                                        'text-xs'
                                    )}
                                >
                                    {Math.sign(percentChange) >= 0 ? '-' : '+'}
                                    {Math.abs(parseInt(percentChange.toFixed(2)))}%
                                </span>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    )
                },
            })
        }),
    ]
    const leadTypeColumns = [
        columnHelper.accessor('user_alias', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        <span className='font-semibold'>{info.getValue()}</span>
                        <span className='text-sm'>compare</span>
                        <span className='text-sm'>% change</span>
                    </div>
                )
            },
        }),
        ...lead_type_titles.map((title) => {
            return columnHelper.accessor(title, {
                header: () => title,
                cell: (info) => {
                    const [data, compareData] = info.renderValue().split(',')
                    const percentChange = ((compareData - data) / Math.abs(compareData)) * 100 || 0

                    return (
                        <div className='flex flex-col gap-2 '>
                            <span className='font-semibold'>{data}</span>
                            <span>{compareData}</span>
                            {dateRangeCompare && percentChange !== 0 ? (
                                <span
                                    className={cn(
                                        Math.sign(percentChange) >= 0 ? 'text-red' : 'text-emerald-700',
                                        'text-xs'
                                    )}
                                >
                                    {Math.sign(percentChange) >= 0 ? '-' : '+'}
                                    {Math.abs(parseInt(percentChange.toFixed(2)))}%
                                </span>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    )
                },
            })
        }),
    ]

    const chartDataFromTableData =
        formatChartDataByStatus(
            companyStatusAnalytics,
            dateRangeCompare,
            users,
            [...relevantUsers?.map((user) => user.id)],
            statusOptions[optionsIndex].map((status: any) => status.title)
        ) ?? PLACEHOLDER_DATA_INSERT

    const columnOptions = [leadTypeColumns, lifecycleStatusColumns]
    const columns = columnOptions[optionsIndex]
    const columnCount = columns?.length.toString() || 10

    return (
        <div>
            <GraphContainer
                options={options}
                setOptionsIndex={setOptionsIndex}
                setChartType={setChartType}
                chartType={chartType}
                timePeriodIndex={3}
                intervalEnabled={false}
            >
                {chartType === 'bar' && (
                    <BarChart chartTitle={chartTitle} chartData={chartDataFromTableData} size={'large'} />
                )}
                {chartType === 'line' && (
                    <LineChart chartTitle={chartTitle} chartData={chartDataFromTableData} size={'large'} />
                )}
            </GraphContainer>
            <>
                <div>
                    {tableDataMemo && (
                        <div className='w-fit p-2  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <Table columns={columns} tableData={tableDataMemo} columnCount={columnCount} />
                        </div>
                    )}
                </div>
            </>
        </div>
    )
}
