import { createColumnHelper } from '@tanstack/react-table'
import Table from 'analytics/reports/Table'
import RefreshButton from 'custom_components/RefreshButton'
import { cn } from 'helpers'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useAddGroupProductLink, useGetProductGroupsQuery } from 'productSetupV2/api/useQueries'
import React, { useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { useLocation, useSearchParams } from 'react-router-dom'
import GroupsSearchBarContainer from './GroupsSearchBarContainer'
import { Button } from 'custom_components/component_Basics/Button'
import CreateNewGroup from './CreateNewGroup'

export default function ProductGroupsSelect({ productId }: { productId: number }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState('')
    const [initRender, setInitRender] = useState(true)
    const [showCreate, setShowCreate] = useState(false)

    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const page = searchParams.get('page') || 1
    const [cursorList, setCursorList] = useState<any>([0])

    const addGroupProductLink = useAddGroupProductLink()

    const productGroupsQuery = useGetProductGroupsQuery({
        filters: { after, before, page, search },
    })
    const { groups: productGroups }: { groups: { id: number | string; group_name: string }[] } =
        productGroupsQuery?.data || {}

    function idSortLargestFirst(a: any, b: any) {
        return b.id - a.id
    }
    if (productGroups?.length > 2) {
        productGroups.sort(idSortLargestFirst)
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            search ? searchParams.set('search', search) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }
    }, [search])

    if (!productGroupsQuery.isFetching && !productGroupsQuery.isLoading) {
        if (after && !(after[2] === null)) {
            if (!cursorList.includes(after)) {
                setCursorList((prev: any[]) => {
                    return [...prev, after]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (productGroups?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', productGroups[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: any) => {
                prev.delete('after')
                prev.set('before', productGroups[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }

    const handleAddProduct = (groupId: string | number) => {
        if (addGroupProductLink.isPending) {
            return
        }
        if (groupId && productId) {
            addGroupProductLink.mutate(
                { groupId, productId },
                {
                    onSuccess: () => {},
                }
            )
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('search')
                prev.delete('before')
                prev.delete('page')
                return prev
            })
        }
    }

    const tableData = productGroups?.length > 0 ? productGroups : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<ProductGroupType>()

    const productsColumns = [
        columnHelper.accessor('id', {
            header: 'Id',
            cell: (info) => {
                const groupId = info.row.original.id
                return (
                    <div
                        onClick={() => {
                            handleAddProduct(groupId)
                        }}
                        className={cn(' flex gap-1 items-center cursor-pointer')}
                    >
                        {info.getValue()}
                    </div>
                )
            },
            size: 20,
        }),
        columnHelper.accessor('group_name', {
            header: 'Group Name',
            cell: (info) => {
                const groupId = info.row.original.id
                return (
                    <div
                        onClick={() => {
                            handleAddProduct(groupId)
                        }}
                        className={cn('flex gap-1 items-center cursor-pointer')}
                    >
                        {info.getValue()}
                    </div>
                )
            },
            size: 350,
        }),
    ]
    const columnCount = productsColumns?.length.toString() || 10

    return (
        <div>
            <div className='flex justify-end mb-[8px]'>
                <div className='flex gap-4 items-center'>
                    <Button variant={'outline'} size={'sm'} onClick={() => setShowCreate(true)}>
                        Create New Group
                    </Button>
                    <RefreshButton
                        queryKeys={[productSetupQueries.productGroups.list._def.toString()]}
                        isFetching={productGroupsQuery.isFetching}
                    />
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <GroupsSearchBarContainer setDebouncedSearch={setSearch} />

                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <Table
                                    columns={productsColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                    width={'w-full'}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={productGroupsQuery.isLoading || productGroupsQuery.isFetching || page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={productGroupsQuery.isLoading || productGroupsQuery.isFetching || page == 1}
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={
                                productGroupsQuery.isLoading ||
                                productGroupsQuery.isFetching ||
                                productGroups?.length < 50
                            }
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
            {showCreate && <CreateNewGroup setShowCreate={setShowCreate} />}
        </div>
    )
}

export type ProductGroupType = {
    id: number | string
    group_name: string
}
