import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'

export default function PermissionRow({
    section,
    objectKey,
    objectValue,
    role,
    setRole,
    level,
    expandMap,
    setExpandMap,
}: any) {
    return (
        <div className='flex gap-2 w-full justify-start'>
            {new Array(level).fill({}).map((block) => {
                return <div className='min-w-[50px]'></div>
            })}
            <div className='flex flex-col items-center w-full gap-1'>
                <Button
                    className='capitalize w-full flex justify-start'
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => {
                        const newMap = structuredClone(expandMap)
                        newMap[objectKey] = newMap[objectKey] === 1 ? 0 : 1
                        setExpandMap(newMap)
                    }}
                >
                    <p className=''>{objectKey.replaceAll('_', ' ')}</p>
                    {expandMap[objectKey] == 1 ? (
                        <CaretDownIcon className='pointer-events-none' />
                    ) : (
                        <CaretUpIcon className='pointer-events-none' />
                    )}
                </Button>
                {expandMap[objectKey] == 1 &&
                    Object.entries(objectValue).map(([key, value]: any, index) => {
                        if (key === 'read' || key === 'write') {
                            if (objectKey === section) {
                                return (
                                    <div className='flex gap-2 w-full justify-start'>
                                        {new Array(level === 0 ? level + 1 : level).fill({}).map((block) => {
                                            return <div className='min-w-[50px]'></div>
                                        })}
                                        <button
                                            onClick={() =>
                                                setRole((prev: any) => {
                                                    if (objectKey === section) {
                                                        return {
                                                            ...prev,
                                                            permission_set: {
                                                                ...prev.permission_set,
                                                                [section]: {
                                                                    ...prev.permission_set[section],
                                                                    [key]: !prev.permission_set[section][key],
                                                                },
                                                            },
                                                        }
                                                    }
                                                    return {
                                                        ...prev,
                                                        permission_set: {
                                                            ...prev.permission_set,
                                                            [section]: {
                                                                ...prev.permission_set[section],
                                                                [objectKey]: {
                                                                    ...prev.permission_set[section][objectKey],
                                                                    [key]: !prev.permission_set[section][objectKey][
                                                                        key
                                                                    ],
                                                                },
                                                            },
                                                        },
                                                    }
                                                })
                                            }
                                            className='p-1 w-full border-b border-lightgrey dark:border-darkgrey flex gap-2 items-center'
                                        >
                                            <div
                                                className={cn(
                                                    'w-4 h-4 rounded border border-lightgrey dark:border-darkgrey',
                                                    role.permission_set[section][key] &&
                                                        'bg-accent1 dark:bg-darkaccent1'
                                                )}
                                            ></div>
                                            <span className='capitalize'>{key.replaceAll('_', ' ')}</span>
                                        </button>
                                    </div>
                                )
                            }

                            return (
                                <div className='flex gap-2 w-full justify-start'>
                                    {new Array(level === 0 ? level + 1 : level).fill({}).map((block) => {
                                        return <div className='min-w-[50px]'></div>
                                    })}
                                    <button
                                        onClick={() =>
                                            setRole((prev: any) => {
                                                if (objectKey === section) {
                                                    return {
                                                        ...prev,
                                                        permission_set: {
                                                            ...prev.permission_set,
                                                            [section]: {
                                                                ...prev.permission_set[section],
                                                                [key]: !prev.permission_set[section][key],
                                                            },
                                                        },
                                                    }
                                                }
                                                return {
                                                    ...prev,
                                                    permission_set: {
                                                        ...prev.permission_set,
                                                        [section]: {
                                                            ...prev.permission_set[section],
                                                            [objectKey]: {
                                                                ...prev.permission_set[section][objectKey],
                                                                [key]: !prev.permission_set[section][objectKey][key],
                                                            },
                                                        },
                                                    },
                                                }
                                            })
                                        }
                                        className='p-1 w-full border-b border-lightgrey dark:border-darkgrey flex gap-2 items-center'
                                    >
                                        <div
                                            className={cn(
                                                'w-4 h-4 rounded border border-lightgrey dark:border-darkgrey',
                                                role.permission_set[section][objectKey][key] &&
                                                    'bg-accent1 dark:bg-darkaccent1'
                                            )}
                                        ></div>
                                        <span className='capitalize'>{key.replaceAll('_', ' ')}</span>
                                    </button>
                                </div>
                            )
                        }
                        return (
                            <PermissionRow
                                section={section}
                                objectKey={key}
                                objectValue={value}
                                role={role}
                                setRole={setRole}
                                level={level + 1}
                                expandMap={expandMap}
                                setExpandMap={setExpandMap}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
