import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from '../../custom_components/component_Basics/Button'
import { vFetch } from '../../helpers'
import { useSelector } from 'react-redux'
import { articleCardDateFormatter } from '../helpers'
import { useQueryClient } from '@tanstack/react-query'

export default function AdminArticleView({ selectedArticle, setView }: { selectedArticle: any; setView: any }) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)
    const [article, setArticle] = useState<any>(selectedArticle || {})
    const currentUser = useSelector((state: any) => state.user)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [review, setReview] = useState('')

    const getArticles = async () => {
        vFetch(`/v1/knowledge/articles?id=${selectedArticle.id}`, {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    setArticle(res.articles[0])
                }
            },
        })
    }

    const queryClient = useQueryClient()

    const handleApproveArticle = () => {
        vFetch('/v1/knowledge/articles/reviews', {
            method: 'POST',
            body: JSON.stringify({
                message: review,
                user_id: currentUser.id,
                article_id: selectedArticle.id,
                status: 'Published',
            }),
            cb: (res: any) => {
                if (res.success) {
                    setView('adminPanel')
                    setSearchParams((prev) => {
                        prev.delete('adminArticleView')
                        return prev
                    })
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'adminPending'],
                    })
                }
            },
        })
    }

    const handleRejectArticle = () => {
        vFetch('/v1/knowledge/articles/reviews', {
            method: 'POST',
            body: JSON.stringify({
                message: review,
                user_id: currentUser.id,
                article_id: selectedArticle.id,
                status: 'Rejected',
            }),
            cb: (res: any) => {
                if (res.success) {
                    setView('adminPanel')
                    setSearchParams((prev) => {
                        prev.delete('adminArticleView')
                        return prev
                    })
                }
            },
        })
    }

    const handleDeleteArticle = (id: any) => {
        return vFetch('/v1/knowledge/articles', {
            method: 'DELETE',
            body: JSON.stringify({ id: selectedArticle.id }),
            cb: (res: any) => {
                if (res.success) {
                    setView('departmentView')
                    setSearchParams((prev) => {
                        prev.delete(`article${selectedArticle.id}`)
                        prev.delete('articleID')
                        prev.delete('departmentView')
                        return prev
                    })
                }
            },
        })
    }

    const handleCancelArticleDelete = () => {
        setConfirmCancel(false)
    }
    const handleBackButton = () => {
        setView('adminPanel')
        setSearchParams((prev) => {
            prev.delete('adminArticleView')
            return prev
        })
    }
    useEffect(() => {
        getArticles()
    }, [article])

    return (
        <div className='relative flex flex-col items-center px-2 mt-4 mb-4'>
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='self-start'>
                    <div className=''>
                        <Button onClick={handleBackButton} variant={'outline'} className='mx-auto mt-2 text-xs'>
                            BACK
                        </Button>
                    </div>
                </div>
            )}

            {confirmCancel && (
                <div className='absolute right-6 top-9 items-center flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                    <div className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>Are you sure?</div>
                    <button
                        value='Yes - Delete'
                        onClick={() => handleDeleteArticle(article.id)}
                        className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                    >
                        Yes
                    </button>
                    <button
                        value='No - Cancel'
                        onClick={handleCancelArticleDelete}
                        className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                    >
                        No
                    </button>
                </div>
            )}

            {!isLoading && !isError && (
                <div className='max-w-[913px] mx-auto'>
                    <div>
                        <h1 className='mt-5 font-semibold text-5xl'>{article.title}</h1>
                        <h3 className='mt-4 text-lg '>{article.excerpt}</h3>
                        <p></p>
                    </div>
                    <img className='w-full mt-5 rounded' src={article.featured_image} />
                    <div className='mt-3 p-2 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                    <div className='mt-3 flex self-start gap-2'>
                        <div>
                            <div className='mr-1 mt-3 font-semibold leading-none'>
                                by {article.user_firstName} {article.user_lastName}
                            </div>
                            <div className='mt-1 self-start text-sm text-grey'>
                                Updated: {articleCardDateFormatter.format(new Date(article.updated_at))}
                            </div>
                        </div>
                    </div>
                    <div className='ql-snow'>
                        <div
                            style={{ padding: '0px', overflow: 'unset' }}
                            className='ql-editor mt-7'
                            dangerouslySetInnerHTML={{ __html: article.content }}
                        ></div>
                    </div>
                    <div className='mt-6 p-2 w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                </div>
            )}
            {!isLoading && !isError && (
                <div className='flex flex-col p-4 w-full max-w-[913px] mt-10 bg-white  dark:bg-darkaccent rounded border border-lightgrey dark:border-darkgrey'>
                    <p className='text-lg font-bold p-2'>Review Article</p>
                    <textarea
                        autoFocus={true}
                        value={review ?? ''}
                        onChange={({ target }) => setReview(target.value)}
                        className='min-h-[40px] max-h-[90px] w-full p-2 max-w-none border-0
                    rounded focus:outline-none  dark:bg-darkbg1
                     dark:text-offwhite  overscroll-contain pb-0'
                        placeholder='Type your review here. After you approve or reject, the author of the article will be able to see your review.'
                    ></textarea>
                    <div className='mt-4 flex self-center pl-2 gap-1.5 py-2 font-medium'>
                        <Button variant={'outline'} className='block mx-auto' onClick={handleApproveArticle}>
                            Approve
                        </Button>
                        <Button variant={'outline'} className='block mx-auto' onClick={handleRejectArticle}>
                            Reject
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}
