import { cn } from 'helpers'
import { useShopifyVendorsQuery } from 'procurement/api/useQueries'
import { useState } from 'react'
import { MdDoubleArrow } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { PrivateEventType } from 'tasks/tasks.types'
import { UserInit } from 'users/users.types'
import RefreshButton from '../custom_components/RefreshButton'
import ProductsTableContainer from './ProductsTableContainer'
import RecentlyVisitedProductsTableContainer from './RecentlyVisitedProductsTableContainer'
import { productSetupQueries } from './api/productSetupQueryKeys'
import { useGetProductSetupPrivateEvents } from './api/useQueries'
import ProductsAllPrivateEventModalV2 from './components/ProductsAllPrivateEventModalV2'
import { useAllUsersQuery } from 'users/api/useQueries'

export default function ProductListScreen() {
    const recentlyVisitedState = localStorage.getItem('productSetupRecentlyVisited')
    const user = useSelector<any, any>((state) => state.user)
    const [showRecentlyVisited, setShowRecentlyVisited] = useState(recentlyVisitedState === 'true' ? true : false)
    const [showAllProductsSetupEventsPrivate, setShowAllProductsSetupEventsPrivate] = useState(false)

    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}

    const privateEventsQuery = useGetProductSetupPrivateEvents()
    const { productSetupEventsPrivate: privateEvents }: { productSetupEventsPrivate: PrivateEventType[] } =
        privateEventsQuery?.data || {}

    function getRecentlyViewed() {
        const filteredEvents = privateEvents?.filter(
            (event) => event.user_id == user.id || event.user_id === user.user_id
        )
        const uniqueProductids = new Set(filteredEvents?.map((event) => event.product_id))
        const recentlyViewedProducts: any = []
        Array.from(uniqueProductids).some((id, index) => {
            recentlyViewedProducts.push(filteredEvents?.find((event) => event.product_id === id))
            return index === 4
        })
        return recentlyViewedProducts.map((product: any) => {
            return {
                id: product.product_id,
                title: product.title,
            }
        })
    }
    const recentlyViewedProducts = getRecentlyViewed()

    return (
        <div className=''>
            <div className='flex justify-between mb-[8px] bg-bg2 relative dark:bg-darkbg2'>
                <div className='flex gap-28 items-center'>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Products</h1>
                    <div
                        onClick={() => {
                            localStorage.setItem('productSetupRecentlyVisited', showRecentlyVisited ? 'false' : 'true')
                            return setShowRecentlyVisited(showRecentlyVisited ? false : true)
                        }}
                        className={cn(
                            showRecentlyVisited && 'border-b border-accent2',
                            'flex gap-2 items-center cursor-pointer'
                        )}
                    >
                        <h2 className='text-[18px] font-semibold dark:text-offwhite capitalize'>Recently Visited</h2>
                        <MdDoubleArrow
                            className={`w-[16px]  h-[16px] transition-all duration-150 ${
                                !showRecentlyVisited ? '-rotate-90' : 'rotate-90'
                            }`}
                        />
                    </div>
                </div>
                <div className='flex gap-[8px] absolute top-[-16px] right-2'>
                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                        <button
                            className='whitespace-nowrap py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite text-[12px] font-bold uppercase'
                            onClick={() => setShowAllProductsSetupEventsPrivate(true)}
                        >
                            Latest Events
                        </button>
                    )}
                    <RefreshButton queryKeys={[productSetupQueries.productSetup._def.toString()]} />
                </div>
            </div>
            <div className='flex justify-between relative bg-bg1 dark:bg-darkbg2 mb-2'>
                <div className='absolute right-52 top-[-8px] bg-bg1 dark:bg-darkbg2 flex items-center gap-8'></div>
            </div>
            <div className='flex flex-col gap-4'>
                {showRecentlyVisited && (
                    <div className='rounded-[4px] shadow-small dark:bg-darkaccent bg-bg1'>
                        <>
                            <RecentlyVisitedProductsTableContainer
                                loading={privateEventsQuery.isLoading}
                                recentlyViewedProducts={recentlyViewedProducts}
                            />
                        </>
                    </div>
                )}
                <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh] bg-bg1'>
                    {shopifyVendorNames && (
                        <>
                            <ProductsTableContainer vendorNames={shopifyVendorNames} />
                        </>
                    )}
                </div>
            </div>
            {privateEvents?.length > 0 && users?.length > 0 && showAllProductsSetupEventsPrivate && (
                <ProductsAllPrivateEventModalV2
                    origin='product'
                    userList={users}
                    setViewModal={setShowAllProductsSetupEventsPrivate}
                    events={privateEvents}
                />
            )}
        </div>
    )
}
