import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import Department from './Department'
import CreateDepartmentModal from './CreateDepartmentModal'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

export default function DepartmentsMain({
    setView,
    selectedDepartment,
    setSelectedDepartment,
    setSelectedArticle,
    selectedArticle,
}: any) {
    const [searchParams] = useSearchParams()
    const [departments, setDepartments] = useState([])
    const [showCreateDepartmentModal, setShowCreateDepartmentModal] = useState(false)
    const currentUser = useSelector((state: any) => state.user)
    const canEdit = currentUser.type === 'Admin' || currentUser.type === 'SuperAdmin'

    const getDepartments = () => {
        vFetch('/v1/knowledge/departments', {
            cb: (res: any) => {
                if (res.success) {
                    const foundDepartment = res.departments.find(
                        (department: any) => department.id == searchParams.get('departmentID')
                    )
                    if (foundDepartment) {
                        setSelectedDepartment(foundDepartment)
                    }
                    setDepartments(res.departments)
                }
            },
        })
    }

    const handleClickAddDepartment = () => {
        setShowCreateDepartmentModal(true)
    }

    useEffect(() => {
        getDepartments()
    }, [])

    return (
        <div>
            <div className='flex items-center justify-between mb-4'>
                <h2 className='font-bold hover:cursor-default'>Departments ({departments.length})</h2>
                {canEdit && (
                    <button onClick={handleClickAddDepartment}>
                        <div className='mr-8 shrink-0 rounded-full flex items-center justify-center w-[16px] h-[16px] text-2xl font-bold bg-blue text-white dark:text-darkness dark:bg-offwhite'>
                            <p className='text-lg mb-0.5'>+</p>
                        </div>
                    </button>
                )}
            </div>

            <div className='flex flex-col gap-1 text-sm font-semibold'>
                <div className='flex flex-col'>
                    {departments?.map((department: any) => (
                        <Department
                            key={`department-${department.id}`}
                            getDepartments={getDepartments}
                            department={department}
                            id={department.id}
                            icon={department.icon}
                            label={department.label}
                            setView={setView}
                            selectedDepartment={selectedDepartment}
                            setSelectedDepartment={setSelectedDepartment}
                            selectedArticle={selectedArticle}
                            setSelectedArticle={setSelectedArticle}
                        />
                    ))}
                </div>
                {showCreateDepartmentModal && (
                    <div>
                        <CreateDepartmentModal
                            setShowCreateDepartmentModal={setShowCreateDepartmentModal}
                            getDepartments={getDepartments}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
