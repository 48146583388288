import { useState } from 'react'
import { formatDate } from '../../helpers'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'

export default function BasicEventGroup({ event }: { event: any }) {
    const events = event.events
    const [showAllEvents, setShowAllEvents] = useState(false)
    return (
        <div className='w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                Event
            </p>
            <div className='flex justify-between'>
                <div className='flex gap-[8px] items-center'>
                    <p className='font-bold text-[14px]'>{event.message}</p>
                    <button onClick={() => setShowAllEvents((previousState) => !previousState)}>
                        {showAllEvents ? <FaCaretUp /> : <FaCaretDown />}
                    </button>
                </div>
                <p className='font-bold text-[12px]'>{formatDate(new Date(events[0].created_at))}</p>
            </div>
            {showAllEvents && (
                <div className='p-[8px] max-h-[200px] overflow-auto'>
                    {events.map((e: any) => {
                        const notTask = !e.message.split(' ').includes('task')
                        return (
                            <div className='flex gap-2 text-[14px]'>
                                <p className='break-words max-w-full font-bold'>{e.message}</p>
                                {(e.previous_value || e.value) && notTask && (
                                    <p>
                                        <span className='line-through'>{e.previous_value}</span> {'=>'} {e.value}
                                    </p>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
