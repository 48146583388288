import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import { useGetProductGroupDetailQuery, useUpdateOptionValues } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { cn, LoadingGear } from '../../helpers'
import { MetafieldProps } from '../ProductSetup.types'
import { ProductGroup } from 'productSetupV2/productGroups/ProductGroupDetail'
import ProductGroupsSelect from 'productSetupV2/productGroups/ProductGroupsSelect'

export default function Group({ product, handleUpdateMetafield }: MetafieldProps) {
    const groupRef = useRef<HTMLDivElement>(null)
    const [editableOptionValueFields, setEditableOptionValueFields] = useState<any>({})
    const [removedOptionValues, setRemovedOptionValues] = useState<any>([])
    const valuesEdited = Object.keys(editableOptionValueFields).length > 0

    // QUERY
    const productGroupQuery = useGetProductGroupDetailQuery({ productId: product.id })

    const { group: productGroups }: { group: ProductGroup[] } = productGroupQuery?.data || {}
    const productGroup = productGroups?.length > 0 ? productGroups[0] : {}

    const { id: group_id, group_name, products, options, product_option_values } = productGroup

    const product_link_id = products?.find((groupedProduct: any) => groupedProduct.product_id == product.id)
        ?.product_link_id

    // MUTATION
    const updateOptionValues = useUpdateOptionValues()

    const handleNestedChange = (
        { target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        product_link_id: any
    ) => {
        const relatedProductOptionValue = product_option_values?.find((option_value: any) => {
            return option_value.product_link_id == product_link_id && option_value.option_id.toString() == target.name
        })
        // if input empty and no row exists, remove from editableFields
        if (target.value === '' && !relatedProductOptionValue) {
            const newEditableFields = structuredClone(editableOptionValueFields)
            delete newEditableFields[product_link_id][target.name]
            setEditableOptionValueFields(newEditableFields)
        }
        // if input empty and row exists, add to removedOptionValue array
        if (target.value === '' && relatedProductOptionValue) {
            const newRemovedOptionValues = structuredClone(removedOptionValues)
            newRemovedOptionValues.push({ option_id: target.name, product_link_id })
            setRemovedOptionValues(newRemovedOptionValues)
            const newEditableFields = structuredClone(editableOptionValueFields)
            newEditableFields[product_link_id] = {
                ...newEditableFields[product_link_id],
                [target.name]: target.value,
            }
            setEditableOptionValueFields(newEditableFields)
        }
        // otherwise add to editableFields and remove from removedOptionValue array
        if (target.value !== '') {
            const newRemovedOptionValues = structuredClone(removedOptionValues)
                .filter((optionValue: any) => {
                    if (
                        optionValue.option_id?.toString() != target.name ||
                        optionValue.product_link_id != product_link_id
                    ) {
                        return optionValue
                    }
                })
                .filter((v: any) => v)

            setRemovedOptionValues(newRemovedOptionValues)
            const newEditableFields = structuredClone(editableOptionValueFields)
            newEditableFields[product_link_id] = {
                ...newEditableFields[product_link_id],
                [target.name]: target.value,
            }
            setEditableOptionValueFields(newEditableFields)
        }
    }

    function handleOptionValuesCancel() {
        setEditableOptionValueFields({})
        setRemovedOptionValues([])
    }

    const handleSaveOptionValues = () => {
        const updates = Object.entries(editableOptionValueFields)
            .map(([key, values]: any) => {
                const editArray = Object.entries(values).map(([key2, value2]: any) => {
                    return { product_link_id: key, option_id: key2, option_value: value2 }
                })
                return editArray
            })
            .flat()

        const deletions = removedOptionValues
        if (productGroup.id) {
            updateOptionValues.mutate(
                { groupId: productGroup.id, updates, deletions },
                {
                    onSuccess: (data, variables) => {
                        handleOptionValuesCancel()
                    },
                }
            )
        }
    }
    return (
        <div className='w-full dark:bg-darkaccent p-2 rounded-[4px] flex flex-col shadow-small relative'>
            <p className='mt-[-8px] ml-[-8px] rounded-tl-[4px] p-[4px] leading-[1] w-fit text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                Group
            </p>
            {group_id && (
                <div className='flex gap-4 absolute top-2 right-3 '>
                    <div
                        className={cn(
                            'flex gap-2 items-end justify-end w-full',
                            !valuesEdited && 'pointer-events-none opacity-40'
                        )}
                    >
                        <div className=' p-2 rounded flex gap-2 items-center'>
                            <button
                                onClick={handleSaveOptionValues}
                                className={cn(
                                    updateOptionValues.isPending && 'opacity-[0.8] pointer-events-none',
                                    'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-accent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[110px]'
                                )}
                            >
                                {updateOptionValues.isPending ? 'SAVING...' : 'SAVE CHANGES'}
                            </button>
                            <button
                                onClick={handleOptionValuesCancel}
                                className={cn(
                                    updateOptionValues.isPending && 'opacity-[0.6] pointer-events-none',
                                    'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                                )}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                    <Button
                        variant={'outline'}
                        size={'sm'}
                        className='dark:border-darkgrey dark:hover:border-lightgrey'
                    >
                        <Link
                            id='product_id'
                            to={`/products/product-groups/${group_id}`}
                            draggable={false}
                            className='flex gap-2 underline items-center relative'
                        >
                            Go to Group Setup
                        </Link>
                    </Button>
                </div>
            )}
            {!productGroupQuery.isLoading && !group_id && product.id && <ProductGroupsSelect productId={product.id} />}
            {!productGroupQuery.isLoading && group_id && (
                <div>
                    <div className='flex flex-col gap-[4px] w-full mb-[8px]'>
                        <div className='w-full border-b mb-2 dark:border-darkgrey'>
                            <label
                                className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                htmlFor='group'
                            >
                                Name
                            </label>
                        </div>
                        <div ref={groupRef} className='relative w-full flex gap-[8px]'>
                            <input
                                id='group'
                                type='text'
                                value={group_name}
                                name='group'
                                readOnly={true}
                                className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey cursor-default dark:text-white rounded-[4px] dark:bg-darkbg2'
                            />
                        </div>
                    </div>
                    <>
                        <div className='w-full border-b mb-2 dark:border-darkgrey'>
                            <label className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                                Options
                            </label>
                        </div>
                        <div className='flex flex-col gap-3'>
                            {product_link_id &&
                                options?.map((option: any) => {
                                    const relatedProductOptionInfo = product_option_values?.find(
                                        (option_value: any) => {
                                            return (
                                                option_value.product_link_id == product_link_id &&
                                                option_value.option_id == option.option_id
                                            )
                                        }
                                    )

                                    // find nested value or return null
                                    const value = editableOptionValueFields[product_link_id]
                                        ? typeof editableOptionValueFields[product_link_id][option.option_id] ===
                                          'string'
                                            ? editableOptionValueFields[product_link_id][option.option_id]
                                            : null
                                        : null

                                    return (
                                        <div className='ml-4'>
                                            <Input
                                                key={option.option_id}
                                                label={option?.option_name}
                                                value={value ?? (relatedProductOptionInfo?.option_value || '')}
                                                type='text'
                                                id={option?.option_id.toString()}
                                                name={option?.option_id.toString()}
                                                onChange={(e) => handleNestedChange(e, product_link_id)}
                                                className={cn('')}
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                    </>
                </div>
            )}
            {productGroupQuery.isLoading && (
                <div className='grid place-items-center p-[32px]'>
                    <LoadingGear />
                </div>
            )}
        </div>
    )
}
