import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const stores = createQueryKeys('stores', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getStores(),
    }),
})

export const storesQueries = mergeQueryKeys(stores)
