import { ChangeEvent, ReactNode } from 'react'
import { cn } from '../../helpers'

export default function Select({
    className,
    outerClassName,
    id,
    name,
    onChange,
    datasetName,
    datasetValue,
    value,
    children,
    label,
    disabled = false,
}: {
    className?: string
    outerClassName?: string
    id: string
    name: string
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void
    datasetName?: string
    datasetValue?: string
    value: any
    children?: ReactNode
    label?: string
    disabled?: boolean
}) {
    const dataSetProps: any = {}
    if (datasetName && datasetValue) {
        dataSetProps[datasetName] = datasetValue
    }
    return (
        <div className={cn(`flex flex-col gap-[4px] w-full`, outerClassName)}>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'
                >
                    {label}
                </label>
            )}
            <select
                disabled={disabled}
                className={cn(
                    `w-full text-[16px] leading-none bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`,
                    className
                )}
                id={id}
                name={name}
                onChange={onChange}
                value={value}
                {...dataSetProps}
            >
                {children}
            </select>
        </div>
    )
}
