import { ChangeEvent, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { cn } from '../../../helpers'
import { useDeleteContact, useUpdateContact } from '../../api/useQueries'

export default function ParentContact({ contact }: { contact: any }) {
    const [editableFields, setEditableFields] = useState<any>({})

    const isEdited = Boolean(Object.keys(editableFields).length)

    const deleteContact = useDeleteContact()
    const updateContact = useUpdateContact()
    const isPending = deleteContact.isPending || updateContact.isPending

    const handleDelete = async () => {
        for (let i = 0; i < contact.contact_list.length; i++) {
            const uniqueContact = contact.contact_list[i]
            deleteContact.mutate({ contact: uniqueContact, skipToast: i !== contact.contact_list.length - 1 })
        }
    }
    const handleUpdate = async () => {
        updateContact.mutate(
            {
                contact: {
                    ...contact,
                    ...editableFields,
                },
            },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    return (
        <div
            className={cn(
                isPending && 'opacity-50',
                'text-[14px] grid grid-cols-[1fr_1fr_1fr_1fr_1fr_50px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
            )}
        >
            <div className='p-[8px]'>{contact.companies?.join(', ')}</div>
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.name ?? contact.name}
                name='name'
                onChange={handleChange}
                disabled={isPending}
            />
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.email ?? contact.email}
                name='email'
                onChange={handleChange}
                disabled={isPending}
            />
            <input
                className='w-full h-full p-[8px] font-bold bg-transparent'
                value={editableFields.phone ?? contact.phone}
                name='phone'
                onChange={handleChange}
                disabled={isPending}
            />
            <textarea
                className='w-full h-full max-h-[40px] rounded-none border-0 p-[8px] font-bold bg-transparent'
                value={editableFields.alternate ?? contact.alternate}
                name='alternate'
                onChange={handleChange}
                disabled={isPending}
            ></textarea>
            {isEdited ? (
                <button
                    onClick={handleUpdate}
                    className='w-full flex items-center justify-center font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent py-[2px] px-[4px] rounded-[4px] self-center uppercase'
                >
                    Save
                </button>
            ) : (
                <button onClick={() => handleDelete()} className='w-full flex items-center justify-center'>
                    <FaTrash className='text-red dark:text-lightred' />
                </button>
            )}
        </div>
    )
}
