import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { FaTrashCan } from 'react-icons/fa6'
import { MdEdit } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
import { vFetch } from '../../helpers'
import { useEffect, useState } from 'react'
import { articleCardDateFormatter } from '../helpers'
import { useQueryClient } from '@tanstack/react-query'

export default function YourArticleCard({
    article,
    setView,
    setSelectedArticle,
    setSelectedArticleView,
    getArticles,
}: {
    article: any
    setView: any
    setSelectedArticle: any
    setSelectedArticleView: any
    getArticles: any
}) {
    const [_searchParams, setSearchParams] = useSearchParams()
    const [showEditButton, setShowEditButton] = useState(true)
    const [editArticle, setEditArticle] = useState(false)
    const [confirmCancel, setConfirmCancel] = useState(false)
    const queryClient = useQueryClient()

    const handleSetArticle = (article: any) => {
        setSelectedArticle(article)
        setSelectedArticleView(true)
        setSearchParams((prev: any) => {
            prev.set('myArticleID', article.id)
            return prev
        })
    }
    const handleDeleteArticle = (id: any) => {
        return vFetch('/v1/knowledge/articles', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
            cb: (res: any) => {
                if (!res.success) {
                }
                if (res.success) {
                    handleCancel()
                    getArticles()
                    return queryClient.invalidateQueries({
                        queryKey: ['knowledge', 'articles', 'global'],
                    })
                }
            },
        })
    }

    function handleCancel() {
        setShowEditButton(true)
        setEditArticle(false)
        setConfirmCancel(false)
    }
    const handleThreeDotsClick = () => {
        setShowEditButton(false)
    }
    const handleEditArticle = (article: any) => {
        setView('myEditArticleView')
        setSelectedArticle(article)
        setSearchParams((prev: any) => {
            prev.set('myEditArticle', article.id)
            return prev
        })
    }

    const handleDeleteAttempt = () => {
        setConfirmCancel(true)
    }
    useEffect(() => {}, [])
    return (
        <div
            key={`my-article-${article.id}`}
            className='relative flex items-center border bg-neutral-50 dark:bg-darkbg1 border-lightgrey dark:border-none shadow-md dark:shadow-darkbg2 hover:shadow-xl rounded overflow-hidden p-4'
        >
            <div className='absolute top-[6px] right-[35px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                {article.status === 'Pending' && (
                    <div className=' bg-yellow-300/75 border border-yellow-400/75 dark:bg-yellow-300/25 rounded px-2'>
                        Pending
                    </div>
                )}
                {article.status === 'Published' && (
                    <div className=' bg-green-300/50 border border-green-400/25 dark:bg-green-400/25 dark:border-green-300/50 rounded px-2'>
                        Published
                    </div>
                )}
                {article.status === 'Rejected' && (
                    <div className='bg-red/50 border border-red/75 dark:bg-red/25 dark:border-red/50 rounded px-2'>
                        Rejected
                    </div>
                )}
            </div>
            <div className='absolute top-[6px] right-[130px] opacity-[70%] dark:opacity-[80%] dark:text-white'>
                {article.hidden === 1 && (
                    <div className=' bg-grey/30 border border-grey dark:bg-grey/25 dark:border-grey rounded px-2'>
                        Hidden
                    </div>
                )}
            </div>
            <img
                src={article.featured_image ? article.featured_image : '/logo.png'}
                className='object-cover w-1/2 h-full rounded mr-2'
            />
            <div className='absolute right-2 top-2'>
                {showEditButton ? (
                    <button className='text-black dark:text-offwhite text-xl font-bold' onClick={handleThreeDotsClick}>
                        <BsThreeDotsVertical />
                    </button>
                ) : (
                    <div>
                        <button
                            onClick={handleCancel}
                            className='shrink-0 whitespace-nowrap
                                 dark:text-offwhite text-xl font-bold'
                        >
                            <FaTimes className='text-red w-[22px] h-[22px]' />
                        </button>
                    </div>
                )}
                {!showEditButton && !editArticle && !confirmCancel && (
                    <div className='absolute right-2 top-7 flex flex-col shrink-0 border shadow-md text-sm whitespace-nowrap dark:border-darkgrey text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                        <button
                            onClick={() => handleEditArticle(article)}
                            value='Edit'
                            className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
                        >
                            <MdEdit className='w-[20px]' />
                            Edit Article
                        </button>
                        <button
                            value='Delete'
                            onClick={handleDeleteAttempt}
                            className='flex gap-2 items-center mb-2 mx-2 text-red/75 dark:text-offwhite'
                        >
                            <FaTrashCan className='w-[20px] text-red/75 dark:text-offwhite text-sm' />
                            Delete Article
                        </button>
                    </div>
                )}
                {confirmCancel && (
                    <div className='absolute items-center right-2 top-7 flex flex-col shrink-0 border shadow-md text-sm whitespace-nowrap dark:border-darkgrey text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                        <p className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>Are you sure?</p>
                        <button
                            value='Yes - Delete'
                            onClick={() => handleDeleteArticle(article.id)}
                            className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white bg-red'
                        >
                            Yes
                        </button>
                        <button
                            value='No - Cancel'
                            onClick={handleCancel}
                            className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                        >
                            No
                        </button>
                    </div>
                )}
            </div>
            <div className='pr-4 pt-4 pl-2'>
                <button
                    className='mt-2 text-md font-semibold leading-tight text-left'
                    onClick={() => handleSetArticle(article)}
                >
                    {article.title}
                </button>
                <div className='mt-1 leading-tight line-clamp-3 text-left text-sm'>{article.excerpt}</div>
                <div className=' p-1  w-full border border-t-0 border-x-0 dark:border-darkgrey'></div>
                <div className='flex items-center gap-2'>
                    <div>
                        <div className='mt-4 font-semibold text-sm leading-none'>
                            {article.user_firstName} {article.user_lastName}
                        </div>
                        <div className='mt-0.5 flex flex-row items-center gap-2'>
                            <div className='text-sm'>
                                {articleCardDateFormatter.format(new Date(article.created_at))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
