import { subDays } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-day-picker'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'
import { cn } from '../../helpers'
import { getFilterBarUsers } from '../../procurement/helpers'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import {
    useCompanyInteractionsQuery,
    useCompanyStatusAnalyticsQueryByUserTotal,
    useProductInteractionsQuery,
} from '../api/useQueries'
import GenericAnalyticsIntervalMultiDataset from '../charts_graphs/GenericAnalyticsIntervalMultiDataset'
import GenericAnalyticsTotals from '../charts_graphs/GenericAnalyticsTotals'
import {
    ChartType,
    DATE_PICKER_OPTIONS,
    LEAD_TYPE_ANALYTICS_FIELDS,
    LIFECYCLE_STATUS_ANALYTICS_FIELDS,
} from '../helpers'
import { AnalyticsHeader } from './AnalyticsHeader'
import { getCompareDateRange } from './DatePickerPresetsCompare'

export default function AnalyticsUserReport() {
    const navigate = useNavigate()
    let [searchParams] = useSearchParams()
    const fromDifference = searchParams.get('from') || '-7'
    const toDifference = searchParams.get('to') || '-1'
    const optionIndexParam: string = searchParams.get('option') || '2'
    const compareIndexParam: string = searchParams.get('compare') || '1'
    const [optionIndex, setOptionIndex] = useState<number>(parseInt(optionIndexParam))
    const user = useSelector<any, any>((state) => state.user)
    const [selectedUsers, setSelectedUsers] = useState<any>({
        users: [user.id],
    })

    const [period, setPeriod] = React.useState<{
        value: string
        function: Function
        rangeString: string
    }>({
        value: DATE_PICKER_OPTIONS[optionIndex].values.join(),
        function: DATE_PICKER_OPTIONS[optionIndex].function,
        rangeString: DATE_PICKER_OPTIONS[optionIndex].label,
    })

    const [date, setDate] = React.useState<DateRange | undefined>({
        from: new Date(subDays(new Date(), -parseInt(fromDifference)).toDateString()),
        to: new Date(subDays(new Date(), -parseInt(toDifference)).toDateString()),
    })
    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    useEffect(() => {
        if (!(user.type === 'Admin' || user.type === 'SuperAdmin')) {
            navigate(`/`)
        }
    }, [])

    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsers(users),
                title: 'Users',
                field: 'users',
                values: selectedUsers.users,
                searchToggle: true,
                selectType: 'single',
            },
        ],
        setFunction: setSelectedUsers,
        resetFunction: () => {
            setSelectedUsers({
                users: [],
            })
        },
    }
    const [customCompareDate, setCustomCompareDate] = React.useState<DateRange | undefined>(undefined)
    const compareDate = getCompareDateRange(date, period, customCompareDate, parseInt(compareIndexParam))

    const chartsArray: ChartType[] = [
        {
            component: GenericAnalyticsIntervalMultiDataset,
            analyticsObjects: [
                {
                    analyticsGetFunction: useCompanyInteractionsQuery,
                    queryOptions: [
                        {
                            tag: 'all',
                            value: [],
                            title: 'All',
                        },
                    ],
                    label: 'Company Interactions',
                },
                {
                    analyticsGetFunction: useProductInteractionsQuery,
                    queryOptions: [
                        {
                            tag: 'all',
                            value: [],
                            title: 'All',
                        },
                    ],
                    label: 'Product Interactions',
                },
            ],
            title: 'Interactions',
            size: 'small',
        },
        {
            component: GenericAnalyticsTotals,
            analyticsObjects: [
                {
                    analyticsGetFunction: useCompanyStatusAnalyticsQueryByUserTotal,
                    queryOptions: [
                        {
                            tag: 'lead_type',
                            title: 'Lead Type',
                            value: ['lead_type'],
                        },
                        {
                            tag: 'lifecycle_status',
                            title: 'Lifecycle Status',
                            value: ['lifecycle_status'],
                        },
                    ],
                    fieldOptions: [LEAD_TYPE_ANALYTICS_FIELDS, LIFECYCLE_STATUS_ANALYTICS_FIELDS],
                },
            ],
            title: 'Status Changes',
            size: 'medium',
        },
    ]

    return (
        <>
            <div className='flex gap-3 items-center min-h-[48px] z-[31] bg-bg1 dark:bg-darkbg2 cursor-pointer sticky top-12'>
                <span
                    onClick={() => navigate(`/analytics?${searchParams.toString()}`)}
                    className='self-center cursor-pointer'
                >
                    <RiArrowGoBackLine />
                </span>
                <h1
                    onClick={() => navigate(`/analytics?${searchParams.toString()}`)}
                    className='self-center capitalize text-[24px] font-semibold dark:text-offwhite cursor-pointer'
                >
                    User Report
                </h1>

                <AnalyticsHeader
                    selectorButtonObject={selectorButtonObject}
                    date={date}
                    setDate={setDate}
                    customCompareDate={customCompareDate}
                    setCustomCompareDate={setCustomCompareDate}
                    period={period}
                    setPeriod={setPeriod}
                    optionIndex={optionIndex}
                    setOptionIndex={setOptionIndex}
                    interval={'1'}
                    compare={compareIndexParam}
                />
            </div>
            <div className='relative flex flex-col gap-4'>
                <div className='grid grid-cols-3 gap-4'>
                    {chartsArray.map((chart) => {
                        return (
                            <div
                                className={cn(
                                    chart.size === 'small' && 'col-span-1',
                                    chart.size === 'medium' && 'col-span-2',
                                    chart.size === 'large' && 'col-span-3'
                                )}
                            >
                                <chart.component
                                    analyticsObjects={chart.analyticsObjects}
                                    user_ids={selectedUsers.users}
                                    dateRange={date}
                                    dateRangeCompare={compareDate}
                                    size={chart.size}
                                    chartTitle={chart.title}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
