import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { usersQueries } from './queryKeys'
import * as service from './service'

export const useGetAllUsers = (filters: any) =>
    useQuery({
        ...usersQueries.users.list(filters),
    })
export const useGetUserLogins = (filters: any) =>
    useQuery({
        ...usersQueries.userLogins.list(filters),
    })

export const useSetupUser = () =>
    useMutation({
        mutationFn: (newUserOptions: any) => service.setupNewUser(newUserOptions),
    })

export const useUpdateUser = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (userUpdates: any) => service.updateUser(userUpdates),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['users'] })
        },
    })
}
