import { differenceInCalendarDays } from 'date-fns'
import { vFetch } from '../../helpers'
import { DateRange } from 'react-day-picker'

const ANALYTICS_BASE_URL = '/v1/analytics/interactions'

//Procurement

const PROCUREMENT_BASE_URL = '/procurement'

export const getCompanyStatusAnalytics = async (
    user_ids: string[],
    dateRange: DateRange,
    dateRangeCompare: {},
    timePeriod: string,
    fields: {
        tag: string
        value: string[]
        title: string
    }
) => {
    const interval = timePeriod === 'DAY' ? 1 : timePeriod === 'WEEK' ? 7 : 30
    return await vFetch(
        `${ANALYTICS_BASE_URL}${PROCUREMENT_BASE_URL}/status/interval?user_ids=${JSON.stringify(
            user_ids
        )}&dateRange=${JSON.stringify(dateRange)}
        &dateRangeCompare=${JSON.stringify(dateRangeCompare)}
        &interval=${interval}
        &fields=${JSON.stringify(fields)}`
    )
}
export const getCompanyStatusAnalyticsByUserTotal = async (
    user_ids: string[],
    dateRange: DateRange,
    dateRangeCompare: {},
    fields: {
        tag: string
        value: string[]
        title: string
    }[]
) => {
    let interval = 0
    if (dateRange?.from && dateRange?.to) {
        interval = differenceInCalendarDays(dateRange?.from, dateRange?.to) - 1
    }
    return await vFetch(
        `${ANALYTICS_BASE_URL}${PROCUREMENT_BASE_URL}/status/total?user_ids=${JSON.stringify(
            user_ids
        )}&dateRange=${JSON.stringify(dateRange)}
        &dateRangeCompare=${JSON.stringify(dateRangeCompare)}
        &interval=${-interval}
        &fields=${JSON.stringify(fields)}`
    )
}
export const getCompanyLeadTypeAnalytics = async (
    user_ids: string[],
    dateRange: {},
    dateRangeCompare: {},
    timePeriod: string,
    leadTypes: string[]
) => {
    const interval = timePeriod === 'DAY' ? 1 : timePeriod === 'WEEK' ? 7 : 30
    return await vFetch(
        `${ANALYTICS_BASE_URL}${PROCUREMENT_BASE_URL}/leadTypes?user_ids=${JSON.stringify(
            user_ids
        )}&dateRange=${JSON.stringify(dateRange)}
        &dateRangeCompare=${JSON.stringify(dateRangeCompare)}
        &interval=${interval}
        &leadTypes=${JSON.stringify(leadTypes)}`
    )
}
export const getCompanyInteractionAnalytics = async (
    user_ids: string[],
    dateRange: {},
    dateRangeCompare: {},
    timePeriod: string,
    fields: {
        tag: string
        value: string[]
        title: string
    }
) => {
    const interval = timePeriod === 'DAY' ? 1 : timePeriod === 'WEEK' ? 7 : 30
    return await vFetch(
        `${ANALYTICS_BASE_URL}${PROCUREMENT_BASE_URL}/company/interactions?user_ids=${JSON.stringify(
            user_ids
        )}&dateRange=${JSON.stringify(dateRange)}
        &dateRangeCompare=${JSON.stringify(dateRangeCompare)}
        &interval=${interval}
        &fields=${JSON.stringify(fields)}`
    )
}

export const getProductInteractionAnalytics = async (
    user_ids: string[],
    dateRange: {},
    dateRangeCompare: {},
    timePeriod: string,
    fields: {
        tag: string
        value: string[]
        title: string
    }
) => {
    const interval = timePeriod === 'DAY' ? 1 : timePeriod === 'WEEK' ? 7 : 30
    return await vFetch(
        `${ANALYTICS_BASE_URL}${PROCUREMENT_BASE_URL}/product/interactions?user_ids=${JSON.stringify(
            user_ids
        )}&dateRange=${JSON.stringify(dateRange)}
        &dateRangeCompare=${JSON.stringify(dateRangeCompare)}
        &interval=${interval}
        &fields=${JSON.stringify(fields)}`,
        {
            reThrowErr: true,
            reThrowMessage: 'failed to load analytics data',
        }
    )
}
