import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { differenceInCalendarDays } from 'date-fns'
import { cn } from 'helpers'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import queryKeys from 'tasksV2/api/queryKeys'
import { getAssociationLink } from 'taxExemptions/helpers'

export default function TasksTable({ tableData, columns, columnCount, showDepartment, showHeaders, size }: any) {
    const user = useSelector((state: any) => state.user)
    const [sorting, setSorting] = React.useState<SortingState>([])
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const tableContainerRef = React.useRef<HTMLDivElement>(null)

    const table = useReactTable({
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
        initialState: {
            columnVisibility: {
                department_id: showDepartment,
            },
        },
    })
    const { rows } = table.getRowModel()
    const rowVirtualizer = useVirtualizer({
        count: rows.length,
        estimateSize: () => 28,
        getScrollElement: () => tableContainerRef.current,
        measureElement:
            typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
                ? (element) => element?.getBoundingClientRect().height
                : undefined,
        overscan: 15,
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }

    return (
        <div
            ref={tableContainerRef}
            className={cn(
                'overflow-y-auto relative',
                'w-full  transition-all delay-200 h-[340px]',
                size === 'pinned' && 'h-[260px]',
                size === 'tall' && 'h-[740px]',
                size === 'lg' && 'h-[740px]',
                size === 'auto' && 'h-full min-h-0'
            )}
        >
            <table className=' grid '>
                {showHeaders && (
                    <thead className='sticky top-0 z-[49] grid'>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                style={{ display: 'flex', width: '100%' }}
                                className={cn(
                                    ' bg-lightgrey text-text1 dark:bg-darkbg2 dark:text-darktext1 flex w-full'
                                )}
                            >
                                {headerGroup.headers.map((header) => (
                                    <th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{
                                            display: 'flex',
                                            width: header.getSize(),
                                        }}
                                        className='text-start flex  px-1 font-semibold capitalize z-50'
                                    >
                                        {header.isPlaceholder ? null : (
                                            <div
                                                className={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none flex gap-1'
                                                        : 'flex gap-1'
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                              ? 'Sort descending'
                                                              : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                                {{
                                                    asc: (
                                                        <div className='self-center min-h-[18px]'>
                                                            <CaretUpIcon />
                                                        </div>
                                                    ),
                                                    desc: (
                                                        <div className='self-center min-h-[18px]'>
                                                            <CaretDownIcon />
                                                        </div>
                                                    ),
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                )}
                <tbody
                    style={{
                        height: `${rowVirtualizer.getTotalSize()}px`,
                    }}
                    className='w-full grid relative'
                >
                    {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                        const row = rows[virtualRow.index] as any
                        const task: any = table.getRow(row.id).original
                        const associations = task.associations.filter(
                            (association: any) =>
                                association.resource_name !== 'user' &&
                                association.resource_name !== 'order_line_item' &&
                                association.resource_name !== 'draft_order_line_item'
                        )
                        const due_at = task.due_at
                        const overdue =
                            differenceInCalendarDays(new Date(), new Date(due_at)) != 0 && task.status != 'Completed'
                        return (
                            <tr
                                data-index={virtualRow.index}
                                ref={(node) => rowVirtualizer.measureElement(node)}
                                key={row.id}
                                style={{
                                    transform: `translateY(${virtualRow.start}px)`,
                                }}
                                className={cn(
                                    `w-full bg-bg1 dark:bg-darkbg1 flex absolute text-black dark:text-offwhite border-b-[2px]  border-lightgrey dark:border-gray-500 hover:bg-lightgrey cursor-pointer dark:hover:bg-darkbg2 hover:z-40`,
                                    overdue && ' '
                                )}
                                onClick={(event) => {
                                    queryClient.setQueryData(queryKeys.tasks.detail(task.id.toString()).queryKey, {
                                        success: true,
                                        task: task,
                                    })
                                    if (event.button === 1 || event.ctrlKey || event.metaKey) {
                                        return window.open(
                                            task.prioritize_association_view &&
                                                associations.length === 1 &&
                                                task.status != 'Archived'
                                                ? getAssociationLink(associations[0], user, queryClient)
                                                : `/tasks/${task.id}`,
                                            '_blank'
                                        )
                                    }
                                    return navigate(
                                        task.prioritize_association_view &&
                                            associations.length === 1 &&
                                            task.status != 'Archived'
                                            ? getAssociationLink(associations[0], user, queryClient)
                                            : `/tasks/${task.id}`
                                    )
                                }}
                            >
                                {row.getVisibleCells().map((cell: any) => {
                                    return (
                                        <td
                                            style={{ width: cell.column.getSize() }}
                                            key={cell.id}
                                            className='p-1 flex items-center'
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
