import { useEffect, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { cn } from '../../../helpers'
import { useDeleteNote, useUpdateNote } from '../../api/useQueries'
import { formatDateWithYear } from '../../helpers'
import TextareaNoResize from '../TextareaNoResize'
import { EventType } from 'procurement/Procurements.types'

export default function CompanyNoteV2({ note }: { note: EventType }) {
    const noteRef = useRef<HTMLDivElement>(null)
    const [editableNote, setEditableNote] = useState<string | undefined>(undefined)
    const [edit, setEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const isEdited = editableNote !== undefined && editableNote !== note.value

    const updateNote = useUpdateNote()
    const deleteNote = useDeleteNote()

    const isPending = updateNote.isPending || deleteNote.isPending

    const handleDelete = async () => {
        deleteNote.mutate({ note })
    }

    const handleUpdate = async () => {
        updateNote.mutate({ note: { ...note, value: editableNote } })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!noteRef.current?.contains(e.target as Element)) {
                setTimeout(() => {
                    setEdit(false)
                }, 100)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            className={cn(
                deleteNote.isPending && 'opacity-50',
                updateNote.isPending && 'opacity-50',
                'w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'
            )}
        >
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                Note
            </p>
            <div className='flex justify-between mb-[8px]'>
                <p className='font-bold text-[14px]'>{note.message}</p>
                <p className='font-bold text-[12px]'>{formatDateWithYear(new Date(note.created_at))}</p>
                {isEdited ? (
                    <div className='flex gap-2'>
                        <button
                            onClick={handleUpdate}
                            className='flex shrink-0 items-center justify-center font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent py-[2px] px-[4px] rounded-[4px] self-center uppercase'
                        >
                            Save Note
                        </button>
                    </div>
                ) : (
                    !showDelete && (
                        <button onClick={() => setShowDelete(true)} className='flex items-center justify-center'>
                            <FaTrash className='text-red dark:text-lightred' />
                        </button>
                    )
                )}
                {showDelete && showDelete && (
                    <div className='flex gap-[8px] self-center items-center justify-self-end'>
                        <button
                            onClick={handleDelete}
                            className={`${
                                isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => setShowDelete(false)}
                            className={`${
                                isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                            } text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            <div className='flex gap-[8px] items-center'>
                <div
                    ref={noteRef}
                    onClick={() => setEdit(true)}
                    className={`w-full relative
                        ${
                            edit
                                ? 'h-[300px]'
                                : 'overflow-hidden h-[100px] after:w-full after:h-[32px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#05111C,transparent)] after:absolute after:bottom-0 after:left-0'
                        }`}
                >
                    <TextareaNoResize
                        className={cn(
                            'dark:bg-darkbg1 w-full !max-w-none p-[8px] bg-transparent border-lightgrey dark:border-darkgrey rounded-[4px] focus:outline-none !max-h-none h-[100px]',
                            edit && 'h-[300px]'
                        )}
                        id={`note-${note.id}`}
                        name={`note-${note.id}`}
                        value={editableNote ?? note.value}
                        onChange={({ target }) => setEditableNote(target.value)}
                        disabled={isPending}
                    />
                </div>
            </div>
        </div>
    )
}
