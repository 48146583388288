import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from './component_Basics/Button'
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons'
import { ToggleSwitch } from './ToggleSwitch'
import TaskListItemSkeleton from '../procurement/components/listItems/TaskListItemSkeleton'
import { CalendarProcurementTasks } from './CalendarProcurementTasks'
import { FetchingSpinner } from './FetchingSpinner'
import { NoResults } from './NoResults'
import { SkeletonBasic } from './component_Basics/SkeletonBasic'
import FilterToolBar from 'procurement/components/FilterToolBar'
import { FilterBarSelectorButton } from './component_Basics/FilterBarSelectorButton'
import { MdOutlineAttachment } from 'react-icons/md'
import AreYouSure from './component_Basics/AreYouSure'
import { FaTimes } from 'react-icons/fa'

export default function ComponentList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const namesString = searchParams.get('names') || ''
    const [exampleFilters, setExampleFilters] = useState<any>({
        colors: [],
    })
    const [search, setSearch] = useState('')
    const [showAreYouSureModal, setShowAreYouSureModal] = useState(false)

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    // turn searchParam string into array of values
    const names = decodeURIComponent(namesString)
        .split(',')
        .filter((v) => v)

    const exampleNames = ['I', 'mutate', 'search', 'params']

    const exampleFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: exampleNames?.map((name: string) => {
                    return {
                        value: name, // value pushed into array or searchParams
                        label: name, // string shown to client
                        icon: undefined,
                    }
                }),
                title: 'names', // text value on button
                field: 'names', // the field this button will attempt to change. eg. filters = {names: []} OR fpdash.com/example?names=name1,name2
                values: names, // the values that will be checked in the selector button
                searchToggle: true, // show search field in popout
                editSearchParams: true, // mutate searchParams instead of state array
            },
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'blue',
                        label: 'Blue',
                        icon: undefined,
                    },
                    {
                        value: 'red',
                        label: 'Red',
                        icon: undefined,
                    },
                    {
                        value: 'green',
                        label: 'Green',
                        icon: undefined,
                    },
                ],
                title: 'Colors',
                field: 'colors',
                values: exampleFilters.colors,
                searchToggle: false,
            },
        ],
        setFunction: setExampleFilters, // function to set client state if not using searchParams when option is selected
        resetFunction: () => {
            // function to reset client state/searchParams to starting values
            setExampleFilters({
                colors: [],
            })
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('names')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('status')
                prev.delete('before')
                return prev
            })
        },
    }

    useEffect(() => {
        if (!user.access.includes('calls') && !user.access.includes('*')) {
            navigate('/home')
        }
    }, [])

    return (
        <>
            {user && (
                <h1>
                    <div className='grid grid-cols-7 gap-6 bg-bg1 dark:bg-darkbg1 p-2 rounded-md'>
                        <span>size = lg</span>
                        <Button size={'lg'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'lg'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'lg'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'lg'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'lg'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'lg'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = default</span>
                        <Button size={'default'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'default'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'default'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'default'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'default'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'default'} variant={'link'}>
                            link
                        </Button>{' '}
                        <span>size = sm</span>
                        <Button size={'sm'} variant={'default'}>
                            default
                        </Button>
                        <Button size={'sm'} variant={'destructive'}>
                            destructive
                        </Button>
                        <Button size={'sm'} variant={'outline'}>
                            outline
                        </Button>
                        <Button size={'sm'} variant={'secondary'}>
                            secondary
                        </Button>
                        <Button size={'sm'} variant={'ghost'}>
                            ghost
                        </Button>
                        <Button size={'sm'} variant={'link'}>
                            link
                        </Button>
                        <span>size = icon</span>
                        <Button size={'icon'} variant={'default'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'destructive'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'outline'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'secondary'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'ghost'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                        <Button size={'icon'} variant={'link'}>
                            <QuestionMarkCircledIcon />
                        </Button>
                    </div>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>ToggleSwitch</p>
                            <ToggleSwitch />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Skeleton</p>
                            <SkeletonBasic className='h-[100px] w-[275px]' />
                            <TaskListItemSkeleton />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Day Picker</p>
                            <p className='text-sm'>Will accept custom day cells </p>
                            <CalendarProcurementTasks date={undefined} setDate={undefined} />
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Background fetch spinner</p>
                            <FetchingSpinner isFetching={true} />
                        </div>

                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Tool Tips</p>
                            {/* copy chunk below, change text, remove OutlineAttachment line and replace it with what you want to hover */}
                            <div className='group flex relative'>
                                <button className='flex'>
                                    <MdOutlineAttachment size={'25px'} className={`m-auto rotate-[-90deg]`} />
                                    <main className='absolute bottom-[-6px] left-6 hidden group-hover:flex'>
                                        <section className='container max-w-2xl p-2'>
                                            <div className='flex items-center'>
                                                <div className='whitespace-nowrap bg-gray-700 dark:bg-accent text-white dark:text-darkaccent p-1 px-2 rounded flex-1 text-xs'>
                                                    Add Attachment
                                                </div>
                                            </div>
                                        </section>
                                    </main>
                                </button>
                            </div>
                        </div>
                        {/* <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>Quilljs Text Editor</p>
                        </div> */}
                        <div className='relative flex gap-2 border-b pb-3 justify-between cursor-default'>
                            <p>"Are you sure" modal</p>
                            <button
                                className='text-lg text-red dark:text-lightred'
                                onClick={() => setShowAreYouSureModal(true)}
                            >
                                <FaTimes />
                            </button>
                            {showAreYouSureModal && (
                                <AreYouSure
                                    onConfirm={() => setShowAreYouSureModal(false)}
                                    onDeny={() => setShowAreYouSureModal(false)}
                                />
                            )}
                        </div>
                        <div className='flex flex-col gap-2 border-b pb-3'>
                            <p>FilterToolBar</p>
                            <FilterToolBar
                                handleClearSearch={handleClearSearch}
                                search={search}
                                setSearch={setSearch}
                                filterBarObject={exampleFilterBarObject}
                            >
                                {exampleFilterBarObject.params.length &&
                                    exampleFilterBarObject.params.map((param: any) => {
                                        return (
                                            <param.component
                                                key={param.title + param.field}
                                                searchToggle={param.searchToggle}
                                                editSearchParams={param.editSearchParams}
                                                title={param.title}
                                                field={param.field}
                                                options={param.options}
                                                filterValues={param.values}
                                                setFilterValues={exampleFilterBarObject.setFunction}
                                            />
                                        )
                                    })}
                            </FilterToolBar>
                            <div className='w-[600px] flex flex-col gap-4'>
                                <p>FilterBarSelectorButton does not need to be inside a filter bar</p>
                                <div className='w-[200px]'>
                                    <FilterBarSelectorButton
                                        key={'colors 2' + 'colors'}
                                        searchToggle={true}
                                        filterValues={exampleFilters.colors}
                                        setFilterValues={setExampleFilters}
                                        title='Colors with search bar'
                                        field='colors'
                                        options={[
                                            {
                                                label: 'Yellow',
                                                value: 'yellow',
                                            },
                                            {
                                                label: 'Purple',
                                                value: 'purple',
                                            },
                                            {
                                                label: 'Pink',
                                                value: 'pink',
                                            },
                                        ]}
                                        variant='outline'
                                        editSearchParams={false}
                                        align='start'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p>No results banner</p>
                            <NoResults margin={'ml-8'} content={'Sorry, Fathers Day!'} />
                        </div>
                    </div>
                </h1>
            )}
        </>
    )
}
