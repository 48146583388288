import { useContext, useState } from 'react'
import { useCompanyNotesQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType } from '../../helpers'
import CompanyNoteV2 from '../listItems/CompanyNoteV2'
import FilterToolBar from '../FilterToolBar'
import { useDebounce } from 'helpers'

export default function NoteListV2() {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search.trim(), 500)

    const companyNoteQuery = useCompanyNotesQuery({ companyId: selectedCompany.id, users, search: debouncedSearch })
    const { events }: { events: EventType[] } = companyNoteQuery?.data || {}

    const createdAtSort = (a: any, b: any) => {
        if (new Date(a.created_at || a.delivered_at).getTime() > new Date(b.created_at || b.delivered_at).getTime()) {
            return -1
        }
        return 1
    }
    const filteredNotes = events?.filter((event: EventType) => event.type === 'note')
    let sortedNotes = filteredNotes?.sort(createdAtSort)
    return (
        <div className='p-[2px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px] sticky top-[32px] z-40 bg-bg1 dark:bg-darkbg1'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined} />
                <button
                    onClick={() => setShowQuickModal('note')}
                    className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New Note
                </button>
            </div>
            <div className='flex flex-col gap-[8px] min-h-[300px]'>
                {sortedNotes?.map((note) => <CompanyNoteV2 key={note.id} note={note} />)}
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
