import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { vFetch, colorSettingsDark, colorSettingsLight, useDebounce } from '../helpers'
import { setSettings } from '../redux/settings'
import ColorPicker from './ColorPicker'

export default function StoreStyleSetupScreen({
    loading,
    colors,
    setColors,
}: {
    loading: boolean
    colors: any
    setColors: Function
}) {
    const dispatch = useDispatch()
    const [tempColors, setTempColors] = useState<any>(colors)
    const debouncedColors = useDebounce(tempColors, 300)
    const [needsSaved, setNeedsSaved] = useState(false)
    const settings = useSelector((state: any) => state.settings)
    const theme = settings.theme
    const tabs: any = ['Light Mode', 'Dark Mode']
    let selectedTab = ''
    theme === '' ? (selectedTab = 'Light Mode') : (selectedTab = 'Dark Mode')

    const colorSettingsAll: any = []
    colorSettingsLight.forEach((setting: any) => {
        colorSettingsAll.push(setting)
    })
    colorSettingsDark.forEach((setting: any) => {
        colorSettingsAll.push(setting)
    })

    useEffect(() => {
        // grabs all locally modified color values and rebuilds tempcolor object to populate table

        let rebuiltTempColors: any = {}
        colorSettingsAll.forEach((setting: any) => {
            rebuiltTempColors[setting] = document.documentElement.style.getPropertyValue(`--${setting}`)
        })
        setTempColors(rebuiltTempColors)
    }, [])

    useEffect(() => {
        if (!loading && tempColors && colors) {
            colorSettingsAll.map((setting: any) => {
                if (!(tempColors[setting] === colors[setting])) {
                    setNeedsSaved(true)
                }
            })
        } else setNeedsSaved(false)
        if (tempColors) {
            for (let [setting, value] of Object.entries(tempColors))
                document.documentElement.style.setProperty(`--${setting}`, `${value}`)
        }
    }, [colors, debouncedColors])

    function handleColorUpdate(setting: any, e: any) {
        // Color changes persist locally until saved / reset / page reload
        let newColors = { ...tempColors }
        newColors[setting] = e.target.value
        setTempColors(newColors)
    }

    function handleUpdate() {
        vFetch(`/v1/stores`, {
            method: 'PUT',
            body: JSON.stringify({
                color_set: tempColors,
            }),
            cb: (res: any) => {
                if (res.success) setColors(tempColors)
                setNeedsSaved(false)
                resetTheme(theme)
            },
        })
    }

    function resetTheme(themeSetting: any) {
        localStorage.setItem('theme', themeSetting)

        if (themeSetting === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }
    function handleColorsReset() {
        setTempColors(colors)
        colorSettingsAll.map((setting: any) => {
            document.documentElement.style.setProperty(`--${setting}`, `${colors[setting]}`)
        })
        resetTheme(theme)
        setNeedsSaved(false)
    }

    function toggleLightEdit() {
        if (settings.theme === 'dark') {
            dispatch(
                setSettings({
                    theme: '',
                })
            )
        }
        resetTheme('')
    }
    function toggleDarkEdit() {
        if (settings.theme === '') {
            dispatch(
                setSettings({
                    theme: 'dark',
                })
            )
        }
        resetTheme('dark')
    }
    return (
        <div className='flex flex-col gap-2'>
            <div className='flex flex-wrap gap-[8px] basis-[calc(66.6%-32px)] grow items-start justify-start'>
                <div className='flex flex-col gap-x-[8px] basis-[calc(33%-32px)] min-w-[400px] grow shrink'>
                    <div className='relative top-[1px] flex gap-[8px] ml-[8px] border-b-darkgrey'>
                        {tabs.map((tab: string) => (
                            <button
                                className={`border-black dark:border-darkgrey border-[1px] border-b-0 ${
                                    selectedTab === tab ? 'bg-white dark:bg-darkness z-index-1' : '!border-transparent'
                                } rounded-t px-[4px] capitalize`}
                                onClick={() => {
                                    tab === 'Light Mode' ? toggleLightEdit() : toggleDarkEdit()
                                }}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className='grid gap-[8px] border-[1px] border-black dark:border-darkgrey rounded p-[8px]'>
                        {selectedTab === 'Light Mode' && (
                            <div className='flex flex-col gap-[8px] dark:bg-darkness rounded p-1'>
                                <ColorPicker
                                    theme={settings.theme}
                                    tempColors={tempColors}
                                    colorSettings={colorSettingsLight}
                                    handleColorUpdate={handleColorUpdate}
                                />
                            </div>
                        )}
                        {selectedTab === 'Dark Mode' && (
                            <div className='flex flex-col gap-[8px] dark:bg-darkaccent rounded p-1'>
                                <ColorPicker
                                    theme={settings.theme}
                                    tempColors={tempColors}
                                    colorSettings={colorSettingsDark}
                                    handleColorUpdate={handleColorUpdate}
                                />
                            </div>
                        )}
                    </div>
                    {colors && (
                        <div className='flex flex-col gap-6 mt-3'>
                            {
                                <>
                                    <div className='flex gap-12 justify-between self-center'>
                                        <div className='flex gap-8'>
                                            <div>
                                                <button
                                                    onClick={handleUpdate}
                                                    className={`bg-blue rounded text-white ${
                                                        needsSaved ? '' : 'opacity-20'
                                                    } py-4 px-4`}
                                                >
                                                    Save Changes
                                                </button>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={handleColorsReset}
                                                    className={`bg-blue rounded text-white ${
                                                        needsSaved ? '' : 'opacity-20'
                                                    } py-4 px-4`}
                                                >
                                                    Reset Changes
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
