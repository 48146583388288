export default function Assignment({ association }: { association: any }) {
    const [firstName = 'Unknown', lastName = 'User'] = association.readable_name?.split(' ') || []
    const formattedName = `${firstName} ${lastName[0]}.`
    const initials = `${firstName[0]}${lastName[0]}`
    return (
        <div className='relative group flex items-center'>
            <div className='rounded-full w-5 h-5 border-2 border-blue bg-blue text-white dark:text-offwhite text-text2 flex font-semibold uppercase text-xs items-center justify-center z-[19]'>
                {initials}
            </div>
            <div className='hidden group-hover:flex border absolute top-[100%] right-[-40px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[21]'>
                <div className=''>{formattedName}</div>
            </div>
        </div>
    )
}
