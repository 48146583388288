import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import queryKeys from 'orderHubScreen/api/queryKeys'
import { useUpdateTags } from 'orderHubScreen/api/useQueries'
import draftOrderQueryKeys from 'orderHubScreenDraft/api/draftOrderQueryKeys'
import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

type TagsProps = {
    tags: string[]
    setTags?: Function
}

export default function Tags({ tags }: TagsProps) {
    const [newTag, setNewTag] = useState<string>('')
    const { orderId } = useParams()
    const location = useLocation()
    const queryClient = useQueryClient()

    const updateTags = useUpdateTags()

    const handleUpdateTags = (newTags?: any) => {
        const origin = location.pathname.toLowerCase().includes('draft-orders') ? 'draft_order' : 'order'
        setNewTag('')

        let originalData: any = {}
        if (origin === 'draft_order') {
            originalData = queryClient.getQueryData(draftOrderQueryKeys.draftOrders.detail(orderId || '').queryKey)
            queryClient.setQueryData(draftOrderQueryKeys.draftOrders.detail(orderId || '').queryKey, (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        draft_order: {
                            ...oldData.draft_order,
                            tags: newTags ? [...newTags].join(',') : [...tags, newTag].join(','),
                        },
                    }
                }
            })
        }
        if (origin === 'order') {
            originalData = queryClient.getQueryData(queryKeys.orders.detail(orderId || '').queryKey)
            queryClient.setQueryData(queryKeys.orders.detail(orderId || '').queryKey, (oldData: any) => {
                if (oldData) {
                    return {
                        ...oldData,
                        order: {
                            ...oldData.order,
                            tags: newTags ? [...newTags].join(',') : [...tags, newTag].join(','),
                        },
                    }
                }
            })
        }
        updateTags.mutate(
            {
                orderId,
                body: { id: orderId, fields: { tags: newTags ? [...newTags] : [...tags, newTag] } },
                origin,
                newTag: newTag,
            },
            {
                onError: (_err, variables) => {
                    if (origin === 'draft_order') {
                        queryClient.setQueryData(draftOrderQueryKeys.draftOrders.detail(orderId || '').queryKey, () => {
                            return originalData
                        })
                    }
                    if (origin === 'order') {
                        queryClient.setQueryData(queryKeys.orders.detail(orderId || '').queryKey, (oldData: any) => {
                            return originalData
                        })
                    }
                    setNewTag(variables.newTag)
                },
                onSuccess: () => {
                    setNewTag('')
                },
            }
        )
    }

    return (
        <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
            <div>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <h2 className='text-[20px] font-bold mb-[4px]'>Tags</h2>
                        <FetchingSpinner isFetching={updateTags.isPending} />
                    </div>
                    {newTag && (
                        <Button variant={'outline'} size={'sm'} onClick={() => handleUpdateTags(undefined)}>
                            Submit
                        </Button>
                    )}
                </div>
                <div className='flex flex-wrap gap-[16px] mt-[16px]'>
                    <form
                        className='w-[100%]'
                        onSubmit={(e) => {
                            e.preventDefault()
                            handleUpdateTags()
                        }}
                    >
                        <input
                            value={newTag}
                            onChange={(e) => {
                                setNewTag(e.target.value)
                            }}
                            className='w-[100%] border-[1px] dark:border-darkgrey p-[8px] rounded dark:bg-darkness'
                            placeholder='Find or create tags'
                        />
                    </form>
                    {tags.map(
                        (tag, i) =>
                            tag && (
                                <span
                                    key={tag + i}
                                    className='relative bg-[lightgrey] dark:bg-darkness px-[8px] py-[2px] text-[14px] rounded'
                                >
                                    {tag}
                                    <button
                                        className="absolute grid bg-grey top-[-10px] right-[-7px] h-[16px] w-[16px] leading-1 content-['x'] rounded-full"
                                        onClick={() => {
                                            const newTags = tags.filter((iTag) => iTag !== tag)
                                            handleUpdateTags(newTags)
                                        }}
                                        aria-label='delete tag'
                                    >
                                        <span className='absolute top-[-3px] right-[4px] leading-1 h-[0] font-semibold text-black'>
                                            x
                                        </span>
                                    </button>
                                </span>
                            )
                    )}
                </div>
            </div>
        </div>
    )
}
