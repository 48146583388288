import { useEffect, useRef, useState } from 'react'
import { AiFillCalendar, AiFillCloseCircle } from 'react-icons/ai'
import './component_styles/Calendar.css'

type CalendarProps = {
    startDate: Date
    setStartDate: Function
    endDate: Date
    setEndDate?: Function
    items?: any[]
    acceptsTextStartDate?: boolean
    acceptsTextEndDate?: boolean
    textInputChanged?: boolean
    setTextInputChanged?: Function
}

export default function Calendar({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    items,
    acceptsTextStartDate,
    acceptsTextEndDate,
    textInputChanged,
    setTextInputChanged = () => {},
}: CalendarProps) {
    const getDaysInMonth = (currentYear: number, currentMonth: number) => {
        return 32 - new Date(currentYear, currentMonth, 32).getDate()
    }
    const buildCellClass = (
        isPrevMonth: boolean,
        isStartDate: boolean,
        isEndDate: boolean,
        isIncludedDate: boolean,
        isToday: boolean
    ) => {
        let cellClass =
            'p-[2px] m-[1px] text-left bg-white dark:bg-darkaccent sm:text-[8px] md:text-[10px] lg:text-[12px] xl:text-[14px] sm:h-[30px] md:h-[40px] lg:h-[60px] xl:h-[80px] flex items-start justify-start relative dark:text-white'
        if (isPrevMonth)
            cellClass =
                cellClass.replace('bg-white', 'bg-black/[.5]').replace('bg-darkaccent', 'bg-blue/[0.25]') +
                ' opacity-[0.75] text-white'
        if (isStartDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.5]') +
                ' rounded-l-[10px] text-white dark:text-offwhite mr-0 font-black'
        if (isEndDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.5]')
                    .replace('opacity-[0.5]', '') + ' rounded-r-[10px] text-white dark:text-offwhite ml-0 font-black'
        if (isIncludedDate)
            cellClass =
                cellClass
                    .replace('bg-white', 'bg-blue/[.25]')
                    .replace('bg-darkaccent', 'bg-blue/[0.25]')
                    .replace('bg-black/[.25]', 'bg-blue/[.25]') + ' mx-0'
        if (isToday) cellClass += ' border-2 border-fire'
        return cellClass
    }
    const calendar: any = useRef(null)
    const closeBtn: any = useRef(null)
    const [currentDayZero, setCurrentDayZero] = useState(new Date(startDate.getFullYear(), startDate.getMonth()))
    const [daysInMonth, setDaysInMonth] = useState(
        getDaysInMonth(currentDayZero.getFullYear(), currentDayZero.getMonth())
    )
    const [selectedDateArr, setSelectedDateArr] = useState([startDate, setEndDate ? endDate : startDate])
    const [tempDate, setTempDate] = useState<any>(null)
    const [open, setOpen] = useState(false)
    const dayArr = []
    const leadingDays = currentDayZero.getDay() - 1

    useEffect(() => {
        if (acceptsTextStartDate && !acceptsTextEndDate) {
            setCurrentDayZero(new Date(startDate.getFullYear(), startDate.getMonth()))
            setSelectedDateArr([startDate, startDate])
        }
        if (acceptsTextStartDate && acceptsTextEndDate && textInputChanged) {
            setCurrentDayZero(new Date(startDate.getFullYear(), startDate.getMonth()))
            setSelectedDateArr([startDate, endDate])
            setTextInputChanged(false)
        }
    }, [startDate])

    useEffect(() => {
        if (acceptsTextStartDate && acceptsTextEndDate && textInputChanged) {
            setSelectedDateArr([startDate, endDate])
            setTextInputChanged(false)
        }
    }, [endDate])
    const handlePushDate = (cellDate: any) => {
        const newDateArray = [...selectedDateArr]
        newDateArray.unshift(cellDate)
        if (newDateArray.length > 2) {
            setTempDate(newDateArray[0])
        } else {
            setTempDate(null)
        }
        setSelectedDateArr(
            newDateArray.length > 2
                ? newDateArray.slice(0, 1)
                : newDateArray.sort((a, b) => (a.getTime() > b.getTime() ? 1 : -1))
        )
    }
    useEffect(() => {
        if (!setEndDate && selectedDateArr.length >= 1) {
            selectedDateArr.push(selectedDateArr[0])
            return setStartDate(selectedDateArr[0])
        }

        if (selectedDateArr.length === 2 && setEndDate) {
            setStartDate(
                selectedDateArr[0].getTime() > selectedDateArr[1].getTime() ? selectedDateArr[1] : selectedDateArr[0]
            )
            setEndDate(
                selectedDateArr[0].getTime() > selectedDateArr[1].getTime() ? selectedDateArr[0] : selectedDateArr[1]
            )
        }
    }, [selectedDateArr])
    useEffect(() => {
        if (!setEndDate && tempDate) {
            return setStartDate(tempDate)
        }

        if (tempDate) {
            setStartDate(tempDate)
            if (setEndDate) setEndDate(tempDate)
        }
    }, [tempDate])
    for (let i = -leadingDays; i <= daysInMonth + 7; i++) {
        const d = new Date(currentDayZero.getFullYear(), currentDayZero.getMonth(), i)
        if (i > daysInMonth && d.getDay() === 0) {
            break
        }
        const hasRange = selectedDateArr.length > 1
        dayArr.push([
            d,
            items &&
                items.filter((i) =>
                    i.due_at
                        ? i.due_at.toISOString().split('T')[0] === d.toISOString().split('T')[0] &&
                          i.status !== 'COMPLETED'
                        : i.created_at.toISOString().split('T')[0] === d.toISOString().split('T')[0] &&
                          i.fulfillment_status !== 'fulfilled'
                ).length,
            buildCellClass(
                i < 1 || i > daysInMonth,
                d.toDateString() === selectedDateArr[0].toDateString(),
                hasRange ? d.toDateString() === selectedDateArr[1].toDateString() : false,
                hasRange ? d.getTime() > startDate.getTime() && d.getTime() < endDate.getTime() : false,
                new Date(Date.now()).toDateString() == d.toDateString()
            ),
        ])
    }
    useEffect(() => {
        const listenToWindow = ({ target }: MouseEvent) => {
            if (calendar.current.contains(target as HTMLElement) && !closeBtn.current.contains(target)) {
                setOpen(true)
            } else {
                setOpen(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [calendar.current])
    return (
        <div className='relative z-index-5 dark:text-white' ref={calendar}>
            <div onClick={() => setOpen(!open)}>
                <div style={open ? {} : { display: 'none' }} ref={closeBtn}>
                    <AiFillCloseCircle className='h-[24px] w-[24px] fill-blue cursor-pointer dark:fill-accent' />
                </div>
                <div style={open ? { display: 'none' } : {}}>
                    <AiFillCalendar className='h-[24px] w-[24px] fill-blue cursor-pointer dark:fill-accent' />
                </div>
            </div>
            {open && (
                <div className='shadow-small rounded bg-[#eeeeee] dark:bg-darkness p-[4px] absolute top-[100%] right-0'>
                    <div className='flex items-center justify-between mb-[4px]'>
                        <button
                            className='bg-white dark:bg-darkaccent dark:hover:bg-blue rounded border-none font-black text-[#a1a1a1] cursor-pointer px-[8px] py-[4px] hover:bg-[lightgrey]'
                            onClick={() =>
                                setCurrentDayZero(new Date(currentDayZero.setMonth(currentDayZero.getMonth() - 1)))
                            }
                        >{`<`}</button>
                        <p className='font-bold text-[#4a4a4a] dark:text-offwhite'>
                            {currentDayZero.toLocaleString('default', { month: 'long', year: 'numeric' })}
                        </p>
                        <button
                            className='bg-white dark:bg-darkaccent dark:hover:bg-blue rounded border-none font-black text-[#a1a1a1] cursor-pointer px-[8px] py-[4px] hover:bg-[lightgrey]'
                            onClick={() =>
                                setCurrentDayZero(new Date(currentDayZero.setMonth(currentDayZero.getMonth() + 1)))
                            }
                        >{`>`}</button>
                    </div>
                    <div className='grid grid-cols-[repeat(7,1fr)] sm:w-[210px] md:w-[280px] lg:w-[420px] xl:w-[560px]'>
                        {dayArr.map(([d, cellTasks, cellClass]) => (
                            <div onClick={() => handlePushDate(d)} className={cellClass as string}>
                                {(d as Date).getDate()}
                                {(cellTasks as number) > 0 && (
                                    <span className='grid sm:w-[15px] sm:h-[15px] md:w-[20px] md:h-[20px] lg:w-[30px] lg:h-[30px] xl:w-[40px] xl:h-[40px] place-items-center md:text-[10px] lg:text-[12px] xl:text-[14px] leading-[1px] bg-fire text-black font-black rounded-full absolute left-[50%] top-[55%] translate-x-[-50%] translate-y-[-50%]'>
                                        {cellTasks as number}
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
