import { DateRange } from 'react-day-picker'
import { AnalyticsRangeSelector } from './AnalyticsRangeSelector'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { differenceInDays, subDays } from 'date-fns'

export function AnalyticsHeader({
    selectorButtonObject,
    date,
    setDate,
    customCompareDate,
    setCustomCompareDate,
    period,
    setPeriod,
    optionIndex,
    setOptionIndex,
    interval,
    compare,
}: {
    selectorButtonObject: any
    date: DateRange | undefined
    setDate: React.Dispatch<React.SetStateAction<any>>
    customCompareDate: DateRange | undefined
    setCustomCompareDate: React.Dispatch<React.SetStateAction<any>>
    period: { value: string | undefined; function: Function | undefined; rangeString: string | undefined }
    setPeriod: React.Dispatch<React.SetStateAction<any>>
    optionIndex: number
    setOptionIndex: React.Dispatch<React.SetStateAction<any>>
    interval?: string
    compare?: string
}) {
    let [_searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()

    const fromDifference = date?.from
        ? differenceInDays(date?.from, new Date(subDays(new Date(), parseInt('0')).toDateString()))
        : undefined
    const toDifference = date?.to
        ? differenceInDays(date?.to, new Date(subDays(new Date(), parseInt('0')).toDateString()))
        : undefined

    useEffect(() => {
        setSearchParams((prev: any) => {
            prev.set('to', toDifference)
            prev.set('from', fromDifference)
            prev.set('option', optionIndex)
            prev.set('interval', interval || '0')
            prev.set('compare', compare || '0')
            return prev
        })
    }, [fromDifference, toDifference, optionIndex])
    return (
        <div className='flex gap-3 items-center min-h-[48px] z-[31] bg-bg1 dark:bg-darkbg2 cursor-pointer'>
            <AnalyticsRangeSelector
                date={date}
                setDate={setDate}
                customCompareDate={customCompareDate}
                setCustomCompareDate={setCustomCompareDate}
                period={period}
                setPeriod={setPeriod}
                setOptionIndex={setOptionIndex}
            />
            <div className='flex gap-[8px]'>
                {selectorButtonObject.params.length &&
                    selectorButtonObject.params.map((param: any) => {
                        return (
                            <param.component
                                align={'start'}
                                key={param.field}
                                searchToggle={param.searchToggle}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={selectorButtonObject.setFunction}
                                selectType={param.selectType}
                            />
                        )
                    })}
            </div>
        </div>
    )
}
