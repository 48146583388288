import { createColumnHelper } from '@tanstack/react-table'
import React from 'react'
import { UserLogin } from 'users/users.types'
import { useGetUserLogins } from 'usersV2/api/useQueries'
import UserLoginsTable from './UserLoginsTable'
import { formatDateToLocale } from 'helpers'
import { renderIconMessage, truncate } from 'tasksV2/helpers'
import { getDate } from 'date-fns'

export default function UserLoginsList({
    filters,
}: {
    filters: { roles: string[]; types: string[]; search: string; from: any; to: any }
}) {
    const userLoginsQuery = useGetUserLogins(filters)
    const { userLogins }: { userLogins: UserLogin[] } = userLoginsQuery?.data || {}

    const filteredUserLogins = userLogins?.filter((userLogin) => {
        const userLoginDate = getDate(userLogin.created_at)
        const fromDate = filters.from ? getDate(filters.from) : undefined
        const toDate = filters.to ? getDate(filters.to) : undefined
        if (!fromDate && !toDate) {
            return 1
        }
        if (fromDate && toDate) {
            if (userLoginDate >= fromDate && userLoginDate <= toDate) {
                return 1
            }
        }
        if (fromDate) {
            if (userLoginDate >= fromDate) {
                return 1
            }
        }
        return 0
    })

    const data = filteredUserLogins?.length > 0 ? filteredUserLogins : []
    const dataMemo = React.useMemo(() => data ?? [], [data])
    const columnHelper = createColumnHelper<UserLogin>()

    const columns = [
        columnHelper.accessor('first_name', {
            header: 'First Name',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('last_name', {
            header: 'Last Name',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
        }),
        columnHelper.accessor('created_at', {
            header: 'Login',
            cell: (info) => {
                const createdAt = new Date(info.getValue())
                return (
                    <div className='flex flex-wrap gap-x-[4px]'>
                        <p>
                            {[
                                createdAt.getMonth() + 1,
                                createdAt.getDate(),
                                String(createdAt.getFullYear()).slice(-2),
                            ].join('/')}
                        </p>
                        <p>{formatDateToLocale(createdAt).split(' ').slice(-2).join(' ')}</p>
                    </div>
                )
            },
            size: 200,
        }),
        columnHelper.accessor('email', {
            header: 'Email',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            minSize: 260,
        }),
        columnHelper.accessor('ip_address', {
            header: 'IP Address',
            cell: (info) => {
                return <p>{info.getValue()}</p>
            },
            minSize: 180,
        }),
        columnHelper.accessor('user_agent', {
            header: 'User Agent',
            cell: (info) => {
                return (
                    <div key={`${info.row.original.id}` + 'title'} className='relative group z-50'>
                        <div>{truncate(info.getValue(), 70)}</div>
                        {info.getValue().length > 70 && (
                            <div className='z-50 hidden group-hover:flex absolute p-[10px] min-w-[350px] top-[calc(100%-10px)] left-[-36px] cursor-auto'>
                                <div className='left-[0px] top-[0px] p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-50 shadow-small'>
                                    {renderIconMessage(info.getValue())}
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            minSize: 900,
        }),
    ]

    return (
        <div className='bg-bg1 dark:bg-darkbg1 shadow-md border border-lightgrey dark:border-darkgrey rounded text-sm leading-none h-full min-h-0'>
            <div className='w-full h-full min-h-0 rounded-md'>
                {dataMemo && (
                    <div className='relative w-full h-full min-h-0 text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness rounded-md'>
                        <UserLoginsTable columns={columns} tableData={dataMemo} showHeaders={true} size={'auto'} />
                        {!userLoginsQuery.isLoading && data?.length < 1 && (
                            <div className='pointer-events-none absolute top-0 left-0 flex justify-center items-center w-full h-full z-[60] text-black dark:text-offwhite'>
                                <div className='p-2 px-4 rounded-md border mt-3 '>No users</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
