import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import FilterToolBar from 'procurement/components/FilterToolBar'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetRecurringTasks } from 'tasksV2/api/useQueries'
import { RecurringTask } from 'tasksV2/constants/tasks'
import Department from 'tasksV2/tasks/components/Department'
import TasksV2TableSimple from 'tasksV2/tasks/lists/TasksV2TableSimple'
import CreateRecurringTaskModal from 'tasksV2/tasks/modals/CreateRecurringTaskModal'

export default function RecurringTasksScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedRecurringTaskId = searchParams.get('recurringTaskId')
    const statusString = searchParams.get('statuses') || ''
    const departmentsString = searchParams.get('departments') || ''

    const searchParam = searchParams.get('search') || ''
    const [debouncedSearch, setDebouncedSearch] = useState('')
    const [initRender, setInitRender] = useState(true)

    const departmentValues = decodeURIComponent(departmentsString)
        .split(',')
        .filter((v) => v)
    const statusValues = decodeURIComponent(statusString)
        .split(',')
        .filter((v) => v)

    // QUERIES
    const recurringTasksQuery = useGetRecurringTasks({
        statuses: statusValues.length > 0 ? statusValues : undefined,
        departmentIds: departmentValues.length > 0 ? departmentValues : undefined,
        search: debouncedSearch,
    })
    const recurringTasks = recurringTasksQuery.data?.recurringTasks || []

    // FILTERS

    const statusOptions = [
        { label: 'Active', value: '1' },
        { label: 'Inactive', value: '0' },
    ]?.map((status: any) => {
        return {
            label: status.label,
            value: status.value,
            icon: undefined,
        }
    })

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: statusOptions,
                title: 'Status',
                field: 'statuses',
                values: statusValues,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('departments')
                prev.delete('statuses')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    const dateFormat = new Intl.DateTimeFormat('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
    }).format

    const tableData = recurringTasks?.length > 0 ? recurringTasks : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<RecurringTask>()

    const columns = [
        columnHelper.accessor('id', {
            enableGlobalFilter: false,
            header: 'id',
            cell: (info) => {
                return <div className='flex '>{info.getValue()}</div>
            },
            size: 65,
        }),

        columnHelper.accessor('active', {
            header: 'Active',
            cell: (info) => {
                const failureCount = info.row.original.failure_count
                return (
                    <div className='flex gap-2 items-center'>
                        {info.getValue() === 1 ? 'True' : 'False'}
                        {failureCount >= 5 && (
                            <div className='group relative'>
                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                <div className='hidden group-hover:flex border absolute top-[100%] right-[-50px] w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[100] '>
                                    Failed to create recurring task
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
        }),
        columnHelper.accessor('task_data', {
            header: 'Title',
            minSize: 300,
            cell: (info) => {
                return <div>{info.getValue().title}</div>
            },
        }),
        columnHelper.accessor('readable_interval', {
            header: 'Interval',
            cell: (info) => {
                const oneUnitMap = {
                    DAY: 'Daily',
                    WEEK: 'Weekly',
                    MONTH: 'Monthly',
                    YEAR: 'Yearly',
                }
                const [intervalValue, intervalType] = info.getValue().split(' ')
                const parsedIntervalValue = [
                    intervalValue !== '1' ? 'Every' : null,
                    intervalValue !== '1' ? intervalValue : null,
                    intervalValue !== '1'
                        ? intervalType.toLowerCase() + 's'
                        : oneUnitMap[intervalType as keyof typeof oneUnitMap],
                ]
                    .filter((v) => v)
                    .join(' ')
                return <div>{parsedIntervalValue}</div>
            },
        }),
        {
            header: 'Department',
            cell: ({ row }: { row: any }) => {
                return (
                    <div key={row.original.id}>
                        <Department departmentId={row.original.task_data.department_id} />
                    </div>
                )
            },
        },
        columnHelper.accessor('created_at', {
            maxSize: 50,
            header: 'Created At',
            cell: (info) => {
                return <p>{dateFormat(new Date(info.getValue()))}</p>
            },
        }),
    ]

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            debouncedSearch ? searchParams.set('search', debouncedSearch) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.delete('after')
                prev.set('page', 1)
                return prev
            })
        }
    }, [debouncedSearch])
    useEffect(() => {
        if (!initRender) {
            setDebouncedSearch(searchParam)
        }
    }, [searchParam])

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setDebouncedSearch('')
    }

    return (
        <div className='text-sm'>
            <div className='flex justify-between mb-4'>
                <div className='flex gap-6 items-center w-5/12'>
                    <h1 className='text-2xl font-semibold min-w-fit'>Recurring Tasks</h1>
                </div>
                <Button variant={'outline'} size={'sm'} onClick={toggleCreateModal}>
                    + Recurring Task
                </Button>
            </div>
            <FilterToolBar
                className='dark:bg-darkbg2'
                handleClearSearch={handleClearSearch}
                filterBarObject={companyFilterBarObject}
                showSearch={false}
            >
                <FetchingSpinner isFetching={recurringTasksQuery.isPending} />

                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                editSearchParams={param.editSearchParams}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div className='w-full'>
                {tableDataMemo && (
                    <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                        <TasksV2TableSimple
                            columns={columns}
                            tableData={tableDataMemo}
                            showHeaders={true}
                            link={`/tasks/recurring-tasks/`}
                        />
                    </div>
                )}
                {!recurringTasksQuery.isLoading && recurringTasks?.length < 1 && (
                    <div className='flex justify-center items-center w-full h-2/3 mt-28'>
                        <div className='p-2 px-4 rounded-md border text-lg'>No results found</div>
                    </div>
                )}
            </div>
            {showCreateModal && <CreateRecurringTaskModal closeModal={toggleCreateModal} />}
            {selectedRecurringTaskId && <></>}
        </div>
    )
}
