import { useDebounce } from 'helpers'
import { useState } from 'react'
import { useGetDraftOrderLineItems, useGetDraftOrderLineItemsSearch } from 'tasksV2/api/useQueries'

export default function DraftOrderLineItemAssociationBuilder({
    associations,
    toggleAssociation,
    draftOrderIds,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
    draftOrderIds?: string[]
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const draftOrderLineItemsSearchQuery = useGetDraftOrderLineItemsSearch(debouncedSearch)
    const { line_items: lineItemsSearch } = draftOrderLineItemsSearchQuery?.data || {}

    const draftOrderLineItemsQuery = useGetDraftOrderLineItems({ draftOrderIds })
    const { lineItems } = draftOrderLineItemsQuery?.data || {}

    return (
        <div className='w-[352px]'>
            <h2 className='font-bold mb-2'>Choose Line Items To Associate</h2>
            {!draftOrderIds && (
                <input
                    className='focus:outline-none py-1 px-2 w-full border border-lightgrey dark:bg-darkbg1 dark:border-darkgrey rounded'
                    id='search'
                    name='search'
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                    placeholder='Search for a draft order...'
                />
            )}
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {draftOrderLineItemsSearchQuery.isLoading || draftOrderLineItemsQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {lineItemsSearch?.map((lineItem: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'draft_order_line_item',
                                        resource_id: lineItem.id,
                                        readable_name: `${lineItem.draft_order_name} - ${
                                            lineItem.sku || lineItem.name
                                        }`,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'draft_order_line_item' &&
                                                association.resource_id === lineItem.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>
                                    {lineItem.draft_order_name} - {lineItem.sku || lineItem.name}
                                </span>
                            </div>
                        ))}
                    </>
                )}
                {lineItems && lineItems?.length > 0 && (
                    <>
                        {lineItems?.map((lineItem: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'draft_order_line_item',
                                        resource_id: lineItem.id,
                                        readable_name: `${lineItem.draft_order_name} - ${
                                            lineItem.sku || lineItem.name
                                        }`,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'draft_order_line_item' &&
                                                association.resource_id === lineItem.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>
                                    {lineItem.draft_order_name} - {lineItem.sku || lineItem.name}
                                </span>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
