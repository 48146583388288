import { useQuery } from '@tanstack/react-query'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import { migrationQueries } from './migrationQueryKeys'

export function useGetProcessList(refetchInterval: number, queryEnabled: boolean) {
    const query = useQuery({
        ...migrationQueries.migrations.processList(),
        refetchInterval,
        enabled: queryEnabled,
        meta: {
            clientErrMessage: 'Failed to load process list',
            pathname: useGetPathname(),
            functionName: 'useGetProcessList',
        },
    })
    return query
}
