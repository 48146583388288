import { Button } from 'custom_components/component_Basics/Button'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { ChangelogEntry, CreateChangelogReleaseOptions } from '../Changelog.types'
import Select from 'procurement/components/Select'
import { useCreateRelease, useGetEntries } from 'changelog/api/useQueries'
import { cn } from 'helpers'

export default function CreateReleaseModal({
    changelog_id,
    closeModal,
}: {
    changelog_id: number
    closeModal: () => void
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [release, setRelease] = useState<CreateChangelogReleaseOptions>({
        type: 'patch',
        status: 'pending',
        changelog_id,
        entries: [],
    })
    const entriesQuery = useGetEntries({
        changelog_id,
        release_id: -1,
    })
    const unreleasedEntries = entriesQuery.data?.entries || []
    const createReleaseMutation = useCreateRelease()

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setRelease((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleCreate = () => {
        createReleaseMutation.mutate(release)
    }

    const toggleEntry = (entry: ChangelogEntry) => {
        setRelease((prev) => {
            const currentEntries = structuredClone(prev.entries)
            if (currentEntries.includes(entry.id)) {
                return {
                    ...prev,
                    entries: currentEntries.filter((entryId) => entryId !== entry.id),
                }
            }
            currentEntries.push(entry.id)
            return {
                ...prev,
                entries: currentEntries,
            }
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 rounded shadow-md relative max-w-[90%] max-h-[90%] min-w-[400px]'>
                <button onClick={closeModal} className='text-lg text-red dark:text-lightred absolute top-1 right-1'>
                    <FaTimes />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Changelog Release</h2>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-1'>
                        <Select id='type' name='type' value={release.type} onChange={handleChange} label='Type'>
                            <option value=''>Select a type</option>
                            <option value='patch'>Patch</option>
                            <option value='minor'>Feature</option>
                            <option value='major'>Major / Breaking Change</option>
                        </Select>
                        <Select id='status' name='status' value={release.status} onChange={handleChange} label='Status'>
                            <option value=''>Select a status</option>
                            <option value='pending'>Pending Review</option>
                            <option value='published'>Published</option>
                        </Select>
                    </div>
                    <div className='flex flex-col gap-1 mt-2'>
                        <label className='text-xs font-bold text-darkgrey dark:text-offwhite leading-none uppercase'>
                            Selected Entries
                        </label>
                        <div className='max-h-[200px] overflow-auto'>
                            {unreleasedEntries.map((entry) => (
                                <button
                                    onClick={() => toggleEntry(entry)}
                                    className='flex items-center gap-4 p-2 border-b border-lightgrey dark:border-darkgrey'
                                >
                                    <div
                                        className={cn(
                                            `w-4 h-4 rounded border border-lightgrey dark:border-darkgrey`,
                                            release.entries.includes(entry.id) && 'bg-accent1 dark:bg-darkaccent1'
                                        )}
                                    ></div>
                                    <p>{entry.title}</p>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                <Button className='block mx-auto mt-4' onClick={handleCreate} disabled={release.entries.length === 0}>
                    Create Release
                </Button>
            </div>
        </div>
    )
}
