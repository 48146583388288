import InputToggleSwitch from 'custom_components/InputToggleSwitch'
import { EmailTemplateType } from 'followUpEmail/FollowUpEmail.types'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import LabelWithInfo from 'productSetupV2/components/LabelWithInfo'
import { Dispatch, SetStateAction } from 'react'

export default function EmailSettingsEditor<EditorType, DelayEditorType>({
    template,
    editedFields,
    setTemplate,
    delay,
    editedDelayFields,
    setDelay,
    editMode,
}: {
    template: EmailTemplateType
    editedFields: any
    setTemplate: Dispatch<SetStateAction<EditorType>>
    delay: {
        weeks: number
        days: number
        hours: number
        minutes: number
    }
    editedDelayFields: any
    setDelay: Dispatch<SetStateAction<DelayEditorType>>
    editMode?: boolean
}) {
    const isActive = editedFields.status === undefined ? template.status === 'active' : editedFields.status === 'active'
    const isUnique = editedFields.is_unique === undefined ? template.is_unique === 1 : editedFields.is_unique === 1
    const isMarketing =
        editedFields.is_marketing === undefined ? template.is_marketing === 1 : editedFields.is_marketing === 1
    const parsedStartsOn = `${new Date(template.starts_on).getFullYear()}-${`0${
        new Date(template.starts_on).getMonth() + 1
    }`.slice(-2)}-${`0${new Date(template.starts_on).getDate()}`.slice(-2)}`

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setTemplate((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }
    const handleChangeStatus = ({ target }: { target: HTMLInputElement }) => {
        setTemplate((prev) => ({
            ...prev,
            status: target.checked ? 'active' : 'paused',
        }))
    }
    const handleCheck = ({ target }: { target: HTMLInputElement }) => {
        setTemplate((prev) => ({
            ...prev,
            [target.name]: target.checked ? 1 : 0,
            run: true,
        }))
    }
    const handleChangeDelay = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setDelay((prev) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    return (
        <>
            <h3 className='font-bold text-sm'>Settings</h3>
            <Input
                id='name'
                name='name'
                type='text'
                label='Name'
                value={editedFields.name ?? template.name}
                onChange={handleChange}
            />
            <Select
                id='event_type'
                name='event_type'
                label='Trigger'
                disabled={editMode}
                value={editedFields.event_type ?? template.event_type}
                onChange={handleChange}
            >
                <option value=''>Select a trigger</option>
                <option value='abandoned-checkout'>Abandoned Checkout</option>
                <option value='order-placed'>Order Placed</option>
                <option value='order-paid'>Order Paid</option>
                <option value='order-fulfilled'>Order Fulfilled</option>
                <option value='order-cancelled'>Order Cancelled</option>
            </Select>
            <Input
                id='starts_on'
                name='starts_on'
                type='date'
                label='Starts On'
                value={editedFields.starts_on ?? parsedStartsOn}
                onChange={handleChange}
            />
            <LabelWithInfo
                label='Delay'
                htmlFor=''
                info='How long to wait before sending the email following the initial trigger'
            />
            <div className='flex gap-2'>
                <Input
                    id='weeks'
                    name='weeks'
                    type='number'
                    label='Weeks'
                    value={editedDelayFields.weeks ?? delay.weeks}
                    onChange={handleChangeDelay}
                />
                <Input
                    id='days'
                    name='days'
                    type='number'
                    label='Days'
                    value={editedDelayFields.days ?? delay.days}
                    onChange={handleChangeDelay}
                />
                <Input
                    id='hours'
                    name='hours'
                    type='number'
                    label='Hours'
                    value={editedDelayFields.hours ?? delay.hours}
                    onChange={handleChangeDelay}
                />
                <Input
                    id='minutes'
                    name='minutes'
                    type='number'
                    label='Minutes'
                    value={editedDelayFields.minutes ?? delay.minutes}
                    onChange={handleChangeDelay}
                />
            </div>
            <label className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                Other Settings
            </label>
            <div className='flex gap-4'>
                <div className='flex flex-col gap-1'>
                    <LabelWithInfo
                        label='Active'
                        htmlFor='status'
                        info='Toggles whether or not to send these automated emails'
                    />
                    <InputToggleSwitch id='status' name='status' checked={isActive} onChange={handleChangeStatus} />
                </div>
                <div className='flex flex-col gap-1'>
                    <LabelWithInfo
                        label='Unique'
                        htmlFor='is_unique'
                        info='If this is toggled, a customer can only recieve this email 1 time in their life'
                    />
                    <InputToggleSwitch id='is_unique' name='is_unique' checked={isUnique} onChange={handleCheck} />
                </div>
                <div className='flex flex-col gap-1'>
                    <LabelWithInfo
                        label='Marketing'
                        htmlFor='is_marketing'
                        info='If this is toggled, a customer can only recieve this email if they are opted into email marketing'
                    />
                    <InputToggleSwitch
                        id='is_marketing'
                        name='is_marketing'
                        checked={isMarketing}
                        onChange={handleCheck}
                    />
                </div>
            </div>
        </>
    )
}
