import { useEffect, useState } from 'react'
import { ResourceType } from '../ProcessingTimesScreen.types'
import { BsFillTrash3Fill } from 'react-icons/bs'

type ResourceProps = {
    resource: ResourceType | null
    deletable: boolean
    handleRemove?: Function
    handleAdd?: Function
}

export default function Resource({ resource, deletable, handleRemove, handleAdd }: ResourceProps) {
    const [type, setType] = useState<ShopifyResourceType>('Product')
    useEffect(() => {
        if (resource) {
            let tempType = resource.gid.split('/')[3]
            if (tempType === 'Collection' && resource.is_vendor) {
                tempType = 'Brand'
            }
            setType(tempType as ShopifyResourceType)
        }
    }, [resource])

    return (
        <>
            {handleAdd ? (
                <button
                    onClick={handleAdd ? () => handleAdd(resource) : () => {}}
                    className={`
                        text-black text-left font-bold p-[4px] px-[8px] rounded-[4px] dark:opacity-[0.8]
                            ${
                                type === 'Product'
                                    ? 'bg-[lightblue]'
                                    : type === 'Brand'
                                      ? 'bg-fire'
                                      : type === 'Collection'
                                        ? 'bg-[tan]'
                                        : type === 'ProductVariant'
                                          ? 'bg-[darkseagreen]'
                                          : ''
                            }
                    `}
                >
                    <h3 className='text-[12px] uppercase'>{type}</h3>
                    <div className='flex gap-[8px] justify-between'>
                        <p>
                            {resource?.sku ? `${resource.sku} - ` : ''}
                            {resource?.product_title || resource?.title}
                        </p>
                    </div>
                </button>
            ) : (
                <div
                    className={`
                        text-black text-left font-bold p-[4px] px-[8px] rounded-[4px] dark:opacity-[0.8]
                            ${
                                type === 'Product'
                                    ? 'bg-[lightblue]'
                                    : type === 'Brand'
                                      ? 'bg-fire'
                                      : type === 'Collection'
                                        ? 'bg-[tan]'
                                        : type === 'ProductVariant'
                                          ? 'bg-[darkseagreen]'
                                          : ''
                            }
                    `}
                >
                    <h3 className='text-[12px] uppercase'>{type}</h3>
                    <div className='flex gap-[8px] justify-between'>
                        <p>
                            {resource?.sku ? `${resource.sku} - ` : ''}
                            {resource?.product_title || resource?.title}
                        </p>
                        {deletable && handleRemove && (
                            <BsFillTrash3Fill
                                onClick={() => handleRemove(resource)}
                                className='w-[20px] h-[20px] shrink-0 text-darkgrey cursor-pointer'
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

type ShopifyResourceType = 'Product' | 'Collection' | 'Brand' | 'ProductVariant'
