import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import queryKeys from './queryKeys'
import * as service from './service'
import draftOrderQueryKeys from 'orderHubScreenDraft/api/draftOrderQueryKeys'

export const useGetFulfillmentOrders = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.fulfillmentOrders.list(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load fullfillment orders',
            pathname: useGetPathname(),
            functionName: 'useGetFullfillmentOrders',
        },
    })
}
export const useGetOrderList = (filters: any, enabled: boolean = true) => {
    return useQuery({
        ...queryKeys.orders.list(filters),
        enabled: Boolean(enabled),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load orders',
            pathname: useGetPathname(),
            functionName: 'useGetOrderList',
        },
    })
}
export const useGetOrder = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.orders.detail(orderId),
        enabled: Boolean(orderId),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load order',
            pathname: useGetPathname(),
            functionName: 'useGetOrder',
        },
    })
}
export const useGetOrderEventsPrivate = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.orders.detail(orderId)._ctx.events(),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load order events',
            pathname: useGetPathname(),
            functionName: 'useGetOrderEventsPrivate',
        },
    })
}
export const useGetOrderTags = () => {
    return useQuery({
        ...queryKeys.orders.tags(),
        meta: {
            clientErrMessage: 'Failed to load order tags',
            pathname: useGetPathname(),
            functionName: 'useGetOrderTags',
        },
    })
}
export const useGetReturns = (orderNumber: number | undefined) => {
    return useQuery({
        ...queryKeys.returns.list(orderNumber),
        enabled: Boolean(orderNumber),
        meta: {
            clientErrMessage: 'Failed to load returns',
            pathname: useGetPathname(),
            functionName: 'useGetReturns',
        },
    })
}
export const useGetPurchaseOrders = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.purchaseOrders.list(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load purchase orders',
            pathname: useGetPathname(),
            functionName: 'useGetPurchaseOrders',
        },
    })
}
export const useGetTimeLineEvents = (orderId: string | undefined) => {
    return useQuery({
        ...queryKeys.orders.timeLineEvents(orderId),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load timeline events',
            pathname: useGetPathname(),
            functionName: 'useGetTimeLineEvents',
        },
    })
}
export const useGetOrderTaskEvents = (taskIds: string[]) => {
    return useQuery({
        ...queryKeys.orders.orderTaskEvents(taskIds),
        enabled: Boolean(taskIds?.length > 0),
        meta: {
            clientErrMessage: 'Failed to load task events',
            pathname: useGetPathname(),
            functionName: 'useGetOrderTaskEvents',
        },
    })
}
export const useGetOrderEmails = (orderId: string | undefined, purchaseOrderIds: number[]) => {
    return useQuery({
        ...queryKeys.orders.emails(orderId, purchaseOrderIds),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load emails',
            pathname: useGetPathname(),
            functionName: 'useGetOrderEmails',
        },
    })
}
export const useGetEmailAttachments = (orderId: string | undefined, table: string) => {
    return useQuery({
        ...queryKeys.orders.attachments(orderId, table),
        enabled: Boolean(orderId),
        meta: {
            clientErrMessage: 'Failed to load attachments',
            pathname: useGetPathname(),
            functionName: 'useGetEmailAttachments',
        },
    })
}

// MUTATIONS

export function useCreateNote() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            orderId,
            body,
            origin,
        }: {
            orderId: string | undefined
            body: any
            origin: 'order' | 'draft_order'
        }) => {
            return service.createNote(orderId, body, origin)
        },
        meta: {
            clientErrMessage: 'Failed to create note',
            pathname: useGetPathname(),
            functionName: 'useCreateNote',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: queryKeys.orders.detail._def })
            queryClient.invalidateQueries({ queryKey: draftOrderQueryKeys.draftOrders.detail._def })
        },
    })
}
export function useUpdateTags() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            orderId,
            body,
            origin,
            newTag,
        }: {
            orderId: string | undefined
            body: any
            origin: 'order' | 'draft_order'
            newTag: string
        }) => {
            return service.updateTags(orderId, body, origin)
        },
        meta: {
            clientErrMessage: 'Failed to create tag',
            pathname: useGetPathname(),
            functionName: 'useCreateTag',
        },
        onSuccess: (data, variables, context) => {},
    })
}
