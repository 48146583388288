import { useNavigate, useSearchParams } from 'react-router-dom'
import CreateTaskModal from '../modals/CreateTaskModal'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { useSelector } from 'react-redux'
import { useGetOrder } from 'orderHubScreen/api/useQueries'
import { useGetDraftOrder } from 'orderHubScreenDraft/api/useQueries'

export default function ChromeExtensionRedirect() {
    const user = useSelector((state: any) => state.user)
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('order_id') || ''
    const orderQuery = useGetOrder(orderId)
    const foundOrder = orderQuery.data?.order || {}
    const draftOrderId = searchParams.get('draft_order_id') || ''
    const draftOrderQuery = useGetDraftOrder(draftOrderId)
    const foundDraftOrder = draftOrderQuery.data?.draft_order || {}
    const taskType = searchParams.get('task_type')
    const departmentsQuery = useGetAllDepartmentConfigs(user.id)
    const departments: any[] = departmentsQuery.data?.departments || []
    const foundDepartment = departments.find((dept) => dept.title === taskType) || {}
    const redirectUrl = orderId
        ? `/orders/${orderId}`
        : draftOrderId
          ? `/orders/draft-orders/${draftOrderId}`
          : '/tasks'
    const navigate = useNavigate()
    return (
        <>
            <CreateTaskModal
                key={`${foundDepartment.id}-${foundOrder?.id}-${foundDraftOrder?.id}`}
                closeModal={() => navigate(redirectUrl)}
                prePopulatedValues={{
                    department_id: foundDepartment.id,
                    associations: [
                        {
                            resource_name: 'user',
                            resource_id: user.id,
                            readable_name: `${user.firstName} ${user.lastName}`,
                            locked: true,
                        },
                        orderId || draftOrderId
                            ? {
                                  resource_name: orderId ? 'order' : 'draft_order',
                                  resource_id: orderId || draftOrderId,
                                  readable_name: foundOrder?.name || foundDraftOrder?.name,
                                  locked: true,
                              }
                            : null,
                    ].filter((v) => v),
                }}
            />
        </>
    )
}
