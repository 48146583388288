import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import { Button } from '../custom_components/component_Basics/Button'
import { SkeletonBasic } from '../custom_components/component_Basics/SkeletonBasic'
import { vFetch } from '../helpers'
import PrivateEvent from '../tasks/components/PrivateEvent'
import PrivateEventModal from '../tasks/components/PrivateEventModal'
import { PrivateEventType } from '../tasks/tasks.types'
import { productSetupQueries } from './api/productSetupQueryKeys'
import { useGetProductSetupEvents, useGetProductVariantsQuery } from './api/useQueries'
import AreYouSure from './components/AreYouSure'
import ProductMenu from './components/ProductMenu'
import ProductsAllPrivateEventModal from './components/ProductsAllPrivateEventModal'
import ProductSelect from './components/ProductSelect'
import { MetafieldUpdateItem, SetupEventCreateType, ShopifyProduct } from './ProductSetup.types'
import Checkmarks from './steps/Checkmarks'
import CrossSells from './steps/CrossSells'
import Features from './steps/Features'
import Filters from './steps/Filters'
import Group from './steps/Group'
import Images from './steps/Images'
import Manuals from './steps/Manuals'
import Plugs from './steps/Plugs'
import Preview from './steps/Preview'
import Product from './steps/Product'
import ProductSetupEvents from './steps/ProductSetupEvents'
import Specifications from './steps/Specifications'
import Tags from './steps/Tags'
import VariantsV2 from './steps/VariantsV2'
import Warranty from './steps/Warranty'
import './styles/description.css'
import { useAllUsersQuery } from 'users/api/useQueries'

export default function ProductSetupV2() {
    const analytics = useAnalytics()
    const user = useSelector<any, any>((state) => state.user)
    const [_searchParams, setSearchParams] = useSearchParams()
    const { productId: productIdParam } = useParams()
    const [initialProduct, setInitialProduct] = useState<ShopifyProduct>({})
    const [product, setProduct] = useState<ShopifyProduct>({})
    const [step, setStep] = useState('product')
    const [edited, setEdited] = useState(false)
    const [savedStep, setSavedStep] = useState('')
    const [status, setStatus] = useState('pending')
    const [showHistory, setShowHistory] = useState(false)
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const [productSetupEventsPrivate, setProductSetupEventsPrivate] = useState<PrivateEventType[]>([])
    const [latestProductSetupEventsPrivate, setLatestProductSetupEventsPrivate] = useState<PrivateEventType[]>([])
    const [privateEventLogged, setPrivateEventLogged] = useState(false)
    const [showProductSetupEventsPrivate, setShowProductSetupEventsPrivate] = useState(false)
    const [showAllProductsSetupEventsPrivate, setShowAllProductsSetupEventsPrivate] = useState(false)
    const usersQuery = useAllUsersQuery()
    const userList = usersQuery.data?.users || []
    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname

    // seed cache for variants tab
    useGetProductVariantsQuery(product.id)

    const setupEventsQuery = useGetProductSetupEvents(productIdParam)
    const { events } = setupEventsQuery?.data || {}

    useEffect(() => {
        if (Object.entries(product).length && !privateEventLogged) {
            analytics.page()
        }
    }, [product])

    useEffect(() => {
        const sortedProduct = JSON.stringify(product)
        const sortedInitialProduct = JSON.stringify(initialProduct)
        if (sortedProduct !== sortedInitialProduct) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [product, initialProduct])

    useEffect(() => {
        vFetch(`/v1/productSetupEventsPrivate/latest`, {
            cb: (res: any) => {
                return setLatestProductSetupEventsPrivate(res.productSetupEventsPrivate)
            },
        })
    }, [product.id])
    const handleStepChange = (step: string, continueWithoutSaving: boolean) => {
        if (edited && !continueWithoutSaving) {
            setSavedStep(step)
        } else {
            setProduct(() => structuredClone(initialProduct))
            setSavedStep('')
            setStep(step)
        }
    }

    const handleFetchProduct = (productId?: number) => {
        if (productId) {
            setStatus('loading-product')
            if (!productIdParam) {
                setSearchParams((prev: any) => {
                    prev.set('product_id', productId)
                    return prev
                })
            }

            vFetch(`/v1/productSetupEventsPrivate?product_id=${productId}`, {
                cb: (res: any) => {
                    return setProductSetupEventsPrivate(res.productSetupEventsPrivate)
                },
            })

            vFetch(`/v2/products?id=${productId}`, {
                cb: (res: any) => {
                    const currentProduct = res.products[0]
                    if (!res.success) {
                        return
                    }
                    setStatus('loaded')
                    setProduct({
                        ...currentProduct,
                        checkmarks: JSON.parse(currentProduct.custom_checkmarks || '[]'),
                        plugs: JSON.parse(currentProduct.custom_plugs || '[]'),
                        features: JSON.parse(currentProduct.custom_features || '[]'),
                        specifications: JSON.parse(currentProduct.custom_specifications || '[]'),
                        package_contents: JSON.parse(currentProduct.custom_package_contents || '[]'),
                        warranty: currentProduct.custom_warranty,
                        manuals: JSON.parse(currentProduct.custom_manuals || '[]'),
                        cross_sells: JSON.parse(currentProduct.custom_cross_sells || '[]'),
                        custom_options: JSON.parse(currentProduct.custom_options || '[]'),
                    })
                    setInitialProduct({
                        ...currentProduct,
                        checkmarks: JSON.parse(currentProduct.custom_checkmarks || '[]'),
                        plugs: JSON.parse(currentProduct.custom_plugs || '[]'),
                        features: JSON.parse(currentProduct.custom_features || '[]'),
                        specifications: JSON.parse(currentProduct.custom_specifications || '[]'),
                        package_contents: JSON.parse(currentProduct.custom_package_contents || '[]'),
                        warranty: currentProduct.custom_warranty,
                        manuals: JSON.parse(currentProduct.custom_manuals || '[]'),
                        cross_sells: JSON.parse(currentProduct.custom_cross_sells || '[]'),
                        custom_options: JSON.parse(currentProduct.custom_options || '[]'),
                    })
                    if (!privateEventLogged) {
                        if (user && productId) {
                            vFetch(`/v1/productSetupEventsPrivate`, {
                                skipToast: true,
                                method: 'POST',
                                body: JSON.stringify({
                                    product_id: productId,
                                    user_id: user.id,
                                    event_type: 'viewed',
                                }),
                                cb: (res: any) => {
                                    if (res.success) {
                                        setPrivateEventLogged(true)
                                        queryClient.invalidateQueries({
                                            queryKey: productSetupQueries.productSetup.privateEvents._def,
                                        })
                                    }
                                },
                            })
                        }
                    }
                },
            })
        }
    }

    const handleUpdateProduct = (changedFields: string[]) => {
        vFetch('/v2/descriptions', {
            method: 'POST',
            body: JSON.stringify({
                id: product.id,
                body_html: product.body_html,
                product_type: product.product_type,
                status: product.status,
                title: product.title,
                vendor: product.vendor,
                supplier_id: product.supplier_id,
                meta_title: product.meta_title,
                meta_description: product.meta_description,
                handle: product.handle,
                metafields: [
                    {
                        ownerId: `gid://shopify/Product/${product.id}`,
                        namespace: 'custom',
                        key: 'custom_note',
                        value: product.custom_custom_note,
                    },
                    {
                        ownerId: `gid://shopify/Product/${product.id}`,
                        namespace: 'custom',
                        key: 'not_carb_compliant',
                        value: product.custom_not_carb_compliant?.toString(),
                    },
                ],
            }),
            cb: (res: any) => {
                if (res.success) {
                    let events: SetupEventCreateType[] = []
                    changedFields.forEach((field: string) => {
                        const value = product[field as keyof typeof product]
                        const previousValue = initialProduct[field as keyof typeof initialProduct]
                        if (!(value === previousValue)) {
                            events.push({
                                step: 'product',
                                field,
                                updated_value: value ? value : '',
                                previous_value: previousValue ? previousValue : '',
                                product_id: product.id,
                                user_id: user.id,
                            })
                        }
                    })
                    vFetch(`/v1/productSetupEvents`, {
                        method: 'POST',
                        body: JSON.stringify({
                            events: events,
                        }),
                    })
                }
                queryClient.invalidateQueries({
                    queryKey: productSetupQueries.productSetup.productSetupEvents._def,
                })
                handleFetchProduct(product.id)
            },
        })
    }

    const handleUpdateMetafield = (metafields: MetafieldUpdateItem[], skipToast?: boolean) => {
        let events: SetupEventCreateType[] = []
        let value: any
        let previousValue: any
        if (metafields[0].key === 'cross_sells') {
            value = JSON.stringify(metafields[0].crossSellTitles) || ['']
            previousValue = JSON.stringify(metafields[0].oldCrossSellTitles)
        } else {
            const key = `custom_${metafields[0].key}`
            value = metafields[0].value
            previousValue = initialProduct[key as keyof typeof initialProduct]
        }
        if (!(value === previousValue)) {
            events.push({
                step: metafields[0].key,
                field: metafields[0].key,
                updated_value: value,
                previous_value: previousValue ? previousValue : '',
                product_id: product.id,
                user_id: user.id,
            })
        }
        vFetch(`/v1/productSetupEvents`, {
            method: 'POST',
            body: JSON.stringify({
                events: events,
            }),
        })
        delete metafields[0].crossSellTitles
        delete metafields[0].oldCrossSellTitles
        vFetch('/v2/descriptions/metafields', {
            method: 'POST',
            body: JSON.stringify({ metafields }),
            cb: () => {
                queryClient.invalidateQueries({
                    queryKey: productSetupQueries.productSetup.productSetupEvents._def,
                })
                handleFetchProduct(product.id)
            },
            skipToast,
        })
    }

    useEffect(() => {
        if (productIdParam) {
            handleFetchProduct(parseInt(productIdParam))
        }
    }, [productIdParam])

    function getRecentlyViewed() {
        const filteredEvents = latestProductSetupEventsPrivate.filter(
            (event) => event.user_id == user.id || event.user_id === user.user_id
        )
        const uniqueProductids = new Set(filteredEvents.map((event) => event.product_id))
        const recentlyViewedProducts: any = []
        Array.from(uniqueProductids).some((id, index) => {
            recentlyViewedProducts.push(filteredEvents.find((event) => event.product_id === id))
            return index === 9
        })
        return recentlyViewedProducts.map((product: any) => {
            return {
                id: product.product_id,
                title: product.title,
            }
        })
    }

    const recentlyViewedProducts = getRecentlyViewed()

    return (
        <>
            <div className='flex flex-col gap-3'>
                <div>
                    {productSetupEventsPrivate && showAllProductsSetupEventsPrivate && (
                        <ProductsAllPrivateEventModal
                            setPrivateEventLogged={setPrivateEventLogged}
                            origin='product'
                            userList={userList}
                            setViewModal={setShowAllProductsSetupEventsPrivate}
                            events={latestProductSetupEventsPrivate}
                        />
                    )}
                    {productSetupEventsPrivate && showProductSetupEventsPrivate && (
                        <PrivateEventModal
                            origin='product'
                            setViewModal={setShowProductSetupEventsPrivate}
                            events={productSetupEventsPrivate}
                        />
                    )}
                    <div className='flex justify-between items-center'>
                        <div className='mb-2 flex flex-col'>
                            <div className='flex gap-2'>
                                <span
                                    onClick={() => {
                                        if (previousLocationPathname?.includes('products')) {
                                            navigate(-1)
                                            return
                                        }
                                        navigate('/products')
                                    }}
                                    className='self-center cursor-pointer'
                                >
                                    <RiArrowGoBackLine />
                                </span>
                                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize w-[100%]'>
                                    Product Setup
                                </h1>
                            </div>
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') &&
                                productSetupEventsPrivate.length > 0 && (
                                    <PrivateEvent
                                        events={productSetupEventsPrivate}
                                        setViewModal={setShowProductSetupEventsPrivate}
                                    />
                                )}
                        </div>
                        <div className='flex gap-1'>
                            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                <button
                                    className='whitespace-nowrap py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite text-[12px] font-bold uppercase'
                                    onClick={() => setShowAllProductsSetupEventsPrivate(true)}
                                >
                                    Latest Events
                                </button>
                            )}
                            <Link
                                className='whitespace-nowrap py-[2px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite text-[12px] font-bold uppercase'
                                to={`https://admin.shopify.com/store/factorypure/products/${product.id}`}
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                View on Shopify
                            </Link>
                        </div>
                    </div>
                    <ProductMenu
                        step={step}
                        handleStepChange={handleStepChange}
                        productIsSelected={!!product.id}
                        showHistory={showHistory}
                        setShowHistory={setShowHistory}
                    />
                    <div className={`${product.id ? '' : 'shadow-small'}`}>
                        <ProductSelect
                            product={product}
                            setProduct={setProduct}
                            status={status}
                            setStatus={setStatus}
                            handleFetchProduct={handleFetchProduct}
                        />
                        {product.id && (
                            <>
                                <div className={`grid ${showHistory ? 'grid-cols-[66%_33%]' : 'grid-cols-1'} gap-2`}>
                                    <div>
                                        {step === 'product' && (
                                            <Product
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateProduct={handleUpdateProduct}
                                                edited={edited}
                                                setEdited={setEdited}
                                            />
                                        )}
                                        {step === 'images' && product.id && <Images product={product} />}
                                        {step === 'tags' && product.id && <Tags product={product} />}
                                        {step === 'identifiers' && product.id && (
                                            <VariantsV2
                                                product_id={product.id}
                                                showHistory={showHistory}
                                                setEdited={setEdited}
                                            />
                                        )}
                                        {step === 'checkmarks' && (
                                            <Checkmarks
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'plugs' && (
                                            <Plugs
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'features' && (
                                            <Features
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'specifications' && (
                                            <Specifications
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'warranty' && (
                                            <Warranty
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'manuals' && (
                                            <Manuals
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'cross-sells' && (
                                            <CrossSells
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                        {step === 'filters' && <Filters product={product} />}
                                        {step === 'preview' && <Preview product={product} />}
                                        {step === 'group' && (
                                            <Group
                                                product={product}
                                                setProduct={setProduct}
                                                handleUpdateMetafield={handleUpdateMetafield}
                                                edited={edited}
                                            />
                                        )}
                                    </div>
                                    {showHistory && (
                                        <ProductSetupEvents
                                            userList={userList}
                                            events={events}
                                            product={product}
                                            setShowHistory={setShowHistory}
                                            step={step}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!product.id && (
                    <div className='flex flex-col gap-2'>
                        <h1 className='text-[18px] font-semibold dark:text-offwhite capitalize w-[100%]'>
                            Recently Visited
                        </h1>
                        <div className={`flex justify-between items-center p-2 dark:bg-darkaccent shadow-small`}>
                            <ul className='flex relative flex-col max-w-[100%] pt-[8px] list-none z-[0]'>
                                {recentlyViewedProducts.length < 1 &&
                                    [1, 2, 3, 4, 5].map((skeleton: any) => {
                                        return (
                                            <li className='flex gap-[10px] self-start '>
                                                <div
                                                    className={`relative top-0 bg-accent1 dark:bg-darkaccent1 border-[5px] border-bg1 dark:border-darkbg1 rounded-full shrink-0 h-[18px]  w-[18px] z-index-2 self-center`}
                                                ></div>
                                                <SkeletonBasic className='font-bold min-w-[400px] items-start justify-start m-2 text-accent2 dark:text-darkaccent2 p-2'></SkeletonBasic>
                                            </li>
                                        )
                                    })}
                                {recentlyViewedProducts.map((product: any) => {
                                    return (
                                        <li className='flex gap-[10px] self-start'>
                                            <div
                                                className={`relative top-0 bg-accent1 dark:bg-darkaccent1 border-[5px] border-bg1 dark:border-darkbg1 rounded-full shrink-0 h-[18px]  w-[18px] z-index-2 self-center`}
                                            ></div>
                                            <Button
                                                onClick={() => {
                                                    setPrivateEventLogged(false)
                                                    return setSearchParams((prev: any) => {
                                                        prev.set('product_id', product.id)
                                                        return prev
                                                    })
                                                }}
                                                variant={'link'}
                                                className={`leading-[17.5px] text-[14px] font-normal `}
                                            >
                                                {product.title}
                                            </Button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            {savedStep && (
                <AreYouSure savedStep={savedStep} setSavedStep={setSavedStep} handleStepChange={handleStepChange} />
            )}
        </>
    )
}
