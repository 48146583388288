export default function TaxExemptionAudit({ taxExemptionAudit }: { taxExemptionAudit: [] }) {
    const auditDateFormatter = new Intl.DateTimeFormat('en-US', {
        // weekday: 'short',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        // hour: 'numeric',
        // minute: 'numeric',
        // second: 'numeric',
        // timeZoneName: 'short',
    })

    return (
        <div className='text-sm font-bold w-full'>
            <div className='border rounded border-lightgrey dark:border-darkgrey mb-4'>
                <div className='grid grid-cols-7 gap-2 text-xs font-bold bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness w-full'>
                    <h2 className='col-span-1 p-2'>User</h2>
                    <h2 className='col-span-1 p-2'>Edited</h2>
                    <h2 className='col-span-2 p-2'>Old Value</h2>
                    <h2 className='col-span-2 p-2'>New Value</h2>
                    <h2 className='col-span-1 p-2'>Date</h2>
                </div>
                {taxExemptionAudit.map((audit: any, index: any) => (
                    <div key={audit.id}>
                        <div
                            className={`font-normal py-1 grid grid-cols-7 gap-2 items-center relative after:w-[2px] after:h-full after:bg-slate after:absolute after:top-0 after:left-[5px] after:z-[-1] border border-b-lightgrey dark:border-b-darkgrey`}
                        >
                            <p className='col-span-1 px-2'>{audit.user_alias}</p>
                            <p className='col-span-1 px-2 capitalize'>
                                {audit.edited_fields === 'exemption_type'
                                    ? 'type'
                                    : audit.edited_fields === 'status'
                                      ? 'status'
                                      : audit.edited_fields === 'exemption_type,status'
                                        ? 'Type, Status'
                                        : audit.edited_fields}
                            </p>
                            <p className='col-span-2 px-2'>
                                {audit.edited_fields === 'exemption_type,status' &&
                                    (audit.old_value.exemption_type != null
                                        ? audit.old_value.exemption_type.split('_').join(' ')
                                        : 'N/A') +
                                        (audit.old_value.exemption_type != null || audit.old_value.exemption_type != ''
                                            ? ', '
                                            : '') +
                                        (audit.old_value.status != null
                                            ? audit.old_value.status.split('_').join(' ')
                                            : audit.old_value.status)}

                                {audit.edited_fields === 'exemption_type' &&
                                    (audit.old_value.exemption_type != null
                                        ? audit.old_value.exemption_type.split('_').join(' ')
                                        : '')}

                                {audit.edited_fields === 'status' &&
                                    (audit.old_value.status != null ? audit.old_value.status.split('_').join(' ') : '')}

                                {audit.edited_fields != 'exemption_type' &&
                                    audit.edited_fields != 'status' &&
                                    audit.edited_fields != 'exemption_type,status' &&
                                    'N/A'}
                            </p>
                            <p className='col-span-2 px-2'>
                                {audit.edited_fields === 'exemption_type,status' &&
                                    (audit.new_value.exemption_type != null
                                        ? audit.new_value.exemption_type.split('_').join(' ')
                                        : audit.new_value.exemption_type) +
                                        (audit.new_value.exemption_type != null && audit.new_value.status != null
                                            ? ', '
                                            : '') +
                                        (audit.new_value.status != null
                                            ? audit.new_value.status.split('_').join(' ')
                                            : audit.new_value.status)}

                                {audit.edited_fields === 'exemption_type' &&
                                    (audit.new_value.exemption_type != ''
                                        ? audit.new_value.exemption_type.split('_').join(' ')
                                        : '')}

                                {audit.edited_fields === 'status' &&
                                    (audit.new_value.status != '' ? audit.new_value.status.split('_').join(' ') : '')}

                                {audit.edited_fields != 'exemption_type' &&
                                    audit.edited_fields != 'status' &&
                                    audit.edited_fields != 'exemption_type,status' &&
                                    'N/A'}
                            </p>
                            <p className='col-span-1 px-2'>{auditDateFormatter.format(new Date(audit.created_at))}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
