import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { ErrorBoundary } from '../helpers'
import KnowledgeScreen from '../knowledge/KnowledgeScreen'
import KnowledgeScreenFallback from '../knowledge/KnowledgeScreenFallback'
import { setSettings } from '../redux/settings'
import './Layout.css'
import Popout from './Popout'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

export default function Layout({ children, access, submenu, currentStoreId, setCurrentStoreId }) {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const knowledgeScreen = searchParams.get('knowledgeScreen')
    const { showKnowledgeScreen } = useSelector((state) => state.settings)

    useEffect(() => {
        if (knowledgeScreen === 'Open') {
            dispatch(setSettings({ showKnowledgeScreen: true }))
        }
    }, [knowledgeScreen])

    return (
        <>
            <Topbar currentStoreId={currentStoreId} setCurrentStoreId={setCurrentStoreId} />
            <Sidebar access={access} submenu={submenu} />
            <main className='w-full h-full px-8 py-6 pb-0 relative text-black dark:text-offwhite'>{children}</main>
            <Popout />
            {showKnowledgeScreen && (
                <ErrorBoundary fallback={<KnowledgeScreenFallback />}>
                    <KnowledgeScreen />
                </ErrorBoundary>
            )}
        </>
    )
}
