import { useDebounce } from 'helpers'
import { useSearchCompanies } from 'procurement/api/useQueries'
import { useState } from 'react'

export default function CompanyAssociationBuilder({
    associations,
    toggleAssociation,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    let params: any = {
        filters: {
            user_ids: [],
            statuses: [],
            due_at_options: [],
            assigned_to: [],
            lead_type: [],
            lifecycle_status: [],
            pageParam: 1,
        },
    }
    const companiesSearchQuery = useSearchCompanies({ ...params, search: debouncedSearch })
    const { companies } = companiesSearchQuery?.data || {}

    return (
        <div>
            <h2 className='font-bold mb-2'>Choose Companies To Associate</h2>
            <input
                className='focus:outline-none py-1 px-2 w-full border border-lightgrey dark:bg-darkbg1 dark:border-darkgrey rounded'
                id='search'
                name='search'
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                placeholder='Search for a company...'
            />
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {companiesSearchQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {companies?.map((company: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'company',
                                        resource_id: company.id,
                                        readable_name: company.shopify_vendor_name || company.name,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'company' &&
                                                association.resource_id === company.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>{company.name}</span>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
